import { normalize } from 'normalizr';
import { Dispatch } from 'redux';

type State = any;
type Store = { dispatch: Dispatch; getState: () => State };
type Middleware = (store: Store) => (next: Dispatch) => Dispatch;

const normalizeMiddleware: Middleware = (store) => (next) => (action) => {
  const normalizePayload = action.meta && action.meta.normalize;

  if (action.type.endsWith('_DONE') && normalizePayload !== undefined) {
    const normalizedData = normalize(action.payload.result, normalizePayload.schema);

    action.payload.result.ids = normalizedData.result;
    action.payload.result.byId = normalizedData.entities[normalizePayload.entityName];
  }

  return next(action);
};

export default normalizeMiddleware;
