import React, { useEffect } from 'react';
import LoadingScreen from '../../../Common/LoadingScreen';
import { useGetApiEffect } from '../../../Hooks/Hooks';
import styled from 'styled-components';

const LogContainer = styled.div`
  height: 600px;
  width: 100%;
  border: 1px solid;
  overflow: auto;
  white-space: pre-wrap;
  padding: 6px;
  font-size: 0.9rem;
  margin-bottom: 12px;
`;
interface IProps {
  customerId: string;
  fileName: string;
}

type Props = IProps;

const EventLogDisplay: React.FC<Props> = (props) => {
  const { customerId, fileName } = props;

  const [data, isLoading, isError, getData] = useGetApiEffect<string>(
    `api/logs/${customerId}/event?fileName=${fileName}`,
    null,
  );

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isError && <div>An error occurred.</div>}
      {data && <LogContainer>{data}</LogContainer>}
    </>
  );
};

export default EventLogDisplay;
