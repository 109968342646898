// General
import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// ChartWrapper helpers
import '../../ChartWrapperStyles.css';
import { IChartBoxProps } from '../../ChartWrapperTypes';
import ChartBoxButtons from './ChartBoxButtons';
import ChartBoxContent from './ChartBoxContent';

/**
 * The blue, non-fullscreen card window that displays the
 * title, minimize/fullscreen buttons and the chart component itself.
 *
 * Contains the header component (ChartBoxButtons) and the actual content component (ChartBoxContent).
 */
const ChartBox = (props: IChartBoxProps) => {
  const { title, chart, minimized, state, onMinimize, onFullscreen, disableFullscreen } = props;
  return (
    <Card className="CardRoot">
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">{title}</Typography>
          <ChartBoxButtons
            state={state}
            onMinimize={onMinimize}
            onFullscreen={onFullscreen}
            disableFullscreen={disableFullscreen}
          />
        </Box>
      </CardContent>
      <Collapse in={!minimized}>
        <CardContent>
          <ChartBoxContent chart={chart} state={state} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ChartBox;
