import { Theme } from '@material-ui/core';

const MuiDivider = (theme: Theme) => {
  return {
    MuiDivider: {
      root: {
        margin: [[theme.spacing(2), 0]],
      },
    },
  };
};

export default MuiDivider;
