import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Link } from '@reach/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logoutUserActionCreator } from '../../redux/actions/user';
import { logout } from '../../services/auth/authService';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IProps {
  userName: string;
}

type Props = IProps;

const UserMenu: React.FC<Props> = (props: Props) => {
  const { userName } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setanchorEl] = useState<Element | null>(null);

  const handleLogout = () => {
    setanchorEl(null);
    logout();
    dispatch(logoutUserActionCreator());
  };

  return (
    <div className={classes.container}>
      <IconButton
        aria-owns={Boolean(anchorEl) ? 'menu-user' : undefined}
        aria-haspopup="true"
        onClick={(e) => setanchorEl(e.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>

      <Menu
        disableAutoFocusItem
        id="menu-user"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setanchorEl(null)}
      >
        <MenuItem>{userName}</MenuItem>
        <Link to={'/user'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={(e) => setanchorEl(null)}>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={t('Profile')} />
          </MenuItem>
        </Link>
        <MenuItem onClick={(e) => handleLogout()}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={t('Log out')} />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
