import { UserAgentApplication, AuthResponse, Logger, LogLevel } from 'msal';
import msalConfig from '../../configs/msalConfig';

const { instance, tenant, clientId, postLogoutRedirectUri, cacheLocation, redirectUri } = msalConfig;

export const msalApp = new UserAgentApplication({
  auth: {
    clientId,
    authority: instance + tenant,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri,
    redirectUri,
  },
  cache: {
    cacheLocation,
    storeAuthStateInCookie: true,
  },
  system: {
    logger: new Logger(
      (level: LogLevel, msg: string) => {
        if (level === LogLevel.Error) {
          console.error(msg);
        } else {
          console.warn(msg);
        }
      },
      { level: LogLevel.Warning, piiLoggingEnabled: false },
    ),
  },
});

export class AcquireTokenError extends Error {
  constructor() {
    super('Failed to acquire access token for back-end API');
    this.name = 'acquire_token_failed';
  }
}

const getApiScopes = () => {
  // constructs scopes e.g. api-client-id/Something.Read
  // this is the expected format by AAD v2
  const apiId = msalConfig.serviceClientId;
  const scopes = msalConfig.serviceScopes;
  return scopes.map((s: string) => `${apiId}/${s}`);
};

export const fetchServiceToken = async () => {
  try {
    const response: AuthResponse = await msalApp.acquireTokenSilent({
      scopes: getApiScopes(),
    });

    return response.accessToken;
  } catch (e) {
    throw new AcquireTokenError();
  }
};

export const login = () => {
  msalApp.loginRedirect({
    scopes: getApiScopes(),
  });
};

export const getUser = () => {
  return msalApp.getAccount();
};

export const logout = () => {
  msalApp.logout();
};
