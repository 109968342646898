import React from 'react';
import FormTable, { IFormTableExtraProps } from '../../FormFrames/FormTable';
import { ITask } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import AddTask from './Task/AddTask';
import { ISchedule, IScheduleState } from './Schedule/ScheduleTypes';
import { shallowEqual, useSelector } from 'react-redux';
import { getAllSchedules } from '../../../redux/reducers';
import moment from 'moment';
import { deleteTask } from '../../../redux/reducers/tasks';
import { useAppDispatch } from '../../Hooks/StoreHooks';
import { toast } from 'react-toastify';
import { createApiMessage } from '../../../redux/actions/apiMessage';

interface IProps {
  customerId: string;
  data: ITask[];
  isLoading: boolean;
  selectedItem?: ITask;
  onSelectItem: (id: number | string) => void;
}

const getAddComponent: (customerId: string) => (props: { values?: ITask; onCancel: () => void }) => React.ReactNode = (
  customerId,
) => {
  return (props) => {
    return <AddTask customerId={customerId} data={props.values} onCancel={props.onCancel} />;
  };
};

type Props = IProps;

const TaskSelector: React.FC<Props> = (props) => {
  const { customerId, data, selectedItem, onSelectItem, isLoading } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const schedules = useSelector<IScheduleState, ISchedule[]>(
    (state: any) => getAllSchedules(state, customerId),
    shallowEqual,
  );

  const tableData: (ITask & IFormTableExtraProps)[] = data.map((d) => ({
    ...d,
    id: d.task_guid,
    status:
      moment().diff(moment(schedules?.find((schedule) => schedule.task_id === d.task_guid)?.next_run), 'minutes') < 0
        ? t('label-scheduled')
        : t('label-unscheduled'),
  }));

  const handleItemChange = (id: number | string) => {
    onSelectItem(id);
  };

  const handleRenderAdd: (duplicate: boolean, onCancel: () => void) => React.ReactNode = (duplicate, onCancel) => {
    return getAddComponent(customerId)({ values: duplicate ? selectedItem : undefined, onCancel });
  };

  const handleDelete = () => {
    if (selectedItem) {
      dispatch(deleteTask(selectedItem))
        .unwrap()
        .then(() => {
          toast.success('Task deleted!');
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(createApiMessage(`customers/${customerId}/tasks/`, err.message));
        });
    }
  };

  return (
    <>
      <FormTable<ITask & IFormTableExtraProps & any>
        label={t('label-tasks')}
        data={tableData}
        properties={[
          { key: 'task_name', label: t('label-task-name') },
          { key: 'task_description', label: t('label-task-description') },
          { key: 'task_type', label: t('label-type') },
          { key: 'status', label: t('label-status') },
        ]}
        isLoading={isLoading}
        selectedId={selectedItem?.task_guid}
        onDelete={handleDelete}
        onSelectRow={handleItemChange}
        duplicateDisabled={true}
        render={handleRenderAdd}
      />
    </>
  );
};

export default TaskSelector;
