/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormTextArea, FormTextField } from '../../../FormFields';
import { useTranslation } from 'react-i18next';
import { IPermissionNew } from '../../../../types/types';

const validationSchema = yup.object<any>({
  name: yup.string(),
  description_en: yup.string(),
  description_fi: yup.string(),
});

const initialValue: IPermissionNew = {
  name: '',
  description_en: '',
  description_fi: '',
};

const AddPermission: React.FC<any> = (props) => {
  const { mutation, mutationFn, onCancel } = props;
  const { t } = useTranslation();
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(mutation.isLoading);
    }
    if (!isSubmitting && mutation.isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !mutation.isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [mutation.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (permission: IPermissionNew) => {
    mutationFn(permission);
  };

  return (
    <ModalFormFrame<any>
      label="label-form-add-permission"
      validationSchema={validationSchema}
      initialValues={initialValue}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField required={true} name="name" label={t('label-permission-name')} readonly={false} />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              required={true}
              name="description_fi"
              label={t('label-permission-description-fi')}
              readonly={false}
            />
            <FormTextArea
              required={true}
              name="description_en"
              label={t('label-permission-description-en')}
              readonly={false}
            />
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddPermission;
