import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Chart from './Chart';
import { SortablePane, Pane } from 'react-sortable-pane';

class ChartCard extends Component {
  render() {
    return (
      <div>
        <div style={{ minHeight: '500px', background: '#e6faff', height: '100%', display: 'block' }}>
          <h2>This is Sortable pane</h2>
          <SortablePane direction={'horizontal'} margin={10}>
            <Pane
              style={{
                border: '2px solid black',
                background: '#e6faff',
                height: '100%',
              }}
              id={0}
              key={0}
              minWidth={300}
              minHeight={300}
            >
              <Chart />
            </Pane>
            <Pane
              style={{
                border: '2px solid black',
                background: '#e6faff',
                height: '100%',
              }}
              id={1}
              key={1}
              minWidth={300}
              minHeight={300}
            >
              <Chart />
            </Pane>
          </SortablePane>
        </div>
        <div>
          <h2>This is Grid</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Chart />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <Chart />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default ChartCard;
