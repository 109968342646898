import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as yup from 'yup';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormCheckbox, FormTextArea, FormTextField } from '../../../FormFields';
import { isActionPending } from '../../../../redux/reducers';
import { ICollectionCreate, IState } from '../../../../types/types';
import { createCollectionApiRequest, CREATE_COLLECTION } from '../../../../redux/actions/collection';

interface IProps {
  data?: IFormObject;
  onCancel: () => void;
}

interface IFormObject {
  name: string;
  description: string;
  multilanguage: boolean;
}

const validationSchema = yup.object<IFormObject>({
  name: yup.string().required(),
  description: yup.string(),
  multilanguage: yup.boolean(),
});

type Props = IProps;

const AddCollection: React.FC<Props> = (props) => {
  const { data, onCancel } = props;

  const isLoading = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, CREATE_COLLECTION),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const formData: IFormObject = {
    name: data?.name || '',
    description: data?.description || '',
    multilanguage: data?.multilanguage || false,
  };

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = useCallback(
    (data, setIsSubmitting) => {
      setIsSubmittingFn(() => setIsSubmitting);

      const payload: ICollectionCreate = {
        name: data?.name || '',
        description: data?.description || '',
        multilanguage: data?.multilanguage || false,
      };

      dispatch(createCollectionApiRequest(payload));
    },
    [dispatch],
  );

  return (
    <ModalFormFrame<IFormObject>
      label="label_form_add_collection"
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ values, isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="name" label="Collection name" readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextArea name="description" label="Collection description" readonly={isSubmitting} />
            <FormCheckbox name="multilanguage" label="Multi language Collection" readonly={isSubmitting} />
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddCollection;
