import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import TabView from '../../Common/TabView';
import { ITabItem } from '../../Common/TabView/TabViewUtils';
import { BasicInfo, Billing, PrivX, Secrets, UsersAndRoles } from '../../Forms/Customers';
import { getAllCustomers, getSelectedCustomerId } from '../../../redux/reducers';
import { ICustomer, IState } from '../../../types/types';
import LoadingScreen from '../../Common/LoadingScreen';
import CheckUserCustomerPermissions from '../../../services/api/APIManagementQueries/Authorization/CheckUserCustomerPermissions';

const Customer = () => {
  const customerId = useSelector<IState, string>(getSelectedCustomerId, shallowEqual);
  const serviceProviderId = 'e96062f1-c6dd-4085-9884-780e29d94f27';
  const data = useSelector<IState, ICustomer[]>(getAllCustomers, shallowEqual);
  const {
    isLoading: isLoadingUserCustomerPermissions,
    isError: isErrorUserCustomerPermissions,
    data: userCustomerPermissions,
  } = CheckUserCustomerPermissions(customerId, serviceProviderId);

  const customer = data.find((d) => d.customer_guid === customerId);

  const tabs: ITabItem[] = customer
    ? [
        {
          displayName: 'menu-sub-basic-info',
          component: <BasicInfo customer={customer} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-billing',
          component: <Billing customer={customer} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-secrets',
          component: <Secrets customer={customer} userCustomerPermissions={userCustomerPermissions} />,
          roles: ['administrators'],
          userCustomerPermissions: userCustomerPermissions ? userCustomerPermissions : null,
        },
        {
          displayName: 'menu-sub-users-roles',
          component: <UsersAndRoles customer={customer} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-privx',
          component: <PrivX />,
          roles: ['administrators'],
        },
      ]
    : [];

  if (!!isLoadingUserCustomerPermissions) {
    return <LoadingScreen />;
  }

  return (
    <>
      {
        /**
         * 2.6.2022: Currently the problem with this approach is that if FetchOivaAuthorization() fails
         * or if the function returns a falsey value for the person who is making the query (e.g. undefined or empty array)
         * then the whole tab row under 'Customer management' is left unrendered.
         */
        !isErrorUserCustomerPermissions && !!userCustomerPermissions ? (
          <TabView tabs={tabs} selectedParentTab="customer" />
        ) : null
      }
    </>
  );
};

export default Customer;
