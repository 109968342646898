import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Box,
  Collapse,
  CardActions,
  CircularProgress,
  Grid,
  Tooltip as MUItooltip,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MinimizeIcon from '@material-ui/icons/Minimize';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import SetSelector from './SetSelector';
import DBarChart from './DBarChart';
import DLineChart from './DLineChart';
import DPieChart from './DPieChart';

type ComponentProps = {
  t: any;
  data: any;
  loading: boolean;
  minimized: boolean;
  handleMinimize: () => void;
  dataKeys: string[];
  legendLabel: string;
  windowTitle: string;
  xAxisLabel?: string;
  graphType: string;
  dataSetOptions?: string[];
  handleSetChange: (event: any) => void;
  selectedDataSet: any;
  handleOpenFullscreen: any;
  loopOptions?: any;
  handleLoopChange?: any;
  selectedLoop?: any;
  eventStatus: any;
  selectedStampType?: any;
  stampTypeOptions?: string[];
  handleSingleChange?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#d1ebfc',
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    radio: {
      '&$checked': {
        color: '#206fb7',
      },
    },
    checked: {},
    content: { maxHeight: '100%' },
  }),
);

const DatawindowFrame = (props: ComponentProps) => {
  const {
    t,
    data,
    minimized,
    handleMinimize,
    dataKeys,
    legendLabel,
    windowTitle,
    loading,
    xAxisLabel,
    graphType,
    handleSetChange,
    selectedDataSet,
    dataSetOptions,
    handleOpenFullscreen,
    loopOptions,
    handleLoopChange,
    selectedLoop,
    stampTypeOptions,
    selectedStampType,
    handleSingleChange,
  } = props;

  const classes = useStyles();

  const renderLoadingCard = () => {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h5">{windowTitle}</Typography>
            <CardActions>
              <MUItooltip title={t('label-minimize')}>
                <IconButton onClick={handleMinimize}>
                  <MinimizeIcon />
                </IconButton>
              </MUItooltip>
              <MUItooltip title={t('label-fullscreen')}>
                <IconButton onClick={handleOpenFullscreen}>
                  <AspectRatioIcon />
                </IconButton>
              </MUItooltip>
            </CardActions>
          </Box>
          <Collapse in={!minimized}>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center">
              <CardContent>
                <CircularProgress />
              </CardContent>
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  const renderContent = () => {
    if (!!!data || data === '' || data.length === 0) {
      return (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{windowTitle}</Typography>
              <CardActions>
                <MUItooltip title={t('label-minimize')}>
                  <IconButton onClick={handleMinimize}>
                    <MinimizeIcon />
                  </IconButton>
                </MUItooltip>
              </CardActions>
            </Box>
            <Collapse in={!minimized}>
              <CardContent style={{ height: '31vh' }}>
                <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                  <Typography>{t('label-no-data')}</Typography>
                </Grid>
              </CardContent>
            </Collapse>
          </CardContent>
        </Card>
      );
    } else if (!!dataSetOptions) {
      return (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{windowTitle}</Typography>
              <CardActions>
                <MUItooltip title={t('label-minimize')}>
                  <IconButton onClick={handleMinimize}>
                    <MinimizeIcon />
                  </IconButton>
                </MUItooltip>
                <MUItooltip title={t('label-fullscreen')}>
                  <IconButton onClick={handleOpenFullscreen}>
                    <AspectRatioIcon />
                  </IconButton>
                </MUItooltip>
              </CardActions>
            </Box>
            {!!selectedLoop ? <Typography variant="body1">({selectedLoop})</Typography> : null}
            {!!selectedStampType ? (
              <Typography variant="body1">({t(selectedStampType)})</Typography>
            ) : (
              <div style={{ height: '27.22px' }}></div>
            )}
            <Collapse in={!minimized}>
              <CardContent style={{ height: '100%' }}>
                <div style={{ height: '31vh' }}>
                  {graphType === 'bar' ? (
                    <DBarChart data={data} legendLabel={legendLabel} dataKeys={dataKeys} xAxisLabel={xAxisLabel} />
                  ) : null}
                  {graphType === 'line' ? (
                    <DLineChart data={data} legendLabel={legendLabel} dataKeys={dataKeys} xAxisLabel={xAxisLabel} />
                  ) : null}
                  {graphType === 'pie' ? (
                    <DPieChart data={data} legendLabel={legendLabel} dataKeys={dataKeys} xAxisLabel={xAxisLabel} />
                  ) : null}
                </div>

                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <SetSelector
                    dataSetOptions={dataSetOptions}
                    selectedDataSet={selectedDataSet}
                    handleSetChange={handleSetChange}
                  />
                  {!!loopOptions ? (
                    <SetSelector
                      dataSetOptions={loopOptions}
                      selectedDataSet={selectedLoop}
                      handleSetChange={handleLoopChange}
                      customTitle="label-stamp-type"
                    />
                  ) : null}
                  {!!stampTypeOptions ? (
                    <SetSelector
                      dataSetOptions={stampTypeOptions}
                      selectedDataSet={selectedStampType}
                      handleSetChange={handleSingleChange}
                      customTitle="label-stamp-type-picker"
                    />
                  ) : null}
                </Box>
              </CardContent>
            </Collapse>
          </CardContent>
        </Card>
      );
    } else if (!dataSetOptions) {
      return (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              style={{ paddingBottom: 'inherit' }}
            >
              <Typography variant="h5">{windowTitle}</Typography>
              <CardActions>
                <MUItooltip title={t('label-minimize')}>
                  <IconButton onClick={handleMinimize}>
                    <MinimizeIcon />
                  </IconButton>
                </MUItooltip>
                <MUItooltip title={t('label-fullscreen')}>
                  <IconButton onClick={handleOpenFullscreen}>
                    <AspectRatioIcon />
                  </IconButton>
                </MUItooltip>
              </CardActions>
            </Box>
            <Collapse in={!minimized}>
              <CardContent style={{ height: '36.4vh' }}>
                {graphType === 'bar' ? (
                  <DBarChart data={data} legendLabel={legendLabel} dataKeys={dataKeys} xAxisLabel={xAxisLabel} />
                ) : null}
                {graphType === 'line' ? (
                  <DLineChart data={data} legendLabel={legendLabel} dataKeys={dataKeys} xAxisLabel={xAxisLabel} />
                ) : null}
                {graphType === 'pie' ? (
                  <DPieChart data={data} legendLabel={legendLabel} dataKeys={dataKeys} xAxisLabel={xAxisLabel} />
                ) : null}
              </CardContent>
            </Collapse>
          </CardContent>
        </Card>
      );
    } else {
      return (
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{windowTitle}</Typography>
              <CardActions>
                <MUItooltip title={t('label-minimize')}>
                  <IconButton onClick={handleMinimize}>
                    <MinimizeIcon />
                  </IconButton>
                </MUItooltip>
              </CardActions>
            </Box>
            <Collapse in={!minimized}>
              <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                <CardContent>
                  <CircularProgress />
                </CardContent>
              </Grid>
            </Collapse>
          </CardContent>
        </Card>
      );
    }
  };

  return <>{!loading ? renderContent() : renderLoadingCard()}</>;
};

export default DatawindowFrame;
