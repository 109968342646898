import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ButtonGroupContainer from './ButtonGroupContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      background: '#2F518A',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
      '&:hover': {
        backgroundColor: '#4c75ba',
        color: '#FFF',
      },
    },
    buttonCancel: {
      marginLeft: theme.spacing(2),
      background: '#FFFFFF',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
    },
    disabled: {},
  }),
);
interface IProps {
  disabled: boolean;
  addDisabled?: boolean;
  id?: number | string;
  onAdd?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
}

type Props = IProps;

const AddDuplicateDelete: React.FC<Props> = (props) => {
  const { disabled, addDisabled, id, onAdd, onDuplicate, onDelete } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <ButtonGroupContainer>
        {onAdd && (
          <Button
            classes={{ root: classes.button, disabled: classes.disabled }}
            variant="contained"
            color="primary"
            role="add"
            disabled={disabled ? disabled : addDisabled}
            onClick={(e) => onAdd()}
          >
            {t('button_table_addnew')}
          </Button>
        )}

        {onDuplicate && (
          <Button
            classes={{ root: classes.buttonCancel, disabled: classes.disabled }}
            variant="outlined"
            color="primary"
            role="duplicate"
            disabled={disabled || !id || !onDuplicate}
            onClick={(e) => onDuplicate()}
          >
            {t('button_table_duplicate')}
          </Button>
        )}

        {onDelete && (
          <Button
            classes={{ root: classes.buttonCancel, disabled: classes.disabled }}
            variant="outlined"
            color="primary"
            role="delete"
            disabled={disabled || !id || !onDelete}
            onClick={(e) => onDelete()}
          >
            {t('button_table_delete')}
          </Button>
        )}
        {!onDelete && (
          <Button
            classes={{ root: classes.buttonCancel, disabled: classes.disabled }}
            variant="outlined"
            color="primary"
            disabled={true}
          >
            {t('button_table_delete')}
          </Button>
        )}
      </ButtonGroupContainer>
    </>
  );
};

export default AddDuplicateDelete;
