/** Misc */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IPermission, IRole, IRoleCreate, IPermissionHandlingOperation } from '../../../types/types';

/** API */
import AddPermissionToRole from '../../../services/api/APIManagementQueries/Permissions/AddPermissionToRole';
import DeletePermissionFromRole from '../../../services/api/APIManagementQueries/Permissions/DeletePermissionFromRole';
import GetAllPermissions from '../../../services/api/APIManagementQueries/Permissions/GetAllPermissions';
import GetPermissionsForRole from '../../../services/api/APIManagementQueries/Permissions/GetPermissionsForRole';
import UpdateRole from '../../../services/api/APIManagementQueries/UsersAndRoles/UpdateRole';
import DeleteRole from '../../../services/api/APIManagementQueries/UsersAndRoles/DeleteRole';
import CreateRole from '../../../services/api/APIManagementQueries/UsersAndRoles/CreateRole';
import GetAllRoles from '../../../services/api/APIManagementQueries/UsersAndRoles/GetAllRoles';

/** UI */
import AlertWindow from '../../Common/ReusableComponents/AlertWindow';
import RoleWindowLoadingScreen from './Roles/RoleWindowLoadingScreen';
import AddRole from './Roles/AddRole';
import { Grid } from '@material-ui/core';
import { FormTextArea, FormTextField } from '../../FormFields';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import MDButton from '../../Common/ReusableComponents/MDButton';
import RolePermissionsDialogWindow from './Roles/RolePermissionsDialogWindow';

const validationSchema = yup.object<IRole>({
  id: yup.number(),
  name: yup.string(),
  scope: yup.string(),
  description: yup.string(),
  permissions: yup.array(),
});

const getAddComponent: (
  mutation: any,
  createRoleMutationFn: (role: IRoleCreate) => void,
) => (props: { values?: any; onCancel: () => void }) => React.ReactNode = (mutation, createRoleMutationFn) => {
  return (props) => {
    return (
      <AddRole createRoleMutation={mutation} createRoleMutationFn={createRoleMutationFn} onCancel={props.onCancel} />
    );
  };
};

const Roles = () => {
  /** State variables */
  const { i18n, t } = useTranslation();
  const languageCode = i18n.language;
  const serviceProviderId = 'e96062f1-c6dd-4085-9884-780e29d94f27';

  /** Regular state hooks */
  const [roleId, setRoleId] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [severity, setSeverity] = useState<string>('success');
  const [dialogSelection, setDialogSelection] = useState<IPermissionHandlingOperation>('');
  const [roleWindowIsUpdating, setRoleWindowIsUpdating] =
    useState<boolean>(false); /** Role window is the box that contains the list of roles   */
  const [roleInfoWindowIsUpdating, setRoleInfoWindowIsUpdating] =
    useState<boolean>(
      false,
    ); /** Role info window is the sub-window that opens underneath when you click a role in role window */

  /** useQuery hooks */
  const {
    data: roleData,
    isLoading: roleDataIsLoading,
    refetch: refetchRoles,
    isError: isErrorRoleData,
  } = GetAllRoles(languageCode);
  const { data: allPermissions, isLoading: allPermissionsIsLoading } = GetAllPermissions(languageCode);
  const {
    data: permissionsForRole,
    isLoading: permissionsForRoleIsLoading,
    refetch: refetchPermissionsForRole,
  } = GetPermissionsForRole(serviceProviderId, roleId, languageCode);

  /** useMutation hooks */
  const addPermissionMutation = AddPermissionToRole();
  const deletePermissionMutation = DeletePermissionFromRole();
  const updateRoleMutation = UpdateRole();
  const deleteRoleMutation = DeleteRole();
  const createRoleMutation = CreateRole();

  /** On failed API request, show display alert */
  useEffect(() => {
    if (isErrorRoleData) {
      setSeverity('error');
      setMessage('label-error-loading-data-unknown');
      setDisplayAlert(true);
    }
  }, [isErrorRoleData]);

  /** Dialog is the pop up window that opens when e.g. new role is being added */
  const toggleDialog = (dialog: IPermissionHandlingOperation) => {
    setOpen(!open);
    setDialogSelection(dialog);
  };

  /** Fires when a role is clicked in the role window. Gets the data for the role info window (apart from permissions, which is a separate task) */
  const getRoleData: (id: string | number) => IRole | undefined = (id) => {
    setRoleId(Number(id));
    const item = roleData.find((d: any) => d.id === Number(id));
    return item;
  };

  /** API calls */
  const addPermissionToRole = (permission: IPermission | undefined) => {
    setRoleInfoWindowIsUpdating(true);
    addPermissionMutation.mutate(
      {
        permission_id: String(permission?.id),
        role_id: String(roleId),
        serviceprovider_id: String(serviceProviderId),
      },
      {
        onSuccess: () => {
          refetchPermissionsForRole();
          setMessage(t('message-permission-added'));
        },
        onError: (error) => {
          setMessage(String(error));
        },
        onSettled: () => {
          setRoleInfoWindowIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
    setOpen(!open);
  };

  const deletePermissionFromRole = (permission: IPermission | undefined) => {
    setRoleInfoWindowIsUpdating(true);
    deletePermissionMutation.mutate(
      {
        permission_id: String(permission?.id),
        role_id: String(roleId),
        serviceprovider_id: String(serviceProviderId),
      },
      {
        onSuccess: () => {
          refetchPermissionsForRole();
          setMessage(t('message-permission-deleted'));
        },
        onError: (error) => {
          setMessage(String(error));
        },
        onSettled: () => {
          setRoleInfoWindowIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
    setOpen(!open);
  };

  const updateRoleDescription = (role: IRole) => {
    setRoleInfoWindowIsUpdating(true);
    updateRoleMutation.mutate(
      {
        id: role.id,
        description: role.description,
        language_code: languageCode,
      },
      {
        onSuccess: () => {
          setMessage(t('message-role-updated'));
          refetchRoles();
        },
        onError: (error) => {
          setMessage(String(error));
        },
        onSettled: () => {
          setRoleInfoWindowIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
  };

  const deleteRole = (role_id: number | string) => {
    setRoleInfoWindowIsUpdating(true);
    deleteRoleMutation.mutate(
      {
        id: String(role_id),
      },
      {
        onSuccess: () => {
          setMessage(t('message-role-deleted'));
          refetchRoles();
        },
        onError: (error) => {
          setMessage(String(error));
        },
        onSettled: () => {
          setRoleInfoWindowIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
  };

  const createRole = (role: IRoleCreate) => {
    setRoleWindowIsUpdating(true);
    createRoleMutation.mutate(
      {
        scope: role.scope,
        name: role.name,
        description_en: role.description_en,
        description_fi: role.description_fi,
      },
      {
        onSuccess: () => {
          setMessage(t('message-role-created'));
          refetchRoles();
        },
        onError: (error) => {
          setMessage(String(error));
        },
        onSettled: () => {
          setRoleWindowIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
  };

  if (!isErrorRoleData && !!!allPermissions) {
    return <RoleWindowLoadingScreen isLoading={allPermissionsIsLoading} />;
  }
  if (!isErrorRoleData && !!!roleData) {
    return <RoleWindowLoadingScreen isLoading={roleDataIsLoading} />;
  }
  if (!isErrorRoleData && roleWindowIsUpdating) {
    return <RoleWindowLoadingScreen isLoading={roleWindowIsUpdating} />;
  }
  return (
    <>
      <AlertWindow message={message} severity={severity} display={displayAlert} setDisplay={setDisplayAlert} />
      <RolePermissionsDialogWindow
        open={open}
        task={dialogSelection}
        handleClose={toggleDialog}
        handleAdd={addPermissionToRole}
        handleDelete={deletePermissionFromRole}
        translator={t}
        allPermissions={allPermissions}
        permissionsForRole={permissionsForRole}
      />
      <SplitFormFrame<IRole>
        tableLabel="Roles"
        formLabel={t('label-role')}
        validationSchema={validationSchema}
        data={roleData}
        isLoading={roleDataIsLoading}
        idProperty="id"
        properties={[
          { key: 'name', label: t('label-role-name') },
          { key: 'scope', label: t('label-role-scope') },
          { key: 'description', label: t('label-role-description') },
        ]}
        fetchDataItem={getRoleData}
        onSubmit={updateRoleDescription}
        onDelete={deleteRole}
        duplicateDisabled={true}
        buttonsDisabled={isErrorRoleData}
        rowsPerPage={!!roleData ? 10 : 0}
        render={({ isEditing }) =>
          roleInfoWindowIsUpdating ? (
            <RoleWindowLoadingScreen isLoading={roleInfoWindowIsUpdating} />
          ) : permissionsForRoleIsLoading ? (
            <RoleWindowLoadingScreen
              isLoading={permissionsForRoleIsLoading}
              errorMsg="Failed to load permissions for this role."
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="name" label={t('label-role-name')} readonly={true} />
                <FormTextField name="scope" label={t('label-role-scope')} readonly={true} />
                <FormTextArea name="description" label={t('label-role-description')} readonly={!isEditing} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextArea
                  name="permissions"
                  label={t('label-role-permissions')}
                  readonly={true}
                  customFieldValue={
                    permissionsForRole.length > 0 ? permissionsForRole : t('label-no-permissions-available')
                  }
                />
                <MDButton label={t('label-add-permission')} handleClick={() => toggleDialog('add')} />
                <MDButton label={t('label-delete-permission')} handleClick={() => toggleDialog('delete')} />
              </Grid>
            </Grid>
          )
        }
        renderAdd={getAddComponent(createRoleMutation, createRole)}
      ></SplitFormFrame>
    </>
  );
};

export default Roles;
