import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, InputAdornment } from '@material-ui/core';
import { FormFieldSet } from '../../FormFields';
import { ITask } from '../../../types/types';
import FormTable from '../../FormFrames/FormTable';

interface IProps {
  task: ITask;
}

type Props = IProps;

const GitHub: React.FC<Props> = (props) => {
  const [selectedRepo, setSelectedRepo] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<number | string>('');

  const repositories = [
    {
      id: 0,
      name: 'repo1',
      users: [
        { id: 0, name: 'Mark Zuckerberg', role: 'Manager', status: 'working' },
        { id: 1, name: 'Elon Musk', role: 'developer', status: 'working' },
        { id: 2, name: 'Sundar Pichai', role: 'developer', status: 'working' },
        { id: 3, name: 'Tim Cook', role: 'developer', status: 'working' },
        { id: 4, name: 'Jeff Bezos', role: 'Scrum master', status: 'coffee break' },
      ],
      files: [
        { id: 0, name: 'file1', lastUpdated: '12-10-2005', size: '5mb' },
        { id: 1, name: 'file2', lastUpdated: '28-3-2010', size: '2,4Gb' },
      ],
      operations: [
        { id: 0, name: 'operation1', time: '20:30', assignedTo: 'Sundar Pichai' },
        { id: 1, name: 'operation2', time: '05:00', assignedTo: 'Jeff Bezos' },
      ],
    },
  ];

  const userData = repositories.map((repo) => {
    return repo.users;
  });

  const fileData = repositories.map((repo) => {
    return repo.files;
  });

  const operationsData = repositories.map((repo) => {
    return repo.operations;
  });

  const handleFilterRepo = (e: any) => {
    setSelectedRepo(e.target.value);
  };

  const handleSelectRow = (id: number | string) => {
    if (selectedItem !== id) {
      setSelectedItem(id);
    } else {
      setSelectedItem('');
    }
  };

  return (
    <>
      <FormFieldSet legend="Git repository information">
        <div style={{ minHeight: '300px', width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextField
                id="outlined-basic"
                label="Repository name"
                variant="outlined"
                value={selectedRepo}
                onChange={handleFilterRepo}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTable
                label={'repo1'}
                data={userData[0]}
                properties={[
                  { key: 'name', label: 'User name' },
                  { key: 'role', label: 'Role' },
                  { key: 'status', label: ' Status' },
                ]}
                isLoading={false}
                selectedId={selectedItem}
                onSelectRow={handleSelectRow}
                onDelete={() => null}
                optionalButtons={false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTable
                label=""
                data={fileData[0]}
                properties={[
                  { key: 'name', label: 'File name' },
                  { key: 'size', label: 'Size' },
                  { key: 'lastUpdated', label: 'Last updated' },
                ]}
                isLoading={false}
                selectedId={selectedItem}
                onSelectRow={handleSelectRow}
                onDelete={() => null}
                optionalButtons={false}
              ></FormTable>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTable
                label=""
                data={operationsData[0]}
                properties={[
                  { key: 'name', label: 'Operation name' },
                  { key: 'time', label: 'Time' },
                  { key: 'assignedTo', label: 'Assigned to' },
                ]}
                isLoading={false}
                selectedId={selectedItem}
                onSelectRow={handleSelectRow}
                onDelete={() => null}
                optionalButtons={false}
              ></FormTable>
            </Grid>
          </Grid>
        </div>
      </FormFieldSet>
    </>
  );
};

export default GitHub;
