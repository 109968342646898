/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from '../../../FormFrames/FormFrame';
import { usePutApiEffect } from '../../../Hooks/Hooks';
import { FormTextField } from '../../../FormFields';
import { ICustomer } from '../../../../types/types';
import { IVNet as IFormObject, IResourceUpdate } from './NetworkTypes';

const functionId = '00000000-0000-0000-0000-000000000000';

interface IProps {
  customer: ICustomer;
}

const validationSchema = yup.object<IFormObject>({
  name: yup.string().required(),
  address_space: yup.string().required(),
});

type Props = IProps;

const VNet: React.FC<Props> = (props) => {
  const { customer } = props;

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  const [response, isSubmitting, isSubmitError, submitUpdate] = usePutApiEffect<IResourceUpdate, void>(
    `api/resources/environments`,
    null,
  );

  const formData: IFormObject | undefined = customer && {
    name: customer.vnet_name,
    address_space: customer.vnet_address_space,
  };

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isSubmitting);
    }
    if (isSubmitting === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (
    data: IFormObject,
    setIsSubmitting: SetIsSubmittingFn,
    setStopEditing: SetStopEditingFn,
  ) => void = useCallback((data, setIsSubmitting, setStopEditing) => {
    setIsSubmittingFn(() => setIsSubmitting);
    setStopEditingFn(() => setStopEditing);
    const payload: IResourceUpdate = {
      id: functionId,
      customer_id: customer.customer_guid,
      data: {
        resource_type: 'vnet',
        resource_data: [
          {
            resource_name: data.name,
            resource_address_space: data.address_space,
          },
        ],
      },
    };

    submitUpdate(payload);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormFrame<IFormObject>
        label="Virtual Network"
        validationSchema={validationSchema}
        initialValues={formData}
        onSubmit={handleSubmit}
        render={({ isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="name" label="Virtual Network Name" readonly={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField name="address_space" label="Address Space" readonly={!isEditing} />
            </Grid>
          </Grid>
        )}
      />
    </>
  );
};

export default VNet;
