import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { getCollection } from '../../redux/reducers';
import { ICollection, ICollectionMember, IState } from '../../types/types';

export interface ICollectionContent {
  id: string;
  value: string;
}

export const useCollection: (collectionId: number) => ICollectionContent[] = (collectionId) => {
  const { i18n } = useTranslation();

  const collection = useSelector<IState, ICollection>(
    (state: IState) => getCollection(state, collectionId),
    shallowEqual,
  );

  const content = collection.members.reduce((p: ICollectionContent[], c: ICollectionMember): ICollectionContent[] => {
    c.values.forEach((v) => {
      if (
        (collection.multilanguage && v.language_code === i18n.language) ||
        (!collection.multilanguage && v.language_code === 'xx-xx')
      ) {
        p.push({
          id: c.id,
          value: v.value,
        });
      }
    });

    return p;
  }, []);

  return content;
};
