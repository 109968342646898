/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormSelect, FormTextField } from '../../FormFields';
import FormTwinMultiSelect from '../../FormFields/FormTwinMultiSelect';
import { useGetApiEffect } from '../../Hooks/Hooks';
import { ITask } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { useCollection } from '../../Hooks/Collections';

interface IProps {
  task: ITask;
}

interface IUser {
  id: string;
  name: string;
  type: string;
  status: number;
  roles: string[];
}

interface IGroup {
  id: string;
  name: string;
  description: string;
}

const validationSchema = yup.object({
  id: yup.string(),
  name: yup.string().required(),
  type: yup.string(),
  status: yup.number(),
  roles: yup.array(yup.string()),
});

type Props = IProps;

const UsersAndRoles: React.FC<Props> = (props) => {
  const { task } = props;

  const statusValues = useCollection(7);

  const [roles, isLoadingRoles, isRolesError, getRoles] = useGetApiEffect<string[]>(
    `api/aad/roles?customerId=${task.customer_guid}`,
    null,
  );

  const [users, isLoadingUsers, isUsersError, getUsers] = useGetApiEffect<IUser[]>(
    `api/aad/users?customerId=${task.customer_guid}`,
    null,
  );

  const { t } = useTranslation();

  useEffect(() => {
    getRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataItem: (id: string | number) => IUser | undefined = (id) => {
    return users.find((d) => d.id === id);
  };

  const handleSubmit: (data: IUser) => void = (data) => {};

  return (
    <>
      <SplitFormFrame<IUser>
        tableLabel={t('label-users')}
        formLabel={t('label-user-information')}
        validationSchema={validationSchema}
        data={users}
        idProperty="id"
        properties={[
          { key: 'name', label: t('label-user-name') },
          { key: 'roles', label: t('label-role') },
          { key: 'status', label: t('label-status') },
        ]}
        isLoading={isLoadingRoles || isLoadingUsers}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        duplicateDisabled={true}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="name" label={t('label-user-name')} readonly={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="status" label={t('label-status')} readonly={true}>
                {statusValues.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
            </Grid>
            <Grid item xs={12}>
              <FormTwinMultiSelect
                name="roles"
                label={t('label-roles')}
                availableValues={roles}
                readonly={!isEditing}
              ></FormTwinMultiSelect>
            </Grid>
          </Grid>
        )}
      ></SplitFormFrame>
    </>
  );
};

export default UsersAndRoles;
