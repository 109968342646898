import { createApiAction } from './common';
import { schema as Schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { INormalizedBase, IRoleCollection } from '../../types/types';
import HttpMethod from '../../services/api/httpMethod';

const actionCreator = actionCreatorFactory();

/**
 * Define schema for normalizing api response
 * The schema can be customised for you own needs, see normalizr docs
 *
 * This schema normalizes an array of objects to a dictionary of objects and an array if ids
 * If the property that defines the object identity is not named 'id' the idAttribute needs to be set
 */
const entityName = 'role';
const entity = new Schema.Entity(
  entityName,
  {},
  {
    idAttribute: 'scope',
  },
);
const schema = [entity];

/**
 * The type of action for a fetch operation
 */
export const FETCH_ROLES = 'FETCH_ROLES';

/**
 * Define action creators for an async operation
 */
export const fetchRolesActionCreator = actionCreator.async<void, INormalizedBase<IRoleCollection, string>>(
  FETCH_ROLES,
  {
    normalize: {
      entityName,
      schema,
    },
  },
);

/**
 * Creates an async action that defines an api call
 */
export const getRolesApiRequest = () => createApiAction(HttpMethod.GET, 'api/roles', fetchRolesActionCreator);
