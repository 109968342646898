/**
 * This module contains the pretty much some of the same functions as
 * the module `src/redux/reducers/index.ts`, just slightly
 * modified to work better with React Query. With that being said,
 * the old logic is still the same.
 */

import moment from 'moment';
import { Crumb, EventType, IEvent, ITimelineEvent, ITimelineResource } from '../../../../types/types';
import { ISchedule } from '../../../Forms/Tasks/Schedule/ScheduleTypes';

var cronParser = require('cron-parser');

export const computeTimelineResources = (
  events: IEvent[],
  schedules: ISchedule[],
  set: (resources: ITimelineResource[]) => void,
) => {
  const eventResources = events.reduce((p: Map<string, ITimelineResource>, c: IEvent) => {
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }
    if (!p.has(c.vm_name)) {
      p.set(c.vm_name, { id: c.vm_name, name: c.vm_name });
    }
    if (!p.has(`${c.vm_name}-${c.task_guid}`)) {
      p.set(`${c.vm_name}-${c.task_guid}`, {
        id: `${c.vm_name}-${c.task_guid}`,
        name: c.task_name,
        parentId: c.vm_name,
      });
    }
    return p;
  }, new Map<string, ITimelineResource>());
  const scheduleResources = schedules.reduce((p: Map<string, ITimelineResource>, c: ISchedule) => {
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }
    if (!p.has(c.vm_name)) {
      p.set(c.vm_name, { id: c.vm_name, name: c.vm_name });
    }

    if (!p.has(`${c.vm_name}-${c.task_id}`)) {
      p.set(`${c.vm_name}-${c.task_id}`, {
        id: `${c.vm_name}-${c.task_id}`,
        name: c.task_name,
        parentId: c.vm_name,
      });
    }
    return p;
  }, eventResources);
  set(Array.from(scheduleResources.values()));
};

export const computeTimelineEvents = (
  events: IEvent[],
  schedules: ISchedule[],
  startDate: Date,
  endDate: Date,
  set: (events: ITimelineEvent[]) => void,
  includeScheduled?: boolean,
) => {
  const start = startDate.getTime();
  const end = endDate.getTime();
  const now = new Date().getTime();
  const scheduled = includeScheduled && end > now ? computeEventsFromSchedule(schedules, endDate) : [];
  const actual = events.reduce((p: ITimelineEvent[], c: IEvent) => {
    const plannedStartTime = new Date(c.scheduled_start_time);
    const plannedEndTime = c.scheduled_stop_time
      ? new Date(c.scheduled_stop_time)
      : moment(c.scheduled_start_time).add(1, 'minutes').toDate();
    const viewStart = c.actual_start_time
      ? plannedStartTime > new Date(c.actual_start_time)
        ? new Date(c.actual_start_time)
        : plannedStartTime
      : plannedStartTime;
    const viewEnd = c.actual_stop_time
      ? plannedEndTime > new Date(c.actual_stop_time)
        ? plannedEndTime
        : new Date(c.actual_stop_time)
      : plannedEndTime;
    // if (scheduled.filter((s: ITimelineEvent) => {
    //   s.start === viewStart.toISOString()
    //   && s.end === viewEnd.toISOString()
    //   && s.vmId === c.vm_name
    //   && c.actual_start_time === null
    //   && c.actual_stop_time === null
    // })) {
    //   return p;
    // }
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }
    if (plannedStartTime.getTime() >= start && plannedStartTime.getTime() < end) {
      p.push({
        id: c.event_guid,
        resourceId: `${c.vm_name}-${c.task_guid}`,
        vmId: c.vm_name,
        taskId: c.task_guid,
        title: '',
        start: viewStart.toISOString(),
        end: viewEnd.toISOString(),
        errorCount: c.error_count,
        status: c.status,
        scheduled_start_time: plannedStartTime,
        scheduled_stop_time: plannedEndTime,
        actual_start_time: c.actual_start_time ? new Date(c.actual_start_time) : undefined,
        actual_stop_time: c.actual_stop_time
          ? new Date(c.actual_stop_time)
          : c.actual_start_time
          ? new Date(c.actual_start_time)
          : undefined,
        type: 'actual',
      });
    }
    return p;
  }, []);
  const combined = actual
    .concat(scheduled)
    .sort((a, b) => a.scheduled_start_time.getTime() - b.scheduled_start_time.getTime());
  set(combined);
};

const computeEventsFromSchedule = (schedules: ISchedule[], endDate: Date) => {
  const now = new Date();
  const start = now.getTime();
  const end = endDate.getTime();
  const result = schedules.reduce((p: ITimelineEvent[], c: ISchedule) => {
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }

    // Daily
    if (c.daily_recurrence) {
      let scheduleStart = new Date(c.start_time);
      let scheduleEnd = new Date(c.end_time);
      while (scheduleStart.getTime() <= end) {
        if (scheduleStart.getTime() >= start && scheduleStart.getTime() <= end) {
          p.push({
            id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${scheduleStart.getTime()}`,
            resourceId: `${c.vm_name}-${c.task_id}`,
            vmId: c.vm_name,
            taskId: c.task_id,
            title: '',
            start: scheduleStart.toISOString(),
            end: scheduleEnd.toISOString(),
            errorCount: 0,
            status: 0,
            scheduled_start_time: scheduleStart,
            scheduled_stop_time: scheduleEnd,
            type: 'scheduled',
          });
        }
        scheduleStart = moment(scheduleStart).add(c.daily_recurrence.frequency, 'days').toDate();
        scheduleEnd = moment(scheduleEnd).add(c.daily_recurrence.frequency, 'days').toDate();
      }

      // Weekly
    } else if (c.weekly_recurrence) {
      let scheduleStart = new Date(c.start_time);
      let scheduleEnd = new Date(c.end_time);
      let weekday = scheduleStart.getDay();
      if (weekday === 0) {
        weekday = 7;
      }
      scheduleStart = moment(scheduleStart)
        .add(1 - weekday, 'days')
        .toDate();
      scheduleEnd = moment(scheduleEnd)
        .add(1 - weekday, 'days')
        .toDate();
      while (scheduleStart.getTime() <= end) {
        for (let i = 0; i < 7; i++) {
          const process =
            (i === 0 && c.weekly_recurrence.runs_monday) ||
            (i === 1 && c.weekly_recurrence.runs_tuesday) ||
            (i === 2 && c.weekly_recurrence.runs_wednesday) ||
            (i === 3 && c.weekly_recurrence.runs_thursday) ||
            (i === 4 && c.weekly_recurrence.runs_friday) ||
            (i === 5 && c.weekly_recurrence.runs_saturday) ||
            (i === 6 && c.weekly_recurrence.runs_sunday);
          const currentStart = moment(scheduleStart).add(i, 'days').toDate();
          const currentEnd = moment(scheduleEnd).add(i, 'days').toDate();
          if (process && currentStart.getTime() >= start && currentStart.getTime() <= end) {
            p.push({
              id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${currentStart.getTime()}`,
              resourceId: `${c.vm_name}-${c.task_id}`,
              vmId: c.vm_name,
              taskId: c.task_id,
              title: '',
              start: currentStart.toISOString(),
              end: currentEnd.toISOString(),
              errorCount: 0,
              status: 0,
              scheduled_start_time: currentStart,
              scheduled_stop_time: currentEnd,
              type: 'scheduled',
            });
          }
        }
        scheduleStart = moment(scheduleStart).add(c.weekly_recurrence.frequency, 'weeks').toDate();
        scheduleEnd = moment(scheduleEnd).add(c.weekly_recurrence.frequency, 'weeks').toDate();
      }

      // Monthly
    } else if (c.monthly_recurrence) {
      const scheduleStart = new Date(c.start_time);
      const scheduleEnd = new Date(c.end_time);
      const nextRunStart = c.next_run ? new Date(c.next_run) : null;
      const nextRunEnd = nextRunStart
        ? new Date(nextRunStart.getTime() + (scheduleEnd.getTime() - scheduleStart.getTime()))
        : null;
      const startMonth = scheduleStart.getMonth();
      const endMonth = endDate.getMonth();
      let months = endMonth - startMonth;
      // DOCK-790: Take into account the difference between the event's start year and the currently selected end year
      const startYear = scheduleStart.getFullYear();
      const endYear = endDate.getFullYear();
      let yearDiff = endYear - startYear;
      while (yearDiff > 0) {
        months += 12;
        yearDiff--;
      }
      for (let i = 0; i <= months; i += c.monthly_recurrence.frequency) {
        const currentMonth = startMonth + i;
        let currentStart = moment(scheduleStart).set('month', currentMonth).toDate();
        let currentEnd = moment(scheduleEnd).set('month', currentMonth).toDate();
        if (i > 0) {
          // DOCK-943: Use the day from the `Recur` rule for occasions other than the initial schedule
          if (!!nextRunStart && !!nextRunEnd) {
            currentStart = moment(nextRunStart).set('month', currentMonth).toDate();
            currentEnd = moment(nextRunEnd).set('month', currentMonth).toDate();
          }
        }
        p.push({
          id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${currentStart.getTime()}`,
          resourceId: `${c.vm_name}-${c.task_id}`,
          vmId: c.vm_name,
          taskId: c.task_id,
          title: '',
          start: currentStart.toISOString(),
          end: currentEnd.toISOString(),
          errorCount: 0,
          status: 0,
          scheduled_start_time: currentStart,
          scheduled_stop_time: currentEnd,
          type: 'scheduled',
        });
      }

      // CRON
    } else if (c.cron_recurrence) {
      const scheduleStart = new Date(c.start_time);
      var options = {
        currentDate: now < scheduleStart ? scheduleStart : now,
        endDate: endDate,
      };
      const interval = cronParser.parseExpression(c.cron_recurrence.cron_string, options);
      const duration = c.cron_recurrence.duration;
      while (interval.hasNext()) {
        const nextRun = interval.next();
        p.push({
          id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${nextRun.getTime()}`,
          resourceId: `${c.vm_name}-${c.task_id}`,
          vmId: c.vm_name,
          taskId: c.task_id,
          title: '',
          start: nextRun.toISOString(),
          end: moment(nextRun.toDate()).add(duration, 'minutes').toDate().toISOString(),
          errorCount: 0,
          status: 0,
          scheduled_start_time: nextRun.toDate(),
          scheduled_stop_time: moment(nextRun.toDate()).add(duration, 'minutes').toDate(),
          type: 'scheduled',
        });
      }

      // Non-recurring
    } else {
      if (!c.event_created_for_next_run) {
        let scheduleStart = new Date(c.start_time);
        let scheduleEnd = new Date(c.end_time);
        p.push({
          id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${scheduleStart.getTime()}`,
          resourceId: `${c.vm_name}-${c.task_id}`,
          vmId: c.vm_name,
          taskId: c.task_id,
          title: '',
          start: scheduleStart.toISOString(),
          end: scheduleEnd.toISOString(),
          errorCount: 0,
          status: 0,
          scheduled_start_time: scheduleStart,
          scheduled_stop_time: scheduleEnd,
          type: 'scheduled',
        });
      }
    }
    return p;
  }, []);
  return result;
};

export const getScopeType = (breadcrumbs: Crumb[]) => {
  let numberToUse;
  let valueForCall;
  if (breadcrumbs.length > 0) {
    numberToUse = breadcrumbs[breadcrumbs.length - 1].type;
    const placeholder = EventType[numberToUse];
    valueForCall = placeholder.toLowerCase();
  }
  return valueForCall;
};

export const getScopeValue = (breadcrumbs: Crumb[]) => {
  let returnValue;
  if (breadcrumbs.length === 1) {
    returnValue = breadcrumbs[0].id;
  } else if (breadcrumbs.length === 2) {
    returnValue = breadcrumbs[0].id + '/' + breadcrumbs[breadcrumbs.length - 1].name;
  } else if (breadcrumbs.length === 3) {
    returnValue =
      breadcrumbs[0].id +
      '/' +
      breadcrumbs[breadcrumbs.length - 2].name +
      '/' +
      breadcrumbs[breadcrumbs.length - 1].name;
  }
  return returnValue;
};
