import React from 'react';
import Compute from './Compute/index';

import { ICustomer } from '../../../types/types';

interface IProps {
  customer: ICustomer;
}

type Props = IProps;

const Environments: React.FC<Props> = (props) => {
  const { customer } = props;

  return (
    <>
      <Compute customer={customer} />
    </>
  );
};

export default Environments;
