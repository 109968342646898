import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import FormatToNDecimals from '../../Common/ReusableUtilityFunctions/FormatToNDecimals';

interface DataTableVmUtilizationProps {
  data: any;
  columnTranslations: string[];
  legend: string[];
}

const DataTableVmUtilization = (props: DataTableVmUtilizationProps) => {
  const { data, columnTranslations, legend } = props;
  const { t } = useTranslation();

  const getPercentage = (input: number) => {
    const total = data.reduce((a: any, b: any) => a + (b['value'] || 0), 0);
    return `${((input / total) * 100).toFixed(0)}%`;
  };

  const renderDataCells = (id: number) => {
    const cells: JSX.Element[] = [];
    const value = data[id]['value'];
    cells.push(
      <TableCell key={'minutes-' + id} align="left">
        {value}
      </TableCell>,
    );
    cells.push(
      <TableCell key={'hours-' + id} align="left">
        {FormatToNDecimals(value / 60, 1)}
      </TableCell>,
    );
    cells.push(
      <TableCell key={'percentages-' + id} align="left">
        {getPercentage(value)}
      </TableCell>,
    );
    return cells;
  };

  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: '10px' }}></TableCell>
            {columnTranslations.map((translation) => (
              <TableCell key={translation} align="left">
                {t(translation)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {legend.map((translation: any, id: number) => {
            return (
              <TableRow key={id}>
                <TableCell sx={{ width: '30vh' }} key={'name-' + id} align="left">
                  {t(translation)}
                </TableCell>
                {renderDataCells(id)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default DataTableVmUtilization;
