import moment from 'moment';

export enum ViewTypes {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export const startOfWeek = () => {
  return moment().startOf(ViewTypes.Week).toDate();
};

export const endOfWeek = () => {
  return moment().startOf(ViewTypes.Week).add(1, ViewTypes.Week).subtract(1, 'day').endOf('day').toDate();
};

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDateToFinnishStyle = (date: any) => {
  let dateObj = date;
  if (typeof dateObj === 'string') {
    dateObj = new Date(date);
  }
  return dateObj.toLocaleDateString('fi-FI');
};
