/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dialog } from '@material-ui/core';
import React from 'react';

interface IProps<T extends {}> {
  open: boolean;
}

type Props<T> = IProps<T>;

function AddNewContainer<T>(props: React.PropsWithChildren<Props<T>>) {
  const { open, children } = props;
  return (
    <>
      <Dialog fullWidth={true} maxWidth="lg" open={open} aria-labelledby="dialog-title">
        {children}
      </Dialog>
    </>
  );
}

export default AddNewContainer;
