import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card, CardContent, Typography } from '@material-ui/core';
import CustomersDialog from './Customers/CustomersDialog/CustomersDialog';

import { getSelectedCustomerId } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { IState } from '../../types/types';

const Home = (props: RouteComponentProps) => {
  const [initialOpen, setInitialOpen] = useState<boolean>(true);
  const currentCustomer = useSelector((state: IState) => getSelectedCustomerId(state));

  useEffect(() => {
    if (!currentCustomer) {
      setInitialOpen(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card elevation={0}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          MOST Digital OIVA
        </Typography>
        <CustomersDialog initialOpen={initialOpen} />
      </CardContent>
    </Card>
  );
};

export default Home;
