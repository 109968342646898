import React from 'react';
import { ICustomer } from '../../../../types/types';
import VNet from './VNet';
import Subnet from './SubNet';

interface IProps {
  customer: ICustomer;
}

type Props = IProps;

const Networks: React.FC<Props> = (props) => {
  const { customer } = props;

  return (
    <>
      <VNet customer={customer} />
      <Subnet customer={customer} />
    </>
  );
};

export default Networks;
