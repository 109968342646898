/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import TabView from '../../Common/TabView';
import { ITabItem } from '../../Common/TabView/TabViewUtils';
import { Jira } from '../../Forms/ContinuousServices';
import { KPIsAndValues } from '../../Forms/Metrics';
import DashboardView from './Dashboard';
import EventLogs from './EventLogs';
import Consumption from './Dashboard/Consumption/Consumption';
import { getSelectedCustomerId } from '../../../redux/reducers';
import { IState } from '../../../types/types';
import TestView from './Dashboard/TestView';

const Dashboard = () => {
  const customerId = useSelector<IState, string>(getSelectedCustomerId, shallowEqual);

  const [selectedVm, setSelectedVm] = useState<string>('');
  const [refreshing, setRefreshing] = useState<boolean>(true);

  const handleSelectVm = (vm: string) => {
    setSelectedVm(vm);
  };

  const handleRefresh = (state: boolean) => {
    setRefreshing(state);
  };

  const tabs: ITabItem[] = [
    {
      displayName: 'menu-sub-automations', // nimi .> "AUTOMATIONS"// ROBOTIT?
      component: (
        <DashboardView
          selectVm={handleSelectVm}
          selectedVm={selectedVm}
          isRefreshing={refreshing}
          setRefresh={handleRefresh}
        />
      ),
    },
    {
      displayName: 'menu-sub-consumption',
      component: <Consumption customerId={customerId} />,
      roles: ['administrators'],
    },
    {
      displayName: 'menu-sub-event-logs',
      component: <EventLogs customerId={customerId} selectedVm={selectedVm} />,
      roles: ['developers', 'administrators'],
    },
    // {
    //   displayName: 'Testi',
    //   component: (
    //     <TestView
    //       selectVm={handleSelectVm}
    //       selectedVm={selectedVm}
    //       isRefreshing={refreshing}
    //       setRefresh={handleRefresh}
    //     />
    //   ),
    // },
  ];

  /* 
  Removed temporarily only from production
  {
      displayName: 'menu-sub-kpi',
      component: <KPIsAndValues />,
    },
    {
      displayName: 'menu-sub-continuous-services',
      component: <Jira />,
    },
  */

  return (
    <>
      <TabView tabs={tabs} selectedParentTab="dashboard" setRefresh={handleRefresh} isRefreshing={refreshing} />
    </>
  );
};

export default Dashboard;
