/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormTextField, FormTwinMultiSelect, FormSelect } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { useGetApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import GuestUser from './GuestUser';
import InternalUser from './InternalUser';
import TypeSelect from './TypeSelect';
import { useFormikContext } from 'formik';
import { IRole, IFormObject, IUserCreate, IInternalUser } from './Types';

interface IProps {
  id: string;
  customerId: string;
  roles: IRole[];
  data?: IFormObject;
  onCancel: () => void;
}

type Props = IProps;

const AddUser: React.FC<Props> = (props) => {
  const { customerId, roles, data, onCancel } = props;

  const [internalUsers, isLoadingUsers, isUsersError, getInternalUsers] = useGetApiEffect<IInternalUser[]>(
    `customers/${customerId}/users/internal`,
    [],
    'pythonApi',
  );

  useEffect(() => {
    getInternalUsers();
  }, []);

  return (
    <TypeSelect
      customerId={customerId}
      data={data}
      roles={roles}
      onCancel={onCancel}
      isLoading={isLoadingUsers}
      users={internalUsers}
    />
  );
};

export default AddUser;
