/**
 * Contains all the non-React components of the Consumption window.
 * The sole purpose is to improve readability.
 */
import * as yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  IConsumption,
  IConsumptionIndividual,
  IDateSeparatedConsumption,
  IResourceSeparatedConsumption,
  ISingleResourceConsumptionInfo,
} from './ConsumptionTypes';

export const validationSchema = yup.object({
  resource_name: yup.string(),
  resource_id: yup.string(),
  consumption: yup.number(),
  unit: yup.string(),
  cost: yup.number(),
  resource_type: yup.string(),
  cost_history: yup.object(),
  consumption_history: yup.object(),
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 100,
      backgroundColor: '#d1ebfc',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    excelEnabled: {
      color: '#2F518A',
      height: '0.3rem',
    },
    enabled: {
      color: '#2F518A',
    },
    disabled: {
      color: 'secondary',
    },
  }),
);

/**
 * Helper function for looping splitting an
 * IConsumption object (the raw data received from the API)
 * into individual days based on how many different days exist in the
 * cost_history / consumption_history arrays.
 * It is guaranteed that cost_history and consumption_history have equal lengths.
 */
export const splitConsumptionEventToDays = (event: IConsumption) => {
  let dateSplitEvents: IConsumptionIndividual[] = [];
  for (let date in event.cost_history) {
    dateSplitEvents.push({
      resource_name: event.resource_name,
      resource_id: event.resource_id,
      resource_type: event.resource_id,
      unit: event.unit,
      consumption: event.consumption_history[date],
      cost: event.cost_history[date],
      date: date,
    });
  }
  return dateSplitEvents;
};

/**
 * Creates an array where each object is a distinct date.
 * In addition, each date has a 'cost' and 'cumulative' variable.
 * e.g. {date: '28.01.2022' cost: 10, cumulative: 10}
 *      {date: '29.01.2022' cost: 2, cumulative: 12}
 * The function also calculates the total cost in the same loop.
 *
 * NOTE: consumptionData is basically an array of IConsumption objects, which is the
 * raw data received from the API.
 */
export const generateConsumptionWindowsData = (consumptionData: any) => {
  let total = 0;
  let dateSeparatedConsumptionEvents: IDateSeparatedConsumption[] = [];
  consumptionData.forEach((consumptionEvent: IConsumption) => {
    for (let dateSeparatedSingleConsumption of splitConsumptionEventToDays(consumptionEvent)) {
      let existingDate = dateSeparatedConsumptionEvents.find((event) => {
        return event.date === dateSeparatedSingleConsumption.date;
      });
      if (!!existingDate) {
        existingDate.cost += dateSeparatedSingleConsumption.cost;
      } else {
        dateSeparatedConsumptionEvents.push({
          date: dateSeparatedSingleConsumption.date,
          cost: dateSeparatedSingleConsumption.cost,
          cumulative: 0,
        });
      }
      total += dateSeparatedSingleConsumption.cost;
    }
  });
  let cumulative = 0;
  for (let date of dateSeparatedConsumptionEvents) {
    cumulative += date.cost;
    date.cumulative = cumulative;
  }

  return { total: total, data: dateSeparatedConsumptionEvents };
};

/**
 * Manipulate the consumptionData received from the API so that the
 * consumption events are separated by their resource_type (e.g. Virtual machine, Disk, etc.)
 * Returns an array of ResourceSeparatedConsumption objects.
 *
 * NOTE: consumptionData is basically an array of IConsumption objects, which is the
 * raw data received from the API.
 */
export const formatConsumptionDataByResourceType = (consumptionData: any) => {
  let resourceSeparated: IResourceSeparatedConsumption[] = [];
  if (!!consumptionData) {
    consumptionData.forEach((consumptionEvent: IConsumption) => {
      var existingResource = resourceSeparated.find((resource) => {
        return resource.name === consumptionEvent.resource_type;
      });
      if (!!existingResource) {
        // A resource of this type already exists. Increment its total_cost value and append the 'data' array
        existingResource.total_cost += consumptionEvent.cost;
        existingResource.data.push(consumptionEvent);
      } else {
        // No existing resource of this type. Appending the array with a new resource.
        resourceSeparated.push({
          name: consumptionEvent.resource_type,
          total_cost: consumptionEvent.cost,
          data: [consumptionEvent],
        });
      }
    });
  }
  return resourceSeparated;
};

/**
 * Based on the dataset retrieved by the API,
 * when a single event is clicked in the 'Resources' collapse table
 * form the daily/cumulative data for that event so that it can be displayed.
 */
export const parseDataForSingleResource = (selectedResource: IConsumption) => {
  let resourceConsumptionData: ISingleResourceConsumptionInfo[] = [];
  let cumulative = 0;
  for (let dateSeparatedSingleConsumption of splitConsumptionEventToDays(selectedResource)) {
    cumulative += dateSeparatedSingleConsumption.cost;
    resourceConsumptionData.push({
      date: dateSeparatedSingleConsumption.date,
      cost: dateSeparatedSingleConsumption.cost,
      cumulative: cumulative,
    });
  }
  return resourceConsumptionData;
};
