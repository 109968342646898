/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormSelect, FormTextArea, FormTextField } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { useCollection } from '../../../Hooks/Collections';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { ISubnet as IFormObject, IResourceUpdate } from './NetworkTypes';

interface IProps {
  id: string;
  customerId: string;
  data?: IFormObject;
  onCancel: () => void;
}

const validationSchema = yup.object<IFormObject>({
  name: yup.string().required(),
  description: yup.string().nullable(),
  address_space: yup.string().required(),
  type: yup.string().required(),
});

type Props = IProps;

const AddSubNet: React.FC<Props> = (props) => {
  const { id, customerId, data, onCancel } = props;

  const subnetTypes = useCollection(5);

  const { t } = useTranslation();

  const [response, isLoading, isError, postData] = usePostApiEffect<IResourceUpdate, void>(
    'api/resources/environments',
    null,
  );

  const formData: IFormObject = data
    ? { ...data, name: '' }
    : {
        name: '',
        address_space: '',
        description: null,
        type: '',
      };

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = (data, setIsSubmitting) => {
    setIsSubmittingFn(() => setIsSubmitting);

    const payload: IResourceUpdate = {
      id,
      customer_id: customerId,
      data: {
        resource_type: 'subnet',
        resource_data: [
          {
            resource_name: data.name,
            resource_description: data.description,
            resource_address_space: data.address_space,
            resource_type: data.type,
          },
        ],
      },
    };

    postData(payload);
  };

  return (
    <ModalFormFrame<IFormObject>
      label={t('label-add-new-subnet')}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="name" label={t('label-subnet-name')} readonly={isSubmitting} />
            <FormTextField name="address_space" label={t('label-address-space')} readonly={isSubmitting} />
            <FormSelect name="type" label={t('label-subnet-type')} readonly={isSubmitting}>
              {subnetTypes.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextArea name="description" label={t('subnet-description')} readonly={isSubmitting} />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default AddSubNet;
