import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import MDButton from '../../../Common/ReusableComponents/MDButton';
import React, { useState } from 'react';
import { IPermission, IPermissionHandlingOperation } from '../../../../types/types';
import { getAvailableRoles } from './UserRoleFilters';
import { IRole, IRoleOperation, IUserAddRole } from './Types';

interface IProps {
  open: boolean;
  task: IRoleOperation;
  handleClose: (dialog: IRoleOperation) => void;
  handleAdd: (role: IRole | undefined) => void;
  handleDelete: (role: IRole | undefined) => void;
  translator: any;
  allRoles: IRole[];
  userRoles: string[] | undefined;
}

const RoleDialogWindow = (props: IProps) => {
  const { open, task, handleClose, handleAdd, handleDelete, translator, allRoles, userRoles } = props;

  const availableRoles = getAvailableRoles(task, allRoles, userRoles);
  const [role, setRole] = useState<IRole | undefined>(undefined);

  const handleRoleChange = (e: any) => {
    let roleName = e.target.value;
    setRole(availableRoles.find((p) => p.role_name === roleName));
  };

  const handleSave = () => {
    setRole(allRoles[0]);
    if (task === 'delete') {
      handleDelete(role);
    } else {
      handleAdd(role);
    }
    handleClose(task);
  };

  const handleCancel = () => {
    setRole(undefined);
    handleClose(task);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {task === 'add' ? translator('label-add-role') : translator('label-delete-role')}
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel>{translator('label-select-role')}</InputLabel>
            <Select
              autoWidth={true}
              defaultValue={translator('label-select-role')}
              value={!!role ? role.role_name : ''}
              onChange={handleRoleChange}
              inputProps={{
                name: 'role',
              }}
            >
              {availableRoles.map((p) => (
                <MenuItem key={p.role_id} value={p.role_name}>
                  {p.role_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton disabled={!!!role} label={translator('button_form_save')} handleClick={handleSave} />
        <Button onClick={handleCancel} color="primary">
          {translator('button_form_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleDialogWindow;
