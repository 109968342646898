/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import TocIcon from '@mui/icons-material/Toc';
import { IActiveEvent } from './Timeline';
import { getSelectedCustomerId, getTimelineEvents } from '../../../../redux/reducers';
import {
  EventType,
  IEventErrorCount,
  IEventRuntime,
  IState,
  ITimelineEvent,
  ISingleError,
  ISingleStep,
  IStep,
} from '../../../../types/types';
import FullscreenDialog from './FullscreenDialog';
import { useGetApiEffect } from '../../../Hooks/Hooks';
import ChartWrapper from '../../../Common/ReusableComponents/ChartWrapper/ChartWrapper';
import RuntimesChart from '../../../Charts/RuntimesChart';
import DataTableRuntimes from '../../../Charts/CustomTabs/DataTableRuntimes';
import DataTableRunErrorsByDay from '../../../Charts/CustomTabs/DataTableRunErrorsByDay';
import RunErrorsByDayChart from '../../../Charts/RunErrorsByDayChart';
import RunErrorsTotalChart from '../../../Charts/RunErrorsTotalChart';

type ComponentProps = {
  events: ITimelineEvent[];
  activeEvent: IActiveEvent;
  startDate: Date;
  endDate: Date;
  chartType: string;
};

interface IEventError {
  errorDescription: string;
  errorTime: string;
  errorLog: string;
}

interface ISingleEvent {
  event_guid: string;
  task_guid: string;
  customer_guid: string;
  task_name: string;
  vm_name: string;
  scheduled_start_time: Date;
  scheduled_stop_time: Date;
  actual_start_time: Date;
  actual_stop_time: Date;
  error_count: number;
  status: number;
  steps: ISingleStep[];
  errors: ISingleError[];
}

const filterData: (events: ITimelineEvent[], activeEvent: IActiveEvent) => IEventRuntime[] = (events, activeEvent) => {
  const eventVMReducer: (p: IEventRuntime[], c: ITimelineEvent) => IEventRuntime[] = (p, c) => {
    if (activeEvent.id === c.vmId) {
      const scheduledRuntime = c.scheduled_stop_time
        ? c.scheduled_stop_time.getTime() - c.scheduled_start_time.getTime()
        : 0;
      const actualRuntime =
        c.actual_start_time && c.actual_stop_time ? c.actual_stop_time.getTime() - c.actual_start_time.getTime() : 0;
      return p.length === 1
        ? [
            {
              scheduled: p[0].scheduled + scheduledRuntime,
              actual: p[0].actual + actualRuntime,
            },
          ]
        : [
            {
              scheduled: scheduledRuntime,
              actual: actualRuntime,
            },
          ];
    }

    return p;
  };

  const eventTaskReducer: (p: IEventRuntime[], c: ITimelineEvent) => IEventRuntime[] = (p, c) => {
    if (activeEvent.id === c.resourceId) {
      const scheduledRuntime = c.scheduled_stop_time
        ? c.scheduled_stop_time.getTime() - c.scheduled_start_time.getTime()
        : 0;
      const actualRuntime =
        c.actual_start_time && c.actual_stop_time ? c.actual_stop_time.getTime() - c.actual_start_time.getTime() : 0;
      return p.concat({
        scheduled: scheduledRuntime,
        actual: actualRuntime,
      });
    }

    return p;
  };

  const eventReducer: (p: IEventRuntime[], c: ITimelineEvent) => IEventRuntime[] = (p, c) => {
    if (activeEvent.id === c.id) {
      const scheduledRuntime = c.scheduled_stop_time
        ? c.scheduled_stop_time.getTime() - c.scheduled_start_time.getTime()
        : 0;
      const actualRuntime =
        c.actual_start_time && c.actual_stop_time ? c.actual_stop_time.getTime() - c.actual_start_time.getTime() : 0;
      return p.concat({
        scheduled: scheduledRuntime,
        actual: actualRuntime,
      });
    }

    return p;
  };

  return activeEvent.type === EventType.VM
    ? events.reduce(eventVMReducer, [])
    : activeEvent.type === EventType.Task
    ? events.reduce(eventTaskReducer, [])
    : activeEvent.type === EventType.Event
    ? events.reduce(eventReducer, [])
    : [];
};

const filterErrorData: (
  events: ITimelineEvent[],
  activeEvent: IActiveEvent,
  startDate: Date,
  endDate: Date,
) => IEventErrorCount[] = (events, activeEvent, startDate, endDate) => {
  const eventReducer: (p: Map<string, IEventErrorCount>, c: ITimelineEvent) => Map<string, IEventErrorCount> = (
    p,
    c,
  ) => {
    const process =
      (activeEvent.type === EventType.VM && activeEvent.id === c.vmId) ||
      (activeEvent.type === EventType.Task && activeEvent.id === c.resourceId) ||
      (activeEvent.type === EventType.Event && activeEvent.id === c.id);

    if (process) {
      const key = moment(c.scheduled_start_time).format('YYYY-MM-DD');
      const count = p.has(key) ? p.get(key)?.errors || 0 : 0;

      p.set(key, {
        date: key,
        errors: count + (c.errorCount || 0),
      });
    }
    return p;
  };

  const startMap = new Map<string, IEventErrorCount>();
  const current = moment(startDate);
  const end = moment(endDate);

  while (current.isBefore(end)) {
    const key = current.format('YYYY-MM-DD');
    startMap.set(key, {
      date: key,
      errors: 0,
    });
    current.add(1, 'day');
  }

  const result = events.reduce(eventReducer, startMap);

  return Array.from(result.values());
};

export const CustomizedAxisTick = (props: { x: number; y: number; stroke: string; payload: { value: string } }) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="end" fill="#666" fontSize={10} transform="rotate(-20)">
        {payload.value}
      </text>
    </g>
  );
};

const EventDetail = (props: ComponentProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [fullscreenType, setFullscreenType] = useState<string>('');

  const { events, activeEvent, startDate, endDate, chartType } = props;
  const { t } = useTranslation();

  //const customerId = useSelector<IState, string>(getSelectedCustomerId, shallowEqual);

  const isSingleEvent = activeEvent.id.length === 36;

  /**
   * Why are we making the same API request every time this component is called??? (4 times in index.tsx)
   * No wonder the dashboard view is slow as heck.
   */
  const [singleEvent, isLoadingSingleEvent, isSingleEventError, getSingleEvent] = useGetApiEffect<ISingleEvent>(
    `api/Events/${activeEvent.id}`,
    null,
  );

  useEffect(() => {
    if (activeEvent.type === EventType.Event && isSingleEvent) {
      getSingleEvent();
    }
  }, [activeEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const barData = filterData(events, activeEvent).map((v) => ({
    scheduled: v.scheduled / 60000,
    actual: v.actual / 60000,
  }));

  const createPieData = () => {
    let ok: number[] = [];
    let notOk: number[] = [];
    let ranEvents: ITimelineEvent[] = [];
    events.forEach((event) => {
      if (!!event.actual_start_time) {
        ranEvents.push(event);
      }
    });
    ranEvents.forEach((event) => {
      if (activeEvent.type === EventType.VM && activeEvent.id === event.vmId) {
        if (event.status === 4 || event.status === 5) {
          ok.push(event.status);
        } else if (event.status === 0 || event.status === 1 || event.status === 3 || event.status === 6)
          notOk.push(event.status);
      } else if (activeEvent.type === EventType.Event && activeEvent.id === event.id) {
        if (event.status === 4 || event.status === 5) {
          ok.push(event.status);
        } else if (event.status === 0 || event.status === 1 || event.status === 3 || event.status === 6)
          notOk.push(event.status);
      } else if (activeEvent.type === EventType.Task && activeEvent.id === event.resourceId) {
        if (event.status === 4 || event.status === 5) {
          ok.push(event.status);
        } else if (event.status === 0 || event.status === 1 || event.status === 3 || event.status === 6)
          notOk.push(event.status);
      }
    });
    return [
      { name: 'ok', value: ok.length },
      { name: 'nok', value: notOk.length },
    ];
  };

  let singleEventSteps: IStep[] = [];
  let stepDataForGraph: IStep[] = [];

  if (!!singleEvent) {
    singleEvent.steps.forEach((step) => {
      if (!step.step_information.startsWith('END')) {
        singleEventSteps.push({
          stepInformation: step.step_information,
          stepLog: step.step_log,
          databaseTimestamp: step.database_timestamp,
        });
        stepDataForGraph.push({
          stepInformation: step.step_information.startsWith('START')
            ? step.step_information.replace('START ', '')
            : step.step_information,
          stepLog: step.step_log,
          databaseTimestamp: moment(step.database_timestamp).format('DD.MM.YYYY hh:mm:ss'),
        });
      } else if (step.step_information.startsWith('END')) {
        singleEventSteps.push({
          stepInformation: step.step_information,
          stepLog: step.step_log,
          databaseTimestamp: step.database_timestamp,
        });
        stepDataForGraph.push({
          stepInformation: step.step_information.startsWith('END')
            ? step.step_information.replace('END ', '')
            : step.step_information,
          stepLog: step.step_log,
          databaseTimestamp: moment(step.database_timestamp).format('DD.MM.YYYY hh:mm:ss'),
        });
      }
    });
  }

  const createStepCountData = () => {
    let res;
    let startCounts;
    let formatted: any = [];
    if (!!singleEvent && !!singleEventSteps) {
      res = Object.values(
        singleEventSteps.reduce((a, { stepInformation }) => {
          a[stepInformation] = a[stepInformation] || { stepInformation, count: 0 };
          a[stepInformation].count++;
          return a;
        }, Object.create(null)),
      );

      startCounts = res.filter((obj: any) => {
        return !obj.stepInformation.startsWith('END ');
      });

      startCounts.forEach((obj: any) => {
        formatted.push({
          stepInformation: obj.stepInformation.replace('START ', ''),
          count: obj.count,
        });
      });
    }
    return formatted;
  };

  const parseStepTimes = (singleEventSteps: IStep[]) => {
    let startTimes: any = {};
    let totals: any = {};
    let finalArray: any = [];

    const formatted = createStepCountData();

    for (let i = 0; i < singleEventSteps.length; i++) {
      let date: any = new Date(singleEventSteps[i].databaseTimestamp);
      if (singleEventSteps[i].stepInformation.substr(0, 6) === 'START ') {
        let eventname = singleEventSteps[i].stepInformation.substr(6);
        startTimes[eventname] = date;
      } else if (singleEventSteps[i].stepInformation.substr(0, 4) === 'END ') {
        let eventname = singleEventSteps[i].stepInformation.substr(4);
        if (startTimes[eventname]) {
          if (totals[eventname] === undefined) {
            totals[eventname] = 0;
          }
          totals[eventname] += date - startTimes[eventname];
        } else {
          // error: end event with no matching start event
        }
      }
    }
    Object.entries(totals).forEach(([key, value]) => {
      finalArray.push({ eventName: key, timeElapsed: value });
    });
    const stepDurations = finalArray.map((event: any) => {
      return {
        stepInformation: event.eventName,
        timeElapsed: (event.timeElapsed / 60000).toFixed(2),
      };
    });

    let arr3 = stepDurations.map((itm: any) => ({
      ...formatted.find((item: any) => item.id === itm.id && item),
      ...itm,
    }));

    arr3.forEach((itm: any) => {});

    let averageTimes = arr3.map((itm: any) => ({
      eventName: itm.stepInformation,
      timeElapsed: (itm.timeElapsed / itm.count).toFixed(2),
    }));

    return averageTimes;
  };

  let singleEventErrors: IEventError[] = [];

  if (!!singleEvent) {
    singleEventErrors = singleEvent.errors.map((error) => {
      return {
        errorDescription: error.error_description,
        errorTime: moment(error.database_timestamp).format('DD.MM.YYYY hh:mm:ss'),
        errorLog: error.error_log,
      };
    });
  } else if (!singleEvent) {
    singleEventErrors = [
      {
        errorDescription: 'null',
        errorTime: 'null',
        errorLog: 'null',
      },
    ];
  }

  const pieData = createPieData();

  const errorData = filterErrorData(events, activeEvent, startDate, endDate);

  const handleOpenFullscreen = (diagram: string) => {
    if (!open) {
      setOpen(true);
      setFullscreenType(diagram);
    } else if (!!open) {
      setOpen(false);
      setFullscreenType('');
    }
  };

  const renderFullscreenDialog = () => {
    if (!!open) {
      return (
        <FullscreenDialog
          open={open}
          handleOpen={() => handleOpenFullscreen('')}
          fullscreenType={fullscreenType}
          fullWidth={true}
          chartType="bar"
          barData={barData}
          errorData={errorData}
          pieData={pieData}
          singleEventErrors={singleEventErrors}
          singleEventSteps={singleEventSteps}
          stepTimes={parseStepTimes(singleEventSteps)}
          stepCounts={createStepCountData()}
          isSingleEvent={!!isSingleEvent}
        />
      );
    } else {
      return null;
    }
  };

  /**
   * 22.04.2022: Replaced old charts with ChartWrapper and removed some dead code.
   * This whole file should be removed and refactored together with the index.tsx
   * AND perhaps even the API endpoints too (how data is received/handled)
   * The current design is awful. And I could not allocate more time to work in this for now.
   *
   * For future reference:
   *    - This component should not be making any API requests.
   *    - This view is rendered 4 times in the Dashboard view and each implementation of this component
   *      makes the EXACT SAME API request to the same endpoint... Should only happen once.
   *    - Why is this file 600 rows long? Why do we have all sorts of utility functions in this file?
   *      At the very minimum these should be separated.
   *    - Surely we can get rid of the if-else conditional renders as seen below? One solution could be
   *      to do these charts like VmUtilization is done in index.tsx.
   *    - Naming conventions. 'arr3'? Really?
   */

  if (chartType === 'runtimes') {
    return (
      <>
        {activeEvent && (
          <ChartWrapper
            title={t('datawindow-runtimes')}
            chart={<RuntimesChart data={barData} />}
            excelData={barData}
            additionalTabs={[
              {
                icon: <TocIcon />,
                content: <DataTableRuntimes data={barData} />,
              },
            ]}
          />
        )}
      </>
    );
  } else if (chartType === 'errorsByDay') {
    return (
      <>
        {activeEvent && (
          <ChartWrapper
            title={t('datawindow-errors')}
            chart={<RunErrorsByDayChart data={errorData} />}
            excelData={errorData}
            additionalTabs={[
              {
                icon: <TocIcon />,
                content: <DataTableRunErrorsByDay data={errorData} />,
              },
            ]}
          />
        )}
      </>
    );
  } else if (chartType === 'errorsTotal') {
    return (
      <>
        {activeEvent && (
          <ChartWrapper
            title={t('datawindow-finished')}
            chart={<RunErrorsTotalChart data={pieData} />}
            excelData={pieData}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default EventDetail;
