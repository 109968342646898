import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRunErrorsTotalChartProps } from './ChartProps';
import { Legend, Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import RunErrorsTotalChartTooltip from './Tooltips/RunErrorsTotalChartTooltip';
import ChartWrapperNoDataError from '../Common/ReusableComponents/ChartWrapper/ChartWrapperComponents/ChartWrapperNoDataError';

const RunErrorsTotalChart = (props: IRunErrorsTotalChartProps) => {
  const { data } = props;
  const legend = ['label-run-successful', 'label-run-unsuccessful'];
  const colors = ['#82ca9d', '#ff7961']; // green, red
  const { t } = useTranslation();

  const errorTotal = () => {
    return data.reduce((a: any, b: any) => a + (b['value'] || 0), 0);
  };

  if (!Array.isArray(data) || !data.length) {
    return <ChartWrapperNoDataError translationKey={'label-no-data'} />;
  }

  if (errorTotal() === 0) {
    return <ChartWrapperNoDataError translationKey={'label-unable-to-display-errors'} />;
  }

  const customizedLabel = (props: any) => {
    return (
      <text x={props.x} y={props.y} fill={props.fill} textAnchor={props.x > props.cx ? 'start' : 'end'}>
        {`${props.value} ${t('label-pieces')}`}
      </text>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Legend
          wrapperStyle={{ fontSize: '90%' }}
          payload={legend.map((translationKey: any, index: any) => ({
            id: t(translationKey),
            value: t(translationKey),
            color: colors[index],
          }))}
        />
        <Pie data={data} dataKey="value" isAnimationActive={false} label={customizedLabel}>
          <Cell key={data[0]['name']} fill={colors[0]} />
          <Cell key={data[1]['name']} fill={colors[1]} />
        </Pie>
        <Tooltip content={<RunErrorsTotalChartTooltip data={data} />} animationDuration={500} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default RunErrorsTotalChart;
