import React, { FunctionComponent } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from '@reach/router';
import { usePermission } from '../Hooks/Permissions';
import { getUserIsAuthenticated } from '../../redux/reducers';
import { IState, Role } from '../../types/types';

/*
 * Used inside <Router>
 */
const PrivateRoute: FunctionComponent<{ comp: FunctionComponent; roles: Role[] } & RouteComponentProps> = ({
  comp: Comp,
  roles,
  ...rest
}) => {
  const isAuthenticated = useSelector<IState, boolean>(getUserIsAuthenticated, shallowEqual);
  const [hasPermission] = usePermission(roles);

  if (!isAuthenticated) {
    window.sessionStorage.setItem('local-url', window.location.pathname);
    return <Redirect noThrow to="login" />;
  }

  if (!hasPermission) {
    return <Redirect noThrow to="/" />;
  }

  return (
    <div style={{ height: 'calc(100vh - 116px)', overflowX: 'auto', overflowY: 'scroll' }}>
      <Comp {...rest} />
    </div>
  );
};

export default PrivateRoute;
