//General
import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// ChartWrapper helpers
import { IChartBoxContentProps } from '../../ChartWrapperTypes';

/**
 * The actual content of the ChartBox card that is separated from the header (title & buttons) part. May contain conditional rendering.
 *
 * If the 'state' prop was provided
 *  - display a loading icon during loading
 *  - on error, display an error message
 */
const ChartBoxContent = (props: IChartBoxContentProps) => {
  const { chart, state } = props;
  const { t } = useTranslation();
  if (!!state) {
    if (state.isLoading) {
      return (
        <Box sx={{ height: 310 }}>
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      );
    } else if (state.isError) {
      return (
        <Box sx={{ height: 310 }}>
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Typography>{t('label-no-data')}</Typography>
          </Grid>
        </Box>
      );
    }
  }
  return <Box sx={{ height: 310 }}>{chart}</Box>;
};

export default ChartBoxContent;
