/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import SplitFormFrame from '../../../FormFrames/SplitFormFrame';
import { FormSelect, FormTextField } from '../../../FormFields';
import { useDeleteApiEffect, useGetApiEffect, usePutApiEffect } from '../../../Hooks/Hooks';
import { IBusinessData, ITask } from '../../../../types/types';
import { useCollection } from '../../../Hooks/Collections';

import Addbusinessdata from './AddBusinessData';

const functionId = '00000000-0000-0000-0000-000000000000';

interface IComponentProps {
  task: ITask;
}

interface IFormObject {
  businessdataId: string;
  businessdataName: string;
  businessdataScreenName: string;
  businessdataDescription: string;
  businessdataExtraInformation: string;
  businessdataType: string;
  businessdataUnit: string;
}

interface IBusinessDataUpdate {
  id: string;
  customer_id: string;
  task_id: string;
  //data: IBusinessData;
  business_data_id: number;
  screen_name: string;
  name: string;
  description: string;
  extra_information: string;
  type: string;
  unit: string;
}

interface IBusinessDataDelete {
  id: string;
  customer_id: string;
  task_id: string;
  business_data_id: number;
}

const validationSchema = yup.object<IFormObject>({
  businessdataId: yup.string().required(),
  businessdataName: yup.string().required(),
  businessdataScreenName: yup.string().required(),
  businessdataDescription: yup.string().required(),
  businessdataType: yup.string().required(),
  businessdataExtraInformation: yup.string(),
  businessdataUnit: yup.string(),
});

const getAddComponent: (
  id: string,
  customerId: string,
  taskId: string,
  handlePostUpdate: () => void,
) => (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (
  id,
  customerId,
  taskId,
  handlePostUpdate,
) => {
  return (props) => {
    return (
      <Addbusinessdata
        id={id}
        customerId={customerId}
        taskId={taskId}
        data={props.values}
        onCancel={props.onCancel}
        handlePostUpdate={handlePostUpdate}
      />
    );
  };
};

const BusinessDataList = (props: IComponentProps) => {
  const dataTypes = useCollection(15);
  const { task } = props;

  const [data, isLoading, isError, getData] = useGetApiEffect<IBusinessData[]>(
    `api/BusinessData?taskId=${task.task_guid}&id=${functionId}&customerId=${task.customer_guid}`,
    null,
  );

  const [response, isUpdating, isPostError, postData] = usePutApiEffect<IBusinessDataUpdate, void>(
    `api/BusinessData`,
    null,
  );
  const [isDeleting, isDeleteError, deleteData] = useDeleteApiEffect<IBusinessDataDelete>(`api/BusinessData`);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const typeToOption = (type: string) => dataTypes.find((x) => x.id === type)?.value || '';

  const businessdatas =
    data &&
    data.map((businessdata) => {
      return {
        businessdataId: '' + businessdata.business_data_id,
        businessdataName: businessdata.business_data_name,
        businessdataScreenName: businessdata.business_data_screen_name,
        businessdataDescription: businessdata.business_data_description,
        businessdataExtraInformation: businessdata.business_data_extra_information || '',
        businessdataType: typeToOption(businessdata.business_data_type),
        businessdataUnit: businessdata.business_data_unit || '',
      };
    });

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    return businessdatas.find((d) => d.businessdataId === id);
  };

  const handleSubmit = (data: any) => {
    const selectionToDataType = (selection: string) => dataTypes.find((x) => x.value === selection)?.id || '';
    const payload = {
      id: functionId,
      business_data_id: parseInt(data.businessdataId),
      customer_id: task.customer_guid,
      task_id: task.task_guid,
      name: data.businessdataName,
      screen_name: data.businessdataScreenName,
      description: data.businessdataDescription,
      extra_information: data.businessdataExtraInformation,
      type: selectionToDataType(data.businessdataType),
      unit: data.businessdataUnit || '',
    };

    postData(payload);
  };

  const handleDelete: (id: string | number) => void = (id) => {
    const payload = {
      id: functionId,
      customer_id: task.customer_guid,
      task_id: task.task_guid,
      business_data_id: parseInt(id.toString()),
    };

    deleteData(payload);

    setTimeout(() => {
      getData();
    }, 2000);
  };

  const handlePostUpdate = () => {
    setTimeout(() => {
      getData();
    }, 2000);
  };

  return (
    <div>
      <SplitFormFrame<IFormObject>
        tableLabel={'Business data metadata'}
        formLabel={'Business data'}
        validationSchema={validationSchema}
        data={businessdatas}
        idProperty="businessdataId"
        properties={[
          { key: 'businessdataId', label: 'Id' },
          { key: 'businessdataScreenName', label: 'Screen name' },
          { key: 'businessdataName', label: 'name' },
          { key: 'businessdataDescription', label: 'Description' },
        ]}
        isLoading={isLoading}
        isUpdating={isUpdating}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="businessdataId" label={'Id'} readonly={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField name="businessdataScreenName" label={'Screen Name'} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField name="businessdataName" label={'Name'} readonly={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="businessdataType" label={'Type'} readonly={true} value={values.businessdataType}>
                {dataTypes.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField name="businessdataUnit" label={'Unit'} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField name="businessdataDescription" label={'Description'} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField name="businessdataExtraInformation" label={'Extra information'} readonly={!isEditing} />
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent(functionId, task.customer_guid, task.task_guid, handlePostUpdate)}
      ></SplitFormFrame>
    </div>
  );
};

export default BusinessDataList;
