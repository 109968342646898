export const listStyles = {
  height: 203,
  maxHeight: 203,
  overflow: 'auto',
  maxWidth: 330,
  '&& .Mui-selected, && .Mui-selected:hover': {
    bgcolor: '#206FB7',
    '&, & .MuiListItemIcon-root': {
      color: 'white',
    },
  },
  '& .MuiListItemButton-root:hover': {
    bgcolor: '#096dd926',
  },
};

export const listItemButtonStyles = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  height: '50px',
};

export const listItemTextStyles = {
  height: '100%', // Expand the actual button size so that it covers the whole element (improves UX)
  span: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

export const listItemIconStyles = {
  justifyContent: 'space-around',
};

export const titleStyles = {
  display: 'flex',
  justifyContent: 'center',
};

export const buttonGridStyles = {
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'center',
};

export const serviceProviderGridStyles = {
  display: 'flex',
  justifyContent: 'center',
};
