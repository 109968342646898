import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormTextField, FormTwinMultiSelect, FormSelect } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { IRole } from './Types';

interface IFormObject {
  first_name: string;
  last_name: string;
  email: string;
  role_id: number;
}
const validationSchema = yup.object<IFormObject>({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  role_id: yup.number(),
});

interface IProps {
  customerId: string;
  roles: IRole[];
  onCancel: () => void;
}
type Props = IProps;

const GuestUser: React.FC<Props> = (props) => {
  const { roles, customerId, onCancel } = props;
  const { t } = useTranslation();
  const id = 'aaa';
  const [response, isLoading, isError, postData] = usePostApiEffect<IFormObject, void>(
    `customers/${customerId}/users`,
    null,
    'pythonApi',
  );
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]);

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = (data, setIsSubmitting) => {
    setIsSubmittingFn(() => setIsSubmitting);
    // create new guest user and add to customer
    postData(data);
  };

  const formData: IFormObject = {
    first_name: '',
    last_name: '',
    email: '',
    role_id: roles[0] ? roles[0].role_id : 0,
  };

  return (
    <ModalFormFrame<IFormObject>
      label={t('label-add-new-user')}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="first_name" label={t('label-user-first-name')} readonly={isSubmitting} />
            <FormTextField name="last_name" label={t('label-user-last-name')} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="email" label={t('label-user-email')} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12}>
            <FormSelect name="role_id" label="label-user-role" readonly={isSubmitting}>
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>
                  {role.role_name}
                </option>
              ))}
            </FormSelect>
            {/* <FormTwinMultiSelect
              name="roles"
              label={t('label-user-roles')}
              availableValues={roles.map((role) => role.role_name)}
              readonly={isSubmitting}
            ></FormTwinMultiSelect> */}
          </Grid>
        </Grid>
      )}
    />
  );
};

export default GuestUser;
