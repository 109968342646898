import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { useField } from 'formik';
import React from 'react';

interface IProps {
  name: string;
  label: string;
  readonly?: boolean;
  required?: boolean;
  TextFieldProps?: TextFieldProps;
}

const FormTimeField: React.FC<IProps> = (props) => {
  const { label, name, readonly, required, TextFieldProps } = props;
  const [field, meta] = useField(name);

  const errorText = meta.touched && meta.error && !readonly ? meta.error : '';
  return (
    <TextField
      {...field}
      {...TextFieldProps}
      label={label}
      error={!!errorText}
      helperText={errorText}
      required={required}
      disabled={!!readonly}
      variant="outlined"
      type="time"
    />
  );
};

export default FormTimeField;
