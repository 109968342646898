/* eslint-disable jsx-a11y/aria-role */
/**
 * For now this is just a wrapper for the MUI Alert component
 * The point is to have a unified info box component across the application
 * where the possible changes affect every implementation.
 */

import React from 'react';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import './InfoBoxStyles.css';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  }),
);

interface IProps {
  variant: any; // `outlined` or `filled`
  severity: any; // `error`, `warning`, `info` or `success`
  text: string;
  additionalText?: any; //string
  sx?: any;
}

const InfoBox = (props: IProps) => {
  const { variant, severity, text, additionalText } = props;
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <Alert
      action={
        !!additionalText ? (
          <Tooltip className="InfoBox-AdditionalText-style" title={additionalText}>
            <strong role="read-more">{t('label-read-more')}</strong>
          </Tooltip>
        ) : null
      }
      classes={{ root: styles.root }}
      variant={variant}
      severity={severity}
    >
      {text}
    </Alert>
  );
};

export default InfoBox;
