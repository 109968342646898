import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserName } from '../../redux/reducers';
import { AppBar, Toolbar } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IState } from '../../types/types';
import LanguageMenu from './LanguageMenu';
import LogoBar from './LogoBar';
import MobileMenu from './MobileMenu';
import NavBar from './NavBar';
import UserMenu from './UserMenu';
import CustomersDialog from '../../components/Pages/Customers/CustomersDialog/CustomersDialog';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    logobar: {
      backgroundColor: '#206FB7',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 0,
    },
  }),
);

const TopHeader = () => {
  const userName = useSelector<IState, string>(getUserName, shallowEqual);
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar className={classes.logobar}>
        <LogoBar />
        <LanguageMenu />
        {!!userName && <UserMenu userName={userName} />}
        {!!userName && <CustomersDialog initialOpen={false} />}
      </Toolbar>
      <Toolbar style={{ backgroundColor: '#206FB7' }}>
        {!!userName && (
          <>
            <MobileMenu />
            <NavBar />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopHeader;
