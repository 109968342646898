import { useQuery } from 'react-query';
import axios from 'axios';
import { apiUrl, apiVersion } from '../../../configs/apiConfig';
import { acquireSilentToken } from '../APIManagementQueries/acquireSilentToken';
import { ISchedule } from '../../../components/Forms/Tasks/Schedule/ScheduleTypes';

/**
 * Workaround for making the schedules refetching to work
 * after deletion. Couldn't make it work via Redux so this just makes
 * the request with React Query instead.
 */
const GetSchedules = (customerGuid: string, taskGuid?: string) => {
  return useQuery(
    ['getSchedules', customerGuid],
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(apiUrl + '/api/schedules', {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          'api-version': apiVersion,
          customerId: customerGuid,
        },
      });
      const d: ISchedule[] = data;
      if (!!taskGuid) {
        return d.filter((schedule: ISchedule) => schedule.task_id === taskGuid);
      } else {
        return d;
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
};

export default GetSchedules;
