import React from 'react';
import TabView from '../../Common/TabView';
import { ITabItem } from '../../Common/TabView/TabViewUtils';
import { Collections, Texts, Roles, PermissionManagement } from '../../Forms/Admin';

const Admin = () => {
  const tabs: ITabItem[] = [
    {
      displayName: 'Collection Management',
      component: <Collections />,
    },
    {
      displayName: 'Text Management',
      component: <Texts />,
    },
    {
      displayName: 'Role Management',
      component: <Roles />,
    },
    {
      displayName: 'Permission management',
      component: <PermissionManagement />,
    },
  ];

  return (
    <>
      <TabView tabs={tabs} selectedParentTab="admin" />
    </>
  );
};

export default Admin;
