import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from './FormFrame';
import FormTable, { IFormTableExtraProps } from './FormTable';

import CollapseFormTable from './CollapseFormTable';

interface IProps<T extends {}> {
  validationSchema: yup.ObjectSchema<T>;
  data: T[];
  properties: {
    key: keyof T;
    label: string;
  }[];
  isLoading?: boolean;
  isUpdating?: boolean;
  idProperty: keyof T;
  fetchDataItem: (id: number | string) => T | undefined;
  onSubmit: any;
  onDelete?: (id: number | string) => void;
  render?: (props: { values: T; isEditing: boolean }) => React.ReactNode;
  renderAdd?: (props: { values?: T; onCancel: () => void }) => React.ReactNode;
  tableLabel: string;
  formLabel: string;
  pagination?: boolean;
  rowsPerPage?: number;
  limitHeight?: boolean;
  duplicateDisabled?: boolean;
  buttonsDisabled?: boolean;
  addDisabled?: boolean;
  editRendered?: boolean;
  handleSearch?: any;
  searchValue?: string;
  collapse?: boolean;
  collapseData?: any;
  excel?: boolean;
  dirtySave?: any;
  onDirtySave?: any;
}

type Props<T> = IProps<T>;

function SplitFormFrame<T>(props: React.PropsWithChildren<Props<T>>) {
  const {
    validationSchema,
    data,
    idProperty,
    properties,
    isLoading,
    isUpdating,
    fetchDataItem,
    onSubmit,
    onDelete,
    render,
    renderAdd,
    tableLabel,
    formLabel,
    children,
    pagination,
    rowsPerPage,
    limitHeight,
    duplicateDisabled,
    buttonsDisabled,
    addDisabled,
    editRendered,
    handleSearch,
    searchValue,
    collapse,
    collapseData,
    excel,
    dirtySave,
    onDirtySave,
  } = props;

  const [selectedItem, setSelectedItem] = useState<T>();
  const [selectedItemId, setSelectedItemId] = useState<number | string>();
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  useEffect(() => {
    if (data && selectedItem) {
      if (!data.find((d) => d[idProperty] === selectedItem[idProperty])) {
        setSelectedItemId(undefined);
        setSelectedItem(undefined);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isUpdating || false);
    }
    if (isUpdating === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isUpdating]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleItemChange = (id: number | string) => {
    setSelectedItemId(id);
    setSelectedItem(fetchDataItem(id));
  };

  const handleSubmit: (data: T, setIsSubmitting: SetIsSubmittingFn, setStopEditing: SetStopEditingFn) => void = (
    data,
    setIsSubmitting,
    setStopEditing,
  ) => {
    setIsSubmittingFn(() => setIsSubmitting);
    setStopEditingFn(() => setStopEditing);

    onSubmit(data);
  };

  const handleDelete: (id: number | string) => void = (id) => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const handleRenderAdd: (duplicate: boolean, onCancel: () => void) => React.ReactNode = (duplicate, onCancel) => {
    return renderAdd && renderAdd({ values: duplicate ? selectedItem : undefined, onCancel });
  };

  const tableData: (T & IFormTableExtraProps)[] = data && data.map((d) => ({ ...d, id: String(d[idProperty]) }));

  if (!!collapse) {
    return (
      <>
        <div>
          <CollapseFormTable
            collapseData={collapseData}
            label={tableLabel}
            data={tableData}
            properties={properties}
            isLoading={isLoading || false}
            selectedId={selectedItemId}
            onSelectRow={handleItemChange}
            onDelete={handleDelete}
            render={handleRenderAdd}
            pagination={pagination}
            rowsPerPage={rowsPerPage}
            limitHeight={limitHeight}
            duplicateDisabled={duplicateDisabled}
            buttonsDisabled={buttonsDisabled}
            handleSearch={handleSearch}
            searchValue={searchValue}
          ></CollapseFormTable>
        </div>
        <div>
          <FormFrame
            label={formLabel}
            validationSchema={validationSchema}
            initialValues={selectedItem}
            onSubmit={handleSubmit}
            render={render}
            editRendered={editRendered}
          >
            {children}
          </FormFrame>
        </div>
      </>
    );
  } else
    return (
      <>
        <div>
          <FormTable
            label={tableLabel}
            data={tableData}
            properties={properties}
            isLoading={isLoading || false}
            selectedId={selectedItemId}
            onSelectRow={handleItemChange}
            onDelete={!!onDelete ? handleDelete : undefined}
            render={handleRenderAdd}
            pagination={pagination}
            rowsPerPage={rowsPerPage}
            limitHeight={limitHeight}
            duplicateDisabled={duplicateDisabled}
            buttonsDisabled={buttonsDisabled}
            addDisabled={addDisabled}
            handleSearch={handleSearch}
            searchValue={searchValue}
            excel={excel}
          ></FormTable>
        </div>
        <div>
          <FormFrame
            label={formLabel}
            validationSchema={validationSchema}
            initialValues={selectedItem}
            onSubmit={handleSubmit}
            render={render}
            editRendered={editRendered}
            dirtySave={dirtySave}
            onDirtySave={onDirtySave}
          >
            {children}
          </FormFrame>
        </div>
      </>
    );
}

export default SplitFormFrame;
