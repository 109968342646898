import { useQuery } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf, newApiConf } from '../../../../configs/apiConfig';

const FetchOivaAuthorization = () => {
  return useQuery(
    'auth',
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(newApiConf.apiUrl + '/permissions/oiva', {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return data;
    },
    {
      staleTime: 5 * 60 * 1000, // refetch authorization every five minutes
    },
  );
};
export default FetchOivaAuthorization;
