import React from 'react';
import { Hidden, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link, RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Role } from '../../types/types';
import { usePermission } from '../Hooks/Permissions';
import routes from './Routes';
import FetchOivaAuthorization from '../../services/api/APIManagementQueries/Authorization/AuthorizationQuery';

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    navBar: {
      flexGrow: 1,
    },
    navList: {
      display: 'flex',
      flexDirection: 'row' as 'row',
      padding: 0,
    },
    navLink: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
      padding: '10px 15px',
      marginRight: 10,
      '&:hover': {
        backgroundColor: '#2F518A',
      },
      '&[aria-current="page"]': {
        backgroundColor: '#2F518A',
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
);

const NavigationLink: React.FC<{ to: string; label: string; roles: Role[] }> = (props) => {
  const { to, label, roles } = props;

  const classes = useStyles(props);

  const { t } = useTranslation();
  const [hasPermission] = usePermission(roles);

  return hasPermission ? (
    <Link to={to} className={classes.navLink}>
      <Typography color="inherit">{t(label)}</Typography>
    </Link>
  ) : null;
};

type Props = RouteComponentProps;

const NavBar: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);

  const { isLoading, data } = FetchOivaAuthorization();

  const isAdmin = !isLoading && !!data ? !!data.find((item: any) => item === 'administration/read') : false;

  return (
    <div className={classes.navBar}>
      <Hidden only={['xs', 'sm']}>
        <div className={classes.navList}>
          {routes.map((item) =>
            item.path === '/admin' && !isAdmin ? null : (
              <NavigationLink key={item.path} to={item.path} label={item.label} roles={item.roles} />
            ),
          )}
        </div>
      </Hidden>
    </div>
  );
};

export default NavBar;
