import React, { ReactNode, useEffect, useState } from 'react';
import { generateUtilizationData } from './WindowUtils/VmUtilizationWindowUtils';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material/Select';
import TocIcon from '@mui/icons-material/Toc';
import VmUtilizationChart from '../../../../Charts/VmUtilizationChart';
import ChartWrapper from '../../../../Common/ReusableComponents/ChartWrapper/ChartWrapper';
import DataTableVmUtilization from '../../../../Charts/CustomTabs/DataTableVmUtilization';

interface IComponentProps {
  events: any;
  selectedVm: string;
  startDate: Date;
  endDate: Date;
}

/**
 * Pre-processes the VM utilization data and renders the chart
 */
const VmUtilization = (props: IComponentProps) => {
  const { t } = useTranslation();
  const { events, selectedVm, startDate, endDate } = props;
  const legendUsedTime = ['label-unused-time', 'label-reserved-time', 'label-executed-time', 'label-overtime'];
  const legendScheduledTime = ['label-unused-time', 'label-scheduled-time'];
  const dataSetOptions = { 'label-used-time': 'used', 'label-scheduled-time': 'scheduled' };
  const [option, setOption] = useState('used');
  const [legend, setLegend] = useState<string[]>(legendUsedTime);
  const [dataSet, setDataSet] = useState<any>(generateUtilizationData(option, selectedVm, events, startDate, endDate));

  useEffect(() => {
    setDataSet(generateUtilizationData(option, selectedVm, events, startDate, endDate));
  }, [selectedVm, t, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    let inputOption = (event.target as HTMLInputElement).value;
    if (inputOption === 'used') {
      setDataSet(generateUtilizationData('used', selectedVm, events, startDate, endDate));
      setLegend(legendUsedTime);
    } else if (inputOption === 'scheduled') {
      setDataSet(generateUtilizationData('scheduled', selectedVm, events, startDate, endDate));
      setLegend(legendScheduledTime);
    }
    setOption(inputOption);
  };

  return (
    <ChartWrapper
      title={t('label-vm-utilization')}
      chart={
        <VmUtilizationChart
          t={t}
          data={dataSet}
          dataSetOptions={dataSetOptions}
          handleSetChange={handleSetChange}
          selectedOption={option}
          legend={legend}
          timespan={{ start: startDate, end: endDate }}
          vm={selectedVm}
        />
      }
      excelData={dataSet}
      additionalTabs={[
        {
          icon: <TocIcon />,
          content: (
            <DataTableVmUtilization
              data={dataSet}
              columnTranslations={['label-in-minutes', 'label-in-hours', 'label-percentage-share']}
              legend={legend}
            />
          ),
        },
      ]}
    />
  );
};

export default VmUtilization;
