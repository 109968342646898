import Slider from '@material-ui/lab/Slider';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import TreeViewNode from './TreeViewNode';
import { IDataNode } from '../../../../types/types';

const TreeViewContainer = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
`;

const ZoomableContainer = styled.div<{ scale: number }>`
  transform-origin: 0% 0%;
  transform: scale(${({ scale }) => 0.2 + 0.03 * scale + ', ' + (0.2 + 0.03 * scale)});
  padding: 8px 0;
`;

const ZoomSliderContainer = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  height: 100px;
`;

interface IProps {
  dataTree: IDataNode;
  onNodeSelect: (uniqueId: string) => void;
  onNodeAddChild: (uniqueId: string) => void;
  onNodeAddChildren: (uniqueId: string, childNameArray: string[]) => void;
  onNodeAddSibling: (uniqueId: string) => void;
  onNodeRemove: (uniqueId: string) => void;
  onNodeSelectUp: () => void;
  onNodeSelectDown: () => void;
  onNodeSelectLeft: () => void;
  onNodeSelectRight: () => void;
  selectedUniqueId: string;
  selectedIndexPath: number[];
}

type Props = IProps;

const TreeView = (props: Props) => {
  // static propTypes = {
  //   dataTree: propTypes.object,
  //   onNodeSelect: propTypes.func.isRequired,
  //   onNodeAddChild: propTypes.func.isRequired,
  //   onNodeAddChildren: propTypes.func.isRequired,
  //   onNodeAddSibling: propTypes.func.isRequired,
  //   onNodeRemove: propTypes.func.isRequired,
  //   onNodeSelectUp: propTypes.func.isRequired,
  //   onNodeSelectDown: propTypes.func.isRequired,
  //   onNodeSelectLeft: propTypes.func.isRequired,
  //   onNodeSelectRight: propTypes.func.isRequired,
  //   selectedUniqueId: propTypes.string,
  //   selectedIndexPath: propTypes.array,
  // };

  // static defaultProps = {
  //   dataTree: {},
  // };

  // state = {
  //   zoom: 50,
  // };

  const {
    //dataTree,
    onNodeSelect,
    onNodeAddChild,
    onNodeAddChildren,
    onNodeAddSibling,
    onNodeRemove,
    onNodeSelectUp,
    onNodeSelectDown,
    onNodeSelectLeft,
    onNodeSelectRight,
    selectedUniqueId,
    //selectedIndexPath,
  } = props;

  const dataTree = props.dataTree || {};

  const [zoom, setZoom] = useState(35);

  // componentDidMount = () => {
  //   document.addEventListener('keydown', onKeyDownListener, false);
  //   //    document.addEventListener("keyup", this.onKeyUpListener, false);
  //   //document.addEventListener('scroll', onScrollListener, false);
  // };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDownListener, false);
    return () => document.removeEventListener('keydown', onKeyDownListener, false);
  });

  const handleChangeZoom = (event: React.ChangeEvent<{}>, zoom: number) => {
    setZoom(zoom);
  };

  const onKeyDownListener = (e: KeyboardEvent) => {
    switch (e.code) {
      case 'ArrowUp':
        onNodeSelectUp();
        break;
      case 'ArrowDown':
        onNodeSelectDown();
        break;
      case 'ArrowLeft':
        onNodeSelectLeft();
        break;
      case 'ArrowRight':
        onNodeSelectRight();
        break;
      default:
    }
    //e.preventDefault();
    return false;
  };

  // const onKeyUpListener = (e: KeyboardEvent) => {
  //   onKeyUp(e.code);
  //   e.preventDefault();
  //   return false;
  // };

  // const renderBranch = (
  //   dataTree,
  //   onNodeSelect,
  //   onNodeAddChild,
  //   onNodeAddChildren,
  //   onNodeAddSibling,
  //   onNodeRemove,
  //   selectedUniqueId,
  //   selectedIndexPath,
  // ) => {
  //   return (
  //     <div>
  //       <TreeViewNode
  //         {...dataTree}
  //         key={dataTree.uniqueId}
  //         onNodeSelect={onNodeSelect}
  //         onNodeAddChild={onNodeAddChild}
  //         onNodeAddChildren={onNodeAddChildren}
  //         onNodeAddSibling={onNodeAddSibling}
  //         onNodeRemove={onNodeRemove}
  //         isSelected={dataTree.uniqueId === selectedUniqueId}
  //       />
  //       {dataTree.children &&
  //         dataTree.children.reduce((renderedNodes: IDataNode[], subTree: IProps) => renderedNodes.concat(renderBranch(...subTree)), [])}
  //     </div>
  //   );
  // };

  return (
    <TreeViewContainer>
      <ZoomableContainer scale={zoom}>
        <TreeViewNode
          {...dataTree}
          key={dataTree.uniqueId}
          onNodeSelect={onNodeSelect}
          onNodeAddChild={onNodeAddChild}
          onNodeAddChildren={onNodeAddChildren}
          onNodeAddSibling={onNodeAddSibling}
          onNodeRemove={onNodeRemove}
          selectedUniqueId={selectedUniqueId}
          indexPath={[]}
        />
      </ZoomableContainer>
      <ZoomSliderContainer>
        <Slider value={zoom} aria-labelledby="label" onChange={handleChangeZoom} vertical />
      </ZoomSliderContainer>
    </TreeViewContainer>
  );
};

export default TreeView;
