/**
 * Makes mocking less painful
 */
import { authProvider, parameters } from '../../auth/authProvider';

const acquireSilentToken = async () => {
  const token = (await authProvider.acquireTokenSilent(parameters)).accessToken;
  return token;
};

export { acquireSilentToken };
