import { useMutation } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';
/**
 * A mutation hook that can be used to add new role in to the database.
 * @returns useMutation hook
 */
const CreateRole = () => {
  return useMutation(async (payload: any) => {
    const bearerToken = await acquireSilentToken();
    return await axios.post(
      apimConf.apiManagementEndpoint + '/users-and-roles/create-role',
      JSON.stringify({
        scope: payload.scope,
        description_fi: payload.description_fi,
        description_en: payload.description_en,
        name: payload.name,
      }),
      {
        headers: {
          'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  });
};

export default CreateRole;
