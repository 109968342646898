import React, { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AzureAD } from 'react-aad-msal';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import themeOverrides from './configs/ThemeOverrides';
import { templateTheme } from './configs/theme';
import store from './redux/store';
import { authProvider } from './services/auth/authProvider';
import Layout from './components/Layout';

import './i18n';
import LoadingScreen from './components/Common/LoadingScreen';

const baseTheme = createMuiTheme(templateTheme);
const overrides = themeOverrides(baseTheme);
const muiTheme = createMuiTheme({ ...baseTheme, ...overrides } as any);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      retry: false,
    },
  },
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      document.title = `OIVA - ${process.env.NODE_ENV}`;
    }
  }, []);
  return (
    // The provider component makes the redux store available to components further down in the hierarchy
    <Provider store={store}>
      {/* The top-level router component */}
      <Router>
        {/* 
        Wraps the app with Azure AD authentication functionality
        https://github.com/syncweek-react-aad/react-aad
        Uses Microsoft Authentication Library (MSAL) for JS under the hood
        https://github.com/AzureAD/microsoft-authentication-library-for-js
        */}
        <AzureAD provider={authProvider} reduxStore={store} forceLogin={true}>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<LoadingScreen />}>
                <Layout />
              </Suspense>
            </QueryClientProvider>
          </MuiThemeProvider>
        </AzureAD>
      </Router>
    </Provider>
  );
}

export default App;
