/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { Grid } from '@material-ui/core';
import { IRoleCreate } from '../../../../types/types';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormTextField, FormSelect } from '../../../FormFields';

interface IProps {
  data?: any;
  onCancel: () => void;
  createRoleMutation: any;
  createRoleMutationFn: (role: IRoleCreate) => void;
}

const validationSchema = yup.object<IRoleCreate>({
  name: yup.string(),
  description_en: yup.string(),
  description_fi: yup.string(),
  scope: yup.string(),
});

const SCOPES = ['Customer', 'Service provider', 'OIVA'];

const initialValue: IRoleCreate = {
  name: '',
  description_en: '',
  description_fi: '',
  scope: SCOPES[0],
};

const AddRole = (props: IProps) => {
  const { onCancel, createRoleMutation, createRoleMutationFn } = props;
  const { t: translator } = useTranslation();
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(createRoleMutation.isLoading);
    }
    if (!isSubmitting && createRoleMutation.isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !createRoleMutation.isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [createRoleMutation.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const createNewRole = (role: IRoleCreate) => {
    createRoleMutationFn(role);
  };

  return (
    <ModalFormFrame<IRoleCreate>
      label="label-form-add-role"
      validationSchema={validationSchema}
      initialValues={initialValue}
      onSubmit={createNewRole}
      onCancel={onCancel}
      render={() => (
        <Grid item xs={12}>
          <FormTextField required={true} name="name" label={translator('label-role-name')} readonly={false} />
          <FormTextField
            required={true}
            name="description_en"
            label={translator('label-role-description-en')}
            readonly={false}
          />
          <FormTextField
            required={true}
            name="description_fi"
            label={translator('label-role-description-fi')}
            readonly={false}
          />
          <FormSelect required={true} name="scope" label={translator('label-role-scope')} readonly={false}>
            {SCOPES.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </FormSelect>
        </Grid>
      )}
    />
  );
};

export default AddRole;
