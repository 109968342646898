import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import FormatToNDecimals from '../../Common/ReusableUtilityFunctions/FormatToNDecimals';
import { formatDateToFinnishStyle } from '../../Common/ReusableUtilityFunctions/DateUtils';

interface DataTableConsumptionProps {
  data: any;
  columnTranslations: string[];
  dataKeys: string[];
}

const DataTableConsumption = (props: DataTableConsumptionProps) => {
  const { data, columnTranslations, dataKeys } = props;
  const { t } = useTranslation();

  /**
   * Format numbers and add € suffix
   */
  const renderCells = (node: any) => {
    const cells: JSX.Element[] = [];
    dataKeys.forEach((key, id) => {
      if (typeof node[key] === 'number') {
        cells.push(
          <TableCell key={id} align="center">
            {FormatToNDecimals(node[key], 2)}€
          </TableCell>,
        );
      } else {
        // The last remaining column is the date column which is displayed as a string
        // If new column are added, then this function needs slight reworking.
        cells.push(
          <TableCell key={id} align="center">
            {formatDateToFinnishStyle(node[key])}
          </TableCell>,
        );
      }
    });
    return cells;
  };

  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {columnTranslations.map((translation, id) => (
              <TableCell key={id} align="center">
                {t(translation)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((node: any, id: number) => {
            return <TableRow key={id}>{renderCells(node)}</TableRow>;
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default DataTableConsumption;
