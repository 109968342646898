/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormSelect, FormTextField } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { IBusinessData } from '../../../../types/types';
import { useCollection } from '../../../Hooks/Collections';

interface IProps {
  id: string;
  customerId: string;
  taskId: string;
  data?: IFormObject;
  onCancel: () => void;
  handlePostUpdate: () => void;
}

interface IFormObject {
  businessdataName: string;
  businessdataScreenName: string;
  businessdataDescription: string;
  businessdataExtraInformation: string;
  businessdataType: string;
  businessdataUnit: string;
}

interface IBusinessDataCreate {
  id: string;
  customer_id: string;
  task_id: string;
  screen_name: string;
  name: string;
  description: string;
  extra_information: string;
  type: string;
  unit: string;
}

const validationSchema = yup.object<IFormObject>({
  businessdataName: yup.string().required(),
  businessdataScreenName: yup.string().required(),
  businessdataDescription: yup.string().required(),
  businessdataType: yup.string().required(),
  businessdataExtraInformation: yup.string(),
  businessdataUnit: yup.string(),
});

type Props = IProps;

const Addbusinessdata: React.FC<Props> = (props) => {
  const dataTypes = useCollection(15);
  const { id, customerId, taskId, data, onCancel, handlePostUpdate } = props;

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  const formData: IFormObject = {
    businessdataName: '',
    businessdataScreenName: data?.businessdataScreenName || '',
    businessdataDescription: data?.businessdataDescription || '',
    businessdataType: data?.businessdataType || '',
    businessdataExtraInformation: data?.businessdataExtraInformation || '',
    businessdataUnit: data?.businessdataUnit || '',
  };

  const [payload, isLoading, isPostError, postData] = usePostApiEffect<IBusinessDataCreate, IBusinessData>(
    `api/BusinessData`,
    null,
  );

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => {
    setIsSubmittingFn(() => setIsSubmitting);

    const selectionToDataType = (selection: string) => dataTypes.find((x) => x.value === selection)?.id || '';

    const payload = {
      id,
      customer_id: customerId,
      task_id: taskId,
      screen_name: data.businessdataScreenName,
      name: data.businessdataName,
      description: data.businessdataDescription,
      extra_information: data.businessdataExtraInformation,
      type: selectionToDataType(data.businessdataType),
      unit: data.businessdataUnit,
    };

    postData(payload);

    handlePostUpdate();
  };

  return (
    <ModalFormFrame<IFormObject>
      label={'Add a new businessdata'}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ values, isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="businessdataScreenName" label={'Screen Name'} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="businessdataName" label={'Name'} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="businessdataDescription" label={'Description'} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="businessdataExtraInformation"
              label={'Additional information'}
              readonly={isSubmitting}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect name="businessdataType" label={'Type'} readonly={isSubmitting}>
              {dataTypes.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="businessdataUnit" label={'Unit'} readonly={isSubmitting} />
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default Addbusinessdata;
