import React from 'react';
import { IVmUtilizationChartProps } from './ChartProps';
import { Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import SetSelector from '../Pages/Dashboard/Dashboard/Datawindows/WindowComponents/SetSelector';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';
import VmUtilizationChartTooltip from './Tooltips/VmUtilizationChartTooltip';
import ChartWrapperNoDataError from '../Common/ReusableComponents/ChartWrapper/ChartWrapperComponents/ChartWrapperNoDataError';
import FormatToNDecimals from '../Common/ReusableUtilityFunctions/FormatToNDecimals';

const VmUtilizationChart = (props: IVmUtilizationChartProps) => {
  const { t } = useTranslation();
  const { data, dataSetOptions, handleSetChange, selectedOption, legend, timespan, vm } = props;

  if (!Array.isArray(data) || !data.length) {
    return <ChartWrapperNoDataError translationKey={'label-no-data'} />;
  }

  // CSS
  const colors = ['#FF8042', '#FFBB28', '#82ca9d', '#722F37	']; // Orange, yellow, green, wine
  const footerStyles = { display: 'flex', alignItems: 'baseline', justifyContent: 'space-around' };
  const footerInfoTextStyles = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'end' };

  const customizedLabel = (props: any) => {
    return (
      <text x={props.x} y={props.y} fill={props.fill} textAnchor={props.x > props.cx ? 'start' : 'end'}>
        {`${FormatToNDecimals(props.value / 60, 1)} ${t('label-hours')}`}
      </text>
    );
  };

  const formatTimespan = (timespan: any) => {
    return `${timespan['start'].toLocaleDateString('fi-FI')} - ${timespan['end'].toLocaleDateString('fi-FI')}`;
  };

  return (
    <Box sx={{ margin: '1vh' }}>
      <ResponsiveContainer height="75%" aspect={2.5}>
        <PieChart>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            wrapperStyle={{ fontSize: '75%' }}
            payload={legend.map((translationKey: any, index: any) => ({
              id: translationKey,
              value: t(translationKey),
              color: colors[index],
            }))}
          />
          <Pie data={data} dataKey="value" isAnimationActive={false} label={customizedLabel}>
            {data.map((entry: any, index: any) => (
              <Cell key={entry.name} fill={colors[index]} />
            ))}
          </Pie>
          <Tooltip content={<VmUtilizationChartTooltip data={data} />} animationDuration={500} />
        </PieChart>
      </ResponsiveContainer>
      <Box sx={footerStyles}>
        <SetSelector
          dataSetOptions={dataSetOptions}
          handleSetChange={handleSetChange}
          selectedDataSet={selectedOption}
        />
        <Box sx={footerInfoTextStyles}>
          <Typography variant="button">
            {t('label-virtual-machine')}: {vm}
          </Typography>
          <Typography variant="button">
            {t('label-timespan')}: {formatTimespan(timespan)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default VmUtilizationChart;
