import { ITimelineEvent } from '../../../../../../types/types';
import NumberTooltipFormatter from '../../Tooltips/NumberTooltipFormatter';

function getFreeTime(startDate: Date, endDate: Date) {
  const difference = endDate.getTime() - startDate.getTime();
  return difference;
}

function filterEventsByVmName(vm: string, events: any) {
  return events.filter((e: ITimelineEvent) => e.vmId === vm);
}

function calculateRunTime(events: any) {
  let executedRunTime = 0;
  for (let event of events) {
    if (!!event.actual_start_time && !!event.actual_stop_time) {
      executedRunTime += event.actual_stop_time.getTime() - event.actual_start_time.getTime();
    }
  }
  return executedRunTime;
}

function calculateScheduledTime(events: ITimelineEvent[], startDate: Date, endDate: Date) {
  let reserved = 0;
  for (let event of events) {
    if (!!event.scheduled_stop_time) {
      if (event.scheduled_start_time < startDate && event.scheduled_stop_time > endDate) {
        reserved += getFreeTime(startDate, endDate);
      } else if (event.scheduled_start_time > startDate && event.scheduled_stop_time > endDate) {
        reserved += event.scheduled_stop_time.getTime() - startDate.getTime();
      } else if (event.scheduled_start_time < startDate && event.scheduled_stop_time < endDate) {
        reserved += endDate.getTime() - event.scheduled_start_time.getTime();
      } else {
        reserved += event.scheduled_stop_time.getTime() - event.scheduled_start_time.getTime();
      }
    }
  }
  return reserved;
}

export const generateUtilizationData = (
  option: string,
  selectedVm: any,
  events: any,
  startDate: Date,
  endDate: Date,
) => {
  const eventsByVm = filterEventsByVmName(selectedVm, events);
  const freeTime = getFreeTime(startDate, endDate);
  const runTime = calculateRunTime(eventsByVm);
  const scheduledTime = calculateScheduledTime(eventsByVm, startDate, endDate);

  let unused: number = 0;
  let reserved: number = 0;
  let scheduled: number = scheduledTime;
  let executed: number = runTime;
  let overtime: number = 0;

  if (scheduledTime >= freeTime) {
    unused = 0;
    scheduled = freeTime;
    reserved = freeTime - executed;
    if (reserved < 0) {
      reserved = 0;
      executed = freeTime;
    }
  } else {
    reserved = scheduled - executed;
    if (reserved < 0) {
      overtime = reserved * -1;
      executed = executed - overtime;
      reserved = 0;
    }
    unused = freeTime - reserved - executed - overtime;
  }

  if (option === 'used') {
    return [
      { name: 'unused_time', value: NumberTooltipFormatter(unused === 0 ? unused : unused / 1000 / 60) },
      { name: 'reserved_time', value: NumberTooltipFormatter(reserved === 0 ? reserved : reserved / 1000 / 60) },
      { name: 'executed_time', value: NumberTooltipFormatter(executed === 0 ? executed : executed / 1000 / 60) },
      { name: 'overtime', value: NumberTooltipFormatter(overtime === 0 ? overtime : overtime / 1000 / 60) },
    ];
  } else if (option === 'scheduled') {
    return [
      { name: 'unused_time', value: NumberTooltipFormatter(unused === 0 ? unused : unused / 1000 / 60) },
      { name: 'scheduled_time', value: NumberTooltipFormatter(scheduled === 0 ? scheduled : scheduled / 1000 / 60) },
    ];
  }
};
