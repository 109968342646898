// General
import React from 'react';

// Components
import Box from '@mui/material/Box';

// Data validation
import { ChartWrapperTab, IChartBoxFullscreenContentProps } from '../../ChartWrapperTypes';

/**
 * Displays the 'content' variable of the currently selected ChartWrapperTab.
 * Wrapped inside a Box element so that the size remains the same among the tabs.
 */
const ChartBoxFullscreenContent = (props: IChartBoxFullscreenContentProps) => {
  const { selectedTab, tabs } = props;
  const renderedComponent = tabs.find((tab: ChartWrapperTab) => {
    return tab.id === selectedTab;
  });
  return <Box sx={{ height: 592 }}>{!!renderedComponent ? renderedComponent.content : null}</Box>;
};

export default ChartBoxFullscreenContent;
