import { Icon } from '@mui/material';
import React from 'react';

const CustomCustomerIcon = (props: any) => {
  const { logo } = props;
  return (
    <Icon>
      <img src={logo} height={24} width={24} alt="logo" />
    </Icon>
  );
};

export default CustomCustomerIcon;
