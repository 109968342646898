import React, { useEffect } from 'react';
import LoadingScreen from '../../../Common/LoadingScreen';
import { useGetApiEffect } from '../../../Hooks/Hooks';
import EventLogDisplay from './EventLogDisplay';

interface IEventLog {
  name: string;
  file_name: string;
  content_disposition: string;
  content_length: number;
  content_type: string;
  uri: string;
}

interface IProps {
  customerId: string;
  eventId: string;
}

type Props = IProps;

const EventLogList: React.FC<Props> = (props) => {
  const { customerId, eventId } = props;

  const [data, isLoading, isError, getData] = useGetApiEffect<IEventLog[]>(
    `api/logs/${customerId}/event/${eventId}`,
    null,
  );

  useEffect(() => {
    getData();
  }, [customerId, eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isError && <div>An error occurred.</div>}
      {data ? (
        data.length === 0 ? (
          <div>No logs found.</div>
        ) : (
          data.map((item) =>
            item.content_type.startsWith('text/') ? (
              <EventLogDisplay key={item.name} customerId={customerId} fileName={item.file_name} />
            ) : item.content_type.startsWith('image/') ? (
              <img key={item.name} src={item.uri} width="100%" alt={item.name} />
            ) : null,
          )
        )
      ) : null}
    </>
  );
};

export default EventLogList;
