import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    alertIcon: {
      color: theme.palette.info.main,
      marginRight: theme.spacing(2),
      fontSize: '50px',
    },
  });

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <HelpOutline className={classes.alertIcon} />
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4, 2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    margin: 0,
  },
}))(MuiDialogActions);

type Props = IProps;

const CancelConfirmation = (props: Props) => {
  const { open, onClose, onConfirm } = props;

  const handleConfirm = () => {
    onConfirm();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Discard changes?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Any changes you have made will be discarded and the form will be reset to its original values.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Keep changes
          </Button>
          <Button
            autoFocus
            onClick={handleConfirm}
            variant="contained"
            style={{ background: '#2F518A', color: '#FFFFFF' }}
          >
            Discard changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelConfirmation;
