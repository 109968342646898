import React, { useState } from 'react';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

interface HrefSettings {
  link: string;
  newTab: boolean;
}

interface IProps {
  text: string;
  hrefSettings?: HrefSettings;
}

const InfoIconPopover = (props: IProps) => {
  const { text, hrefSettings } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!!hrefSettings) {
    let target;
    if (!!hrefSettings.newTab) {
      target = '_blank';
    } else {
      target = undefined;
    }
    return (
      <div>
        <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={text}>
          <a href={hrefSettings.link} target={target}>
            <IconButton>
              <HelpOutline />
            </IconButton>
          </a>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div>
        <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={text}>
          <IconButton>
            <HelpOutline />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
};

export default InfoIconPopover;
