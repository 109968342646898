import React, { useState } from 'react';
import { Grid, Checkbox, FormControlLabel, FormControl, InputLabel, Select } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormFieldSet, FormSelect, FormTextArea, FormTextField, FormTextDisplay } from '../../FormFields';
import { useCollection } from '../../Hooks/Collections';
import { useTranslation } from 'react-i18next';

interface IFormObject {
  connection_name: string;
  connection_description: string;
  connection_type: number | string;
  connection_status: number | string;
}

const validationSchema = yup.object({
  connection_name: yup.string().required(),
  connection_description: yup.string().required(),
  connection_type: yup.number().required(),
  connection_status: yup.number().required(),
});

const Connections = () => {
  const [customerStatus, setCustomerStatus] = useState<number | string>('Standard');
  const [checked, setChecked] = useState<boolean>(false);
  // TODO: fetch data from api or redux state

  const { t } = useTranslation();

  const data: IFormObject[] = [
    {
      connection_name: 'AlexanderOy-VPN-Connection',
      connection_description: 'description',
      connection_type: 'IPsec tunnel',
      connection_status: 'Connected',
    },
  ];

  const connectionTypes = useCollection(6);
  const statusValues = useCollection(7);
  const vpnTypes = useCollection(10);

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    return data.find((d) => d.connection_name === id);
  };

  const handleSubmit: (data: IFormObject) => void = (data) => {};

  const handleCheckPolicy = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleCustomerStatus = (e: any) => {
    setCustomerStatus(e.target.value);
  };

  const renderSelect = (isEditing: boolean) => {
    return (
      <FormControl variant="outlined" style={{ minWidth: '100%', marginBottom: '17px' }}>
        <InputLabel>{t('label-vpn-gateway-type')}</InputLabel>
        <Select
          native
          label={t('label-vpn-gateway-type')}
          value={customerStatus}
          onChange={handleCustomerStatus}
          disabled={!isEditing}
        >
          {vpnTypes.map((item) => (
            <option value={item.value}>{item.value}</option>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderStandardTypeParameters = (isEditing: boolean) => {
    if (customerStatus === 'Standard') {
      return (
        <Grid item xs={12} sm={6}>
          <div style={{ minHeight: '300px' }}>
            <FormFieldSet legend="Standard type parameters">
              <FormSelect name="encryption" label="IkeEncryption AES256" readonly={!isEditing}></FormSelect>
              <FormSelect name="integrity" label="IkeIntegrity SHA1" readonly={!isEditing}></FormSelect>
              <FormSelect name="dhGroup" label="DhGroup DHGroup2" readonly={!isEditing}></FormSelect>
              <FormSelect name="ipsecEncrypt" label="IpsecEncryption AES256" readonly={!isEditing}></FormSelect>
              <FormSelect name="ipsecIntegrity" label="​IpsecIntegrity SHA256" readonly={!isEditing}></FormSelect>
              <FormSelect name="pfsGroup" label="PfsGroup None" readonly={!isEditing}></FormSelect>
              <FormTextArea name="lifetime" label="SALifeTimeSeconds 3600" readonly={!isEditing} />
              <FormControlLabel
                disabled={!isEditing}
                control={<Checkbox checked={checked} onChange={handleCheckPolicy} color="primary" />}
                label="Use Policy Based Traffic Selectors"
              />
            </FormFieldSet>
          </div>
        </Grid>
      );
    } else if (customerStatus === 'Basic') {
      return null;
    }
  };

  return (
    <>
      <SplitFormFrame<IFormObject>
        tableLabel="Connections"
        formLabel={t('label-connection-information')}
        validationSchema={validationSchema}
        data={data}
        idProperty="connection_name"
        properties={[
          { key: 'connection_name', label: t('label-connection-name') },
          { key: 'connection_type', label: t('label-connection-type') },
          { key: 'connection_status', label: t('label-connection-status') },
        ]}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="connection_name" label={t('label-connection-name')} readonly={!isEditing} />
                <FormTextArea
                  name="connection_description"
                  label={t('label-connection-description')}
                  readonly={!isEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="connection_status"
                  label={t('label-connection-status')}
                  readonly={true}
                  value={values.connection_status}
                >
                  <option key={0} value={values.connection_status}>
                    {values.connection_status}
                  </option>
                  {statusValues.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </FormSelect>

                <FormSelect
                  name="connection_type"
                  label={t('label-connection-type')}
                  readonly={!isEditing}
                  value={values.connection_type}
                >
                  {connectionTypes.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </FormSelect>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormFieldSet legend={t('label-type-specified-information')}>
                    {renderSelect(isEditing)}
                    <FormTextDisplay label={t('label-connection-status')} value={'2'} />
                    <FormTextDisplay label={t('label-source-vpn-address')} value={'3'} />
                    <FormTextDisplay label={t('label-source-network-address')} value={'4'} />
                    <FormTextDisplay label={t('label-psk-Key')} value={'5'} />
                  </FormFieldSet>
                </Grid>
                {renderStandardTypeParameters(isEditing)}
              </Grid>
            </Grid>
          );
        }}
      ></SplitFormFrame>
    </>
  );
};

export default Connections;
