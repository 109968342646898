import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import { FormFieldSet, FormSelect, FormTextArea, FormTextDisplay, FormTextField } from '../../FormFields';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { useCollection } from '../../Hooks/Collections';
import { ICustomer, ISecret } from '../../../types/types';
import SecretLink from './Secrets/SecretLink';
import AddSecret from './Secrets/AddSecret';
import AlertWindow from '../../Common/ReusableComponents/AlertWindow';
import { useAppDispatch } from '../../Hooks/StoreHooks';
import { fetchSecrets } from '../../../redux/reducers/secrets';
import { IState } from '../../../types/types';
import { useSelector } from 'react-redux';
import { getAllSecrets, selectSecretsStatus, updateSecret } from '../../../redux/reducers/secrets';
import { toast } from 'react-toastify';
import { createApiMessage } from '../../../redux/actions/apiMessage';

interface IProps {
  customer: ICustomer;
  userCustomerPermissions: string[]; //{ [permissions: string]: string[] };
}

type IFormObject = any;

const validationSchema = yup.object({
  name: yup.string().required(),
  status: yup.string().nullable(),
  description: yup.string().nullable().notRequired(),
  additional_info: yup.string().nullable().notRequired(),
  production_value: yup.string(),
  test_value: yup.string(),
  type: yup.string(),
});

const getAddComponent: (customerId: string) => (props: { values?: any; onCancel: () => void }) => React.ReactNode = (
  customerId,
) => {
  return (props) => {
    return <AddSecret data={props.values} onCancel={props.onCancel} customerId={customerId} />;
  };
};

type Props = IProps;

const Secrets: React.FC<Props> = (props) => {
  const { customer, userCustomerPermissions } = props;
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<string>('success');
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const { t } = useTranslation();
  const secretTypes = useCollection(9);
  const dispatch = useAppDispatch();
  const data = useSelector<IState, ISecret[]>((state: IState) => getAllSecrets(state));
  const isLoading = useSelector(selectSecretsStatus) === 'loading';
  const isSubmitting = useSelector(selectSecretsStatus) === 'submitting';

  /** API
   * Fetch secrets with redux thunk
   */
  useEffect(() => {
    dispatch(fetchSecrets(customer.customer_guid))
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
        dispatch(createApiMessage(`customers/${customer.customer_guid}/secrets/`, err.message));
      });
  }, []);

  const getDataItem: (id: string | number) => ISecret | undefined = (id) => {
    return data.find((d: any) => d.name === id);
  };

  const handleUpdate: (data: IFormObject) => void = (data) => {
    const payload = {
      name: data.name,
      type: data.type,
      production_value: data.production_value,
      test_value: data.test_value,
      description: data.description,
      additional_info: data.additional_info,
      status: null,
    };

    dispatch(updateSecret({ customerId: customer.customer_guid, payload }))
      .unwrap()
      .then(() => {
        toast.success('Secret updated');
      })
      .catch((err: any) => {
        toast.error(err.message);
        dispatch(createApiMessage(`customers/${customer.customer_guid}/secrets/`, err.message));
      });
  };

  return (
    <>
      <AlertWindow message={message} severity={severity} display={displayAlert} setDisplay={setDisplayAlert} />
      <FormFieldSet legend={t('label-keyvault-info')}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextDisplay label={t('label-keyvault-name-production')} value={customer?.keyvault_prod_name} />
            <FormTextDisplay label={t('label-keyvault-address-production')} value={customer?.keyvault_prod_base_url} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextDisplay label={t('label-keyvault-name-test')} value={customer?.keyvault_test_name} />
            <FormTextDisplay label={t('label-keyvault-address-test')} value={customer?.keyvault_test_base_url} />
          </Grid>
        </Grid>
      </FormFieldSet>
      <SplitFormFrame<IFormObject>
        tableLabel={t('label-secrets')}
        formLabel={t('label-secret')}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
        data={data}
        idProperty="name"
        properties={[
          { key: 'name', label: 'Secret name' },
          { key: 'type', label: 'Secret type' },
          { key: 'status', label: 'Secret link status' },
        ]}
        isLoading={isLoading}
        isUpdating={isSubmitting}
        fetchDataItem={getDataItem}
        addDisabled={!userCustomerPermissions.includes('secrets/create')}
        editRendered={userCustomerPermissions.includes('secrets/update')}
        pagination={true}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="name" label={t('label-secret-name')} readonly={true} />
              <FormTextArea name="description" label={t('label-secret-description')} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="type" label={t('label-secret-type')} readonly={true} value={values.type}>
                <option key={0} value={values.type}>
                  {values.type}
                </option>
                {secretTypes.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
              <FormTextArea name="additional_info" label={t('label-additional-information')} readonly={!isEditing} />
            </Grid>
            <SecretLink isEditing={isEditing} />
          </Grid>
        )}
        renderAdd={getAddComponent(customer.customer_guid)}
      ></SplitFormFrame>
    </>
  );
};

export default Secrets;
