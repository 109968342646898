import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Typography, GridList, GridListTile } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FETCH_CUSTOMERS, getCustomersApiRequest } from '../../../../redux/actions/customer';
import { isActionPending, getAllCustomers } from '../../../../redux/reducers';
import { ICustomer, IState } from '../../../../types/types';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface IComponentProps {
  setCustomerId: (customerId: string) => void;
}

const CustomerList = (props: IComponentProps) => {
  const dispatch = useDispatch();
  const customers = useSelector<IState, ICustomer[]>(getAllCustomers, shallowEqual);
  const customersFetching = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, FETCH_CUSTOMERS),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getCustomersApiRequest());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Customers
      </Typography>
      {customersFetching && <CircularProgress />}
      {!customersFetching && (
        <Select className={classes.formControl}>
          {customers.map((item: ICustomer) => (
            <ListItem key={item.customer_guid} divider={true}>
              <ListItemText primary={`${item.customer_name}`} onClick={() => props.setCustomerId(item.customer_guid)} />
            </ListItem>
          ))}
        </Select>
      )}
      {!customersFetching && (
        <List>
          {customers.map((item: ICustomer) => (
            <ListItem key={item.customer_guid}>
              {/* <ListItemText
                primary={`${item.customer_name}`}
                onClick={() => props.setCustomerId(item.customer_guid)}
              />       */}
              {/* <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => props.setCustomerId(item.customer_guid)}
              >
              {`${item.customer_name}`}
            </Button> */}
            </ListItem>
          ))}
        </List>
      )}

      {!customersFetching && (
        <GridList cellHeight={130} cols={2}>
          {customers.map((item: ICustomer) => (
            <GridListTile key={item.customer_guid}>
              {/* <ListItemText
                primary={`${item.customer_name}`}
                onClick={() => props.setCustomerId(item.customer_guid)}
              />       */}
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => props.setCustomerId(item.customer_guid)}
              >
                {`${item.customer_name}`}
              </Button>
            </GridListTile>
          ))}
        </GridList>
      )}
    </>
  );
};

export default CustomerList;
