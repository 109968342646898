import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { getCollectionsApiRequest } from '../../redux/actions/collection';
import { getCustomersApiRequest } from '../../redux/actions/customer';
import { getRolesApiRequest } from '../../redux/actions/role';
import { initializeUserActionCreator } from '../../redux/actions/user';
import { getIsAppInitialized } from '../../redux/reducers';
import { getUser } from '../../services/auth/authService';
import { IState } from '../../types/types';

import { getAllApiMessages } from '../../redux/reducers';

import ToastMemo from './ToastMemo';
import routes from './Routes';
import TopHeader from './TopHeader';
import PrivateRoute from '../Common/PrivateRoute';

import AadCallback from '../Pages/AadCallback';
import Login from '../Pages/Login';

const Layout = (props: any) => {
  const applicationReady = useSelector<IState, boolean>(getIsAppInitialized, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollectionsApiRequest());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getCustomersApiRequest());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getRolesApiRequest());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(initializeUserActionCreator(getUser()));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const apiMessages = useSelector((state: any) => getAllApiMessages(state));

  return (
    <>
      <ToastContainer style={{ zIndex: 9999 }} pauseOnHover position="bottom-right" autoClose={5000} />
      {apiMessages.length > 0 ? <ToastMemo apiMessages={apiMessages} /> : null}
      <TopHeader />

      {applicationReady && (
        <Router primary={false} component="main">
          {routes.map((item) => (
            <PrivateRoute key={item.path} comp={item.component} roles={item.roles} path={item.path} />
          ))}
          <AadCallback path="aad-callback" />
          <Login path="login" />
        </Router>
      )}
    </>
  );
};

export default Layout;
