import { IScheduleState } from '../components/Forms/Tasks/Schedule/ScheduleTypes';
import { EntityState, EntityId } from '@reduxjs/toolkit';
export type Nullable<T> = T | null;

export interface IState {
  appState: IAppState;
  collection: ICollectionState;
  customer: ICustomerState;
  task: ITaskState;
  event: IEventState;
  user: IUserState;
  schedule: IScheduleState;
  role: IRoleState;
  apiMessage: any;
  secrets: ISecretState;
  tasks: ITasksState;
  consumption: IConsumptionState;
}

export interface IConsumptionState extends EntityState<IConsumption> {
  status: string;
  selectedConsumption: string;
}

export interface IConsumption {
  resource_name: string;
  resource_id: string;
  resource_type: string;
  consumption: number;
  cost: number;
  unit: string;
  date: Date;
  consumption_history: {};
  cost_history: {};
  tasks: ITasksState;
}

export interface IDictionary<T> {
  [key: string]: T;
}

export interface INormalizedBase<T, K extends string | number> {
  byId: IDictionary<T>;
  ids: K[];
}

export interface ApiRequestDict {
  [type: string]: number;
}

export interface IAppState {
  isInitialized: boolean;
  apiRequestsPending: number;
  apiRequests: ApiRequestDict;
}

export interface ICollectionState extends INormalizedBase<ICollection, number> {}

export interface ICustomerState extends INormalizedBase<ICustomer, string> {
  selectedCustomer: string;
}

export interface IDataModelState {
  draft: IDictionary<IDataNode>;
}

export interface IDataNode {
  nodeid: string;
  uniqueId: string;
  name: string;
  description: string;
  status: string;
  type: string;
  kpi: string;
  gdpr: boolean;
  onNodeSelect: (uniqueId: string) => void;
  onNodeAddChild: (uniqueId: string) => void;
  onNodeAddChildren: (uniqueId: string, childNameArray: string[]) => void;
  onNodeAddSibling: (uniqueId: string) => void;
  onNodeRemove: (uniqueId: string) => void;
  selectedUniqueId: string;
  indexPath: number[];
  children: IDataNode[];
  isLastChild: boolean;
  readOnly?: boolean;
}

export interface ITasksState extends EntityState<ITask> {
  selectedTask: string;
  status: string;
}

export interface ITaskState extends INormalizedBase<ITask, string> {
  selectedTask: string;
}

export interface IEventState extends INormalizedBase<IEvent, string> {}

export interface IRoleState extends INormalizedBase<IRoleCollection, string> {}

export interface IUserState {
  userName: string;
  loggingOut: boolean;
  acquireTokenFailed: boolean;
}

export interface ICollection {
  id: number;
  name: string;
  description: string;
  multilanguage: boolean;
  deleted: boolean;
  members: ICollectionMember[];
}

export interface ICollectionMember {
  id: string;
  description: string;
  deleted: boolean;
  values: ICollectionValue[];
}

export interface ICollectionValue {
  value: string;
  language_code: string;
}

export interface ICollectionCreate {
  name: string;
  description: string;
  multilanguage: boolean;
}

export interface ICollectionUpdate {
  id: number;
  name: string;
  description: string;
}

export interface ICollectionMemberCreate {
  collectionId: number;
  id: string;
  description: string;
  values: ICollectionValue[];
}

export interface ICollectionMemberUpdate {
  collectionId: number;
  id: string;
  description: string;
  values: ICollectionValue[];
}

export interface IText {
  id: string;
  value_en: string;
  values: ITextValue[];
}

export interface ITextValue {
  value: string;
  language_code: string;
}

export interface ILanguage {
  name: string;
  code: string;
}

export interface ICustomer extends ICustomerUpdate {
  customer_logo_url: Nullable<string>;
  tech_customer_name: string;
  lowercase_customer_name: string;
  short_customer_name: string;
  short_lowercase_customer_name: string;
  data_storage_account: string;
  diagnostics_storage_account: string;
  resourcegroup_name: string;
  vnet_name: string;
  teams_site_url: string;
  keyvault_prod_name: string;
  keyvault_prod_base_url: string;
  keyvault_test_name: string;
  keyvault_test_base_url: string;
}

export interface ICustomerCreate {
  customer_name: string;
  customer_description: Nullable<string>;
  screenshots_allowed?: boolean;
  recording_allowed?: boolean;
  vnet_address_space: string;
  prod_vm_count?: number;
  test_vm_count?: number;
  jbox_vm_count?: number;
  privx_used?: boolean;
  vpn_used?: boolean;
  specific_vpn_device_in_use?: boolean;
  on_site_implementation: boolean;
  customer_timezone: Nullable<string>;
  daylight_saving_used: Nullable<boolean>;
}

export interface ICustomerUpdate extends ICustomerCreate {
  customer_guid: string;
}

export interface ISecret {
  name: string;
  description: Nullable<string>;
  additional_info: Nullable<string>;
  production_value: string;
  test_value: string;
  type: string;
  status: Nullable<string>;
}

export interface ISecretState extends EntityState<ISecret> {
  status: string;
}

export interface ILinkedSecret {
  name: string;
  type: string;
}

export interface ITask extends ITaskUpdate {
  customer_guid: string;
  task_logo_url: Nullable<string>;
  o365_most_username: Nullable<string>;
  o365_most_server: Nullable<string>;
  o365_most_port: Nullable<string>;
  o365_most_password: Nullable<string>;
  o365_customer_username: Nullable<string>;
  o365_customer_server: Nullable<string>;
  o365_customer_port: Nullable<string>;
  o365_customer_password: Nullable<string>;
  keyvault_prod_base_url: Nullable<string>;
  keyvault_test_base_url: Nullable<string>;
  on_site_implementation: boolean;
}

export interface ITaskCreate {
  customer_guid: string;
  task_name: string;
  task_description: Nullable<string>;
  task_type: Nullable<string>;
  task_logo: Nullable<File>;
  // screenshots_allowed: Nullable<boolean>;
  // recording_allowed: Nullable<boolean>;
  start_script_path: Nullable<string>;
  priority: Nullable<number>;
}

export interface ITaskUpdate {
  task_guid: string;
  task_name: string;
  task_description: Nullable<string>;
  task_type: Nullable<string>;
  screenshots_allowed: Nullable<boolean>;
  recording_allowed: Nullable<boolean>;
  vm_names: string[];
  priority: Nullable<number>;
  start_script_path: Nullable<string>;
  technical_contact_email: Nullable<string>;
  business_contact_email: Nullable<string>;
  customer_email: Nullable<string>;
  customer_error_email: Nullable<string>;
}

export interface IEvent {
  event_guid: string;
  task_guid: string;
  customer_guid: string;
  task_name: string;
  vm_name: string;
  scheduled_start_time: string;
  scheduled_stop_time?: string;
  actual_start_time?: string;
  actual_stop_time?: string;
  error_count?: number;
  status?: number;
  errors?: ISingleError[];
}

export enum EventType {
  VM = 0,
  Task = 1,
  Event = 2,
}

export interface ITimelineResource {
  id: string;
  name: string;
  parentId?: string;
}

export interface ISingleError {
  error_type: number;
  error_description: string;
  error_log: string;
  database_timestamp: Date;
  robot_timestamp: Date;
}

export interface ISingleStep {
  step_information: string;
  step_log: string;
  database_timestamp: Date;
  robot_timestamp: Date;
}
export interface IStep {
  stepInformation: string;
  stepLog: string;
  databaseTimestamp: string | Date;
}

export interface IPermission {
  id: number;
  name: string;
  description: string;
}

export interface IPermissionNew {
  name: string;
  description_fi: string;
  description_en: string;
}

export interface ITimelineEvent {
  id: string;
  resourceId: string;
  vmId: string;
  taskId: string;
  title: string;
  start: string;
  end: string;
  scheduled_start_time: Date;
  scheduled_stop_time?: Date;
  actual_start_time?: Date;
  actual_stop_time?: Date;
  errorCount?: number;
  status?: number;
  type: 'scheduled' | 'actual';
  errors?: ISingleError[];
}

export interface IEventRuntime {
  scheduled: number;
  actual: number;
}

export interface IEventStatusCount {
  statusOk: number;
  statusNotOk: number;
}

export interface IEventErrorCount {
  date: string;
  errors: number;
}

export interface IRoleCollection {
  scope: string;
  roles: Role[];
}

export type Role =
  | 'all'
  | 'administrators'
  | 'developers'
  | 'coordinators'
  | 'customerusers'
  | 'dashboarduser'
  | 'datawriter'
  | 'datareader';

export interface IRole {
  id: number;
  name: string;
  scope: string;
  description: string;
  permissions?: any;
}

export interface IRoleCollection {
  scope: string;
  roles: Role[];
}

export interface IRoleCreate {
  name: string;
  description_en: string;
  description_fi: string;
  scope: string;
}

export type IPermissionHandlingOperation = 'add' | 'delete' | '';

export interface IStamp {
  stamp_name: string;
  stamp_screen_name: string;
  stamp_description: string;
}

export interface IBusinessData {
  business_data_id: number;
  business_data_screen_name: string;
  business_data_name: string;
  business_data_description: string;
  business_data_extra_information: string;
  business_data_type: string;
  business_data_unit: string;
}

export type Crumb = {
  id: string;
  name: string;
  type: number;
};
