import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { renderTotalRow, getHeaderTranslations, renderMinSuffix, getRelevantDatakeys } from './DataTableRowUtils';
import { useTranslation } from 'react-i18next';
import FormatToNDecimals from '../../../../../Common/ReusableUtilityFunctions/FormatToNDecimals';

type ComponentProps = {
  data: any;
};

const DataTable = (props: ComponentProps) => {
  const { data } = props;
  const { t } = useTranslation();
  let dataKeys = getRelevantDatakeys(data[0]);
  let headerTranslations = getHeaderTranslations(dataKeys, t);
  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: '10px' }}></TableCell>
            {headerTranslations.length !== 0
              ? headerTranslations.map((key, id) => (
                  <TableCell key={id} align="left">
                    {key}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((node: any, id: number) => {
            return (
              <TableRow key={id}>
                <TableCell key={id} align="left">
                  {id + 1}
                </TableCell>
                {dataKeys.map((key, id) => {
                  return (
                    <TableCell key={id} align="left">
                      {FormatToNDecimals(node[key], 2) + renderMinSuffix(key, node[key])}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {renderTotalRow(data, dataKeys, t)}
        </TableBody>
      </Table>
    </>
  );
};

export default DataTable;
