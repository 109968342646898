import { createApiAction } from './common';
import { schema as Schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { INormalizedBase, ICustomer, ICustomerUpdate, ICustomerCreate } from '../../types/types';
import HttpMethod from '../../services/api/httpMethod';

const actionCreator = actionCreatorFactory();

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';

export const selectCustomerActionCreator = actionCreator<string>(SELECT_CUSTOMER);

/**
 * Define schema for normalizing api response
 * The schema can be customised for you own needs, see normalizr docs
 *
 * This schema normalizes an array of objects to a dictionary of objects and an array if ids
 * If the property that defines the object identity is not named 'id' the idAttribute needs to be set
 */
const entityName = 'customer';
const entity = new Schema.Entity(
  entityName,
  {},
  {
    idAttribute: 'customer_guid',
  },
);
const schema = [entity];

/*



data:
0: {id: 1, firstName: "George", lastName: "Bluth",…}
1: {id: 2, firstName: "Janet", lastName: "Weaver",…}
2: {id: 3, firstName: "Emma", lastName: "Wong",…}

*/

/**
 * The type of action for a fetch operation
 */
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';

/**
 * Define action creators for an async operation
 */
export const fetchCustomersActionCreator = actionCreator.async<void, INormalizedBase<ICustomer, string>>(
  FETCH_CUSTOMERS,
  {
    normalize: {
      entityName,
      schema,
    },
  },
);

/**
 * Creates an async action that defines an api call
 */
export const getCustomersApiRequest = () =>
  createApiAction(HttpMethod.GET, 'api/customers', fetchCustomersActionCreator);

/**
 * The type of action for an update operation
 */
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';

/**
 * Define action creators for an async operation
 */
export const createCustomerActionCreator = actionCreator.async<ICustomerCreate, ICustomer>(CREATE_CUSTOMER);

/**
 * Creates an async action that defines an api call
 */
export const createCustomerApiRequest = (data: ICustomerCreate) =>
  createApiAction(HttpMethod.POST, `api/customers`, createCustomerActionCreator, data);

/**
 * The type of action for an update operation
 */
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

/**
 * Define action creators for an async operation
 */
export const updateCustomerActionCreator = actionCreator.async<ICustomerUpdate, void>(UPDATE_CUSTOMER);

/**
 * Creates an async action that defines an api call
 */
export const updateCustomerApiRequest = (id: string, data: ICustomerUpdate) =>
  createApiAction(HttpMethod.PUT, `api/customers/${id}`, updateCustomerActionCreator, data);
