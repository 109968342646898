import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const CustomerDialogNoResults = () => {
  const { t } = useTranslation();
  const styles = {
    display: 'flex',
    justifyContent: 'center',
  };
  return (
    <Typography sx={styles} variant="overline" display="block" gutterBottom>
      {t('label-no-results')}
    </Typography>
  );
};

export default CustomerDialogNoResults;
