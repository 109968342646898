import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTasksApiRequest } from '../../../../redux/actions/task';

import TaskList from './TaskList';

interface IComponentProps {
  customerId: string;
}

const Task = (props: IComponentProps) => {
  const { customerId } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId) {
      dispatch(getTasksApiRequest(customerId));
    }
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return <TaskList customerId={customerId} />;
};

export default Task;
