import React, { ChangeEvent, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormTextField, FormTwinMultiSelect, FormSelect } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { IRole, IFormObject, IInternalUser } from './Types';
import { useFormikContext } from 'formik';
import GuestUser from './GuestUser';
import InternalUser from './InternalUser';
import { MenuItem, Select } from '@material-ui/core';
import { SelectChangeEvent } from '@mui/material/Select';

enum UserType {
  guest = 'guest',
  internal = 'internal',
}

interface IProps {
  customerId: string;
  roles: IRole[];
  data?: IFormObject;
  isLoading: boolean;
  users: IInternalUser[];
  onCancel: () => void;
}
type Props = IProps;

const TypeSelect: React.FC<Props> = (props) => {
  const { roles, customerId, data, isLoading, users, onCancel } = props;
  const { t } = useTranslation();
  const types = ['internal', 'guest'];
  const [type, setType] = React.useState(types[0]);
  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    setType(event.target.value as UserType);
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="Type"
          onChange={handleChange}
          style={{ minWidth: '100%' }}
        >
          {types.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        {type == 'guest' ? (
          <GuestUser customerId={customerId} roles={roles} onCancel={onCancel} />
        ) : (
          <InternalUser customerId={customerId} roles={roles} onCancel={onCancel} isLoading={isLoading} users={users} />
        )}
      </Grid>
    </Grid>
  );
};

export default TypeSelect;
