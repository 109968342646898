import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const history = useHistory();
  const handleLogoff = () => {
    history.push('login');
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: '#555559' }}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <BusinessOutlinedIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            Dashboard
          </Typography>
          <IconButton color="inherit" className={classes.menuButton} onClick={handleLogoff}>
            <LockOutlinedIcon />
            <Typography color="inherit">Logoff</Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
