import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { msalApp } from './services/auth/authService';
import * as serviceWorker from './serviceWorker';

/**
 * Tests if the app is executed in an IFrame
 */
const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Renders the application into the specified container element
 * @param Component The top-level application component to render
 */
const render: (Component: any) => void = (Component) => {
  ReactDOM.render(<Component />, document.getElementById('root'));
};

/**
 * This application uses Azure AD as its identity provider
 * and OpenId Connect as the authentication protocol.
 * Identity and access tokens are retirieved using the implicit flow.
 * Microsoft Authentication Library (MSAL) for JS
 * https://github.com/AzureAD/microsoft-authentication-library-for-js
 * is used under the hood.
 * This library uses iframes to silently retrieve tokens without user interaction.
 * Only render the application if not executed in the context of an iframe.
 */
if (!inIframe()) {
  msalApp.handleRedirectCallback((err) => {
    if (err && err.errorCode !== 'login_progress_error') {
      console.error(`${err.errorCode}: ${err.errorMessage}`);
    }
  });

  render(App);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
