import { createSlice, createEntityAdapter, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiRequest from '../../services/api/apiService';
import HttpMethod from '../../services/api/httpMethod';
import { ISecret, IState } from '../../types/types';

const secretsAdapter = createEntityAdapter<ISecret>({
  selectId: (secret) => secret.name,
});

const initialState = secretsAdapter.getInitialState({
  status: '',
});

const secretsSlice = createSlice({
  name: 'secrets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSecrets.fulfilled, (state, { payload }: PayloadAction<ISecret[]>) => {
        secretsAdapter.setAll(state, payload);
        state.status = '';
      })
      .addCase(fetchSecrets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateSecret.fulfilled, (state, { payload }: PayloadAction<ISecret>) => {
        secretsAdapter.upsertOne(state, payload);
        state.status = '';
      })
      .addCase(updateSecret.pending, (state) => {
        state.status = 'submitting';
      })
      .addCase(createSecret.fulfilled, (state, { payload }: PayloadAction<ISecret>) => {
        secretsAdapter.addOne(state, payload);
        state.status = '';
      })
      .addCase(createSecret.pending, (state) => {
        state.status = 'submitting';
      })
      .addDefaultCase((state) => {
        state.status = '';
      });
  },
});

export default secretsSlice.reducer;

export const fetchSecrets = createAsyncThunk('secrets/fetchSecrets', async (customerId: string) => {
  // api call for fetching all secrets
  const response = await apiRequest<any, ISecret[]>({
    resource: `customers/${customerId}/secrets/`,
    method: HttpMethod.GET,
    apiVersion: 'pythonApi',
  });
  return response.data;
});

export const updateSecret = createAsyncThunk(
  'secrets/updateSecret',
  async (data: { customerId: string; payload: ISecret }) => {
    const { customerId, payload } = data;
    const response = await apiRequest<ISecret, any>({
      resource: `customers/${customerId}/secrets/${payload.name}`,
      payload,
      method: HttpMethod.PUT,
      apiVersion: 'pythonApi',
    });
    // API does not return the secret after upload operation, so instead
    // remove prod/test values from updated secret payload and return it to reducer to update store
    const updatedSecret = { ...payload, production_value: '', test_value: '' };
    return updatedSecret;
  },
);

export const createSecret = createAsyncThunk(
  'secrets/createSecret',
  async (data: { customerId: string; payload: ISecret }) => {
    const { customerId, payload } = data;
    const response = await apiRequest<ISecret, any>({
      resource: `customers/${customerId}/secrets/`,
      payload,
      method: HttpMethod.POST,
      apiVersion: 'pythonApi',
    });
    // API does not return the secret after create operation, so instead
    // remove prod/test values from created secret payload and return it to reducer to update store
    const createdSecret = { ...payload, production_value: '', test_value: '' };
    return createdSecret;
  },
);

const secretsSelectors = secretsAdapter.getSelectors((state: IState) => state.secrets);

export const getAllSecrets = (state: IState) => {
  return secretsSelectors.selectAll(state);
};

export const selectSecretsStatus = (state: IState) => state.secrets.status;
