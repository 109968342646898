import React, { useState } from 'react';
import { Grid, IconButton, Box } from '@material-ui/core';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import TocIcon from '@material-ui/icons/Toc';
import CloseIcon from '@material-ui/icons/Close';

import ExportExcel from '../../../../../FormButtonGroups/ExportExcel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DBarChart from './DBarChart';
import DLineChart from './DLineChart';
import DPieChart from './DPieChart';

import DataTable from './DataTable';

type ComponentProps = {
  t: any;
  fullscreenOpen: boolean;
  handleOpenFullscreen: any;
  data: any;
  graphType: string;
  legendLabel: string;
  windowTitle: string;
  dataKeys: string[];
  xAxisLabel?: string;
  selectedLoop?: any;
};

const FullscreenGraph = (props: ComponentProps) => {
  const [displayMode, setDisplayMode] = useState<string>('graph');

  const {
    data,
    fullscreenOpen,
    handleOpenFullscreen,
    graphType,
    legendLabel,
    dataKeys,
    xAxisLabel,
    windowTitle,
    selectedLoop,
  } = props;

  const handleDisplayChangeTable = () => {
    setDisplayMode('table');
  };
  const handleDisplayChangeGraph = () => {
    setDisplayMode('graph');
  };

  const renderContent = () => {
    if (displayMode === 'table') {
      return (
        <span style={{ minHeight: '40rem', width: '120rem', paddingLeft: '10px', paddingRight: '10px' }}>
          <DataTable data={data} />
        </span>
      );
    } else if (displayMode === 'graph') {
      return (
        <Grid item xs={12} style={{ minHeight: '40rem', paddingRight: '20px' }}>
          <span style={{ minHeight: '52rem', width: '120rem', paddingLeft: '10px', paddingRight: '10px' }}>
            {graphType === 'bar' ? (
              <DBarChart
                data={data}
                legendLabel={legendLabel}
                dataKeys={dataKeys}
                xAxisLabel={xAxisLabel}
                fullscreenSize={true}
              />
            ) : null}
            {graphType === 'line' ? (
              <DLineChart
                data={data}
                legendLabel={legendLabel}
                dataKeys={dataKeys}
                xAxisLabel={xAxisLabel}
                fullscreenSize={true}
              />
            ) : null}
            {graphType === 'pie' ? (
              <DPieChart
                data={data}
                legendLabel={legendLabel}
                dataKeys={dataKeys}
                xAxisLabel={xAxisLabel}
                fullscreenSize={true}
              />
            ) : null}
          </span>
        </Grid>
      );
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={fullscreenOpen}
        onClose={handleOpenFullscreen}
        aria-labelledby="max-width-dialog-title"
      >
        <Grid container alignItems="center" justify="center">
          <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <DialogTitle id="dialog-title">
                {windowTitle} {!!selectedLoop ? `(${selectedLoop})` : null}
              </DialogTitle>
              <IconButton onClick={handleDisplayChangeGraph}>
                <EqualizerIcon />
              </IconButton>
              <IconButton onClick={handleDisplayChangeTable}>
                <TocIcon />
              </IconButton>
              <ExportExcel data={data} colorUsed={false} />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleOpenFullscreen}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {renderContent()}
        </Grid>
      </Dialog>
    </>
  );
};

export default FullscreenGraph;
