import { MsalAuthProvider, LoginType, IMsalAuthProviderConfig } from 'react-aad-msal';
import { config, parameters } from '../../configs/msalConfig';

const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect,
};

const authProvider = new MsalAuthProvider(config, parameters, options);

export { authProvider, parameters };
