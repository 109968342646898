import { useMutation } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

interface Payload {
  machine_name: string;
  customer_guid: string;
  state: boolean;
}

const ToggleAutomaticShutdown = () => {
  return useMutation('toggleAutomaticShutdown', async (payload: Payload) => {
    const bearerToken = await acquireSilentToken();
    return await axios.put(apimConf.apiManagementEndpoint + '/resources/toggle-automatic-shutdown', payload, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
      },
    });
  });
};

export default ToggleAutomaticShutdown;
