import { Console } from 'console';
import { AuthenticationParameters, CacheLocation, Configuration } from 'msal';

interface IMSALConfig {
  clientId: string;
  cacheLocation: CacheLocation;
  serviceClientId: string;
  scopes: string[];
  config: Configuration;
  parameters: AuthenticationParameters;
}

//Change scopes to api://mostdigital.fi/dock.api.local/... to use locally running API
const local: IMSALConfig = {
  clientId: '7bb53e24-c9e1-4cf0-8dfd-9b4a8b3ca8c8',
  cacheLocation: 'localStorage',
  serviceClientId: 'api://mostdigital.fi/dock.api.test',
  scopes: ['Customers.Read', 'Customers.ReadWrite', 'Tasks.Read', 'Tasks.ReadWrite'],
  config: {
    auth: {
      authority: 'https://login.microsoftonline.com/31fe9eb3-165a-4e2a-b3ca-8015d5342ac3',
      clientId: '7bb53e24-c9e1-4cf0-8dfd-9b4a8b3ca8c8',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  },
  parameters: {
    redirectUri: 'http://localhost:3000/aad-callback',
    scopes: [
      'api://mostdigital.fi/dock.api.test/Customers.Read',
      'api://mostdigital.fi/dock.api.test/Customers.ReadWrite',
      'api://mostdigital.fi/dock.api.test/Tasks.Read',
      'api://mostdigital.fi/dock.api.test/Tasks.ReadWrite',
    ],
  },
};

const test: IMSALConfig = {
  clientId: 'dd834256-e2b4-4c87-9457-75f4a99485eb',
  cacheLocation: 'localStorage',
  serviceClientId: 'api://mostdigital.fi/dock.api.test',
  scopes: ['Customers.Read', 'Customers.ReadWrite', 'Tasks.Read', 'Tasks.ReadWrite'],
  config: {
    auth: {
      authority: 'https://login.microsoftonline.com/31fe9eb3-165a-4e2a-b3ca-8015d5342ac3',
      clientId: 'dd834256-e2b4-4c87-9457-75f4a99485eb',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  },
  parameters: {
    redirectUri: 'https://oiva-portal-test.azurewebsites.net/aad-callback',
    scopes: [
      'api://mostdigital.fi/dock.api.test/Customers.Read',
      'api://mostdigital.fi/dock.api.test/Customers.ReadWrite',
      'api://mostdigital.fi/dock.api.test/Tasks.Read',
      'api://mostdigital.fi/dock.api.test/Tasks.ReadWrite',
    ],
  },
};

const prod: IMSALConfig = {
  clientId: '707c3f7d-0899-4019-8915-9ea317ac9d9f',
  cacheLocation: 'localStorage',
  serviceClientId: 'api://mostdigital.fi/dock.api.prod',
  scopes: ['Customers.Read', 'Customers.ReadWrite', 'Tasks.Read', 'Tasks.ReadWrite'],
  config: {
    auth: {
      authority: 'https://login.microsoftonline.com/31fe9eb3-165a-4e2a-b3ca-8015d5342ac3',
      clientId: '707c3f7d-0899-4019-8915-9ea317ac9d9f',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  },
  parameters: {
    redirectUri: 'https://oiva-portal.azurewebsites.net/aad-callback',
    scopes: [
      'api://mostdigital.fi/dock.api.prod/Customers.Read',
      'api://mostdigital.fi/dock.api.prod/Customers.ReadWrite',
      'api://mostdigital.fi/dock.api.prod/Tasks.Read',
      'api://mostdigital.fi/dock.api.prod/Tasks.ReadWrite',
    ],
  },
};

const staging: IMSALConfig = {
  clientId: '707c3f7d-0899-4019-8915-9ea317ac9d9f',
  cacheLocation: 'localStorage',
  serviceClientId: 'api://mostdigital.fi/dock.api.prod',
  scopes: ['Customers.Read', 'Customers.ReadWrite', 'Tasks.Read', 'Tasks.ReadWrite'],
  config: {
    auth: {
      authority: 'https://login.microsoftonline.com/31fe9eb3-165a-4e2a-b3ca-8015d5342ac3',
      clientId: '707c3f7d-0899-4019-8915-9ea317ac9d9f',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  },
  parameters: {
    redirectUri: 'https://prod-mostautomationdock-staging.azurewebsites.net/aad-callback',
    scopes: [
      'api://mostdigital.fi/dock.api.prod/Customers.Read',
      'api://mostdigital.fi/dock.api.prod/Customers.ReadWrite',
      'api://mostdigital.fi/dock.api.prod/Tasks.Read',
      'api://mostdigital.fi/dock.api.prod/Tasks.ReadWrite',
    ],
  },
};

const msalConfig =
  process.env.REACT_APP_ENV === 'prod'
    ? prod
    : process.env.REACT_APP_ENV === 'staging'
    ? staging
    : process.env.REACT_APP_ENV === 'test'
    ? test
    : local;

export const config = msalConfig.config;
export const parameters = msalConfig.parameters;
const msal = {
  instance: 'https://login.microsoftonline.com/',
  tenant: '31fe9eb3-165a-4e2a-b3ca-8015d5342ac3',
  clientId: msalConfig.clientId,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: msalConfig.cacheLocation,
  serviceClientId: msalConfig.serviceClientId,
  serviceScopes: msalConfig.scopes,
  redirectUri: msalConfig.parameters.redirectUri,
};
export default msal;
