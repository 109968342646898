import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, ClickAwayListener, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { useField } from 'formik';

interface IProps {
  name: string;
  label: string;
  multiple?: boolean;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  TextFieldProps?: TextFieldProps;
  showHelp?: boolean;
  helpText?: any;
  className?: any;
  value?: string | number | undefined | null;
}

const FormSelect: React.FC<IProps> = (props) => {
  const { label, name, multiple, placeholder, readonly, required, showHelp, helpText, className, value } = props;
  const [field, meta] = useField(name);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    if (!!isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const errorText = meta.touched && meta.error && !readonly ? meta.error : '';

  let pageContent;

  if (!!showHelp && !!isShown) {
    pageContent = (
      <TextField
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        {...field}
        select
        className={className}
        label={label}
        placeholder={placeholder}
        value={value}
        error={!!errorText}
        helperText={errorText}
        required={required}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="left"
                  onClose={handleTooltipClose}
                  open={isOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={helpText}
                >
                  <IconButton>
                    <HelpIcon onClick={handleTooltipOpen} style={{ color: '#7eccff' }} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </InputAdornment>
          ),
        }}
        SelectProps={{
          native: true,
          multiple: multiple,
        }}
        disabled={!!readonly}
        variant="outlined"
      >
        {props.children}
      </TextField>
    );
  } else {
    pageContent = (
      <TextField
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        {...field}
        select
        value={value}
        className={className}
        label={label}
        placeholder={placeholder}
        error={!!errorText}
        helperText={errorText}
        required={required}
        SelectProps={{
          native: true,
          multiple: multiple,
        }}
        disabled={!!readonly}
        variant="outlined"
      >
        {props.children}
      </TextField>
    );
  }

  return <>{pageContent}</>;
};

export default FormSelect;
