const local = {
  api: {
    apiUrl: 'https://dock-api-test-api.azurewebsites.net', // change to the local API url if using local instance
  },
  apiManagement: {
    apiManagementEndpoint: 'https://oiva-test-api-management.azure-api.net',
    apiManagementSubscriptionKey: 'f5fae9e3d1f948599d3296908a829ffb',
  },
  newApi: {
    apiUrl: 'https://oiva-api-test.azurewebsites.net', //'http://localhost:8000', //'https://oiva-api-test.azurewebsites.net'
  },
};

const dev = {
  api: {
    apiUrl: 'https://dock-api-dev-api.azurewebsites.net',
  },
  apiManagement: {
    apiManagementEndpoint: 'https://oiva-dev-api-management.azure-api.net',
    apiManagementSubscriptionKey: 'bc3105733a624294b1958399c15d0a2e',
  },
  newApi: {
    apiUrl: 'https://oiva-api-test.azurewebsites.net',
  },
};

const test1 = {
  api: {
    apiUrl: 'https://dock-api-test-api.azurewebsites.net',
  },
  apiManagement: {
    apiManagementEndpoint: 'https://oiva-test-api-management.azure-api.net',
    apiManagementSubscriptionKey: 'f5fae9e3d1f948599d3296908a829ffb',
  },
  newApi: {
    apiUrl: 'https://oiva-api-test.azurewebsites.net',
  },
};

const test2 = {
  api: {
    apiUrl: 'https://dock-api-test-api.azurewebsites.net',
  },
  apiManagement: {
    apiManagementEndpoint: 'https://oiva-test-api-management.azure-api.net',
    apiManagementSubscriptionKey: 'f5fae9e3d1f948599d3296908a829ffb',
  },
};

const prod = {
  api: {
    apiUrl: 'https://dock-api-prod-api.azurewebsites.net',
  },
  apiManagement: {
    apiManagementEndpoint: 'https://oiva-prod-api-management.azure-api.net',
    apiManagementSubscriptionKey: '310aff3803444cb285edba62eab116bf',
  },
  newApi: {
    apiUrl: 'https://oiva-api-prod.azurewebsites.net',
  },
};
const staging = {
  api: {
    apiUrl: 'https://dock-api-prod-api.azurewebsites.net',
  },
  apiManagement: {
    apiManagementEndpoint: 'https://oiva-prod-api-management.azure-api.net',
    apiManagementSubscriptionKey: '310aff3803444cb285edba62eab116bf',
  },
  newApi: {
    apiUrl: 'https://oiva-api-prod.azurewebsites.net',
  },
};

const config =
  process.env.REACT_APP_ENV === 'prod'
    ? prod
    : process.env.REACT_APP_ENV === 'staging'
    ? staging
    : process.env.REACT_APP_ENV === 'test'
    ? test1
    : local;

export const apiUrl = config.api.apiUrl;
export const apiVersion = '2020-01-01';
export const apimConf = config.apiManagement;
export const newApiConf = config.newApi;
