import { Hidden, IconButton, List, ListItem, ListItemText, SwipeableDrawer } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, Match } from '@reach/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../../types/types';
import { usePermission } from '../Hooks/Permissions';
import routes from './Routes';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.primary.light,
    },
    link: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'none',
    },
  }),
);

const NavigationLink: React.FC<{ to: string; label: string; roles: Role[]; onClick: () => void }> = (props) => {
  const { to, label, roles, onClick } = props;

  const classes = useStyles(props);

  const { t } = useTranslation();
  const [hasPermission] = usePermission(roles);

  return hasPermission ? (
    <Match path={to}>
      {({ match }) => (
        <Link to={to} className={classes.link} onClick={onClick}>
          <ListItem button selected={!!match}>
            <ListItemText primary={t(label)} />
          </ListItem>
        </Link>
      )}
    </Match>
  ) : null;
};

interface IProps {}

type Props = IProps;

const MobileMenu: React.FC<Props> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const classes = useStyles();

  return (
    <Hidden only={['md', 'lg', 'xl']}>
      <IconButton
        aria-owns={menuOpen ? 'menu-mobile' : undefined}
        aria-haspopup="true"
        onClick={() => setMenuOpen(true)}
        color="inherit"
        style={{ marginLeft: -12, marginRight: 20 }}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        id="menu-mobile"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        classes={classes}
      >
        <List component="nav">
          {routes.map((item) => (
            <NavigationLink
              key={item.path}
              to={item.path}
              label={item.label}
              roles={item.roles}
              onClick={() => setMenuOpen(false)}
            />
          ))}
        </List>
      </SwipeableDrawer>
    </Hidden>
  );
};

export default MobileMenu;
