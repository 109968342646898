const config = {
  colors: {
    mostBlue: '#0071bc',
    secondary: '#db3069',
    background: '#ffffff',
    treeLineColor: '#ccccdd',
  },
  fonts: {
    header: {
      family: 'Open Sans Condensed',
      size: '20px',
    },
    text: {
      family: 'Open Sans',
      size: '10px',
    },
  },
  uniqueIdLength: 10,
  uniqueIdChars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
};

export default config;
