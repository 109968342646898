import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from './FormFrame';
import FormTableMulti, { IFormTableExtraProps } from './FormTableMulti';

interface IProps<T extends {}> {
  validationSchema: yup.ObjectSchema<T>;
  data: T[];
  properties: {
    key: keyof T;
    label: string;
  }[];
  isLoading?: boolean;
  isUpdating?: boolean;
  idProperty: keyof T;
  fetchDataItem: (id: number | string) => T | undefined;
  onSubmit: any;
  onDelete?: (tasks: any[]) => void;
  render?: (props: { values: T; isEditing: boolean }) => React.ReactNode;
  renderAdd?: (props: { values?: T; onCancel: () => void }) => React.ReactNode;
  tableLabel: string;
  formLabel: string;
  pagination?: boolean;
  rowsPerPage?: number;
  limitHeight?: boolean;
  duplicateDisabled?: boolean;
  buttonsDisabled?: boolean;
  addDisabled?: boolean;
  editRendered?: boolean;
  handleSearch?: any;
  searchValue?: string;
  excel?: boolean;
  dirtySave?: any;
  onDirtySave?: any;
}

type Props<T> = IProps<T>;

function SplitFormFrameMulti<T>(props: React.PropsWithChildren<Props<T>>) {
  const {
    validationSchema,
    data,
    idProperty,
    properties,
    isLoading,
    isUpdating,
    fetchDataItem,
    onSubmit,
    onDelete,
    render,
    renderAdd,
    tableLabel,
    formLabel,
    children,
    pagination,
    rowsPerPage,
    limitHeight,
    duplicateDisabled,
    buttonsDisabled,
    addDisabled,
    editRendered,
    excel,
    dirtySave,
    onDirtySave,
  } = props;

  const [currentSelection, setCurrentSelection] = useState<T>();
  const [allSelections, setAllSelections] = useState<any[]>([]);
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  useEffect(() => {
    if (data && currentSelection) {
      if (!data.find((d) => d[idProperty] === currentSelection[idProperty])) {
        setCurrentSelection(undefined);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isUpdating || false);
    }
    if (isUpdating === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isUpdating]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectAll = () => {
    if (allSelections === data) {
      setAllSelections([]);
    } else {
      setAllSelections(data);
    }
    setCurrentSelection(undefined);
  };

  const handleItemChange = (id: number | string) => {
    const dataItem = fetchDataItem(id);
    if (!allSelections.includes(dataItem)) {
      setCurrentSelection(dataItem);
    } else {
      // On de-select, set values to `undefined` so that the previous FormFrame render disappears
      setCurrentSelection(undefined);
    }
    if (allSelections.includes(dataItem)) {
      // Only allow the item to exist in the array once
      setAllSelections(allSelections.filter((item) => item !== dataItem));
    } else {
      setAllSelections((allSelections) => [...allSelections, dataItem]);
    }
  };

  const handleSubmit: (data: T, setIsSubmitting: SetIsSubmittingFn, setStopEditing: SetStopEditingFn) => void = (
    data,
    setIsSubmitting,
    setStopEditing,
  ) => {
    setIsSubmittingFn(() => setIsSubmitting);
    setStopEditingFn(() => setStopEditing);

    onSubmit(data);
  };

  const handleDelete: () => void = () => {
    if (onDelete) {
      onDelete(allSelections);
      setCurrentSelection(undefined);
    }
  };

  const handleRenderAdd: (duplicate: boolean, onCancel: () => void) => React.ReactNode = (duplicate, onCancel) => {
    return renderAdd && renderAdd({ values: duplicate ? currentSelection : undefined, onCancel });
  };

  const tableData: (T & IFormTableExtraProps)[] = data && data.map((d) => ({ ...d, id: String(d[idProperty]) }));

  return (
    <>
      <div>
        <FormTableMulti
          label={tableLabel}
          data={tableData}
          properties={properties}
          isLoading={isLoading || false}
          selectedItems={allSelections}
          onSelectAll={handleSelectAll}
          onSelectRow={handleItemChange}
          onDelete={!!onDelete ? handleDelete : undefined}
          render={handleRenderAdd}
          pagination={pagination}
          rowsPerPage={rowsPerPage}
          limitHeight={limitHeight}
          duplicateDisabled={duplicateDisabled}
          buttonsDisabled={buttonsDisabled}
          addDisabled={addDisabled}
          excel={excel}
        ></FormTableMulti>
      </div>
      <div>
        <FormFrame
          label={formLabel}
          validationSchema={validationSchema}
          initialValues={currentSelection}
          onSubmit={handleSubmit}
          render={render}
          editRendered={editRendered}
          dirtySave={dirtySave}
          onDirtySave={onDirtySave}
        >
          {children}
        </FormFrame>
      </div>
    </>
  );
}

export default SplitFormFrameMulti;
