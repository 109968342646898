/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { useField } from 'formik';
import LoadingScreen from '../Common/LoadingScreen/index';

import { useTranslation } from 'react-i18next';

interface IProps {
  name: string;
  label: string;
  readonly?: boolean;
  TextFieldProps?: TextFieldProps;
  availableValues: string[];
  isLoading?: boolean;
}

const FormTwinMultiSelect: React.FC<IProps> = (props) => {
  const { name, readonly, availableValues, isLoading } = props;
  const [field, meta, helpers] = useField<string[]>(name);

  const [leftHandList, setLeftHandList] = useState<string[]>([]);
  const [rightHandList, setRightHandList] = useState<string[]>([]);

  const [leftHandSelected, setLeftHandSelected] = useState<string[]>([]);
  const [rightHandSelected, setRightHandSelected] = useState<string[]>([]);

  useEffect(() => {
    setLeftHandList(field.value);
    setRightHandList(availableValues.filter((value) => field.value.indexOf(value) === -1));

    setLeftHandSelected([]);
    setRightHandSelected([]);
  }, [field.value, availableValues]);

  const { t } = useTranslation();

  const handleLeftHandChange: (e: any) => void = (e) => {
    setLeftHandSelected(
      Array.from(e.target.options)
        .filter((o: any) => o.selected)
        .map((o: any) => o.value),
    );

    setRightHandSelected([]);
  };

  const handleRightHandChange: (e: any) => void = (e) => {
    setRightHandSelected(
      Array.from(e.target.options)
        .filter((o: any) => o.selected)
        .map((o: any) => o.value),
    );

    setLeftHandSelected([]);
  };

  const handleShiftLeft: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = (e) => {
    const newLeft = leftHandList.concat(rightHandSelected);
    const newRight = rightHandList.filter((value) => rightHandSelected.indexOf(value) === -1);

    setLeftHandList(newLeft);
    setRightHandList(newRight);

    setLeftHandSelected([]);
    setRightHandSelected([]);

    helpers.setValue(newLeft);
  };

  const handleShiftRight: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = (e) => {
    const newLeft = leftHandList.filter((value) => leftHandSelected.indexOf(value) === -1);
    const newRight = rightHandList.concat(leftHandSelected);

    setLeftHandList(newLeft);
    setRightHandList(newRight);

    setLeftHandSelected([]);
    setRightHandSelected([]);

    helpers.setValue(newLeft);
  };

  return (
    <>
      <Grid item container spacing={0}>
        <Grid item xs={12} sm={5}>
          {!!isLoading && <LoadingScreen />}

          {!isLoading && (
            <span>
              <Typography variant="subtitle1">{t('label-split-selected')}</Typography>
              <TextField
                value={leftHandSelected}
                onChange={handleLeftHandChange}
                select
                SelectProps={{
                  native: true,
                  multiple: true,
                }}
                disabled={!!readonly}
                variant="outlined"
              >
                {leftHandList.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </TextField>
            </span>
          )}
        </Grid>
        <Grid item xs={12} sm={2} container direction="column" justify="center" alignContent="center">
          <Grid item>
            <Button
              variant="contained"
              disabled={leftHandSelected.length === 0}
              onClick={handleShiftRight}
              style={{ margin: '4px' }}
            >
              <ChevronRight />
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={rightHandSelected.length === 0}
              onClick={handleShiftLeft}
              style={{ margin: '4px' }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          {!!isLoading && <LoadingScreen />}

          {!isLoading && (
            <span>
              <Typography variant="subtitle1">{t('label-split-available')}</Typography>
              <TextField
                value={rightHandSelected}
                onChange={handleRightHandChange}
                select
                SelectProps={{
                  native: true,
                  multiple: true,
                }}
                disabled={!!readonly}
                variant="outlined"
              >
                {rightHandList.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </TextField>
            </span>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormTwinMultiSelect;
