import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CancelSave } from '../FormButtonGroups';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        display: 'block',
        marginBottom: theme.spacing(2),
      },
      '& .MuiInputBase-root': {
        width: '100%',
      },
      '& .MuiFormControlLabel-root': {
        display: 'block',
        textAlign: 'left',
      },
    },
  }),
);

export type SetIsSubmittingFn = Pick<FormikHelpers<any>, 'setSubmitting'>['setSubmitting'];

export interface IFormFrameProps<T extends {}> {
  label?: string;
  validationSchema: yup.ObjectSchema<T>;
  initialValues: T;
  onSubmit: (values: T, setIsSubmitting: SetIsSubmittingFn) => Promise<T> | void;
  onCancel: () => void;
  render?: (props: { values: T; isSubmitting: boolean }) => React.ReactNode;
}

type Props<T> = IFormFrameProps<T>;

function ModalFormFrame<T>(props: React.PropsWithChildren<Props<T>>) {
  const { label, validationSchema, initialValues, onSubmit, onCancel, render } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const handleCancel: () => void = () => {
    onCancel();
  };

  return (
    <>
      <DialogTitle id="max-width-dialog-title">{label ? t(label) : ''}</DialogTitle>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onSubmit(values, actions.setSubmitting);
        }}
        enableReinitialize={true}
      >
        {({ values, isSubmitting }) => (
          <>
            <DialogContent>
              <Form className={classes.root}>
                {render ? render({ values, isSubmitting }) : null}
                <CancelSave disabled={isSubmitting} onCancel={handleCancel} />
              </Form>
            </DialogContent>
          </>
        )}
      </Formik>
    </>
  );
}

export default ModalFormFrame;
