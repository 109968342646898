const enums = {
  nodeStatus: {
    ADDED: 'ADDED',
    DRAFT: 'DRAFT',
    SAVED: 'PUBLISHED',
    DISABLED: 'DISABLED',
  },
  nodeType: {
    UNKNOWN: 'UNKNOWN',
    ROOT: 'ROOT',
    ROBOTROOT: 'ROBOTROOT',
    BUSINESSROOT: 'BUSINESSROOT',
    OBJECT: 'OBJECT',
    TIMESTAMP: 'TIMESTAMP',
    KEY: 'KEY',
  },
};

export default enums;
