import {
  createTaskActionCreator,
  fetchTasksActionCreator,
  selectTaskActionCreator,
  updateTaskActionCreator,
} from '../actions/task';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IDictionary, INormalizedBase, ITask, ITaskCreate, ITaskState, ITaskUpdate } from '../../types/types';

import { Success } from 'typescript-fsa';
import { combineReducers } from 'redux';

const initialState: ITaskState = {
  selectedTask: '',
  byId: {},
  ids: [],
};

const selectedTaskHandler = (selectedTask: string, payload: string) => {
  if (payload) {
    return payload;
  }

  return selectedTask;
};

const selectIfOneTaskHandler = (selectedTask: string, payload: Success<string, INormalizedBase<ITask, string>>) => {
  if (payload.result.ids && payload.result.ids.length === 1) {
    return payload.result.ids[0];
  }

  return selectedTask;
};

const selectedTask = reducerWithInitialState<string>(initialState.selectedTask)
  .case(selectTaskActionCreator, selectedTaskHandler)
  .case(fetchTasksActionCreator.done, selectIfOneTaskHandler);

const byIdHandler = (byId: IDictionary<ITask>, payload: Success<string, INormalizedBase<ITask, string>>) => {
  if (payload.result.byId) {
    return { ...byId, ...payload.result.byId };
  }
  return byId;
};

const byIdCreateHandler = (byId: IDictionary<ITask>, payload: Success<ITaskCreate, ITask>) => {
  if (payload.result) {
    return { ...byId, [payload.result.task_guid]: payload.result };
  }
  return byId;
};

const byIdUpdateHandler = (byId: IDictionary<ITask>, payload: Success<ITaskUpdate, void>) => {
  if (payload.params) {
    const current = byId[payload.params.task_guid];
    const updated = current ? { ...current, ...payload.params } : payload.params;
    return { ...byId, [payload.params.task_guid]: updated };
  }
  return byId;
};

const byId = reducerWithInitialState<{}>(initialState.byId)
  .cases([fetchTasksActionCreator.done], byIdHandler)
  .cases([createTaskActionCreator.done], byIdCreateHandler)
  .cases([updateTaskActionCreator.done], byIdUpdateHandler);

const idsHandler = (ids: string[], payload: Success<string, INormalizedBase<ITask, string>>) => {
  if (payload.result.ids && payload.result.ids.length > 0) {
    return Array.from(new Set(ids.concat(payload.result.ids)));
  }
  return ids;
};

const idsCreateHandler = (ids: string[], payload: Success<ITaskCreate, ITask>) => {
  if (payload.result) {
    return Array.from(new Set(ids.concat(payload.result.task_guid)));
  }
  return ids;
};

const idsUpdateHandler = (ids: string[], payload: Success<ITaskUpdate, void>) => {
  return ids;
};

const ids = reducerWithInitialState<string[]>(initialState.ids)
  .cases([fetchTasksActionCreator.done], idsHandler)
  .cases([createTaskActionCreator.done], idsCreateHandler)
  .cases([updateTaskActionCreator.done], idsUpdateHandler);

export default combineReducers({
  selectedTask,
  byId,
  ids,
});

export const getSelectedTaskId: (state: ITaskState) => string = (state) => {
  return state.selectedTask;
};

export const getSelectedTask: (state: ITaskState) => ITask = (state) => {
  return state.byId[state.selectedTask];
};

export const getSelectedTaskName: (state: ITaskState) => string = (state) => {
  return state.byId[state.selectedTask]?.task_name || '';
};

export const getAllTasks: (state: ITaskState, customerId: string) => ITask[] = (state, customerId) => {
  return state.ids.map((id) => state.byId[id]).filter((t) => t.customer_guid === customerId);
};
