// General
import React, { useState } from 'react';

// MUI
import EqualizerIcon from '@mui/icons-material/Equalizer';

// ChartWrapper related components
import { ChartWrapperTab, IChartWrapperProps } from './ChartWrapperTypes';
import ChartBox from './ChartWrapperComponents/ChartBox/ChartBox';
import ChartBoxFullscreen from './ChartWrapperComponents/ChartBoxFullscreen/ChartBoxFullscreen';

/**
 * A wrapper for a general OIVA chart container component.
 *
 * @param {string} title REQUIRED: The title text of the chart component
 *
 * @param {JSX.Element} chart REQUIRED: Preferably a Recharts component that is wrapped inside a ResponsiveContainer component (which is also a Recharts feature).
 *
 * @param {StateParameters} state OPTIONAL: A StateParameters object for handling loading icons, error messages etc. Compatible with the usual isLoading and isError booleans provided by most API hooks.
 *
 * @param {object[]} excelData OPTIONAL: Enables the 'Export to Excel' feature. Pass in the same data that is used in creating the chart component.
 *
 * @param {ChartWrapperTabProp[]} additionalTabs OPTIONAL: Any additional tabs to be displayed in fullscreen mode, for example case-specific customized data tables etc.
 *
 * @param {boolean} disableFullscreen OPTIONAL: Hide the fullscreen button
 *
 * @author Kalle Sundell <www.github.com/sundeka>
 */

const ChartWrapper = (props: IChartWrapperProps) => {
  const { title, chart, state, excelData, additionalTabs, disableFullscreen } = props;

  const [minimized, setMinimized] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  var tabs: ChartWrapperTab[] = [{ id: 0, icon: <EqualizerIcon />, content: chart }];

  if (!!additionalTabs) {
    var i = 1;
    for (let additionalTab of additionalTabs) {
      tabs.push({ id: i, icon: additionalTab.icon, content: additionalTab.content });
      i += 1;
    }
  }

  const onFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  const onMinimize = () => {
    setMinimized(!minimized);
  };

  return (
    <>
      <ChartBox
        title={title}
        chart={chart}
        minimized={minimized}
        state={state}
        onMinimize={onMinimize}
        onFullscreen={onFullscreen}
        disableFullscreen={disableFullscreen}
      />
      <ChartBoxFullscreen
        title={title}
        chart={chart}
        excelData={excelData}
        tabs={tabs}
        isOpen={fullscreen}
        onClose={onFullscreen}
      />
    </>
  );
};

export default ChartWrapper;
