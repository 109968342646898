/**
 * message: what message to display to user (for example, API response)
 *
 * severity: 'success' | 'info' | 'warning' | 'error'
 *
 * displayAlert: boolean that determines whether error msg is visible or not
 *
 * setDisplay: setter (useState) for the above mentioned boolean
 *
 * duration: OPTIONAL. set the duration for the pop-up (milliseconds). defaults to 3000
 *
 *
 * USAGE:
 * Insert the component in the beginning of the parent like so:
 *
 * return (
 *  <>
 *    <AlertWindow message={apiErrorMessage} display={displayAlert} setDisplay={setDisplayAlert}/>
 *    <MyOtherComponent
 *      ....
 *    />
 *  </>
 * )
 *
 */

import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface IProps {
  message: string | undefined;
  severity: any; // string
  display: boolean;
  setDisplay: (state: boolean) => void;
  duration?: number | null;
}

const AlertWindow = (props: IProps) => {
  const { message, severity, display, setDisplay, duration } = props;

  const closeDisplayAlert = (e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDisplay(false);
  };

  return (
    <Snackbar
      open={display}
      onClose={closeDisplayAlert}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={!!duration ? duration : 3000}
    >
      <MuiAlert severity={severity} variant="filled" onClose={closeDisplayAlert}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default AlertWindow;
