import React, { Component } from 'react';
import TreeView from './DataModel/TreeView';
import ContentView from './DataModel/ContentView';
import config from './DataModel/config';
import enums from './DataModel/enums';
import testData from './DataModel/testData';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faPlusCircle,
  faProjectDiagram,
  faTrash,
  faFileExcel,
  faFileCsv,
  faFileCode,
  faChevronCircleUp,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons';

library.add(faPlus);
library.add(faPlusCircle);
library.add(faProjectDiagram);
library.add(faTrash);
library.add(faFileExcel);
library.add(faFileCsv);
library.add(faFileCode);
library.add(faChevronCircleUp);
library.add(faChevronCircleDown);

class DataModel extends Component {
  state = {
    dataTree: undefined,
    selectedUniqueId: undefined,
    uniqueIdsToIndexPathMap: {},
  };

  usedUniqueIds = {}; // just temp storage for unique id's to prevent duplicates

  generateUniqueId = () => {
    let uniqueId = '';

    while (uniqueId === '' || uniqueId in this.state.uniqueIdsToIndexPathMap || uniqueId in this.usedUniqueIds) {
      uniqueId = '';
      for (let i = 0; i < config.uniqueIdLength; i++) {
        uniqueId += config.uniqueIdChars.charAt(Math.floor(Math.random() * config.uniqueIdChars.length));
      }
    }

    this.usedUniqueIds[uniqueId] = uniqueId;
    return uniqueId;
  };

  createNode = ({
    name = 'new node',
    description = '',
    status = enums.nodeStatus.DRAFT,
    type = enums.nodeType.UNKNOWN,
    children = [],
    value = undefined,
  } = {}) => ({ uniqueId: this.generateUniqueId(), name, description, status, type, children, value });

  parseJsonToDataTree = (nodeName, jsonData) =>
    this.createNode(
      typeof jsonData !== 'object'
        ? { name: nodeName, value: jsonData }
        : {
            name: nodeName,
            children: Object.entries(jsonData).map((entry) => this.parseJsonToDataTree(entry[0], entry[1])),
          },
    );

  rebuildUniqueIdsToIndexPathMap = () => {
    let treeTraverse = (node, indexPath = []) => {
      let ret = { [node.uniqueId]: indexPath };
      if (node.children && node.children.length > 0)
        for (let i = 0; i < node.children.length; i++)
          ret = Object.assign(ret, treeTraverse(node.children[i], indexPath.concat(i)));

      return ret;
    };
    this.setState({ uniqueIdsToIndexPathMap: JSON.parse(JSON.stringify(treeTraverse(this.state.dataTree))) });
  };

  componentDidMount() {
    // fixed nodes
    let dataTree = this.createNode({
      name: 'ROOT',
      status: enums.nodeStatus.SAVED,
      type: enums.nodeType.ROOT,
      children: [
        {
          ...this.parseJsonToDataTree('Technical Header', testData),
          status: enums.nodeStatus.SAVED,
          type: enums.nodeType.ROBOTROOT,
        },
        this.createNode({
          name: 'Business Information',
          status: enums.nodeStatus.SAVED,
          type: enums.nodeType.BUSINESSROOT,
        }),
      ],
    });

    this.setState({ dataTree: dataTree }, () => this.rebuildUniqueIdsToIndexPathMap());
  }

  getNodeFromIndexPath = (indexPath) =>
    indexPath && indexPath.reduce((node, step) => node.children[step], this.state.dataTree);

  getNodeFromUniqueId = (uniqueId) => this.getNodeFromIndexPath(this.state.uniqueIdsToIndexPathMap[uniqueId]);

  getNodeParent = (uniqueId) => {
    if (!(uniqueId in this.state.uniqueIdsToIndexPathMap)) return undefined;
    const indexPath = this.state.uniqueIdsToIndexPathMap[uniqueId];
    return indexPath.length > 0 ? this.getNodeFromIndexPath(indexPath.slice(0, -1)) : undefined;
  };

  onNodeAddChild = (uniqueId) => {
    let dataTree = this.state.dataTree;
    let node = this.getNodeFromUniqueId(uniqueId);
    let child = this.createNode();
    node.children.unshift(child);
    this.setState({ dataTree: dataTree }, () => this.rebuildUniqueIdsToIndexPathMap());
  };

  onNodeAddChildren = (uniqueId, childNameArray) => {
    let dataTree = this.state.dataTree;
    let node = this.getNodeFromUniqueId(uniqueId);
    node.children = childNameArray.reduce(
      (nodes, name) => nodes.concat(this.createNode({ name: name })),
      node.children,
    );

    this.setState({ dataTree: dataTree }, () => this.rebuildUniqueIdsToIndexPathMap());
  };

  onNodeAddSibling = (uniqueId) => {
    let dataTree = this.state.dataTree;
    const indexPath = this.state.uniqueIdsToIndexPathMap[uniqueId];
    const nodeIndex = indexPath[indexPath.length - 1] + 1;
    let parentNode = this.getNodeParent(uniqueId);
    parentNode.children.splice(nodeIndex, 0, this.createNode());

    this.setState({ dataTree: dataTree }, () => this.rebuildUniqueIdsToIndexPathMap());
  };

  onNodeRemove = (uniqueId) => {
    let dataTree = this.state.dataTree;
    const indexPath = this.state.uniqueIdsToIndexPathMap[uniqueId];
    const nodeIndex = indexPath[indexPath.length - 1];
    let parentNode = this.getNodeParent(uniqueId);
    parentNode.children.splice(nodeIndex, 1);

    this.setState({ dataTree: dataTree }, () => this.rebuildUniqueIdsToIndexPathMap());
  };

  onNodeFieldChange = (uniqueId, fieldName, newValue) => {
    let dataTree = this.state.dataTree;
    let node = this.getNodeFromUniqueId(uniqueId);
    node[fieldName] = newValue;
    this.setState({ dataTree: dataTree });
  };

  onNodeSelect = (uniqueId) => {
    this.setState({ selectedUniqueId: uniqueId });
  };

  onNodeSelectUp = () => {
    if (this.state.selectedUniqueId === '') {
      this.getNodeFromIndexPath([this.state.dataTree.children.length - 1]);
    } else {
      const indexPath = this.state.uniqueIdsToIndexPathMap[this.state.selectedUniqueId];
      const parent = this.getNodeParent(this.state.selectedUniqueId);
      const childCount = parent.children.length;
      const node = parent.children[(indexPath.slice(-1)[0] + childCount - 1) % childCount];
      this.onNodeSelect(node.uniqueId);
    }
  };

  onNodeSelectDown = () => {
    if (this.state.selectedUniqueId === '') {
      this.getNodeFromIndexPath([0]);
    } else {
      const indexPath = this.state.uniqueIdsToIndexPathMap[this.state.selectedUniqueId];
      const parent = this.getNodeParent(this.state.selectedUniqueId);
      const childCount = parent.children.length;
      const node = parent.children[(indexPath.slice(-1)[0] + 1) % childCount];
      this.onNodeSelect(node.uniqueId);
    }
  };

  onNodeSelectLeft = () => {
    if (this.state.selectedUniqueId !== '') {
      const parent = this.getNodeParent(this.state.selectedUniqueId);
      if (parent) this.onNodeSelect(parent.uniqueId);
    }
  };

  onNodeSelectRight = () => {
    if (this.state.selectedUniqueId === '') {
      this.getNodeFromIndexPath([0]);
    } else {
      const node = this.getNodeFromUniqueId(this.state.selectedUniqueId);
      if (node.children.length > 0) {
        this.onNodeSelect(node.children[0].uniqueId);
      }
    }
  };

  quickTests = () => {
    /// temp tests
    console.log('TESTS');

    console.log(this.getNodeFromIndexPath([]));
    console.log(this.getNodeFromUniqueId('123') + ' = undefined');
    console.log(this.getNodeFromIndexPath([0]));
    console.log(this.getNodeFromUniqueId(Object.keys(this.state.uniqueIdsToIndexPathMap).slice(0, 1)));

    console.log('getNodeParent() of unknown id: ' + (this.getNodeParent('123') === undefined));
    console.log(
      'getNodeParent() of first node of root: ' +
        (this.getNodeParent(this.getNodeFromIndexPath([0, 0]).uniqueId).uniqueId ===
          this.getNodeFromIndexPath([0]).uniqueId),
    );
  };

  render() {
    const { dataTree, selectedUniqueId } = this.state;
    const selectedIndexPath = this.state.uniqueIdsToIndexPathMap[selectedUniqueId];
    console.log(selectedUniqueId, this.getNodeFromUniqueId(selectedUniqueId), this.state.uniqueIdsToIndexPathMap);
    return (
      <>
        <TreeView
          dataTree={dataTree}
          selectedUniqueId={selectedUniqueId}
          selectedIndexPath={selectedIndexPath}
          onNodeSelect={this.onNodeSelect}
          onNodeAddChild={this.onNodeAddChild}
          onNodeAddChildren={this.onNodeAddChildren}
          onNodeAddSibling={this.onNodeAddSibling}
          onNodeRemove={this.onNodeRemove}
          onNodeSelectUp={this.onNodeSelectUp}
          onNodeSelectDown={this.onNodeSelectDown}
          onNodeSelectLeft={this.onNodeSelectLeft}
          onNodeSelectRight={this.onNodeSelectRight}
        />
        {selectedUniqueId && (
          <ContentView
            dataNode={this.getNodeFromUniqueId(selectedUniqueId)}
            onNodeFieldChange={this.onNodeFieldChange}
          />
        )}
      </>
    );
  }
}

export default DataModel;
