export const CREATE_APIMESSAGE = 'CREATE_APIMESSAGE';

export const CLEAR_APIMESSAGE = 'CLEAR_APIMESSAGE';

export const createApiMessage = (apiAddress: string, apiMsg: string) => {
  return {
    type: CREATE_APIMESSAGE,
    data: {
      apiAddress: apiAddress,
      apiMessage: apiMsg,
    },
  };
};

export const clearApiMessageList = () => {
  return {
    type: CLEAR_APIMESSAGE,
  };
};
