import { Hidden, Typography, Theme, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Language from '@material-ui/icons/Language';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { getPortalLanguages } from '../../redux/reducers';
import { ILanguage, IState } from '../../types/types';

const useStyles = makeStyles<Theme, {}>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  link: {
    cursor: 'pointer',
    margin: [[0, 10]] as any,
    color: 'inherit',

    '&.active': {
      fontWeight: 700,
    },
  },
}));

const LanguageMenu = () => {
  const [anchorEl, setanchorEl] = useState<Element | null>(null);
  const { i18n } = useTranslation();
  const classes = useStyles({});

  const languages = useSelector<IState, ILanguage[]>(getPortalLanguages, shallowEqual);

  const changeLanguage: (lng: string) => void = (lng) => {
    i18n.changeLanguage(lng);
    setanchorEl(null);
  };

  return (
    <div className={classes.container}>
      <Hidden only={['xs', 'sm']}>
        <nav className={classes.linkContainer}>
          {languages &&
            languages.map((l) => (
              <Typography
                key={l.code}
                className={classNames(classes.link, { active: l.code === i18n.language })}
                onClick={() => changeLanguage(l.code)}
              >
                {l.name}
              </Typography>
            ))}
        </nav>
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <IconButton
          aria-owns={Boolean(anchorEl) ? 'menu-language' : undefined}
          aria-haspopup="true"
          onClick={(e) => setanchorEl(e.currentTarget)}
          color="inherit"
        >
          <Language />
        </IconButton>
        <Menu
          disableAutoFocusItem
          id="menu-language"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(e) => setanchorEl(null)}
        >
          {languages &&
            languages.map((l) => (
              <MenuItem key={l.code} selected={l.code === i18n.language} onClick={() => changeLanguage(l.code)}>
                <ListItemText disableTypography primary={l.name} />
              </MenuItem>
            ))}
        </Menu>
      </Hidden>
    </div>
  );
};

export default LanguageMenu;
