import React from 'react';
import { Typography, Button, Card, CardActions, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PieChart from 'react-minimal-pie-chart';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: '#b3f0ff',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Chart = (props: any) => {
  const classes = useStyles();
  const data = [
    { title: 'One', value: 10, color: '#E38627' },
    { title: 'Two', value: 15, color: '#C13C37' },
    { title: 'Three', value: 20, color: '#6A2135' },
    { title: 'Four', value: 20, color: '#FF6347' },
  ];

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterButton-variant="headline" component="h2">
            This is test chart
            <PieChart data={data}></PieChart>
          </Typography>
          <Typography component="p">This is just going to be paragraph for now</Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary">
            Chart
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default Chart;
