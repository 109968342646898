import { IConsumption } from '../../types/types';
import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import apiRequest from '../../services/api/apiService';
import HttpMethod from '../../services/api/httpMethod';

const consumptionAdapter = createEntityAdapter<IConsumption>({
  selectId: (consumption) => consumption.resource_name,
});

const initialStateConsumption = consumptionAdapter.getInitialState({
  selectedConsumption: '',
  status: 'idle',
});

const consumptionSlice = createSlice({
  name: 'consumption',
  initialState: initialStateConsumption,
  reducers: {
    selectConsumption(state, action: PayloadAction<string>) {
      state.selectedConsumption = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConsumptions.fulfilled, (state, { payload }: PayloadAction<IConsumption[]>) => {
        state.status = 'fulfilled';
        consumptionAdapter.setAll(state, payload);
      })
      .addCase(fetchConsumptions.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const fetchConsumptions = createAsyncThunk('consumption/listConsumption', async (data: string[]) => {
  const [customerId, startTime, stopTime] = data;
  const newStartTime = startTime.split('T')[0];
  const newStopTime = stopTime.split('T')[0];

  const res = await apiRequest<any, IConsumption[]>({
    resource: `customers/${customerId}/consumption`,
    method: HttpMethod.GET,
    apiVersion: 'pythonApi',
    payload: {
      customer_id: customerId,
      start_time: newStartTime,
      stop_time: newStopTime,
    },
  });
  return res.data;
});

export const { selectConsumption } = consumptionSlice.actions;
export const consumptionSelectors = consumptionAdapter.getSelectors((state: any) => state.consumption);
export const consumptionStatus = (state: any) => state.consumption.status;
export const getAllConsumptions = (state: any) => {
  return state;
};

export default consumptionSlice.reducer;
