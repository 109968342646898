import React, { useState } from 'react';
import { TextField, Tooltip, ClickAwayListener, IconButton } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import HelpIcon from '@material-ui/icons/Help';
import { useField } from 'formik';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  TextFieldProps?: TextFieldProps;
  showHelp?: boolean;
  customFieldValue?: any;
}

const FormTextArea: React.FC<IProps> = (props) => {
  const { label, name, placeholder, readonly, required, showHelp, customFieldValue } = props;
  const [field, meta] = useField(name);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    if (!!isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  if (!!customFieldValue) {
    field.value = customFieldValue;
  } else {
    field.value = field.value || '';
  }

  const errorText = meta.touched && meta.error && !readonly ? meta.error : '';

  let pageContent;
  if (!!showHelp && !!isShown) {
    pageContent = (
      <span>
        <TextField
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          {...field}
          label={label}
          placeholder={placeholder}
          error={!!errorText}
          helperText={errorText}
          required={required}
          disabled={!!readonly}
          id={name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="left"
                    onClose={handleTooltipClose}
                    open={isOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        <h3>Help tooltip</h3>
                        <p></p>
                      </React.Fragment>
                    }
                  >
                    <IconButton>
                      <HelpIcon onClick={handleTooltipOpen} style={{ color: '#7eccff' }} />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          multiline
          rows={4}
        />
      </span>
    );
  } else {
    pageContent = (
      <TextField
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        {...field}
        label={label}
        placeholder={placeholder}
        error={!!errorText}
        helperText={errorText}
        required={required}
        disabled={!!readonly}
        variant="outlined"
        multiline
        rows={4}
        id={name}
      />
    );
  }

  return <>{pageContent}</>;
};

export default FormTextArea;
