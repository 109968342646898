import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { startsWith, contains } from 'ramda';
import { apiUrl, apiVersion, newApiConf } from '../../configs/apiConfig';
import ApiRequest from './apiRequest';
import HttpMethod, { ResponseType } from './httpMethod';
import { acquireSilentToken } from '../auth/acquireSilentToken';

/*
Centralized code for all API requests. Extend axiosConfig if you need more advanced features.
*/

interface IHeaders {
  [header: string]: string;
}

class RequestHeaders {
  private headers: IHeaders;
  public constructor() {
    this.headers = {};
  }
  public async addAuthorizationHeader(token?: string) {
    if (!token) {
      token = await acquireSilentToken();
    }

    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    };

    return this;
  }
  public addJsonContentType() {
    this.headers = {
      ...this.headers,
      'Content-Type': 'application/json',
    };
    return this;
  }

  public addNoCache() {
    this.headers = {
      ...this.headers,
      'Cache-Control': 'no-cache',
    };
    return this;
  }

  public add() {
    return this.headers;
  }
}

const formatResourceUrl = (resource: string, api?: string) => {
  const url = api === 'pythonApi' ? newApiConf.apiUrl : apiUrl;

  if (!startsWith('http', resource)) {
    resource = `${url}/${resource}`;
  }

  if (!contains('api-version', resource)) {
    const apiVersionQueryParamDelimiter = resource.indexOf('?') > -1 ? '&' : '?';
    resource = `${resource}${apiVersionQueryParamDelimiter}api-version=${apiVersion}`;
  }

  return resource;
};

const addPayload = (options: AxiosRequestConfig, method: HttpMethod, payload: any) => {
  if (payload && method === HttpMethod.GET) {
    options.params = payload;
  } else {
    options.data = payload;
  }
};

const addResponseType = (options: AxiosRequestConfig, responseType?: ResponseType) => {
  if (!!responseType) {
    options.responseType = responseType;
  }
};

export default async function apiRequest<D, T>({
  method,
  resource,
  token,
  payload,
  responseType,
  apiVersion,
}: ApiRequest<D>): Promise<AxiosResponse<T>> {
  if (!method) {
    method = HttpMethod.GET;
  }

  if (!apiVersion) {
    apiVersion = 'zureApi';
  }
  const url = formatResourceUrl(resource, apiVersion);
  const headers = new RequestHeaders().addJsonContentType();

  await headers.addAuthorizationHeader(token);

  const options: AxiosRequestConfig = {
    method,
    url,
    headers: headers.add(),
  };

  addPayload(options, method, payload);
  addResponseType(options, responseType);

  return axios(options)
    .then((response: AxiosResponse<T>) => {
      if (method === HttpMethod.POST && response.status === 201) {
        // This is to allow starting background processes:
        // response contains 'location' header from which the result can be obtained
        if (response.headers['location']) {
          const url = formatResourceUrl(response.headers.location, apiVersion);
          return axios({ ...options, url, method: HttpMethod.GET }).then((getResponse: AxiosResponse) => {
            return getResponse as any;
          });
        }
      }
      return response as any;
    })
    .catch(async (error: AxiosError) => {
      throw error;
    });
}
