import { authProvider, parameters } from './authProvider';

/**
 * Exporting acquireSilentToken and using it this way for apiService makes token mocking for tests easier
 */
const acquireSilentToken = async () => {
  const token = (await authProvider.acquireTokenSilent(parameters)).accessToken;
  return token;
};

export { acquireSilentToken };
