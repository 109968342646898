/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import apiRequest from '../../services/api/apiService';
import HttpMethod from '../../services/api/httpMethod';
import { createApiMessage } from '../../redux/actions/apiMessage';

import { useDispatch } from 'react-redux';

export function useGetApiEffect<T>(
  resource: string,
  initialData?: any,
  api?: string,
): [T, boolean, boolean, () => void] {
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const dispatch = useDispatch();

  const apiAddress = resource.substring(resource.lastIndexOf('/') + 1, resource.lastIndexOf('?'));
  const addressCapitalized = apiAddress.charAt(0).toUpperCase() + apiAddress.slice(1);

  const fetchData = () => {
    setCount((c) => c + 1);
  };
  useEffect(() => {
    let didCancel = false;
    let didFinish = false;
    const fetch = () => {
      setIsError(false);
      setTimeout(() => {
        if (!didFinish) {
          setIsLoading(true);
        }
      }, 300);

      const request = apiRequest<any, T>({
        resource,
        method: HttpMethod.GET,
        apiVersion: api,
      });

      request
        .then((res) => {
          if (!didCancel) {
            setData(res.data);
          }
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(createApiMessage(addressCapitalized, err.message));
          setIsError(true);
        })
        .finally(() => {
          didFinish = true;
          setIsLoading(false);
        });
    };
    if (count > 0) {
      fetch();
    }

    return () => {
      didCancel = true;
    };
    //resource - removed dep since we want to fetch manually only via count
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setData(initialData);
  }, [resource]); // eslint-disable-line react-hooks/exhaustive-deps
  return [data, isLoading, isError, fetchData];
}

export function usePostApiEffect<D, T>(
  resource: string,
  initialData?: any,
  api?: string,
  refresh?: () => void,
): [T, boolean, boolean, (payload: D) => void] {
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [payload, setPayload] = useState<D>();
  const [count, setCount] = useState<number>(0);
  const postData = (dataPayload: D) => {
    setPayload(dataPayload);
    setCount((c) => c + 1);
  };
  useEffect(() => {
    let didCancel = false;
    const post = (payload?: D) => {
      setIsError(false);
      setIsLoading(true);

      const request = apiRequest<D, T>({
        resource,
        payload,
        method: HttpMethod.POST,
        apiVersion: api,
      });
      request
        .then((res) => {
          if (!didCancel) {
            setData(res.data);
            if (refresh !== undefined) {
              refresh();
            }
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoading(false));
    };
    if (count > 0) {
      post(payload);
    }
    return () => {
      didCancel = true;
    };
    //resource - removed dep since we want to fetch manually only via count
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, isLoading, isError, postData];
}

export function usePutApiEffect<D, T>(
  resource: string,
  initialData?: any,
): [T, boolean, boolean, (payload: D) => void] {
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [payload, setPayload] = useState<D>();
  const [count, setCount] = useState<number>(0);
  const postData = (dataPayload: D) => {
    setPayload(dataPayload);
    setCount((c) => c + 1);
  };
  useEffect(() => {
    let didCancel = false;
    const post = (payload?: D) => {
      setIsError(false);
      setIsLoading(true);

      const request = apiRequest<D, T>({
        resource,
        payload,
        method: HttpMethod.PUT,
      });
      request
        .then((res) => {
          if (!didCancel) {
            setData(res.data);
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoading(false));
    };
    if (count > 0) {
      post(payload);
    }
    return () => {
      didCancel = true;
    };
    //resource - removed dep since we want to fetch manually only via count
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, isLoading, isError, postData];
}

export function useDeleteApiEffect<D>(
  resource: string,
  api?: string,
  refresh?: () => void,
): [boolean, boolean, (payload: D) => void] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [payload, setPayload] = useState<D>();
  const [count, setCount] = useState<number>(0);
  const deleteData = (dataPayload: D) => {
    setPayload(dataPayload);
    setCount((c) => c + 1);
  };
  useEffect(() => {
    let didCancel = false;
    const post = (payload?: D) => {
      setIsError(false);
      setIsLoading(true);

      const request = apiRequest<D, void>({
        resource,
        payload,
        method: HttpMethod.DELETE,
        apiVersion: api,
      });
      request
        .then((res) => {
          if (refresh !== undefined) {
            refresh();
          }
        })
        .catch((err) => setIsError(true))
        .finally(() => setIsLoading(false));
    };
    if (count > 0) {
      post(payload);
    }
    return () => {
      didCancel = true;
    };
    //resource - removed dep since we want to fetch manually only via count
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  return [isLoading, isError, deleteData];
}
