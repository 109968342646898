import { createApiAction } from './common';
import { schema as Schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { INormalizedBase } from '../../types/types';
import {
  ISchedule,
  IScheduleCreate,
  IScheduleDelete,
  IScheduleUpdate,
} from '../../components/Forms/Tasks/Schedule/ScheduleTypes';
import HttpMethod from '../../services/api/httpMethod';

const actionCreator = actionCreatorFactory();

/**
 * Define schema for normalizing api response
 * The schema can be customised for you own needs, see normalizr docs
 *
 * This schema normalizes an array of objects to a dictionary of objects and an array if ids
 * If the property that defines the object identity is not named 'id' the idAttribute needs to be set
 */
const entityName = 'schedule';
const entity = new Schema.Entity(
  entityName,
  {},
  {
    idAttribute: 'schedule_id',
  },
);
const schema = [entity];

/**
 * The type of action for a fetch operation
 */
export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';

export interface IFetchSchedulesParams {
  customerId: string;
}

/**
 * Define action creators for an async operation
 */
export const fetchSchedulesActionCreator = actionCreator.async<
  IFetchSchedulesParams,
  INormalizedBase<ISchedule, string>
>(FETCH_SCHEDULES, {
  normalize: {
    entityName,
    schema,
  },
});

/**
 * Creates an async action that defines an api call
 */
export const getSchedulesApiRequest = (customerId: string) =>
  createApiAction(HttpMethod.GET, 'api/schedules', fetchSchedulesActionCreator, { customerId: customerId });

/**
 * The type of action for an update operation
 */
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';

/**
 * Define action creators for an async operation
 */
export const createScheduleActionCreator = actionCreator.async<IScheduleCreate, ISchedule>(CREATE_SCHEDULE);

/**
 * Creates an async action that defines an api call
 */
export const createScheduleApiRequest = (data: IScheduleCreate) =>
  createApiAction(HttpMethod.POST, `api/schedules`, createScheduleActionCreator, data);

/**
 * The type of action for an update operation
 */
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';

/**
 * Define action creators for an async operation
 */
export const updateScheduleActionCreator = actionCreator.async<IScheduleUpdate, void>(UPDATE_SCHEDULE);

/**
 * Creates an async action that defines an api call
 */
export const updateScheduleApiRequest = (data: IScheduleUpdate) =>
  createApiAction(HttpMethod.PUT, `api/schedules/${data.schedule_id}`, updateScheduleActionCreator, data);

/**
 * The type of action for an update operation
 */
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';

/**
 * Define action creators for an async operation
 */
export const deleteScheduleActionCreator = actionCreator.async<IScheduleDelete, void>(DELETE_SCHEDULE);

/**
 * Creates an async action that defines an api call
 */
export const deleteScheduleApiRequest = (data: IScheduleDelete) =>
  createApiAction(HttpMethod.DELETE, `api/schedules/${data.schedule_id}`, deleteScheduleActionCreator, data);
