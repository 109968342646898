import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { FormFieldSet, FormTextDisplay } from '../../../FormFields';

interface IComponentProps {
  values: {
    name: string;
    description: string | null;
    type: string;
    category: string;
    level: string;
    status: string;
  };
}

const SituationMonitor = (props: IComponentProps) => {
  const { values } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <FormFieldSet legend={t('label-situation-monitor')}>
          <FormTextDisplay label={t('label-status') + ':'} value={values.status} />
          <FormTextDisplay label={t('label-vm-controller-process') + ':'} value="Running (Placeholder)" />
          <FormTextDisplay label={t('label-storage-space') + ' C:'} value="xxx GB free of xxx GB (Placeholder)" />
          <FormTextDisplay label={t('label-storage-space') + ' D:'} value="xxx GB free of xxx GB (Placeholder)" />
        </FormFieldSet>
      </Grid>
    </>
  );
};

export default SituationMonitor;
