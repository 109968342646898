/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Radio,
} from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AddDuplicateDelete } from '../FormButtonGroups';
import { FormFieldSet } from '../FormFields';
import LoadingScreen from '../Common/LoadingScreen';
import AddNewContainer from './AddNewContainer';
import DeleteConfirmation from './DeleteConfirmation';
import TablePagination from '@material-ui/core/TablePagination';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ExportExcel from '../FormButtonGroups/ExportExcel';

import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    containerLimitHeight: {
      padding: '0' + theme.spacing(2) + ' ' + theme.spacing(2) + ' ' + theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxHeight: '45vh',
    },
    table: {},
    tableRowHighlight: {
      backgroundColor: lightBlue[50],
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export interface IFormTableExtraProps {
  id: number | string;
}

export interface IFormTableProps<T> {
  label: string;
  data: T[];
  properties: {
    key: keyof T;
    label: string;
  }[];
  isLoading: boolean;
  selectedId?: number | string;
  onSelectRow: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
  render?: (duplicate: boolean, onCancel: () => void) => React.ReactNode;
  pagination?: boolean;
  rowsPerPage?: number;
  limitHeight?: boolean;
  optionalButtons?: boolean;
  duplicateDisabled?: boolean;
  addDisabled?: boolean;
  buttonsDisabled?: boolean;
  searchValue?: string;
  handleSearch?: (e: any) => void;
  excel?: boolean;
}

type Props<T> = IFormTableProps<T>;

type Order = 'asc' | 'desc';

function FormTable<T extends IFormTableExtraProps>(props: React.PropsWithChildren<Props<T>>) {
  const {
    label,
    data,
    properties,
    isLoading,
    selectedId,
    onSelectRow,
    onDelete,
    render,
    pagination,
    rowsPerPage,
    limitHeight,
    optionalButtons,
    duplicateDisabled,
    buttonsDisabled,
    addDisabled,
    searchValue,
    handleSearch,
    excel,
  } = props;

  const classes = useStyles();

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [checked, setChecked] = useState<number | string | undefined>(selectedId);
  const [page, setPage] = useState<number>(0);
  const [excelUsed, setExcelUsed] = useState<boolean>(excel || true);
  const [rowsPerPageState, setRowsPerPageState] = useState<number>(rowsPerPage || 100);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    setChecked(selectedId);
  }, [selectedId]);

  const handleInvokeAddNew: () => void = () => {
    setIsDuplicate(false);
    setIsAddModalOpen(true);
  };

  const handleInvokeDuplicate: () => void = () => {
    setIsDuplicate(true);
    setIsAddModalOpen(true);
  };

  const handleInvokeDelete: () => void = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmAddNew: () => void = () => {
    setIsAddModalOpen(false);
  };

  const handleConfirmDelete: () => void = () => {
    if (selectedId && !!onDelete) {
      onDelete(selectedId);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete: () => void = () => {
    setIsDeleteModalOpen(false);
  };

  const handleChange = (id: number | string) => {
    setChecked(id);
    onSelectRow(id);
  };

  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPageState(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderButtonContainer = () => {
    if (!onDelete) {
      return (
        <AddDuplicateDelete
          disabled={false}
          addDisabled={addDisabled}
          id={checked}
          onAdd={handleInvokeAddNew}
          onDuplicate={!duplicateDisabled ? handleInvokeDuplicate : undefined}
        />
      );
    } else if (!!onDelete && !optionalButtons) {
      return (
        <AddDuplicateDelete
          disabled={false}
          addDisabled={addDisabled}
          id={checked}
          onAdd={handleInvokeAddNew}
          onDuplicate={!duplicateDisabled ? handleInvokeDuplicate : undefined}
          onDelete={handleInvokeDelete}
        />
      );
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: string, orderBy: string): ((a: any, b: any) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <FormFieldSet legend={label || ''}>
        {!!handleSearch && !isLoading && !!data ? (
          <Grid container item spacing={3} direction="row" justify="flex-end">
            <TextField
              id="filter"
              label={t('label-search')}
              variant="outlined"
              value={searchValue}
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {!!excelUsed && !isLoading && !!data ? <ExportExcel disabled={isLoading} data={data} /> : null}
          </Grid>
        ) : null}
        {!!excelUsed && !handleSearch && !isLoading && !!data ? (
          <Grid container item spacing={0} direction="row" justify="flex-end" style={{ paddingBottom: '1rem' }}>
            <ExportExcel disabled={isLoading} data={data} />
          </Grid>
        ) : null}

        <TableContainer className={limitHeight === false ? classes.container : classes.containerLimitHeight}>
          <Table stickyHeader className={classes.table} size="small" aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell key={'id_field'}></TableCell>
                {properties.map((p) => (
                  <TableCell key={String(p.key)}>
                    <TableSortLabel
                      active={orderBy === p.key}
                      direction={orderBy === p.key ? order : 'asc'}
                      onClick={createSortHandler(p.key)}
                    >
                      {p.label}
                      {orderBy === p.key ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                data &&
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPageState, page * rowsPerPageState + rowsPerPageState)
                  .map((row: T) => (
                    <TableRow
                      className={checked === row.id ? classes.tableRowHighlight : ''}
                      key={row.id}
                      onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => handleChange(row.id)}
                    >
                      <TableCell key={'id_field'}>
                        <Radio
                          checked={checked === row.id}
                          color="primary"
                          role="row-item-radio-button"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(row.id)}
                          style={{ color: '#2F518A' }}
                        />
                      </TableCell>
                      {properties.map((p) => (
                        <TableCell data-testid="permission-row" key={String(p.key)}>
                          {row[p.key]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && <LoadingScreen />}
        {!!pagination && !!data && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, data.length]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPageState}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        {!buttonsDisabled ? renderButtonContainer() : null}
        <AddNewContainer open={isAddModalOpen}>{render && render(isDuplicate, handleConfirmAddNew)}</AddNewContainer>
        <DeleteConfirmation open={isDeleteModalOpen} onClose={handleCancelDelete} onConfirm={handleConfirmDelete} />
      </FormFieldSet>
    </>
  );
}

export default FormTable;
