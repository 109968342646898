import React from 'react';

import { Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

type ComponentProps = {
  data: any;
  dataKeys: string[];
  legendLabel: string;
  xAxisLabel?: string;
  fullscreenSize?: boolean;
};

const DPieChart = (props: ComponentProps) => {
  const { data, fullscreenSize } = props;
  const colors = ['#00e676', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#8884d8', '#82ca9d'];
  return (
    <>
      <ResponsiveContainer width="100%" height={!!fullscreenSize ? 594 : '100%'}>
        <PieChart width={400} height={300}>
          <Legend />
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={!!fullscreenSize ? 245 : 95}
            fill="#82ca9d"
            label={true}
            isAnimationActive={false}
          >
            {data.map((entry: any, index: any) => (
              <Cell key={entry.name} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default DPieChart;
