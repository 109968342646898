import React, { useEffect, useState, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TextField from '@mui/material/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { ICustomer, IState } from '../../../../types/types';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import {
  FETCH_CUSTOMERS,
  getCustomersApiRequest,
  selectCustomerActionCreator,
} from '../../../../redux/actions/customer';
import { isActionPending, getAllCustomers } from '../../../../redux/reducers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearApiMessageList } from '../../../../redux/actions/apiMessage';
import { useTranslation } from 'react-i18next';
import {
  listStyles,
  listItemTextStyles,
  listItemButtonStyles,
  listItemIconStyles,
  titleStyles,
  buttonGridStyles,
  serviceProviderGridStyles,
} from './CustomersDialogStyles';
import CustomCustomerIcon from './CustomCustomerIcon';
import MDButton from '../../../Common/ReusableComponents/MDButton';
import CustomerDialogNoResults from './CustomerDialogNoResults';
import LoadingScreen from '../../../Common/LoadingScreen';

const compare = (a: any, b: any) => {
  if (a.customer_name < b.customer_name) {
    return -1;
  }
  if (a.customer_name > b.customer_name) {
    return 1;
  }
  return 0;
};

interface IComponentProps {
  initialOpen: boolean;
}

interface ServiceProvider {
  id: number;
  name: string;
  serviceProviderId: string;
}

const CustomersDialog = (props: IComponentProps) => {
  // Variables
  const { initialOpen } = props;
  const serviceProviders = [
    { id: 1, name: 'MOST Digital OY', serviceProviderId: 'e96062f1-c6dd-4085-9884-780e29d94f27' },
  ]; // Hard coded for now since only one service provider exists

  // Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(initialOpen);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<ServiceProvider>();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [searchParameters, setSearchParameters] = useState<string>('');
  const [currentlyDisplayedCustomers, setCurrentlyDisplayedCustomers] = useState<ICustomer[]>();

  // Redux
  const setCustomerId: (customerId: string) => void = (customerId) => dispatch(selectCustomerActionCreator(customerId));

  // API
  const customers = useSelector<IState, ICustomer[]>(getAllCustomers, shallowEqual).sort(compare);
  const customersFetching = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, FETCH_CUSTOMERS),
    shallowEqual,
  );

  // Other
  useEffect(() => {
    setSelectedServiceProvider(serviceProviders[0]);
    if (!customers || customers.length === 0) {
      dispatch(getCustomersApiRequest());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentlyDisplayedCustomers(customers);
  }, [customers]);

  useEffect(() => {
    if (searchParameters) {
      const filtered = customers.filter((customer: ICustomer) => {
        const nameLower = customer.customer_name.toLowerCase();
        const searchParamsLower = searchParameters.toLowerCase();
        return nameLower.includes(searchParamsLower);
      });
      setCurrentlyDisplayedCustomers(filtered);
    } else {
      setCurrentlyDisplayedCustomers(customers);
    }
  }, [searchParameters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleClose = (customerId: string) => {
    setCustomerId(customerId);
    dispatch(clearApiMessageList());
    setOpen(false);
  };

  const handleSelect = (customerId: string) => {
    if (customerId !== selectedCustomerId) {
      setSelectedCustomerId(customerId);
    } else {
      setSelectedCustomerId('');
    }
  };

  if (!selectedServiceProvider) {
    return <LoadingScreen />;
  }

  return (
    <Fragment>
      <Tooltip title={t('label-switch-customers')}>
        <IconButton onClick={handleOpenClose}>
          <SwapHorizontalCircleIcon style={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle sx={titleStyles}>{t('label-select-customer')}</DialogTitle>
        <DialogContent>
          <Grid sx={serviceProviderGridStyles}>
            <FormControl sx={{ m: 2, minWidth: 300 }}>
              <InputLabel disabled id="serviceprovider">
                {t('serviceprovider')}
              </InputLabel>
              <Select
                disabled
                labelId="serviceprovider"
                id="serviceprovider-select"
                value={selectedServiceProvider.name}
                label={t('serviceprovider')}
              >
                {serviceProviders.map((provider: ServiceProvider) => {
                  return (
                    <MenuItem value={provider.name} key={provider.id}>
                      {provider.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Divider />
          <Grid sx={{ margin: '16px', width: '330px' }}>
            {!currentlyDisplayedCustomers || customersFetching ? (
              <LoadingScreen />
            ) : (
              <List sx={listStyles}>
                {currentlyDisplayedCustomers.length === 0 ? (
                  <CustomerDialogNoResults />
                ) : (
                  currentlyDisplayedCustomers.map((customer: ICustomer) => (
                    <ListItemButton
                      sx={listItemButtonStyles}
                      key={customer.customer_guid}
                      divider={true}
                      selected={customer.customer_guid === selectedCustomerId}
                    >
                      <ListItemIcon sx={listItemIconStyles}>
                        {!!customer.customer_logo_url ? (
                          <CustomCustomerIcon logo={customer.customer_logo_url} />
                        ) : (
                          <HelpOutlineIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        sx={listItemTextStyles}
                        primary={customer.customer_name}
                        onClick={() => handleSelect(customer.customer_guid)}
                      />
                    </ListItemButton>
                  ))
                )}
              </List>
            )}
          </Grid>
          <Grid sx={{ margin: '16px' }}>
            <TextField
              sx={{ width: '100%' }}
              id="customer-search"
              label={t('label-search')}
              type="search"
              variant="outlined"
              value={searchParameters}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setSearchParameters(event.target.value)
              }
            />
          </Grid>
          <Divider />
        </DialogContent>
        <Grid sx={buttonGridStyles}>
          <MDButton disabled={!selectedCustomerId} handleClick={() => handleClose(selectedCustomerId)} label="OK" />
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default CustomersDialog;
