import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AlertWindow from '../../../../Common/ReusableComponents/AlertWindow';
import { startOfWeek, endOfWeek } from '../../../../Common/ReusableUtilityFunctions/DateUtils';
import moment from 'moment';
import { generateConsumptionWindowsData } from './ConsumptionUtilities';
import {
  ConsumptionResourcesTable,
  ConsumptionWindows,
  SearchHeader,
  TableTypeSwitcher,
} from './ConsumptionComponents';
import { useAppDispatch } from '../../../../Hooks/StoreHooks';
import { fetchConsumptions, consumptionStatus, consumptionSelectors } from '../../../../../redux/reducers/consumption';
import { useSelector } from 'react-redux';

const Consumption = ({ customerId }: { customerId: string }) => {
  /** State hooks */
  const [startDate, setStartDate] = useState<Date>(startOfWeek());
  const [endDate, setEndDate] = useState<Date>(endOfWeek());
  const [apiStartDate, setApiStartDate] = useState<string>(startDate.toISOString());
  const [apiEndDate, setApiEndDate] = useState<string>(endDate.toISOString());
  const [useCollapse, setUseCollapse] = useState<boolean>(true);
  const [totalCost, setTotalCost] = useState<number>();
  const [consumptionWindowsData, setConsumptionWindowsData] = useState<any[] | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const [consumptionData, setConsumptionData] = useState<any>();
  const [isLoadingConsumptionData, setIsLoadingConsumptionData] = useState<boolean>(false);
  const [isErrorConsumptionData, setIsErrorConsumptionData] = useState<boolean>(false);
  const isPending = useSelector(consumptionStatus) === 'pending';
  const isFulfilled = useSelector(consumptionStatus) === 'fulfilled';
  const isIdle = useSelector(consumptionStatus) === 'idle';
  const isRejected = useSelector(consumptionStatus) === 'rejected';
  const consumptionStateData = useSelector((state) => consumptionSelectors.selectAll(state));
  const dispatch = useAppDispatch();

  /** API */

  const getAndSetConsumptionData = () => {
    const input = [customerId, apiStartDate, apiEndDate];
    dispatch(fetchConsumptions(input)).then(() => {
      setConsumptionData(consumptionStateData);
    });
  };

  useEffect(() => {
    if (isPending || isIdle) {
      setIsLoadingConsumptionData(true);
      setIsErrorConsumptionData(false);
    } else if (isRejected) {
      setIsErrorConsumptionData(true);
    } else if (isFulfilled) {
      setIsErrorConsumptionData(false);
      setIsLoadingConsumptionData(false);
      setConsumptionData(consumptionStateData);
    }
  }, [isPending, isFulfilled, isIdle, isRejected]);

  useEffect(() => {
    getAndSetConsumptionData();
  }, []);

  useEffect(() => {
    getAndSetConsumptionData();
  }, [apiStartDate, apiEndDate]);

  /** Refresh the consumptionsWindowsData and the total cost everytime a new query is initiated. */
  useEffect(() => {
    if (!!consumptionData) {
      const { total, data } = generateConsumptionWindowsData(consumptionData);
      setTotalCost(total);
      setConsumptionWindowsData(data);
    }
  }, [consumptionData]);

  /** Trigger the AlertWindow when isErrorConsumptionData turns 'true' */
  useEffect(() => {
    if (isErrorConsumptionData) {
      setShowError(true);
    }
  }, [isErrorConsumptionData]);

  /** Getters & setters initiated by user input */
  const handleSetDates = () => {
    setApiStartDate(startDate.toISOString());
    setApiEndDate(endDate.toISOString());
  };

  const handleStartDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    setStartDate(moment(e.target.value).toDate());
  };

  const handleEndDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    setEndDate(moment(e.target.value).endOf('day').toDate());
  };

  const handleSlider = () => {
    setUseCollapse(!useCollapse);
  };

  return (
    <div>
      <AlertWindow message={'Failed to load data'} severity="error" display={showError} setDisplay={setShowError} />
      <Grid container spacing={2} justify="flex-start" style={{ margin: 0, width: '100%' }}>
        <SearchHeader
          loading={isLoadingConsumptionData}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handleSetDates={handleSetDates}
        />
        <ConsumptionWindows
          data-testid="cons-wind"
          loading={isLoadingConsumptionData}
          error={isErrorConsumptionData}
          data={consumptionWindowsData}
          totalCost={totalCost}
        />
        <TableTypeSwitcher
          data-testid="tabletype"
          loading={isLoadingConsumptionData}
          useCollapseTable={useCollapse}
          onClick={handleSlider}
        />
        <ConsumptionResourcesTable
          loading={isLoadingConsumptionData}
          error={isErrorConsumptionData}
          data={consumptionData}
          useCollapseTable={useCollapse}
        />
      </Grid>
    </div>
  );
};

export default Consumption;
