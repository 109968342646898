import React from 'react';
import SplitFormFrame from '../../../../FormFrames/SplitFormFrame';
import { FormTextField } from '../../../../FormFields';
import MDButton from '../../../../Common/ReusableComponents/MDButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  useStyles,
  validationSchema,
  formatConsumptionDataByResourceType,
  parseDataForSingleResource,
} from './ConsumptionUtilities';
import {
  ISearchHeaderProps,
  IConsumptionWindowsProps,
  ITableTypeSwitcherProps,
  IConsumptionResourcesTableProps,
} from './ConsumptionProps';
import { IConsumption } from './ConsumptionTypes';
import InfoBox from '../../../../Common/ReusableComponents/InfoBox/InfoBox';
import ChartWrapper from '../../../../Common/ReusableComponents/ChartWrapper/ChartWrapper';

/** MUI */
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TocIcon from '@mui/icons-material/Toc';

/** Charts */
import CumulativeConsumptionChart from '../../../../Charts/CumulativeConsumptionChart';
import CostWindowChart from '../../../../Charts/CostWindowChart';
import DailyConsumptionChart from '../../../../Charts/DailyConsumptionChart';

/** Tabs */
import DataTableConsumption from '../../../../Charts/CustomTabs/DataTableConsumption';

export const SearchHeader = (props: ISearchHeaderProps) => {
  const { loading, startDate, endDate, handleStartDateChange, handleEndDateChange, handleSetDates } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            id="startDate"
            label={t('label-begin-date')}
            type="date"
            value={moment(startDate).format('YYYY-MM-DD')}
            onChange={handleStartDateChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="endDate"
            label={t('label-end-date')}
            type="date"
            value={moment(endDate).format('YYYY-MM-DD')}
            onChange={handleEndDateChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <MDButton data-testid="showbtn" label={t('label-show')} handleClick={handleSetDates} disabled={loading} />
          <InfoBox
            variant="outlined"
            severity="info"
            text={t('label-consumption-delay-information')}
            additionalText={t('consumption-hover-content')}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export const ConsumptionWindows = (props: IConsumptionWindowsProps) => {
  const { loading, error, data, totalCost } = props;
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction="row" justify="center" alignItems="center">
        <Grid item xs={12} sm={6} lg={4}>
          <ChartWrapper
            title={t('label-daily-costs')}
            chart={<DailyConsumptionChart data={data} isLoading={loading} />}
            state={{ isLoading: loading, isError: error }}
            excelData={data}
            additionalTabs={[
              {
                icon: <TocIcon />,
                content: (
                  <DataTableConsumption
                    data={data}
                    columnTranslations={['label-date', 'label-cost', 'label-cumulative-costs']}
                    dataKeys={['date', 'cost', 'cumulative']}
                  />
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ChartWrapper
            title={t('label-cumulative-costs')}
            chart={<CumulativeConsumptionChart data={data} isLoading={loading} />}
            state={{ isLoading: loading, isError: error }}
            excelData={data}
            additionalTabs={[
              {
                icon: <TocIcon />,
                content: (
                  <DataTableConsumption
                    data={data}
                    columnTranslations={['label-date', 'label-cost', 'label-cumulative-costs']}
                    dataKeys={['date', 'cost', 'cumulative']}
                  />
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <ChartWrapper
            data-testid="totalcost-chart"
            title={t('label-total-cost')}
            chart={<CostWindowChart totalCost={totalCost} />}
            state={{ isLoading: loading, isError: error }}
            disableFullscreen={true}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TableTypeSwitcher = (props: ITableTypeSwitcherProps) => {
  const { loading, useCollapseTable, onClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Switch
            classes={{
              checked: classes.enabled,
              disabled: classes.disabled,
            }}
            checked={useCollapseTable}
            onChange={onClick}
            disabled={loading}
          />
        }
        label={t('label-tableswitch')}
        data-testid="tabletype-inner"
      />
    </Grid>
  );
};

export const ConsumptionResourcesTable = (props: IConsumptionResourcesTableProps) => {
  const { loading, error, data, useCollapseTable } = props;
  const { t } = useTranslation();

  const getDataItem: (id: any) => IConsumption | undefined = (id: any) => {
    return data.find((d: any) => d.resource_id === id);
  };

  return (
    <Grid item xs={12}>
      <SplitFormFrame
        collapse={useCollapseTable}
        collapseData={formatConsumptionDataByResourceType(data)}
        tableLabel={t('label-resources')}
        formLabel={t('label-resource')}
        validationSchema={validationSchema}
        data={data}
        idProperty="resource_name"
        properties={[
          { key: 'resource_name', label: t('label-resource-name') },
          { key: 'resource_type', label: t('label-resource-type') },
          { key: 'resource_id', label: t('label-resource-id') },
          { key: 'consumption', label: t('label-consumption') },
          { key: 'unit', label: t('label-unit') },
          { key: 'cost', label: t('label-cost') },
        ]}
        isLoading={loading}
        isUpdating={false}
        fetchDataItem={getDataItem}
        onSubmit={() => null}
        duplicateDisabled={true}
        buttonsDisabled={true}
        editRendered={false}
        excel={false}
        render={({ values }) => {
          if (!!data) {
            return (
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormTextField name="resource_id" label={'Id'} readonly={true} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField name="resource_name" label={t('label-name')} readonly={true} />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <ChartWrapper
                      title={t('label-daily-costs')}
                      chart={<DailyConsumptionChart data={parseDataForSingleResource(values)} isLoading={loading} />}
                      state={{ isLoading: loading, isError: error }}
                      excelData={parseDataForSingleResource(values)}
                      additionalTabs={[
                        {
                          icon: <TocIcon />,
                          content: (
                            <DataTableConsumption
                              data={parseDataForSingleResource(values)}
                              columnTranslations={['label-date', 'label-cost', 'label-cumulative-costs']}
                              dataKeys={['date', 'cost', 'cumulative']}
                            />
                          ),
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ChartWrapper
                      title={t('label-cumulative-costs')}
                      chart={
                        <CumulativeConsumptionChart data={parseDataForSingleResource(values)} isLoading={loading} />
                      }
                      state={{ isLoading: loading, isError: error }}
                      excelData={parseDataForSingleResource(values)}
                      additionalTabs={[
                        {
                          icon: <TocIcon />,
                          content: (
                            <DataTableConsumption
                              data={parseDataForSingleResource(values)}
                              columnTranslations={['label-date', 'label-cost', 'label-cumulative-costs']}
                              dataKeys={['date', 'cost', 'cumulative']}
                            />
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        }}
      ></SplitFormFrame>
    </Grid>
  );
};
