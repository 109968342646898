import { useMutation } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

/**
 * A mutation hook for removing a permission from a role.
 * @returns useMutation hook
 */
const DeletePermissionFromRole = () => {
  return useMutation(async (payload: any) => {
    const bearerToken = await acquireSilentToken();
    return await axios.delete(apimConf.apiManagementEndpoint + '/permissions/remove-permission-from-role', {
      data: {
        role_id: payload.role_id,
        permission_id: payload.permission_id,
        serviceprovider_id: payload.serviceprovider_id,
      },
      headers: {
        'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
        Authorization: `Bearer ${bearerToken}`,
      },
    });
  });
};

export default DeletePermissionFromRole;
