/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  SvgIcon,
  SvgIconProps,
  Tooltip,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IActiveEvent } from './Timeline';
import { EventType, ITimelineEvent, ITimelineResource } from '../../../../types/types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 100,
      backgroundColor: '#d1ebfc',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M14 6.3V16H4v-3H0V0h6.7l3 3h1zM4 3h4.3l-2-2H1v11h3zm9 4h-3V4H5v11h8zm-2-1h1.3L11 4.7z" />
    </SvgIcon>
  );
};

const makeBreadcrumbs: (
  resources: ITimelineResource[],
  events: ITimelineEvent[],
  activeEvent?: IActiveEvent,
) => IActiveEvent[] = (resources, events, activeEvent) => {
  if (!activeEvent) {
    return [];
  }

  if (activeEvent.type === EventType.Event) {
    const item = events.find((e) => e.id === activeEvent.id);
    if (!item) {
      return [];
    }

    const items = makeBreadcrumbs(resources, events, { id: item.resourceId, name: '', type: EventType.Task });

    return items.concat({ id: item.id, name: item.id, type: EventType.Event });
  }

  if (activeEvent.type === EventType.Task) {
    let item = resources.find((e) => e.id === activeEvent.id);
    if (!item) {
      resources.forEach((r: any) => {
        if (!item && r.children) {
          item = r.children.find((c: any) => c.id === activeEvent.id);
        }
      });
      if (!item) {
        return [];
      }
    }
    const items = makeBreadcrumbs(resources, events, { id: item.parentId || '', name: item.name, type: EventType.VM });

    return items.concat({ id: item.id, name: item.name, type: EventType.Task });
  }

  if (activeEvent.type === EventType.VM) {
    const item = resources.find((e) => e.id === activeEvent.id);
    if (!item) {
      return [];
    }

    return [{ id: item.id, name: item.name, type: EventType.VM }];
  }

  return [];
};

type ComponentProps = {
  resources: ITimelineResource[];
  events: ITimelineEvent[];
  activeEvent?: IActiveEvent;
  setActiveEvent: (event: IActiveEvent) => void;
  startDate: Date;
  setStartDate: (startDate: Date) => void;
  endDate: Date;
  setEndDate: (endDate: Date) => void;
  getCrumbs: any;
  selectVm: (vm: string) => void;
  showDateSelection: boolean;
};

const SelectorPane = (props: ComponentProps) => {
  const {
    resources,
    events,
    activeEvent,
    setActiveEvent,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getCrumbs,
    selectVm,
    showDateSelection,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const breadcrumbs = makeBreadcrumbs(resources, events, activeEvent);

  const handleStartDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    setStartDate(moment(e.target.value).toDate());
  };

  const handleEndDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    setEndDate(moment(e.target.value).endOf('day').toDate());
  };

  const [copySuccess, setCopySuccess] = useState<string>('');

  const copyToClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const handleCopy: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: IActiveEvent) => void = (e, item) => {
    copyToClipBoard(item.id);
  };

  useEffect(() => {
    getCrumbs(breadcrumbs);
    if (breadcrumbs) {
      breadcrumbs.forEach((crumb) => {
        if (crumb.type === 0) {
          selectVm(crumb.name);
        }
      });
    }
  }, [activeEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className={classes.root}>
      <CardContent>
        <Breadcrumbs aria-label="breadcrumb">
          {breadcrumbs.map((item, index, array) => (
            <span key={item.id}>
              <Chip
                label={item.name}
                onClick={() => (index !== array.length - 1 ? setActiveEvent(item) : null)}
                style={{ background: '#7eccff', color: '#000000' }}
              />
              {index === array.length - 1 && (
                <Tooltip title={t('label_copy')}>
                  <IconButton size="small" onClick={(e) => handleCopy(e, item)}>
                    <CopyIcon viewBox="-5 -4 24 24" />
                  </IconButton>
                </Tooltip>
              )}
            </span>
          ))}
        </Breadcrumbs>
        {showDateSelection ? (
          <>
            <TextField
              id="startDate"
              label={t('label-begin-date')}
              type="date"
              value={moment(startDate).format('YYYY-MM-DD')}
              onChange={handleStartDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="endDate"
              label={t('label-end-date')}
              type="date"
              value={moment(endDate).format('YYYY-MM-DD')}
              onChange={handleEndDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default SelectorPane;
