// General
import React, { useState } from 'react';

// MUI
import Dialog from '@mui/material/Dialog';

// ChartWrapper helpers
import '../../ChartWrapperStyles.css';
import { IChartBoxFullscreenProps } from '../../ChartWrapperTypes';
import ChartBoxFullscreenHeader from './ChartBoxFullscreenHeader';
import ChartBoxFullscreenContent from './ChartBoxFullscreenContent';

/**
 * The root component of ChartWrapper's fullscreen view. Displayed to the user if 'isOpen' is true.
 *
 * Handles the tab change event so that the header & content componed are in agreement about what to display.
 */
const ChartFullscreen = (props: IChartBoxFullscreenProps) => {
  const { title, tabs, excelData, isOpen, onClose } = props;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const changeTab = (id: number) => {
    setSelectedTab(id);
  };

  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={isOpen} onClose={onClose} aria-labelledby="max-width-dialog-title">
      <ChartBoxFullscreenHeader
        title={title}
        tabs={tabs}
        excelData={excelData}
        changeTab={changeTab}
        onClose={onClose}
      />
      <ChartBoxFullscreenContent tabs={tabs} selectedTab={selectedTab} />
    </Dialog>
  );
};

export default ChartFullscreen;
