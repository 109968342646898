import { useQuery } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

const ListOnsiteVms = (customerGuid: string) => {
  return useQuery(
    ['listOnsiteVms', customerGuid],
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(apimConf.apiManagementEndpoint + '/resources/list-onsite-vms', {
        headers: {
          'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          customer_guid: customerGuid,
        },
      });
      return data;
    },
    {
      enabled: !!customerGuid,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
};

export default ListOnsiteVms;
