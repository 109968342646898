import React from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormSelect, FormTextField, FormTextArea } from '../../FormFields';
import { useCollection } from '../../Hooks/Collections';
import { useTranslation } from 'react-i18next';
interface IFormObject {
  phase_guid: string;
  phase_name: string;
  phase_description: string;
  phase_status: number;
}

const validationSchema = yup.object({
  phase_guid: yup.string(),
  phase_name: yup.string().required(),
  phase_description: yup.string().required(),
  phase_status: yup.number().required(),
});

const ProcessInfo = () => {
  // TODO: fetch data from api or redux state

  const { t } = useTranslation();

  const data: IFormObject[] = [
    {
      phase_guid: '1',
      phase_name: 'phase1',
      phase_description: 'description1',
      phase_status: 0,
    },
    {
      phase_guid: '2',
      phase_name: 'phase2',
      phase_description: 'description2',
      phase_status: 0,
    },
    {
      phase_guid: '3',
      phase_name: 'phase3',
      phase_description: 'description3',
      phase_status: 1,
    },
  ];

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    return data.find((d) => d.phase_guid === id);
  };

  const handleSubmit: (data: IFormObject) => void = (data) => {};

  const statusValues = useCollection(7);

  return (
    <>
      <SplitFormFrame<IFormObject>
        tableLabel={t('label-process-info')}
        formLabel={t('label-phase-information')}
        validationSchema={validationSchema}
        data={data}
        idProperty="phase_guid"
        properties={[
          { key: 'phase_name', label: t('label-phase-name') },
          { key: 'phase_status', label: t('label-status') },
        ]}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="phase_name" label={t('label-phase-name')} readonly={!isEditing} />
              <FormTextArea name="phase_description" label={t('label-phase-description')} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="phase_status" label={t('label-status')} readonly={true}>
                {statusValues.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
            </Grid>
          </Grid>
        )}
      ></SplitFormFrame>
    </>
  );
};

export default ProcessInfo;
