import React, { useState, useEffect } from 'react';
import SingleDatawindow from './WindowComponents/SingleDatawindow';

type ComponentProps = {
  t: any;
  data: any;
  loading: boolean;
  eventStatus: number | undefined;
};

const LoopWindow = (props: ComponentProps) => {
  const { t, data, loading, eventStatus } = props;
  const [selectedDataSet, setSelectedDataSet] = useState<string>('count');
  const [selectedLoop, setSelectedLoop] = useState<string>('');
  const [selectedStampType, setSelectedStampType] = useState<string>('double');
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [showOptions, setShowOptions] = useState<boolean>(true);
  const [onlySingles, setOnlySingles] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && !!data) {
      checkShowOptions();
    }
  }, [selectedDataSet, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var loopOptions = getLoopOptions();
    var keys = Object.keys(loopOptions);
    if (!!firstRender && keys.length > 0) {
      setSelectedLoop(loopOptions[keys[0]]);
      setFirstRender(false);
    }
  }, [selectedDataSet, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStampTypeOptions = () => {
    let pickOptions: object = { 'label-stamp-all': 'all' };
    if (!!data) {
      data.forEach((stamp: any) => {
        if (!!stamp.hasOwnProperty('loopInformation')) {
          if (!('label-stamp-double' in pickOptions)) {
            Object.assign(pickOptions, { 'label-stamp-double': 'double' });
          }
        } else if (!stamp.hasOwnProperty('loopInformation')) {
          if (!('label-stamp-single' in pickOptions)) {
            Object.assign(pickOptions, { 'label-stamp-single': 'single' });
          }
        }
      });
    }

    return pickOptions;
  };

  const checkShowOptions = () => {
    const doubles: any[] = [];

    if (!!data) {
      data.forEach((stamp: any) => {
        if (!!stamp.hasOwnProperty('loopInformation')) {
          doubles.push(stamp);
        } else if (!stamp.hasOwnProperty('loopInformation')) {
          setShowOptions(true);
        }
      });
      if (doubles.length === 0) {
        setSelectedStampType('single');
        setOnlySingles(true);
      }
    }
  };

  const getLoopOptions = () => {
    const selectOptions: any = {};
    if (!!data) {
      data.forEach((loop: any) => {
        if (!!loop.hasOwnProperty('loopInformation')) {
          selectOptions[loop.name] = loop.name;
        }
      });
    }
    return selectOptions;
  };

  const getOnePartStamps = () => {
    const onePartStamps: any[] = [];
    if (!!data) {
      data.forEach((stamp: any) => {
        if (!stamp.hasOwnProperty('loopInformation')) {
          onePartStamps.push(stamp);
        }
      });
    }
    return onePartStamps;
  };

  const getTwoPartStamps = () => {
    const twoPartStamps: any[] = [];
    if (!!data) {
      data.forEach((stamp: any) => {
        if (!!stamp.hasOwnProperty('loopInformation')) {
          twoPartStamps.push(stamp);
        }
      });
    }
    return twoPartStamps;
  };

  const calculateOk = (selectedLoop: any) => {
    const okArray = [];
    const notOk = [];
    if (!!selectedLoop && !!data) {
      data.forEach((event: any) => {
        if (event.name === selectedLoop) {
          event.loopInformation.forEach((loop: any) => {
            if (loop.loopStatus === 'OK') {
              okArray.push('ok');
            } else if (loop.loopStatus === 'NOK') {
              notOk.push('nok');
            }
          });
        }
      });
    }
    return [
      { name: t('label-run-successful'), value: okArray.length },
      { name: t('label-run-unsuccessful'), value: notOk.length },
    ];
  };
  const calculateDurations = (selectedLoop: any) => {
    const bars: any[] = [];
    if (!!selectedLoop && !!data) {
      data.forEach((event: any) => {
        if (event.name === selectedLoop) {
          event.loopInformation.forEach((loop: any) => {
            if (loop.loopDuration !== 'null') {
              bars.push({
                name: event.name,
                duration: loop.loopDuration,
                timestampStart: loop.timestampStart,
                timestampEnd: loop.timestampEnd,
              });
            }
          });
        }
      });
    }
    return bars;
  };

  const calculateSums = (selectedLoop: string) => {
    const bars: any[] = [];
    if (!!selectedLoop && !!data) {
      data.forEach((event: any) => {
        if (event.name === selectedLoop) {
          bars.push({
            name: event.name,
            averageRuntime: event.averageRuntime,
            medianRuntime: event.medianRuntime,
            runtimeLowest: event.runtimeLowest,
            runtimeHighest: event.runtimeHighest,
          });
        }
      });
    }
    return bars;
  };

  const stampTypeProp = () => {
    if (!!onlySingles) {
      return data;
    } else if (selectedStampType === 'all') {
      return data;
    } else if (selectedStampType === 'single') {
      return getOnePartStamps();
    } else if (selectedStampType === 'double') {
      return getTwoPartStamps();
    }
  };

  const handleSetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDataSet((event.target as HTMLInputElement).value);
  };

  const handleLoopChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLoop((event.target as HTMLInputElement).value);
  };

  const handleSingleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStampType((event.target as HTMLInputElement).value);
  };

  let conditionalGraph;

  const renderCountView = () => {
    if (!!showOptions) {
      conditionalGraph = (
        <SingleDatawindow
          t={t}
          data={stampTypeProp()}
          loading={loading}
          graphType="bar"
          windowTitle={t('window_label_stamps')}
          dataKeys={['totalCount']}
          legendLabel={null}
          xAxisLabel="name"
          selectedStampType={selectedStampType}
          stampTypeOptions={getStampTypeOptions()}
          handleSingleChange={handleSingleChange}
          selectedDataSet={selectedDataSet}
          handleSetChange={handleSetChange}
          dataSetOptions={{
            'label-total-count': 'count',
            'label-sum': 'sum',
            'label-status': 'status',
            'label-runtimes': 'runtimes',
          }}
          eventStatus={eventStatus}
        />
      );
    } else if (!!onlySingles && !showOptions) {
      conditionalGraph = (
        <SingleDatawindow
          t={t}
          data={stampTypeProp()}
          loading={loading}
          graphType="bar"
          windowTitle={t('window_label_stamps')}
          dataKeys={['totalCount']}
          legendLabel={null}
          xAxisLabel="name"
          selectedDataSet={selectedDataSet}
          handleSetChange={handleSetChange}
          eventStatus={eventStatus}
        />
      );
    } else if (!showOptions) {
      conditionalGraph = (
        <SingleDatawindow
          t={t}
          data={stampTypeProp()}
          loading={loading}
          graphType="bar"
          windowTitle={t('window_label_stamps')}
          dataKeys={['totalCount']}
          legendLabel={null}
          xAxisLabel="name"
          selectedDataSet={selectedDataSet}
          handleSetChange={handleSetChange}
          dataSetOptions={{
            'label-total-count': 'count',
            'label-sum': 'sum',
            'label-status': 'status',
            'label-runtimes': 'runtimes',
          }}
          eventStatus={eventStatus}
        />
      );
    }
  };

  if (selectedDataSet === 'count') {
    renderCountView();
  } else if (selectedDataSet === 'sum') {
    conditionalGraph = (
      <SingleDatawindow
        t={t}
        data={calculateSums(selectedLoop)}
        loading={loading}
        graphType="bar"
        xAxisLabel="name"
        windowTitle={t('window_label_stamps')}
        dataKeys={['medianRuntime', 'averageRuntime', 'runtimeHighest', 'runtimeLowest']}
        legendLabel=" min"
        selectedDataSet={selectedDataSet}
        handleSetChange={handleSetChange}
        dataSetOptions={{
          'label-total-count': 'count',
          'label-sum': 'sum',
          'label-status': 'status',
          'label-runtimes': 'runtimes',
        }}
        handleLoopChange={handleLoopChange}
        selectedLoop={selectedLoop}
        loopOptions={getLoopOptions()}
        eventStatus={eventStatus}
      />
    );
  } else if (selectedDataSet === 'status') {
    conditionalGraph = (
      <SingleDatawindow
        t={t}
        data={calculateOk(selectedLoop)}
        loading={loading}
        graphType="pie"
        windowTitle={t('window_label_stamps')}
        dataKeys={['name']}
        legendLabel={null}
        selectedDataSet={selectedDataSet}
        handleSetChange={handleSetChange}
        dataSetOptions={{
          'label-total-count': 'count',
          'label-sum': 'sum',
          'label-status': 'status',
          'label-runtimes': 'runtimes',
        }}
        handleLoopChange={handleLoopChange}
        selectedLoop={selectedLoop}
        loopOptions={getLoopOptions()}
        eventStatus={eventStatus}
      />
    );
  } else if (selectedDataSet === 'runtimes') {
    conditionalGraph = (
      <SingleDatawindow
        t={t}
        data={calculateDurations(selectedLoop)}
        loading={loading}
        graphType="bar"
        windowTitle={t('window_label_stamps')}
        dataKeys={['duration']}
        legendLabel=" min"
        selectedDataSet={selectedDataSet}
        handleSetChange={handleSetChange}
        dataSetOptions={{
          'label-total-count': 'count',
          'label-sum': 'sum',
          'label-status': 'status',
          'label-runtimes': 'runtimes',
        }}
        handleLoopChange={handleLoopChange}
        selectedLoop={selectedLoop}
        loopOptions={getLoopOptions()}
        eventStatus={eventStatus}
      />
    );
  }
  return <div>{conditionalGraph}</div>;
};

export default LoopWindow;
