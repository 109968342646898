import { FunctionComponent } from 'react';
import { Role } from '../../types/types';

import Home from '../Pages';
import Admin from '../Pages/Admin';
import Customers from '../Pages/Customers';
import Dashboard from '../Pages/Dashboard';
import Environments from '../Pages/Environments';
import Tasks from '../Pages/Tasks';
import PlayGround from '../Pages/PlayGround';

export interface IRoute {
  path: string;
  label: string;
  component: FunctionComponent;
  roles: Role[];
}

const routes: IRoute[] = [
  {
    path: '/',
    label: 'menu-main-home',
    component: Home,
    roles: ['all'],
  },
  {
    path: '/dashboard',
    label: 'menu-main-dashboard',
    component: Dashboard,
    roles: [
      'administrators',
      'developers',
      'coordinators',
      'customerusers',
      'dashboarduser',
      'datawriter',
      'datareader',
    ],
  },
  {
    path: '/customers',
    label: 'menu-main-customer',
    component: Customers,
    roles: ['administrators', 'coordinators', 'customerusers', 'datawriter', 'datareader'],
  },
  {
    path: '/environments',
    label: 'menu-main-environment',
    component: Environments,
    roles: ['administrators', 'developers', 'coordinators', 'customerusers', 'datawriter', 'datareader'],
  },
  {
    path: '/tasks',
    label: 'menu-main-task',
    component: Tasks,
    roles: ['administrators', 'developers', 'coordinators', 'customerusers', 'datawriter', 'datareader'],
  },
  {
    path: '/admin',
    label: 'menu-main-admin',
    component: Admin,
    roles: ['all'],
  },
  {
    path: '/playground',
    label: 'menu-main-playground',
    component: PlayGround,
    roles: [],
  },
];

export default routes;
