import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import TabView from '../../Common/TabView';
import { ITabItem } from '../../Common/TabView/TabViewUtils';
import { Collaborations, Connections, Environments as EnvironmentsForm, Networks } from '../../Forms/Environments';
import { getAllCustomers, getSelectedCustomerId } from '../../../redux/reducers';
import { ICustomer, IState } from '../../../types/types';

const Environments = () => {
  const customerId = useSelector<IState, string>(getSelectedCustomerId, shallowEqual);

  const data = useSelector<IState, ICustomer[]>(getAllCustomers, shallowEqual);

  const customer = data.find((d) => d.customer_guid === customerId);

  const tabs: ITabItem[] = customer
    ? [
        {
          displayName: 'menu-sub-environments',
          component: <EnvironmentsForm customer={customer} />,
          roles: ['developers', 'administrators'],
        },
        {
          displayName: 'menu-sub-networks',
          component: <Networks customer={customer} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-connections',
          component: <Connections />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-collaborations',
          component: <Collaborations customer={customer} />,
          roles: ['administrators'],
        },
      ]
    : [];

  return (
    <>
      <TabView tabs={tabs} selectedParentTab="environments" />
    </>
  );
};

export default Environments;
