import { IRole } from './Types';

function getRolesAvailableForAdd(allRoles: IRole[], currentRoles: string[] | undefined) {
  let availableRoles: any[] = [];
  if (!!currentRoles) {
    for (let p of allRoles) {
      if (!currentRoles.includes(p.role_name)) {
        availableRoles.push(p);
      }
    }
  }
  return availableRoles;
}

function getRolesAvailableForDeletion(allRoles: IRole[], currentRoles: string[] | undefined) {
  let availableRoles: any[] = [];
  if (!!currentRoles) {
    for (let p of allRoles) {
      if (currentRoles.includes(p.role_name)) {
        availableRoles.push(p);
      }
    }
  }
  return availableRoles;
}

export function getAvailableRoles(task: string, allRoles: IRole[], RolesForUser: string[] | undefined) {
  if (task === 'delete') {
    return getRolesAvailableForDeletion(allRoles, RolesForUser);
  } else {
    return getRolesAvailableForAdd(allRoles, RolesForUser);
  }
}
