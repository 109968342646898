import { dequeueApiRequest, queueApiRequest } from '../actions/appState';
import { initializeUserActionCreator } from '../actions/user';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { has, filter } from 'ramda';
import { IAppState } from '../../types/types';

const initialState: IAppState = {
  isInitialized: false,
  apiRequestsPending: 0,
  apiRequests: {},
};

const queueApiRequestHandler = (state: IAppState, payload: string) => {
  const newState = { ...state };
  newState.apiRequests[`'${payload}'`] = (state.apiRequests[`'${payload}'`] || 0) + 1;
  newState.apiRequestsPending = state.apiRequestsPending + 1;
  return newState;
};
const dequeueApiRequestHandler = (state: IAppState, payload: string) => {
  const newState = { ...state };
  newState.apiRequests[`'${payload}'`] = (state.apiRequests[`'${payload}'`] || 0) - 1;
  if (newState.apiRequests[`'${payload}'`] <= 0) {
    const { payload, ...newRequests } = newState.apiRequests;
    newState.apiRequests = newRequests;
  }
  newState.apiRequestsPending = state.apiRequestsPending - 1;
  return newState;
};

const appStateReducer = reducerWithInitialState<IAppState>(initialState)
  .case(queueApiRequest, queueApiRequestHandler)
  .case(dequeueApiRequest, dequeueApiRequestHandler)
  .case(initializeUserActionCreator, (state) => ({ ...state, isInitialized: true }));

export const isActionPending: (state: IAppState, type: string) => boolean = (state, type) => {
  return has(`'${type}'`, state.apiRequests) && state.apiRequests[`'${type}'`] > 0;
};

export const areActionsPending: (state: IAppState, types: string[]) => boolean = (state, types) => {
  return filter((type) => isActionPending(state, type), types).length > 0;
};

export const getIsAppInitialized: (state: IAppState) => boolean = (state) => {
  return state.isInitialized;
};

export default appStateReducer;
