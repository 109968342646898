import { IPermission } from '../../../../types/types';

function getPermissionsAvailableForAdd(allPermissions: IPermission[], currentPermissions: string[] | undefined) {
  let availablePermissions: any[] = [];
  if (!!currentPermissions) {
    for (let p of allPermissions) {
      if (!currentPermissions.includes(p.name)) {
        availablePermissions.push(p);
      }
    }
  }
  return availablePermissions;
}

function getPermissionsAvailableForDeletion(allPermissions: IPermission[], currentPermissions: string[] | undefined) {
  let availablePermissions: any[] = [];
  if (!!currentPermissions) {
    for (let p of allPermissions) {
      if (currentPermissions.includes(p.name)) {
        availablePermissions.push(p);
      }
    }
  }
  return availablePermissions;
}

export function getAvailablePermissions(
  task: string,
  allPermissions: IPermission[],
  permissionsForRole: string[] | undefined,
) {
  if (task === 'delete') {
    return getPermissionsAvailableForDeletion(allPermissions, permissionsForRole);
  } else {
    return getPermissionsAvailableForAdd(allPermissions, permissionsForRole);
  }
}
