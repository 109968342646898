import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroupContainer from './ButtonGroupContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      background: '#2F518A',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
      '&:hover': {
        backgroundColor: '#4c75ba',
        color: '#FFF',
      },
    },

    buttonCancel: {
      marginLeft: theme.spacing(2),
      background: '#FFFFFF',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
    },
  }),
);

interface IProps {
  disabled: boolean;
  onCancel: () => void;
}

type Props = IProps;

const CancelSave: React.FC<Props> = (props) => {
  const { disabled, onCancel } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const handleCancel: () => void = () => {
    onCancel();
  };

  return (
    <>
      <ButtonGroupContainer>
        <Button className={classes.button} variant="contained" color="primary" disabled={disabled} type="submit">
          {t('button_form_save')}
        </Button>
        <Button
          className={classes.buttonCancel}
          variant="outlined"
          color="primary"
          disabled={disabled}
          type="reset"
          onClick={(e) => handleCancel()}
        >
          {t('button_form_cancel')}
        </Button>
      </ButtonGroupContainer>
    </>
  );
};

export default CancelSave;
