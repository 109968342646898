import { shallowEqual, useSelector } from 'react-redux';
import { getRoles } from '../../redux/reducers';
import { Role, IState } from '../../types/types';

export interface ICollectionContent {
  id: string;
  value: string;
}

export const usePermission: (roles: Role[]) => [boolean] = (roles) => {
  const userRoles = useSelector<IState, Role[]>((state: IState) => getRoles(state), shallowEqual);

  const intersect: (a: Role[], b: Role[]) => Role[] = (a, b) => {
    var setB = new Set(b);
    return Array.from(new Set(a)).filter((x) => setB.has(x));
  };

  if (roles.find((r) => r === 'all')) {
    return [true];
  }

  const hasPermission = intersect(roles, userRoles).length > 0;

  return [hasPermission];
};

export const useCheckPermission: () => (roles: Role[]) => boolean = () => {
  const userRoles = useSelector<IState, Role[]>((state: IState) => getRoles(state), shallowEqual);

  const intersect: (a: Role[], b: Role[]) => Role[] = (a, b) => {
    var setB = new Set(b);
    return Array.from(new Set(a)).filter((x) => setB.has(x));
  };

  const checkPermission = (roles: Role[]) => {
    if (roles.find((r) => r === 'all')) {
      return true;
    }

    return intersect(roles, userRoles).length > 0;
  };

  return checkPermission;
};
