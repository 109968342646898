import { createApiAction } from './common';
import { schema as Schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { INormalizedBase, ITask, ITaskCreate, ITaskUpdate } from '../../types/types';
import HttpMethod from '../../services/api/httpMethod';

const actionCreator = actionCreatorFactory();

export const SELECT_TASK = 'SELECT_TASK';

export const selectTaskActionCreator = actionCreator<string>(SELECT_TASK);

/**
 * Define schema for normalizing api response
 * This schema normalizes an array of objects to a dictionary of objects and an array if ids
 * If the property that defines the object identity is not named 'id' the idAttribute needs to be set
 */
const entityName = 'task';
const entity = new Schema.Entity(
  entityName,
  {},
  {
    idAttribute: 'task_guid',
  },
);
const schema = [entity];

/**
 * The type of action for a fetch operation
 */
export const FETCH_TASKS = 'FETCH_TASKS';

/**
 * Define action creators for an async operation
 */
export const fetchTasksActionCreator = actionCreator.async<string, INormalizedBase<ITask, string>>(FETCH_TASKS, {
  normalize: {
    entityName,
    schema,
  },
});

/**
 * Creates an async action that defines an api call to fetch tasks for a customer
 * @param customerId The id of the customer to fetch tasks for
 */
export const getTasksApiRequest = (customerId: string) =>
  createApiAction(HttpMethod.GET, `api/tasks?customerId=${customerId}`, fetchTasksActionCreator, customerId);

/**
 * The type of action for an update operation
 */
export const CREATE_TASK = 'CREATE_TASK';

/**
 * Define action creators for an async operation
 */
export const createTaskActionCreator = actionCreator.async<ITaskCreate, ITask>(CREATE_TASK);

/**
 * Creates an async action that defines an api call
 */
export const createTaskApiRequest = (data: ITaskCreate) =>
  createApiAction(HttpMethod.POST, `api/tasks`, createTaskActionCreator, data);

/**
 * The type of action for an update operation
 */
export const UPDATE_TASK = 'UPDATE_TASK';

/**
 * Define action creators for an async operation
 */
export const updateTaskActionCreator = actionCreator.async<ITaskUpdate, void>(UPDATE_TASK);

/**
 * Creates an async action that defines an api call
 */
export const updateTaskApiRequest = (id: string, data: ITaskUpdate) =>
  createApiAction(HttpMethod.PUT, `api/tasks/${id}`, updateTaskActionCreator, data);
