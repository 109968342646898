import { useMutation } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

/**
 * A mutation hook for deleting a role
 * @returns useMutation hook
 */
const DeleteRole = () => {
  return useMutation(async (payload: any) => {
    const bearerToken = await acquireSilentToken();
    return await axios.delete(apimConf.apiManagementEndpoint + '/users-and-roles/delete-role', {
      headers: {
        'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
        Authorization: `Bearer ${bearerToken}`,
      },
      data: {
        id: payload.id,
      },
    });
  });
};

export default DeleteRole;
