import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { ApiAction } from '../actions/common';
import { API_ACTION } from '../actions/constants';
import apiRequest from '../../services/api/apiService';
import HttpMethod from '../../services/api/httpMethod';

/*
API MIDDLEWARE

Centralized place to send API requests when using redux
*/

type State = any;
type Store = { dispatch: Dispatch; getState: () => State };
type Middleware = (store: Store) => (next: Dispatch) => Dispatch<ApiAction<any, any, any>>;

const apiMiddleware: Middleware = (store) => (next) => (action) => {
  const { dispatch } = store;

  if (action.type === API_ACTION) {
    const { meta } = action;

    dispatch(meta.actions.started(action.type));

    const { method, resource, responseType } = meta;
    const { payload } = action;

    apiRequest({
      method,
      resource,
      payload,
      responseType,
    })
      .then((result) => {
        const payload = result.data ? result.data : result;
        dispatch(meta.actions.done({ params: meta.params || {}, result: payload }));
        if (method !== HttpMethod.GET) {
          toast(`Operation succeeded!`, { type: 'success' });
        }
      })
      .catch((error: any) => {
        if (error.response) {
          dispatch(
            meta.actions.failed({
              params: meta.params || {},
              error: error.response.data,
            }),
          );
          if (error.response.data.status === 422) {
            toast(`Request failed! ${error.response.data.detail}`, { type: 'error' });
          } else if (error.response.data.status === 400) {
            toast(`${error.response.data.errors['400']}`, { type: 'error' });
          } else {
            toast(`Request failed! ${error.response.data}`, { type: 'error' });
          }
        } else if (error.request) {
          dispatch(
            meta.actions.failed({
              params: meta.params || {},
              error: 'request was made, no response',
            }),
          );
        } else {
          dispatch(
            meta.actions.failed({
              params: meta.params || {},
              error: 'unable to make request',
            }),
          );
        }
      });
  }

  return next(action);
};

export default apiMiddleware;
