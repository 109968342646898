import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },
  }),
);

interface IProps {
  label: string;
  value?: string | null;
  TextFieldProps?: TextFieldProps;
}

const FormTextDisplay: React.FC<IProps> = (props) => {
  const { label, value, TextFieldProps } = props;

  const [isShown, setIsShown] = useState<boolean>(false);
  const classes = useStyles();

  if (!!isShown) {
    return (
      <TextField
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className={classes.root}
        {...TextFieldProps}
        label={label}
        defaultValue={value || ''}
        key={value || ''}
        disabled={true}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <HelpIcon style={{ color: '#7eccff' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  } else
    return (
      <TextField
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className={classes.root}
        {...TextFieldProps}
        label={label}
        defaultValue={value || ''}
        key={value || ''}
        disabled={true}
        variant="outlined"
      />
    );
};

export default FormTextDisplay;
