import {
  createCollectionActionCreator,
  createCollectionMemberActionCreator,
  fetchCollectionsActionCreator,
  updateCollectionActionCreator,
  updateCollectionMemberActionCreator,
} from '../actions/collection';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  ICollectionState,
  ICollection,
  ICollectionMember,
  ICollectionCreate,
  ICollectionUpdate,
  IDictionary,
  INormalizedBase,
  ICollectionMemberCreate,
  ICollectionMemberUpdate,
} from '../../types/types';

import { Success } from 'typescript-fsa';
import { combineReducers } from 'redux';

const initialState: ICollectionState = {
  byId: {},
  ids: [],
};

const byIdHandler = (byId: IDictionary<ICollection>, payload: Success<void, INormalizedBase<ICollection, number>>) => {
  if (payload.result.byId) {
    return { ...byId, ...payload.result.byId };
  }
  return byId;
};

const byIdCollectionCreateHandler = (
  byId: IDictionary<ICollection>,
  payload: Success<ICollectionCreate, ICollection>,
) => {
  if (payload.result) {
    return { ...byId, [payload.result.id]: payload.result };
  }
  return byId;
};

const byIdCollectionUpdateHandler = (byId: IDictionary<ICollection>, payload: Success<ICollectionUpdate, void>) => {
  if (payload.params) {
    const current = byId[payload.params.id];
    const updated = current ? { ...current, ...payload.params } : payload.params;
    return { ...byId, [payload.params.id]: updated };
  }
  return byId;
};

const byIdCollectionMemberCreateHandler = (
  byId: IDictionary<ICollection>,
  payload: Success<ICollectionMemberCreate, void>,
) => {
  if (payload.params) {
    const current = byId[payload.params.collectionId];
    const members = current.members.concat({
      id: payload.params.id,
      description: payload.params.description,
      deleted: false,
      values: payload.params.values,
    });
    const updated = { ...current, members };
    return { ...byId, [payload.params.collectionId]: updated };
  }
  return byId;
};

const byIdCollectionMemberUpdateHandler = (
  byId: IDictionary<ICollection>,
  payload: Success<ICollectionMemberUpdate, void>,
) => {
  if (payload.params) {
    const current = byId[payload.params.collectionId];
    const members = current.members.reduce((p: ICollectionMember[], c: ICollectionMember) => {
      if (c.id === payload.params.id) {
        p.push({
          id: payload.params.id,
          description: payload.params.description,
          deleted: false,
          values: payload.params.values,
        });
      } else {
        p.push(c);
      }

      return p;
    }, []);
    const updated = { ...current, members };
    return { ...byId, [payload.params.collectionId]: updated };
  }
  return byId;
};

const byId = reducerWithInitialState<{}>(initialState.byId)
  .cases([fetchCollectionsActionCreator.done], byIdHandler)
  .case(createCollectionActionCreator.done, byIdCollectionCreateHandler)
  .case(updateCollectionActionCreator.done, byIdCollectionUpdateHandler)
  .case(createCollectionMemberActionCreator.done, byIdCollectionMemberCreateHandler)
  .case(updateCollectionMemberActionCreator.done, byIdCollectionMemberUpdateHandler);

const idsHandler = (ids: number[], payload: Success<void, INormalizedBase<ICollection, number>>) => {
  if (payload.result.ids && payload.result.ids.length > 0) {
    return Array.from(new Set(ids.concat(payload.result.ids)));
  }
  return ids;
};

const idsCollectionCreateHandler = (ids: number[], payload: Success<ICollectionCreate, ICollection>) => {
  if (payload.result) {
    return Array.from(new Set(ids.concat(payload.result.id)));
  }
  return ids;
};

const idsCollectionUpdateHandler = (ids: number[], payload: Success<ICollectionUpdate, void>) => {
  return ids;
};

const ids = reducerWithInitialState<number[]>(initialState.ids)
  .cases([fetchCollectionsActionCreator.done], idsHandler)
  .case(createCollectionActionCreator.done, idsCollectionCreateHandler)
  .case(updateCollectionActionCreator.done, idsCollectionUpdateHandler);

export default combineReducers({
  byId,
  ids,
});

export const getAllCollections: (state: ICollectionState) => ICollection[] = (state) => {
  return state.ids.map((id) => state.byId[id]);
};

export const getCollection: (state: ICollectionState, id: number) => ICollection = (state, id) => {
  return state.byId[id];
};

export const getCollectionMembers: (state: ICollectionState, id: number) => ICollectionMember[] = (state, id) => {
  return state.byId[id]?.members || [];
};
