import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Redirect, RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import LoadingScreen from '../Common/LoadingScreen';
import { getUserIsLoggingOut, getUserIsAuthenticated } from '../../redux/reducers';
import { login } from '../../services/auth/authService';
import { IState } from '../../types/types';

const Login = (props: RouteComponentProps) => {
  const [loginInProgress, setLoginInProgress] = useState(false);

  const isAuthenticated = !!useSelector<IState, boolean>(getUserIsAuthenticated, shallowEqual);
  const userIsLoggingOut = useSelector<IState, boolean>(getUserIsLoggingOut, shallowEqual);

  const formSubmitted = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoginInProgress(true);
    login();
  };

  return (
    <>
      {userIsLoggingOut && <LoadingScreen />}

      {isAuthenticated && <Redirect noThrow to={'/'} />}

      {!userIsLoggingOut && !isAuthenticated && (
        <Card>
          <CardContent>
            <Typography variant="h2" gutterBottom>
              Unauthorized
            </Typography>
            <Typography gutterBottom>This example app is for internal use only.</Typography>
            <br />
            <Button variant="contained" color="primary" onClick={formSubmitted} disabled={loginInProgress}>
              {loginInProgress ? 'Login in progress...' : 'AD Login'}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Login;
