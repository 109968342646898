import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { EditCancelSave } from '../FormButtonGroups';
import { FormFieldSet } from '../FormFields';
import CancelConfirmation from './CancelConfirmation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        display: 'block',
        marginBottom: theme.spacing(2),
      },
      '& .MuiInputBase-root': {
        width: '100%',
      },
      '& .MuiFormControlLabel-root': {
        display: 'block',
        textAlign: 'left',
      },
    },
  }),
);

export type SetIsSubmittingFn = Pick<FormikHelpers<any>, 'setSubmitting'>['setSubmitting'];
export type SetStopEditingFn = () => void;

export interface IFormFrameProps<T extends {}> {
  label?: string;
  validationSchema: yup.ObjectSchema<T>;
  initialValues?: T;
  onSubmit: (values: T, setIsSubmitting: SetIsSubmittingFn, setStopEditing: SetStopEditingFn) => Promise<T> | void;
  render?: (props: { values: T; isEditing: boolean }) => React.ReactNode;
  editRendered?: boolean;
  collapse?: boolean;
  dirtySave?: any;
  onDirtySave?: any;
}

type Props<T> = IFormFrameProps<T>;

function FormFrame<T>(props: React.PropsWithChildren<Props<T>>) {
  const { label, validationSchema, initialValues, onSubmit, render, children, editRendered, dirtySave, onDirtySave } =
    props;
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const stopEditing: () => void = () => {
    setIsEditing(false);
  };

  const handleEdit: () => void = () => {
    setIsEditing(true);
  };

  const handleCancel: () => void = () => {
    setIsCancelModalOpen(true);
  };

  const handleReset: (reset: () => void) => () => void = (reset) => {
    return () => {
      reset();
      setIsEditing(false);
      setIsCancelModalOpen(false);
    };
  };

  const keepChanges: () => void = () => {
    setIsCancelModalOpen(false);
  };

  return (
    <>
      {initialValues && (
        <FormFieldSet legend={label || ''}>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              onSubmit(values, actions.setSubmitting, stopEditing);
            }}
            enableReinitialize={true}
          >
            {({ values, isSubmitting, resetForm }) => (
              <>
                <Form className={classes.root}>
                  {render ? render({ values, isEditing: isEditing && !isSubmitting }) : null}
                  <EditCancelSave
                    disabled={isSubmitting}
                    isEditing={isEditing}
                    onEdit={handleEdit}
                    onCancel={handleCancel}
                    editRendered={editRendered}
                    dirtySave={dirtySave}
                    onDirtySave={onDirtySave}
                  />
                  <CancelConfirmation
                    open={isCancelModalOpen}
                    onClose={keepChanges}
                    onConfirm={handleReset(resetForm)}
                  />
                </Form>
              </>
            )}
          </Formik>
          {children}
        </FormFieldSet>
      )}
    </>
  );
}

export default FormFrame;
