import { Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InfoIconPopover from '../../../Common/ReusableComponents/InfoIconPopover';
import React from 'react';
import { useFormikContext } from 'formik';
import { FormCheckbox, FormNumberField, FormTextField } from '../../../FormFields';
import { IScheduleFormObject as IFormObject } from './ScheduleTypes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      '& .MuiTextField-root': {
        display: 'inline-block',
        margin: 0,
        width: '60px',
        verticalAlign: 'baseline',
      },
    },
    wide_text_field: {
      textAlign: 'left',
      '& .MuiTextField-root': {
        display: 'inline-block',
        margin: 0,
        width: '175px',
        verticalAlign: 'baseline',
      },
    },
  }),
);

const ScheduleRecurrence = (props: { isEditing: boolean }) => {
  const { isEditing } = props;
  const classes = useStyles();
  const { values } = useFormikContext<IFormObject>();
  const { t } = useTranslation();

  const recurrenceType = values.recurrenceType;

  return (
    <Grid item xs={12}>
      {recurrenceType === 'recurrence_daily' ? (
        <div className={classes.root}>
          Every <FormNumberField name="frequency" label="" readonly={!isEditing} TextFieldProps={{ size: 'small' }} />{' '}
          day(s)
        </div>
      ) : recurrenceType === 'recurrence_weekly' ? (
        <div className={classes.root}>
          Recur every{' '}
          <FormNumberField name="frequency" label="" readonly={!isEditing} TextFieldProps={{ size: 'small' }} /> week(s)
          on:{' '}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormCheckbox name="runsMonday" label="Monday" readonly={!isEditing} />
              <FormCheckbox name="runsTuesday" label="Tuesday" readonly={!isEditing} />
              <FormCheckbox name="runsWednesday" label="Wednesday" readonly={!isEditing} />
              <FormCheckbox name="runsThursday" label="Thursday" readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormCheckbox name="runsFriday" label="Friday" readonly={!isEditing} />
              <FormCheckbox name="runsSaturday" label="Saturday" readonly={!isEditing} />
              <FormCheckbox name="runsSunday" label="Sunday" readonly={!isEditing} />
            </Grid>
          </Grid>
        </div>
      ) : recurrenceType === 'recurrence_monthly' ? (
        <div className={classes.root}>
          Recur day{' '}
          <FormNumberField name="dayNumber" label="" readonly={!isEditing} TextFieldProps={{ size: 'small' }} /> of
          every <FormNumberField name="frequency" label="" readonly={!isEditing} TextFieldProps={{ size: 'small' }} />{' '}
          month(s)
        </div>
      ) : recurrenceType === 'recurrence_cron' ? (
        <div>
          <Grid container spacing={1}>
            <Grid item>
              <div className={classes.wide_text_field}>
                <FormTextField name="cronString" label="CRON expression" readonly={!isEditing} />
              </div>
            </Grid>
            <Grid item>
              <div style={{ width: '80px' }}>
                <FormNumberField
                  name="duration"
                  label="Duration"
                  readonly={!isEditing}
                  TextFieldProps={{ size: 'medium' }}
                />
              </div>
            </Grid>
            <Grid item>
              <InfoIconPopover
                text={`${t('info-cron-recurrence-popover')}`}
                hrefSettings={{ link: 'https://crontab.guru', newTab: true }}
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </Grid>
  );
};

export default ScheduleRecurrence;
