/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from '../../../FormFrames/FormFrame';
import { useGetApiEffect, usePutApiEffect } from '../../../Hooks/Hooks';
import { ICustomer } from '../../../../types/types';

import { featureGroups } from './Features';
import FeatureGroup from './FeatureGroup';

const functionId = '00000000-0000-0000-0000-000000000000';

interface IProps {
  customer: ICustomer;
}

interface IFormObject {
  [key: string]: string | number | boolean | Date | null | undefined;
}

interface IBillingUpdate {
  id: string;
  customer_id: string;
  data: IFormObject;
}

const validationSchema = yup.object<IFormObject>({});

type Props = IProps;

const Billing: React.FC<Props> = (props) => {
  const { customer } = props;

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  const [data, isLoading, isError, getData] = useGetApiEffect<IFormObject>(
    `api/billing?id=${functionId}&customerId=${customer.customer_guid}`,
    null,
  );

  const [response, isSubmitting, isPostError, postData] = usePutApiEffect<IBillingUpdate, void>(`api/billing`, null);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formData = isError
    ? {}
    : data
    ? Object.keys(data).reduce((p: IFormObject, c: string) => {
        const value = data[c];
        if (c !== 'customer_guid') {
          p = { ...p, [c]: value ?? (c.endsWith('_discount_until') ? '' : undefined) };
        }
        return p;
      }, {})
    : undefined;

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isSubmitting);
    }
    if (isSubmitting === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (
    data: IFormObject,
    setIsSubmitting: SetIsSubmittingFn,
    setStopEditing: SetStopEditingFn,
  ) => void = useCallback((data, setIsSubmitting, setStopEditing) => {
    setIsSubmittingFn(() => setIsSubmitting);
    setStopEditingFn(() => setStopEditing);
    const payload = {
      id: functionId,
      customer_id: customer.customer_guid,
      data: Object.keys(data).reduce((p: IFormObject, c: string) => {
        const dataValue = data[c];
        const value = dataValue === undefined ? null : dataValue === '' ? null : dataValue;
        p = {
          ...p,
          [c]: value,
        };

        return p;
      }, {}),
    };

    postData(payload);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormFrame<IFormObject>
      label="Features"
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      render={({ isEditing }) =>
        featureGroups.map((group) => <FeatureGroup key={group.name} group={group} readonly={!isEditing} />)
      }
    />
  );
};

export default Billing;
