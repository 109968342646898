import React from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';
import TabPanel from './TabPanel';
import { useTranslation } from 'react-i18next';
import { useCheckPermission } from '../../Hooks/Permissions';
import RefreshButton from '../../Other/RefreshButton';
import { IComponentProps, useStyles, filterPermissions, ITabItem, a11yProps } from '../TabView/TabViewUtils';

const TabView = (props: IComponentProps) => {
  const [value, setValue] = React.useState<number>(0);
  const { tabs, selectedParentTab, setRefresh, isRefreshing } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const checkPermission = useCheckPermission();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleOnRefreshClick = () => {
    if (!!setRefresh) {
      setRefresh(true);
    }
  };

  const tabsToShow = filterPermissions(tabs, checkPermission);

  return (
    <div className={classes.root}>
      <Paper>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="tab view">
          {tabsToShow.map((tab: ITabItem, index: number) => (
            <Tab key={tab.displayName} label={t(tab.displayName)} {...a11yProps(index)} />
          ))}
          {value === 0 && selectedParentTab === 'dashboard' ? (
            <RefreshButton onClick={handleOnRefreshClick} isRefreshing={isRefreshing} />
          ) : null}
        </Tabs>
      </Paper>
      {tabsToShow.map((tab: ITabItem, index: number) => (
        <TabPanel key={tab.displayName} value={value} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
};

export default TabView;
