import { FormControl, FormLabel } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldset: {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '3px',
      display: 'block',
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    legend: {
      color: theme.palette.primary.light,
      padding: theme.spacing(0, 2),
    },
  }),
);

interface IProps {
  legend: string;
}

const FormFieldSet: React.FC<IProps> = (props) => {
  const { legend, children } = props;

  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      <FormLabel component="legend" className={classes.legend}>
        {legend}
      </FormLabel>
      {children}
    </FormControl>
  );
};

export default FormFieldSet;
