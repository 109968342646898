import { Dispatch } from 'redux';
import { queueApiRequest, dequeueApiRequest } from '../actions/appState';
import { API_ACTION } from '../actions/constants';

/*
ASYNC MIDDLEWARE

This middleware is place for all common functionality async actions have in the app.
This version takes care of updating application state with list of async actions currently in pending state.
Components can use this slice of state to trigger loading indicators etc.

This way component can get all relevant async actions states in single selector call
so that only one check is required in render. Also, views with multiple async components in which loading
can be triggered separately can use this state.
*/

type State = any;
type Store = { dispatch: Dispatch; getState: () => State };
type Middleware = (store: Store) => (next: Dispatch) => Dispatch;

const asyncMiddleware: Middleware = (store) => (next) => (action) => {
  const { dispatch } = store;
  if (action.type.endsWith('_STARTED') || action.type === API_ACTION) {
    dispatch(queueApiRequest(action.type.replace('_STARTED', '')));
  }
  if (action.type.endsWith('_DONE')) {
    dispatch(dequeueApiRequest(action.type.replace('_DONE', '')));
  }
  if (action.type.endsWith('_FAILED')) {
    dispatch(dequeueApiRequest(action.type.replace('_FAILED', '')));
  }

  return next(action);
};

export default asyncMiddleware;
