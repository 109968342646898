import { useMutation } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

const CreatePermission = () => {
  return useMutation('permission', async (payload: any) => {
    const bearerToken = await acquireSilentToken();
    return await axios.post(apimConf.apiManagementEndpoint + '/permissions/create-permission', payload, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
      },
    });
  });
};

export default CreatePermission;
