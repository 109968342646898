import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Checkbox } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AddDuplicateDelete } from '../FormButtonGroups';
import { FormFieldSet } from '../FormFields';
import LoadingScreen from '../Common/LoadingScreen';
import AddNewContainer from './AddNewContainer';
import DeleteConfirmationMulti from './DeleteConfirmationMulti';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ExportExcel from '../FormButtonGroups/ExportExcel';
import { useTranslation } from 'react-i18next';
import MDButton from '../Common/ReusableComponents/MDButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    containerLimitHeight: {
      padding: theme.spacing(2),
      maxHeight: '45vh',
    },
    table: {},
    tableRowHighlight: {
      backgroundColor: lightBlue[50],
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export interface IFormTableExtraProps {
  id: number | string;
}

export interface IFormTableProps<T> {
  label: string;
  data: T[];
  properties: {
    key: keyof T;
    label: string;
  }[];
  isLoading: boolean;
  selectedId?: number | string;
  selectedItems: any[];
  onSelectAll: () => void;
  onSelectRow: (id: number | string) => void;
  onDelete?: () => void;
  render?: (duplicate: boolean, onCancel: () => void) => React.ReactNode;
  pagination?: boolean;
  rowsPerPage?: number;
  limitHeight?: boolean;
  optionalButtons?: boolean;
  duplicateDisabled?: boolean;
  addDisabled?: boolean;
  buttonsDisabled?: boolean;
  excel?: boolean;
}

type Props<T> = IFormTableProps<T>;

type Order = 'asc' | 'desc';

function FormTable<T extends IFormTableExtraProps>(props: React.PropsWithChildren<Props<T>>) {
  const {
    label,
    data,
    properties,
    isLoading,
    selectedId,
    selectedItems,
    onSelectAll,
    onSelectRow,
    onDelete,
    render,
    pagination,
    rowsPerPage,
    limitHeight,
    optionalButtons,
    duplicateDisabled,
    buttonsDisabled,
    addDisabled,
  } = props;

  const classes = useStyles();

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [checked, setChecked] = useState<number | string | undefined>(selectedId);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPageState, setRowsPerPageState] = useState<number>(rowsPerPage || 100);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    setChecked(selectedId);
  }, [selectedId]);

  useEffect(() => {
    if (selectedItems.length === 0) {
      setChecked(undefined);
    }
  }, [selectedItems]);

  const handleInvokeAddNew: () => void = () => {
    setIsDuplicate(false);
    setIsAddModalOpen(true);
  };

  const handleInvokeDuplicate: () => void = () => {
    setIsDuplicate(true);
    setIsAddModalOpen(true);
  };

  const handleInvokeDelete: () => void = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmAddNew: () => void = () => {
    setIsAddModalOpen(false);
  };

  const handleConfirmDelete: () => void = () => {
    if (!!onDelete) {
      onDelete();
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete: () => void = () => {
    setIsDeleteModalOpen(false);
  };

  const handleChange = (id: number | string) => {
    setChecked(id);
    onSelectRow(id);
  };

  const handleSelectAll = () => {
    onSelectAll();
    setChecked(-1); // Just to trigger the delete button visibility
  };

  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPageState(parseInt(event.target.value, 10));
    setPage(0);
  };

  const idExistsInSelectedRows = (id: string | number) => {
    const existing = selectedItems.filter((item) => item.id === id);
    if (existing.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  let emptyRows = 0;

  if (!!data && !!pagination) {
    emptyRows = rowsPerPageState - Math.min(rowsPerPageState, data.length - page * rowsPerPageState);
  }

  const renderButtonContainer = () => {
    if (!onDelete) {
      return (
        <AddDuplicateDelete
          disabled={false}
          addDisabled={addDisabled}
          id={checked}
          onAdd={handleInvokeAddNew}
          onDuplicate={!duplicateDisabled ? handleInvokeDuplicate : undefined}
        />
      );
    } else if (!!onDelete && !optionalButtons) {
      return (
        <AddDuplicateDelete
          disabled={false}
          addDisabled={addDisabled}
          id={checked}
          onAdd={handleInvokeAddNew}
          onDuplicate={!duplicateDisabled ? handleInvokeDuplicate : undefined}
          onDelete={handleInvokeDelete}
        />
      );
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order: string, orderBy: string): ((a: any, b: any) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <FormFieldSet legend={label || ''}>
        <Grid container item spacing={0} direction="row" justify="space-between" style={{ paddingBottom: '1rem' }}>
          <MDButton handleClick={handleSelectAll} label={t('label-select-all')} />
          <ExportExcel disabled={isLoading} data={data} />
        </Grid>
        <TableContainer className={limitHeight === false ? classes.container : classes.containerLimitHeight}>
          <Table stickyHeader className={classes.table} size="small" aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell key={'id_field'}></TableCell>
                {properties.map((p) => (
                  <TableCell key={String(p.key)}>
                    <TableSortLabel
                      active={orderBy === p.key}
                      direction={orderBy === p.key ? order : 'asc'}
                      onClick={createSortHandler(p.key)}
                    >
                      {p.label}
                      {orderBy === p.key ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                data &&
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPageState, page * rowsPerPageState + rowsPerPageState)
                  .map((row: T) => (
                    <TableRow className={idExistsInSelectedRows(row.id) ? classes.tableRowHighlight : ''} key={row.id}>
                      <TableCell key={'id_field'}>
                        <Checkbox
                          checked={idExistsInSelectedRows(row.id)}
                          color="primary"
                          role="row-item-checkbox"
                          onChange={() => handleChange(row.id)}
                          style={{ color: '#2F518A' }}
                        />
                      </TableCell>
                      {properties.map((p) => (
                        <TableCell data-testid="permission-row" key={String(p.key)}>
                          {row[p.key]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              {!!data && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && <LoadingScreen />}
        {!!pagination && !!data && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, data.length]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPageState}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        {!buttonsDisabled ? renderButtonContainer() : null}
        <AddNewContainer open={isAddModalOpen}>{render && render(isDuplicate, handleConfirmAddNew)}</AddNewContainer>
        <DeleteConfirmationMulti
          open={isDeleteModalOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />
      </FormFieldSet>
    </>
  );
}

export default FormTable;
