import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormTextField, FormTwinMultiSelect, FormSelect } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { IRole, IUserCreate, IInternalUser } from './Types';
import { useFormikContext } from 'formik';

interface IFormObject {
  user_id: string;
  role_id: number;
}

const validationSchema = yup.object<IFormObject>({
  user_id: yup.string().required(),
  role_id: yup.number(),
});

interface IProps {
  customerId: string;
  roles: IRole[];
  isLoading: boolean;
  users: IInternalUser[];
  onCancel: () => void;
}
type Props = IProps;

const InternalUser: React.FC<Props> = (props) => {
  const { roles, customerId, isLoading, users, onCancel } = props;
  const { t } = useTranslation();
  const id = 'aaa';
  const [responseInternal, isLoadingInternal, isErrorInternal, postInternalData] = usePostApiEffect<IFormObject, void>(
    `customers/${customerId}/userroles`,
    null,
    'pythonApi',
  );
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  //const { values } = useFormikContext<IFormObject>();
  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoadingInternal);
    }
    if (!isSubmitting && isLoadingInternal) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoadingInternal && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoadingInternal]);

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = (data, setIsSubmitting) => {
    setIsSubmittingFn(() => setIsSubmitting);
    // Call the endpoint to add role to mostdigital user
    postInternalData(data);
  };

  const formData: IFormObject = {
    user_id: users[0] ? users[0].user_id : '',
    role_id: roles[0] ? roles[0].role_id : 0,
  };

  return (
    <ModalFormFrame<IFormObject>
      label={t('label-add-new-user')}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormSelect name="user_id" label="label-internal-user" readonly={isSubmitting}>
              {users.map((user) => (
                <option key={user.user_id} value={user.user_id}>
                  {user.user_name}
                </option>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={12}>
            <FormSelect name="role_id" label="label-user-role" readonly={isSubmitting}>
              {roles.map((role) => (
                <option key={role.role_id} value={role.role_id}>
                  {role.role_name}
                </option>
              ))}
            </FormSelect>
          </Grid>
        </Grid>
      )}
    />
  );
};

export default InternalUser;
