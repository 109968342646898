import React from 'react';
import ChartWrapperNoDataError from '../Common/ReusableComponents/ChartWrapper/ChartWrapperComponents/ChartWrapperNoDataError';
import { IDailyConsumptionChartProps } from './ChartProps';
import ConsumptionChartTooltip from './Tooltips/ConsumptionChartTooltip';
import { useTranslation } from 'react-i18next';
import { Legend, XAxis, YAxis, BarChart, Bar, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const DailyConsumptionChart = (props: IDailyConsumptionChartProps) => {
  const { data, isLoading } = props; // Keys: "date", "cost", "cumulative"
  const { t } = useTranslation();

  if (!isLoading && (!Array.isArray(data) || !data.length)) {
    return <ChartWrapperNoDataError />;
  }

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis hide dataKey="date" />
        <YAxis unit="€" />
        <Tooltip content={<ConsumptionChartTooltip />} animationDuration={500} />
        <Legend />
        <Bar name={t('label-daily-costs')} dataKey="cost" fill="#206FB7" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DailyConsumptionChart;
