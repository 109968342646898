/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { Link, LinkOff } from '@material-ui/icons';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormFieldSet, FormSelect, FormTextArea, FormTextDisplay, FormTextField } from '../../FormFields';
import { useGetApiEffect } from '../../Hooks/Hooks';
import { ITask, ISecret } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import LinkedSecrets from './LinkedSecrets';

import { useCollection } from '../../Hooks/Collections';

interface IProps {
  task: ITask;
}

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
  additional_info: yup.string(),
  production_value: yup.string(),
  test_value: yup.string(),
  type: yup.string(),
  status: yup.string(),
});

type Props = IProps;

const Secrets: React.FC<Props> = (props) => {
  const { task } = props;

  const [activeName, setActiveName] = useState<string>('');
  const [linkActive, setLinkActive] = useState<boolean>(true);

  const [data, isLoading, isError, getData] = useGetApiEffect<ISecret[]>(
    `api/secrets?customerId=${task.customer_guid}`,
    null,
  );

  const { t } = useTranslation();

  const secretTypes = useCollection(9);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataItem: (id: string | number) => ISecret | undefined = (id) => {
    setActiveName(String(id));
    return data.find((d) => d.name === id);
  };

  const handleToggleLink: () => void = () => {
    setLinkActive((prev) => !prev);
  };

  const handleSubmit: (data: ISecret) => void = (data) => {};

  return (
    <>
      <FormFieldSet legend="Keyvault ID:s">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextDisplay label={t('label-production-keyvault-address')} value={task.keyvault_prod_base_url} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextDisplay label={t('label-development-keyvault-address')} value={task.keyvault_test_base_url} />
          </Grid>
        </Grid>
      </FormFieldSet>
      <SplitFormFrame<ISecret>
        tableLabel={t('title-secrets')}
        formLabel={t('label-secret')}
        validationSchema={validationSchema}
        data={data}
        idProperty="name"
        properties={[
          { key: 'name', label: t('label-secret-name') },
          { key: 'type', label: t('label-secret-type') },
          { key: 'status', label: t('label-secret-link-status') },
        ]}
        isLoading={isLoading}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="name" label={t('label-secret-name')} readonly={true} />
              <FormTextArea name="description" label={t('label-secret-description')} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="type" label={t('label-secret-type')} readonly={!isEditing}>
                {secretTypes.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
              <FormTextArea name="additional_info" label={t('label-additional-information')} readonly={!isEditing} />
            </Grid>
            <Grid item container spacing={0}>
              <Grid item xs={12} sm={5}>
                <FormTextField
                  name="production_value"
                  label={t('label-production-keyvault-value')}
                  readonly={!isEditing}
                  TextFieldProps={{ type: 'password' }}
                />
              </Grid>
              <Grid item xs={12} sm={2} container direction="column" justify="center" alignContent="center">
                <Grid item>
                  <Button variant="contained" disabled={!isEditing} onClick={(e) => handleToggleLink()}>
                    {linkActive ? <Link /> : <LinkOff />}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FormTextField
                  name="test_value"
                  label={'label-development-keyvault-value'}
                  readonly={!isEditing}
                  TextFieldProps={{ type: 'password' }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      >
        <LinkedSecrets customerId={task.customer_guid} name={activeName} secrets={data} />
      </SplitFormFrame>
    </>
  );
};

export default Secrets;
