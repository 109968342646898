const NumberTooltipFormatter = (value: any) => {
  if (typeof value === 'number' && value % 1 !== 0) {
    return parseFloat(value.toFixed(2));
  } else if (typeof value === 'undefined') {
    return '';
  } else {
    return value;
  }
};

export default NumberTooltipFormatter;
