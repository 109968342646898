/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import { FormFieldSet } from '../FormFields';
import LoadingScreen from '../Common/LoadingScreen';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Paper from '@material-ui/core/Paper';

import { useTranslation } from 'react-i18next';

const CollapseRow = (props: { row: any; handleChange: any; checked: any }) => {
  const { row, handleChange, checked } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {row.data.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name + ' '}
          {row.data.length === 0 && <Typography style={{ fontWeight: 600 }}> (No data available) </Typography>}
        </TableCell>
        <TableCell>{Math.round(row.total_cost * 100) / 100}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{t('label-resource-name')}</TableCell>
                    <TableCell>{t('label-resource-id')}</TableCell>
                    <TableCell align="left">{t('label-consumption')}</TableCell>
                    <TableCell align="left">{t('label-unit')}</TableCell>
                    <TableCell align="left">{t('label-cost')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.data.map((node: any) => {
                    return (
                      <TableRow key={node.resource_id}>
                        <TableCell key={'id_field'}>
                          <Radio
                            checked={checked === node.resource_id}
                            color="primary"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(node.resource_id)}
                            style={{ color: '#2F518A' }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {node.resource_name}
                        </TableCell>
                        <TableCell>{node.resource_id}</TableCell>
                        <TableCell>{node.consumption}</TableCell>
                        <TableCell>{node.unit}</TableCell>
                        <TableCell>{Math.round(node.cost * 100) / 100}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export interface IFormTableExtraProps {
  id: number | string;
}

export interface IFormTableProps<T> {
  label: string;
  data: T[];
  properties: {
    key: keyof T;
    label: string;
  }[];
  isLoading: boolean;
  selectedId?: number | string;
  onSelectRow: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
  render?: (duplicate: boolean, onCancel: () => void) => React.ReactNode;
  pagination?: boolean;
  rowsPerPage?: number;
  limitHeight?: boolean;
  optionalButtons?: boolean;
  duplicateDisabled?: boolean;
  buttonsDisabled?: boolean;
  searchValue?: string;
  handleSearch?: (e: any) => void;
  collapseData?: any;
}

type Props<T> = IFormTableProps<T>;

function CollapseFormTable<T extends IFormTableExtraProps>(props: React.PropsWithChildren<Props<T>>) {
  const {
    label,
    data,
    properties,
    isLoading,
    selectedId,
    onSelectRow,
    onDelete,
    render,
    pagination,
    rowsPerPage,
    limitHeight,
    optionalButtons,
    duplicateDisabled,
    buttonsDisabled,
    searchValue,
    handleSearch,
    collapseData,
  } = props;

  const [checked, setChecked] = useState<number | string | undefined>(selectedId);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPageState, setRowsPerPageState] = useState<number>(rowsPerPage || 100);

  const { t } = useTranslation();

  useEffect(() => {
    setChecked(selectedId);
  }, [selectedId]);

  const handleChange = (id: number | string) => {
    setChecked(id);
    onSelectRow(id);
  };

  let emptyRows = 0;

  if (!!data && !!pagination) {
    emptyRows = rowsPerPageState - Math.min(rowsPerPageState, data.length - page * rowsPerPageState);
  }

  return (
    <>
      {!isLoading ? (
        <FormFieldSet legend={label || ''}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t('label-resource-type')}</TableCell>
                  <TableCell align="left">{t('label-total-cost')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collapseData.map((node: any) => (
                  <CollapseRow key={node.name} row={node} handleChange={handleChange} checked={checked} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormFieldSet>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

export default CollapseFormTable;
