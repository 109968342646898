import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Hidden } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormSelect, FormTextField, FormDateField, FormNumberField, FormCheckbox } from '../../../FormFields';

interface IProps {
  feature: IFeature;
  readonly: boolean;
}

interface ISelectOptions {
  value: string | number | string[];
  text: string;
}

export interface IFeature {
  name: string;
  label: string;
  translateLabel: boolean;
  type: 'Text' | 'Number' | 'Boolean' | 'Select';
  options?: ISelectOptions[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      width: theme.spacing(2),
    },
    boxContent: {
      flexGrow: 2,
    },
  }),
);

type Props = IProps;

const Feature = (props: Props) => {
  const { feature, readonly } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <>
      <Grid item lg={6} xs={12}>
        {feature.type === 'Text' ? (
          <FormTextField
            name={feature.name}
            label={feature.translateLabel ? t(feature.label) : feature.label}
            readonly={readonly}
          />
        ) : feature.type === 'Number' ? (
          <FormNumberField
            name={feature.name}
            label={feature.translateLabel ? t(feature.label) : feature.label}
            readonly={readonly}
          />
        ) : feature.type === 'Boolean' ? (
          <FormCheckbox
            key={feature.name}
            name={feature.name}
            label={feature.translateLabel ? t(feature.label) : feature.label}
            readonly={readonly}
          />
        ) : feature.type === 'Select' ? (
          <FormSelect
            name={feature.name}
            label={feature.translateLabel ? t(feature.label) : feature.label}
            readonly={readonly}
          >
            <option value={undefined}>{t('label_billing_feature_select_option_undefined')}</option>
            {feature.options &&
              feature.options.map((option) => (
                <option key={feature.name + String(option.value)} value={option.value}>
                  {option.text}
                </option>
              ))}
          </FormSelect>
        ) : null}
      </Grid>
      <Grid container spacing={0} item lg={6} xs={12}>
        <Hidden mdDown={true}>
          <Grid item lg={3} sm={4} xs={12}></Grid>
        </Hidden>
        <Grid item lg={3} sm={4} xs={12}>
          <Box display="flex">
            <Hidden mdDown={true}>
              <div className={classes.spacer}></div>
            </Hidden>
            <FormNumberField
              name={`${feature.name}_margin_percentage`}
              label={t('label_billing_feature_margin_percentage')}
              readonly={readonly}
              className={classes.boxContent}
            />
          </Box>
        </Grid>
        <Grid item lg={3} sm={4} xs={12}>
          <Box display="flex">
            <Hidden xsDown={true}>
              <div className={classes.spacer}></div>
            </Hidden>
            <FormNumberField
              name={`${feature.name}_discount_percentage`}
              label={t('label_billing_feature_discount_percentage')}
              readonly={readonly}
              className={classes.boxContent}
            />
          </Box>
        </Grid>
        <Grid item lg={3} sm={4} xs={12}>
          <Box display="flex">
            <Hidden xsDown={true}>
              <div className={classes.spacer}></div>
            </Hidden>
            <FormDateField
              name={`${feature.name}_discount_until`}
              label={t('label_billing_feature_discount_until')}
              readonly={readonly}
              className={classes.boxContent}
              TextFieldProps={{ InputLabelProps: { shrink: true } }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Feature;
