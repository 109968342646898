import { Theme } from '@material-ui/core';
import { blue, green, grey, orange, red } from '@material-ui/core/colors';

export const generalFontStack = [
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const headingFontStack = generalFontStack;
export const primaryColor = grey[800];
export const spacingUnit = 8;
export const gutter = spacingUnit * 2.5;

export const templateTheme: Theme = {
  // @ts-ignore
  palette: {
    primary: {
      dark: grey[900],
      light: grey[600],
      main: grey[800],
      contrastText: '#ffffff',
    },
    secondary: {
      dark: grey[600],
      light: grey[400],
      main: grey[500],
      contrastText: '#ffffff',
    },
    info: {
      dark: blue[500],
      light: blue[100],
      main: blue[300],
      contrastText: '#ffffff',
    },
    success: {
      dark: green[700],
      light: green[100],
      main: green[500],
      contrastText: '#ffffff',
    },
    warning: {
      dark: orange[700],
      light: orange[100],
      main: orange[400],
      contrastText: '#ffffff',
    },
    danger: {
      dark: red[700],
      light: red[100],
      main: red[400],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
  },
  shape: {
    borderRadius: 3,
  },
  // @ts-ignore
  typography: {
    fontFamily: generalFontStack,
    fontSize: 16,
    h1: {
      color: primaryColor,
      fontFamily: headingFontStack,
      fontSize: 42,
      fontStyle: 'normal',
      fontWeight: 200,
    },
    h2: {
      color: primaryColor,
      fontFamily: headingFontStack,
      fontSize: 30,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    h3: {
      color: primaryColor,
      fontFamily: generalFontStack,
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h4: {
      color: primaryColor,
      fontFamily: generalFontStack,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  // To remove all shadows, apply:
  // shadows: Array(25).fill('none') as any
};
