import { Theme } from '@material-ui/core';

const MuiButton = (theme: Theme) => {
  return {
    MuiButton: {
      root: {
        '&:focus': {
          outline: '1px dotted #212121',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:active, &:hover': {
          boxShadow: 'none',
        },
      },
    },
  };
};

export default MuiButton;
