/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { FormTextField } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { useTranslation } from 'react-i18next';
import { IStamp } from '../../../../types/types';

interface IProps {
  id: string;
  customerId: string;
  taskId: string;
  data?: IFormObject;
  onCancel: () => void;
  handlePostUpdate: () => void;
}

interface IFormObject {
  stampName: string;
  stampScreenName: string;
  stampDescription: string;
}

interface IStampCreate {
  id: string;
  customer_id: string;
  task_id: string;
  data: IStamp;
}

const validationSchema = yup.object<IFormObject>({
  stampName: yup.string().required(),
  stampScreenName: yup.string().required(),
  stampDescription: yup.string().required(),
});

type Props = IProps;

const AddStamp: React.FC<Props> = (props) => {
  const { id, customerId, taskId, data, onCancel, handlePostUpdate } = props;

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  const formData: IFormObject = {
    stampName: '',
    stampScreenName: data?.stampScreenName || '',
    stampDescription: data?.stampDescription || '',
  };

  const [payload, isLoading, isPostError, postData] = usePostApiEffect<IStampCreate, IStamp>(`api/stamps`, null);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => {
    setIsSubmittingFn(() => setIsSubmitting);

    const payload = {
      id,
      customer_id: customerId,
      task_id: taskId,
      data: {
        stamp_name: data.stampName,
        stamp_screen_name: data.stampScreenName,
        stamp_description: data.stampDescription,
      },
    };

    postData(payload);

    handlePostUpdate();
  };

  return (
    <ModalFormFrame<IFormObject>
      label={'Add a new stamp'}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ values, isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="stampScreenName" label={'Screen Name'} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="stampName" label={'Stamp Name'} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField name="stampDescription" label={'Description'} readonly={isSubmitting} />
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddStamp;
