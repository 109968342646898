import React from 'react';
import { FieldArray } from 'formik';

interface IFormFieldArrayProps<T> {
  name: string;
  children?: T[];
}

type Props<T> = IFormFieldArrayProps<T>;

function FormFieldArray<T>(props: React.PropsWithChildren<Props<T>>) {
  const { name, children } = props;

  return <FieldArray name={name}>{({ insert, remove, push }) => children}</FieldArray>;
}

export default FormFieldArray;
