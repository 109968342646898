import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import MDButton from '../../../Common/ReusableComponents/MDButton';

interface IComponentProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (duration: number, vm: string) => void;
  vms: string[];
}

const StartTaskDialog = (props: IComponentProps) => {
  const { open, handleClose, handleSubmit, vms } = props;

  const [duration, setDuration] = useState<number>(0);
  const [vm, setVm] = useState<string>(vms[0]);

  const handleDurationChange = (e: any) => {
    setDuration(e.target.value);
  };

  const handleVmChange = (e: any) => {
    setVm(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Start task immediately?'}</DialogTitle>
      <DialogContent>
        <Grid container justify="center" alignItems="center">
          <DialogContentText id="alert-dialog-description">Give task duration in minutes</DialogContentText>
          <Grid item xs={12}>
            <Input error={duration < 1} type="number" value={duration} onChange={handleDurationChange} />
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ paddingLeft: '0.5vh' }}>
              <InputLabel htmlFor="outlined-age-native-simple">Select vm</InputLabel>
              <Select
                native
                value={vm}
                onChange={handleVmChange}
                label="Vm"
                inputProps={{
                  name: 'vm',
                }}
              >
                {vms.map((vm) => (
                  <option value={vm}>{vm}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>

        <MDButton handleClick={() => handleSubmit(duration, vm)} label="Run" disabled={duration < 1} />
      </DialogActions>
    </Dialog>
  );
};

export default StartTaskDialog;
