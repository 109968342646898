import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormCheckbox, FormSelect, FormTextArea, FormTextField } from '../../FormFields';
import { updateCollectionApiRequest, UPDATE_COLLECTION } from '../../../redux/actions/collection';
import { getAllCollections, isActionPending } from '../../../redux/reducers';
import { ICollection, IState } from '../../../types/types';

import CollectionValues from './CollectionValues';
import AddCollection from './Collections/AddCollection';

interface IProps {}

interface IFormObject extends Omit<ICollection, 'members'> {}

const validationSchema = yup.object<IFormObject>({
  id: yup.number(),
  name: yup.string().required(),
  description: yup.string(),
  multilanguage: yup.boolean(),
  deleted: yup.boolean(),
});

const getAddComponent: (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (props) => {
  return <AddCollection data={props.values} onCancel={props.onCancel} />;
};

type Props = IProps;

const Collections: React.FC<Props> = (props) => {
  const [id, setId] = useState<number>();
  const [multilanguage, setMultilanguage] = useState<boolean>(false);

  const data = useSelector<IState, ICollection[]>((state: IState) => getAllCollections(state), shallowEqual);

  const isUpdating = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, UPDATE_COLLECTION),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    const item = data.find((d) => d.id === Number(id));
    setId(Number(id));
    setMultilanguage(item?.multilanguage || false);
    return item;
  };

  const handleSubmit: (data: IFormObject) => void = useCallback(
    (data) => {
      dispatch(updateCollectionApiRequest({ id: data.id, name: data.name, description: data.description }));
    },
    [dispatch],
  );

  return (
    <>
      <SplitFormFrame<IFormObject>
        tableLabel="Collections"
        formLabel="Collection"
        validationSchema={validationSchema}
        data={data}
        idProperty="id"
        properties={[
          { key: 'name', label: 'Collection name' },
          { key: 'id', label: 'Collection ID' },
          { key: 'deleted', label: 'Status' },
        ]}
        isUpdating={isUpdating}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        pagination={true}
        limitHeight={false}
        rowsPerPage={data.length}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="id" label="Collection ID" readonly={true} />
              <FormTextField name="name" label="Collection name" readonly={!isEditing} />
              <FormSelect name="deleted" label="Collection status" readonly={!isEditing}>
                <option value={0}>Active</option>
                <option value={1}>Disabled</option>
              </FormSelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea name="description" label="Collection description" readonly={!isEditing} />
              <FormCheckbox name="multilanguage" label="Multi language Collection" readonly={true} />
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent}
      >
        {id && <CollectionValues collectionId={id} isMultilanguage={multilanguage} />}
      </SplitFormFrame>
    </>
  );
};

export default Collections;
