import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import FormTwinMultiSelect from '../../../FormFields/FormTwinMultiSelect';
import { FormSelect, FormTextArea, FormTextField } from '../../../FormFields';
import { IRole, IUserUpdate, IUser, IRoleOperation, IUserAddRole } from './Types';
import { useTranslation } from 'react-i18next';
import { useDeleteApiEffect, usePostApiEffect, usePutApiEffect } from '../../../Hooks/Hooks';
import MDButton from '../../../Common/ReusableComponents/MDButton';
import RoleDialogWindow from './RoleDialogWindow';
import { useFormikContext } from 'formik';
interface IProps {
  customerId: string;
  roles: IRole[];
  isEditing: boolean;
  onCancel: () => void;
  onRefresh: () => void;
}
type Props = IProps;

const UpdateUser: React.FC<Props> = (props) => {
  const { customerId, roles, isEditing, onRefresh } = props;
  const { t } = useTranslation();
  const [roleId, setRoleId] = useState<number>();

  const { values, setValues } = useFormikContext<IUser>();
  const [response, isUpdatingUsers, isUpdateUserError, putData] = usePutApiEffect<IUserUpdate, void>(
    `api/aad/users`,
    null,
  );
  const [addRoleResponse, isLoading, isError, addRole] = usePostApiEffect<IRole | undefined, void>(
    `customers/${customerId}/userroles/${values.id}`,
    null,
    'pythonApi',
    onRefresh,
  );

  const [isLoadingDelete, isErrorDelete, deleteRole] = useDeleteApiEffect<null>(
    `customers/${customerId}/userroles/${values.id}/roles/${roleId}`,
    'pythonApi',
    onRefresh,
  );

  const handleRoleDelete: (role: IRole | undefined) => void = (role) => {
    setRoleId(role?.role_id);
    deleteRole(null);
  };

  const handleRoleAdd: (role: IRole | undefined) => void = (role) => {
    addRole(role);
  };

  const handleSubmit: (data: IUser) => void = (data) => {
    const names = data.name.split(' ');
    const first_name = names.length === 2 ? names[0] : '';
    const last_name = names.length === 2 ? names[1] : '';
    const payload: IUserUpdate = {
      object_id: data.id,
      first_name,
      last_name,
      email: data.email,
      roles: data.roles,
    };
    putData(payload);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [dialogSelection, setDialogSelection] = useState<IRoleOperation>('');

  const toggleDialog = (dialog: IRoleOperation) => {
    setOpen(!open);
    setDialogSelection(dialog);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormTextField name="name" label={t('label-user-name')} readonly={true} />
          <FormTextField name="email" label={t('label-email')} readonly={!isEditing} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField name="type" label={t('label-type')} readonly={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextArea
            name="roles"
            label={t('label-role')}
            readonly={true}
            customFieldValue={values.roles.length > 0 ? values.roles : t('label-no-roles-available')}
          />
          <MDButton label={t('label-add-role')} handleClick={() => toggleDialog('add')} />
          <MDButton label={t('label-delete-role')} handleClick={() => toggleDialog('delete')} />
        </Grid>
      </Grid>
      <RoleDialogWindow
        open={open}
        task={dialogSelection}
        handleClose={toggleDialog}
        handleAdd={handleRoleAdd}
        handleDelete={handleRoleDelete}
        translator={t}
        allRoles={roles}
        userRoles={values.roles.map((role) => role.toString())}
      />
    </>
  );
};

export default UpdateUser;
