import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateScheduleApiRequest } from '../../../redux/actions/schedule';
import moment from 'moment';
import * as yup from 'yup';
import { FormDateField, FormSelect, FormTimeField, FormTwinMultiSelect } from '../../FormFields';
import { ITask } from '../../../types/types';

import { useCollection } from '../../Hooks/Collections';
import ScheduleRecurrence from './Schedule/ScheduleRecurrence';
import { IScheduleFormObject as IFormObject, IScheduleUpdate } from './Schedule/ScheduleTypes';

import AddSchedule from './Schedule/AddSchedule';
import { useTranslation } from 'react-i18next';
import SplitFormFrameMulti from '../../FormFrames/SplitFormFrameMulti';
import DeleteSchedules from '../../../services/api/APIManagementQueries/Scheduler/DeleteSchedules';
import AlertWindow from '../../Common/ReusableComponents/AlertWindow';
import GetSchedules from '../../../services/api/Other/GetSchedules';

interface IProps {
  task: ITask;
}

type Props = IProps;

const validationSchema = yup.object<IFormObject>({
  id: yup.string(),
  vmName: yup.array<string>().required(),
  taskName: yup.string(),
  startDate: yup.string().required(),
  startTime: yup.string().required(),
  endDate: yup.string().required(),
  endTime: yup.string().required(),
  recurrenceType: yup.string().required(),
  frequency: yup.number().required(),
  dayNumber: yup.number(),
  runsMonday: yup.boolean(),
  runsTuesday: yup.boolean(),
  runsWednesday: yup.boolean(),
  runsThursday: yup.boolean(),
  runsFriday: yup.boolean(),
  runsSaturday: yup.boolean(),
  runsSunday: yup.boolean(),
  cronString: yup.string(),
  duration: yup.number(),
});

const getAddComponent: (
  task: ITask,
  refetchSchedules: () => void,
  setMessage: (msg: string) => void,
  setSeverity: (msg: string) => void,
  setDisplayAlert: (state: boolean) => void,
) => (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (
  task,
  refetchSchedules,
  setMessage,
  setSeverity,
  setDisplayAlert,
) => {
  return (props) => {
    return (
      <AddSchedule
        task={task}
        data={props.values}
        onCancel={props.onCancel}
        refetchSchedules={refetchSchedules}
        setMessage={setMessage}
        setSeverity={setSeverity}
        setDisplayAlert={setDisplayAlert}
      />
    );
  };
};

const Scheduler: React.FC<Props> = (props) => {
  const { task } = props;
  const recurrence = useCollection(13);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [severity, setSeverity] = useState<string>('success');
  const [message, setMessage] = useState<string>('');
  const vmNames = task.vm_names;

  /**
   * API
   */
  const {
    data: schedules,
    isLoading: schedulesLoading,
    isRefetching: schedulesFetching,
    refetch: RefetchSchedules,
  } = GetSchedules(task.customer_guid, task.task_guid);
  const deleteSchedules = DeleteSchedules();

  const data: any = schedules?.map((schedule: any) => {
    return {
      id: schedule.schedule_id,
      vmName: schedule.vm_name ? [schedule.vm_name] : [],
      taskName: schedule.task_name,
      startDate: moment(schedule.start_time).format('YYYY-MM-DD'),
      startTime: moment(schedule.start_time).format('HH:mm'),
      endDate: moment(schedule.end_time).format('YYYY-MM-DD'),
      endTime: moment(schedule.end_time).format('HH:mm'),
      recurrenceType: schedule.daily_recurrence
        ? 'recurrence_daily'
        : schedule.weekly_recurrence
        ? 'recurrence_weekly'
        : schedule.monthly_recurrence
        ? 'recurrence_monthly'
        : schedule.cron_recurrence
        ? 'recurrence_cron'
        : 'recurrence_none',
      frequency: schedule.daily_recurrence
        ? schedule.daily_recurrence.frequency
        : schedule.weekly_recurrence
        ? schedule.weekly_recurrence.frequency
        : schedule.monthly_recurrence
        ? schedule.monthly_recurrence.frequency
        : 0,
      dayNumber: schedule.monthly_recurrence?.day_number || 0,
      runsMonday: schedule.weekly_recurrence?.runs_monday || false,
      runsTuesday: schedule.weekly_recurrence?.runs_tuesday || false,
      runsWednesday: schedule.weekly_recurrence?.runs_wednesday || false,
      runsThursday: schedule.weekly_recurrence?.runs_thursday || false,
      runsFriday: schedule.weekly_recurrence?.runs_friday || false,
      runsSaturday: schedule.weekly_recurrence?.runs_saturday || false,
      runsSunday: schedule.weekly_recurrence?.runs_sunday || false,
      cronString: schedule.cron_recurrence?.cron_string || '',
      duration: schedule.cron_recurrence?.duration || 0,
    };
  });

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    return data.find((d: any) => d.id === id);
  };

  const handleSubmit: (data: any) => void = useCallback(
    (data) => {
      const startDate = moment(`${data.startDate}T${data.startTime}`).toDate();
      const endDate = moment(`${data.endDate}T${data.endTime}`).toDate();
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const payload: IScheduleUpdate = {
        customer_id: task.customer_guid,
        task_id: task.task_guid,
        schedule_id: data.id,
        start_time: startDate,
        end_time: endDate,
        vm_name: data.vmName[0] || '',
        daily_recurrence:
          data.recurrenceType === 'recurrence_daily'
            ? {
                frequency: data.frequency,
              }
            : null,
        weekly_recurrence:
          data.recurrenceType === 'recurrence_weekly'
            ? {
                frequency: data.frequency,
                runs_monday: data.runsMonday,
                runs_tuesday: data.runsTuesday,
                runs_wednesday: data.runsWednesday,
                runs_thursday: data.runsThursday,
                runs_friday: data.runsFriday,
                runs_saturday: data.runsSaturday,
                runs_sunday: data.runsSunday,
              }
            : null,
        monthly_recurrence:
          data.recurrenceType === 'recurrence_monthly'
            ? {
                frequency: data.frequency,
                day_number: data.dayNumber,
              }
            : null,
        cron_recurrence:
          data.recurrenceType === 'recurrence_cron'
            ? {
                cron_string: data.cronString,
                duration: data.duration,
              }
            : null,
        time_zone: timeZone,
      };
      dispatch(updateScheduleApiRequest(payload));
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleDelete = (tasks: any[]) => {
    setDisplayAlert(true);
    setMessage(t('label-loading'));
    setSeverity('info');
    deleteSchedules.mutate(tasks, {
      onSuccess: () => {
        setSeverity('success');
        setMessage('OK');
        RefetchSchedules();
      },
      onError: (error) => {
        setSeverity('error');
        setMessage(String(error));
      },
      onSettled: () => {
        setDisplayAlert(true);
      },
    });
  };

  return (
    <>
      <AlertWindow
        message={message}
        severity={severity}
        display={displayAlert}
        setDisplay={setDisplayAlert}
        duration={severity === 'info' ? null : 3000}
      />
      <SplitFormFrameMulti<IFormObject>
        tableLabel={t('label-scheduled-events')}
        formLabel={t('label-scheduler')}
        validationSchema={validationSchema}
        data={data}
        idProperty="id"
        properties={[
          { key: 'startDate', label: t('label-start-date') },
          { key: 'startTime', label: t('label-start-time') },
          { key: 'endDate', label: t('label-end-date') },
          { key: 'endTime', label: t('label-end-time') },
          { key: 'recurrenceType', label: t('label-recurring') },
        ]}
        isLoading={schedulesLoading || schedulesFetching || deleteSchedules.isLoading}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        duplicateDisabled={true}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTwinMultiSelect
                name="vmName"
                label={t('label-running-environments')}
                availableValues={vmNames}
                readonly={!isEditing}
              ></FormTwinMultiSelect>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormDateField name="startDate" label={t('label-start-date')} readonly={!isEditing} />
                <FormTimeField name="startTime" label={t('label-start-time')} readonly={!isEditing} />
              </Grid>
              <Grid item xs={6}>
                <FormDateField name="endDate" label={t('label-end-date')} readonly={!isEditing} />
                <FormTimeField name="endTime" label={t('label-end-time')} readonly={!isEditing} />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  name="recurrenceType"
                  label={t('label-recurrence')}
                  readonly={!isEditing}
                  value={values.recurrenceType}
                >
                  {recurrence.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </FormSelect>
              </Grid>
              <ScheduleRecurrence isEditing={isEditing} />
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent(task, RefetchSchedules, setMessage, setSeverity, setDisplayAlert)}
      ></SplitFormFrameMulti>
    </>
  );
};

export default Scheduler;
