import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      background: '#2F518A',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
      '&:hover': {
        backgroundColor: '#4c75ba',
        color: '#FFF',
      },
    },
    buttonCancel: {
      marginLeft: theme.spacing(2),
      background: '#FFFFFF',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
    },
    disabled: {},
  }),
);

interface IProps {
  handleClick: () => void;
  label: string;
  disabled?: boolean;
  icon?: any;
}

type Props = IProps;

const MDButton: React.FC<Props> = (props) => {
  const { handleClick, label, disabled, icon } = props;
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.button, disabled: classes.disabled }}
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={handleClick}
    >
      {icon ? icon : label}
    </Button>
  );
};

export default MDButton;
