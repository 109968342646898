/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormFieldArray, FormTextField } from '../../FormFields';
import { useGetApiEffect, usePutApiEffect } from '../../Hooks/Hooks';
import { getPortalLanguages } from '../../../redux/reducers';
import { ILanguage, IState, IText, ITextValue } from '../../../types/types';
import LanguageArrayItem from './LanguageArrayItem';
import AddText from './Texts/AddText';

interface IProps {}

interface IFormObject extends Omit<IText, 'values'> {
  values: IFormObjectValue[];
}

interface IFormObjectValue extends ITextValue {
  label: string;
}

interface ITextUpdate {
  id: string;
  values: ITextUpdateValue[];
}

interface ITextUpdateValue {
  value: string;
  language_code: string;
}

const validationSchema = yup.object<IFormObject>({
  id: yup.string(),
  value_en: yup.string(),
  values: yup.array<IFormObjectValue>(),
});

const getAddComponent: (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (props) => {
  return <AddText data={props.values} onCancel={props.onCancel} />;
};

type Props = IProps;

const Texts: React.FC<Props> = (props) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const languages = useSelector<IState, ILanguage[]>(getPortalLanguages, shallowEqual);

  const [data, isLoading, isError, getData] = useGetApiEffect<IText[]>('api/texts', null);
  const [payload, isUpdating, isUpdateError, putData] = usePutApiEffect<ITextUpdate, IText>('api/texts', null);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formData =
    data &&
    data.map((d) => {
      const itemValues = languages.map((language, index) => {
        const value = d.values.find((v) => v.language_code === language.code);
        return {
          value: value?.value || '',
          language_code: value?.language_code || language.code,
          label: `Value text - ${language.name}`,
        };
      });

      return {
        id: d.id,
        value_en: d.value_en || '',
        values: itemValues,
      };
    });

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    return formData.find((d) => d.id === id);
  };

  const handleSubmit: (data: IFormObject) => void = (data) => {
    const payload: ITextUpdate = {
      id: data.id,
      values: data.values.map((v) => {
        return {
          value: v.value,
          language_code: v.language_code,
        };
      }),
    };
    putData(payload);
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const filterData = () => {
    if (searchValue.length > 0) {
      return formData.filter((text) => text.id.toLowerCase().includes(searchValue.toLowerCase()));
    } else return formData;
  };

  return (
    <>
      <SplitFormFrame<IFormObject>
        tableLabel="Texts"
        formLabel="Text"
        validationSchema={validationSchema}
        data={filterData()}
        idProperty="id"
        properties={[
          { key: 'value_en', label: 'Text - EN' },
          { key: 'id', label: 'Text ID' },
        ]}
        isLoading={isLoading}
        isUpdating={isUpdating}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        pagination={true}
        rowsPerPage={15}
        limitHeight={false}
        handleSearch={handleSearch}
        searchValue={searchValue}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="id" label="Text ID:" readonly={true} />
            </Grid>
            <Grid item xs={12}>
              <FormFieldArray name="values">
                {values.values.map((value, index) => (
                  <LanguageArrayItem
                    key={index}
                    hiddenName={`values.${index}.language_code`}
                    hiddenValue={value.language_code}
                    name={`values.${index}.value`}
                    label={value.label}
                    readonly={!isEditing}
                  />
                ))}
              </FormFieldArray>
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent}
      ></SplitFormFrame>
    </>
  );
};

export default Texts;
