import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const LoadingScreen = () => {
  return (
    <Card style={{ marginTop: 12, marginBottom: 12 }}>
      <CardContent>
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </CardContent>
    </Card>
  );
};

export default LoadingScreen;
