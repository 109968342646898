import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoBox from '../../InfoBox/InfoBox';
import Box from '@mui/material/Box';

interface IChartWrapperNoDataErrorProps {
  translationKey?: string;
}

const ChartWrapperNoDataError = (props: IChartWrapperNoDataErrorProps) => {
  const { t } = useTranslation();
  const { translationKey } = props;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '18%' }}>
      <InfoBox
        variant="outlined"
        severity="error"
        text={t('label-unable-to-display-chart')}
        additionalText={!!translationKey ? t(translationKey) : null}
      />
    </Box>
  );
};

export default ChartWrapperNoDataError;
