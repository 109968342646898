import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormDateField, FormSelect, FormTimeField, FormTwinMultiSelect } from '../../../FormFields';
import { ITask } from '../../../../types/types';

import ScheduleRecurrence from './ScheduleRecurrence';
import { IScheduleFormObject, IScheduleCreate } from './ScheduleTypes';
import { useCollection } from '../../../Hooks/Collections';
import AddSchedule from '../../../../services/api/Other/AddSchedule';
import { useTranslation } from 'react-i18next';

interface IProps {
  task: ITask;
  data?: IScheduleFormObject;
  onCancel: () => void;
  refetchSchedules: () => void;
  setMessage: (msg: string) => void;
  setSeverity: (msg: string) => void;
  setDisplayAlert: (state: boolean) => void;
}

interface IFormObject extends Omit<IScheduleFormObject, 'id'> {}

const validationSchema = yup.object<IFormObject>({
  vmName: yup.array<string>().required(),
  taskName: yup.string(),
  startDate: yup.string().required(),
  startTime: yup.string().required(),
  endDate: yup.string().required(),
  endTime: yup.string().required(),
  recurrenceType: yup.string().required(),
  frequency: yup.number().required(),
  dayNumber: yup.number(),
  runsMonday: yup.boolean(),
  runsTuesday: yup.boolean(),
  runsWednesday: yup.boolean(),
  runsThursday: yup.boolean(),
  runsFriday: yup.boolean(),
  runsSaturday: yup.boolean(),
  runsSunday: yup.boolean(),
  cronString: yup.string(),
  duration: yup.number(),
});

type Props = IProps;

const AddCollection: React.FC<Props> = (props) => {
  const { task, data, onCancel, refetchSchedules, setMessage, setSeverity, setDisplayAlert } = props;
  const { t } = useTranslation();
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const addScheduleMutation = AddSchedule();

  const recurrence = useCollection(13).sort((a, b) => {
    if (a.id === 'recurrence_none') {
      return -1;
    } else if (b.id === 'recurrence_none') {
      return 1;
    } else {
      return 0;
    }
  });

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(addScheduleMutation.isLoading);
    }
    if (!isSubmitting && addScheduleMutation.isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !addScheduleMutation.isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [addScheduleMutation.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const formData: IFormObject = data || {
    vmName: [],
    taskName: '',
    startDate: moment().format('YYYY-MM-DD'),
    startTime: moment().format('HH:mm'),
    endDate: moment().format('YYYY-MM-DD'),
    endTime: moment().format('HH:mm'),
    recurrenceType: 'recurrence_none',
    frequency: 0,
    dayNumber: 0,
    runsMonday: false,
    runsTuesday: false,
    runsWednesday: false,
    runsThursday: false,
    runsFriday: false,
    runsSaturday: false,
    runsSunday: false,
    cronString: '',
    duration: 0,
  };

  const vmNames = task.vm_names;

  const handleSubmit = (data: any) => {
    const startDate = moment(`${data.startDate}T${data.startTime}`).toDate();
    const endDate = moment(`${data.endDate}T${data.endTime}`).toDate();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const payload: IScheduleCreate = {
      customer_id: task.customer_guid,
      task_id: task.task_guid,
      start_time: startDate,
      end_time: endDate,
      vm_name: data.vmName[0],
      daily_recurrence:
        data.recurrenceType === 'recurrence_daily'
          ? {
              frequency: data.frequency,
            }
          : null,
      weekly_recurrence:
        data.recurrenceType === 'recurrence_weekly'
          ? {
              frequency: data.frequency,
              runs_monday: data.runsMonday,
              runs_tuesday: data.runsTuesday,
              runs_wednesday: data.runsWednesday,
              runs_thursday: data.runsThursday,
              runs_friday: data.runsFriday,
              runs_saturday: data.runsSaturday,
              runs_sunday: data.runsSunday,
            }
          : null,
      monthly_recurrence:
        data.recurrenceType === 'recurrence_monthly'
          ? {
              frequency: data.frequency,
              day_number: data.dayNumber,
            }
          : null,
      cron_recurrence:
        data.recurrenceType === 'recurrence_cron'
          ? {
              cron_string: data.cronString,
              duration: data.duration,
            }
          : null,
      time_zone: timeZone,
    };
    setDisplayAlert(true);
    setSeverity('info');
    setMessage(t('label-loading'));
    addScheduleMutation.mutate(payload, {
      onSuccess: () => {
        refetchSchedules();
        setSeverity('success');
        setMessage('OK');
      },
      onError: (error) => {
        setMessage(String(error));
        setSeverity('error');
      },
      onSettled: () => {
        setDisplayAlert(true);
      },
    });
  };

  return (
    <ModalFormFrame<IFormObject>
      label="label_form_add_schedule"
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTwinMultiSelect
              name="vmName"
              label="Running environments"
              availableValues={vmNames}
              readonly={isSubmitting}
            ></FormTwinMultiSelect>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormDateField name="startDate" label="Start date" readonly={isSubmitting} />
              <FormTimeField name="startTime" label="Start time" readonly={isSubmitting} />
            </Grid>
            <Grid item xs={6}>
              <FormDateField name="endDate" label="End date" readonly={isSubmitting} />
              <FormTimeField name="endTime" label="End time" readonly={isSubmitting} />
            </Grid>
            <Grid item xs={12}>
              <FormSelect name="recurrenceType" label="Recurrence" readonly={isSubmitting}>
                {recurrence.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
            </Grid>
          </Grid>
          <ScheduleRecurrence isEditing={!isSubmitting} />
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddCollection;
