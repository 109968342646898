import {
  createCustomerActionCreator,
  fetchCustomersActionCreator,
  selectCustomerActionCreator,
  updateCustomerActionCreator,
} from '../actions/customer';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  ICustomer,
  ICustomerCreate,
  ICustomerState,
  ICustomerUpdate,
  IDictionary,
  INormalizedBase,
} from '../../types/types';

import { Success } from 'typescript-fsa';
import { combineReducers } from 'redux';

const initialState: ICustomerState = {
  selectedCustomer: '',
  byId: {},
  ids: [],
};

const selectedCustomerHandler = (selectedCustomer: string, payload: string) => {
  if (payload) {
    return payload;
  }

  return selectedCustomer;
};

const selectIfOneCustomerHandler = (
  selectedCustomer: string,
  payload: Success<void, INormalizedBase<ICustomer, string>>,
) => {
  if (payload.result.ids && payload.result.ids.length === 1) {
    return payload.result.ids[0];
  }

  return selectedCustomer;
};

const selectedCustomer = reducerWithInitialState<string>(initialState.selectedCustomer)
  .case(selectCustomerActionCreator, selectedCustomerHandler)
  .case(fetchCustomersActionCreator.done, selectIfOneCustomerHandler);

const byIdHandler = (byId: IDictionary<ICustomer>, payload: Success<void, INormalizedBase<ICustomer, string>>) => {
  if (payload.result.byId) {
    return { ...byId, ...payload.result.byId };
  }
  return byId;
};

const byIdCreateHandler = (byId: IDictionary<ICustomer>, payload: Success<ICustomerCreate, ICustomer>) => {
  if (payload.result) {
    return { ...byId, [payload.result.customer_guid]: payload.result };
  }
  return byId;
};

const byIdUpdateHandler = (byId: IDictionary<ICustomer>, payload: Success<ICustomerUpdate, void>) => {
  if (payload.params) {
    const current = byId[payload.params.customer_guid];
    const updated = current ? { ...current, ...payload.params } : payload.params;
    return { ...byId, [payload.params.customer_guid]: updated };
  }
  return byId;
};

const byId = reducerWithInitialState<{}>(initialState.byId)
  .cases([fetchCustomersActionCreator.done], byIdHandler)
  .cases([createCustomerActionCreator.done], byIdCreateHandler)
  .cases([updateCustomerActionCreator.done], byIdUpdateHandler);

const idsHandler = (ids: string[], payload: Success<void, INormalizedBase<ICustomer, string>>) => {
  if (payload.result.ids && payload.result.ids.length > 0) {
    return Array.from(new Set(ids.concat(payload.result.ids)));
  }
  return ids;
};

const idsCreateHandler = (ids: string[], payload: Success<ICustomerCreate, ICustomer>) => {
  if (payload.result) {
    return Array.from(new Set(ids.concat(payload.result.customer_guid)));
  }
  return ids;
};

const idsUpdateHandler = (ids: string[], payload: Success<ICustomerUpdate, void>) => {
  return ids;
};

const ids = reducerWithInitialState<string[]>(initialState.ids)
  .cases([fetchCustomersActionCreator.done], idsHandler)
  .cases([createCustomerActionCreator.done], idsCreateHandler)
  .cases([updateCustomerActionCreator.done], idsUpdateHandler);

export default combineReducers({
  selectedCustomer,
  byId,
  ids,
});

export const getSelectedCustomerId: (state: ICustomerState) => string = (state) => {
  return state.selectedCustomer;
};

export const getSelectedCustomerName: (state: ICustomerState) => string = (state) => {
  return state.byId[state.selectedCustomer]?.customer_name || '';
};

export const getAllCustomers: (state: ICustomerState) => ICustomer[] = (state) => {
  return state.ids.map((id) => state.byId[id]);
};
