/* eslint-disable @typescript-eslint/no-unused-vars */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { useField } from 'formik';
import React from 'react';

interface IProps {
  name: string;
  label: string;
  readonly?: boolean;
  required?: boolean;
  CheckboxProps?: CheckboxProps;
}

const FormCheckbox: React.FC<IProps> = (props) => {
  const { label, name, readonly, required, CheckboxProps } = props;
  const [field, meta] = useField(name);

  field.checked = CheckboxProps?.checked || field?.checked || field?.value || false;

  return <FormControlLabel control={<Checkbox {...field} required={required} disabled={!!readonly} />} label={label} />;
};

export default FormCheckbox;
