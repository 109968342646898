import { Theme } from '@material-ui/core';
import muiButton from './MuiButton';
import muiForm from './MuiForm';
import muiTable from './MuiTable';
import muiDialog from './MuiDialog';
import muiDivider from './MuiDivider';

const themeOverride = (theme: Theme) => {
  const overrides = {
    ...muiForm(theme),
    ...muiButton(theme),
    ...muiTable(theme),
    ...muiDialog(theme),
    ...muiDivider(theme),
  };

  return {
    props: {
      MuiButtonBase: {
        // disableRipple: true
      },
      MuiInput: {
        // disableUnderline: true
      },
      MuiSelect: {
        // displayEmpty: true
      },
      MuiInputLabel: {
        // disableAnimation: true,
        // shrink: false
      },
    },
    overrides,
  };
};

export default themeOverride;
