import React, { useCallback, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as yup from 'yup';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from '../../../FormFrames/FormFrame';
import {
  FormFieldSet,
  FormSelect,
  FormTextDisplay,
  FormTextField,
  FormTextArea,
  FormCheckbox,
} from '../../../FormFields';
import { ICustomer, ICustomerUpdate, IState, Nullable } from '../../../../types/types';
import { updateCustomerApiRequest, UPDATE_CUSTOMER } from '../../../../redux/actions/customer';
import { getTimeZones, isActionPending } from '../../../../redux/reducers';

import { useTranslation } from 'react-i18next';
import { useCollection } from '../../../Hooks/Collections';

interface IProps {
  customer: ICustomer;
}

interface IFormObject {
  customer_name: string;
  customer_description: Nullable<string>;
  customer_type: string;
  customer_timezone: Nullable<string>;
  daylight_saving_used: Nullable<boolean>;
}

const validationSchema = yup.object<IFormObject>({
  customer_name: yup.string().required(),
  customer_description: yup.string().nullable(),
  customer_type: yup.string().required(),
  customer_timezone: yup.string().nullable(),
  daylight_saving_used: yup.boolean().nullable(),
});

type Props = IProps;

const Customer: React.FC<Props> = (props) => {
  const { customer } = props;
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const customerTypes = useCollection(8);
  const statusValues = useCollection(7);

  const timeZones = useSelector<IState, string[]>(getTimeZones, shallowEqual);

  const isSubmitting = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, UPDATE_CUSTOMER),
    shallowEqual,
  );

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isSubmitting);
    }
    if (isSubmitting === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (
    data: IFormObject,
    setIsSubmitting: SetIsSubmittingFn,
    setStopEditing: SetStopEditingFn,
  ) => void = useCallback(
    (data, setIsSubmitting, setStopEditing) => {
      setIsSubmittingFn(() => setIsSubmitting);
      setStopEditingFn(() => setStopEditing);

      const payload: ICustomerUpdate = {
        ...data,
        customer_guid: customer.customer_guid,
        on_site_implementation: data.customer_type === 'onsite',
        screenshots_allowed: customer.screenshots_allowed,
        recording_allowed: customer.recording_allowed,
        vnet_address_space: customer.vnet_address_space,
        privx_used: customer.privx_used,
        vpn_used: customer.vpn_used,
        specific_vpn_device_in_use: customer.specific_vpn_device_in_use,
        prod_vm_count: customer.prod_vm_count,
        test_vm_count: customer.test_vm_count,
        jbox_vm_count: customer.jbox_vm_count,
      };

      dispatch(updateCustomerApiRequest(customer.customer_guid, payload));
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const data: IFormObject = customer && {
    ...customer,
    customer_type: customer.on_site_implementation ? 'onsite' : 'default',
  };

  return (
    <>
      <FormFrame<IFormObject>
        label={t('basic-info')}
        validationSchema={validationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="customer_name" label={t('customer-name')} readonly={true} showHelp={true} />
              <FormTextField
                name="customer_screen_name"
                label={t('customer-screen-name')}
                readonly={!isEditing}
                showHelp={true}
              />
              <FormTextArea
                name="customer_description"
                label={t('customer-description')}
                readonly={!isEditing}
                showHelp={true}
              />
              <FormSelect
                name="customer_type"
                label={t('customer-type')}
                readonly={!isEditing}
                showHelp={true}
                value={values.customer_type}
              >
                {customerTypes.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
              <FormSelect
                name="customer_timezone"
                label={t('customer-timezone')}
                readonly={!isEditing}
                showHelp={true}
                value={values.customer_timezone}
              >
                {timeZones.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </FormSelect>
              <FormCheckbox
                name="daylight_saving_used"
                label={t('customer-daylight-saving-used')}
                readonly={!isEditing}
              />
              {isEditing ? (
                <FormTextField
                  name="customer_logo"
                  label=""
                  TextFieldProps={{ type: 'file' }}
                  readonly={!isEditing}
                  showHelp={true}
                />
              ) : (
                <div style={{ marginBottom: '16px', width: '100%', textAlign: 'left' }}>
                  <img
                    src={
                      customer.customer_logo_url
                        ? customer.customer_logo_url
                        : 'https://www.mostdigital.fi/wp-content/uploads/2019/03/most-digital-logo.png'
                    }
                    width="50px"
                    alt="logo"
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="customer_status" label={t('customer-status')} readonly={true} showHelp={true}>
                {statusValues.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
              <FormFieldSet legend="Generated ID:s">
                <FormTextDisplay label={t('customer-guid')} value={customer.customer_guid} />
                <FormTextDisplay label={t('lowercase-customer-name')} value={customer.lowercase_customer_name} />
                <FormTextDisplay label={t('tech-customer-name')} value={customer.tech_customer_name} />
                <FormTextDisplay label={t('short-customer-name')} value={customer.short_customer_name} />
                <FormTextDisplay
                  label={t('short-lowercase-customer-name')}
                  value={customer.short_lowercase_customer_name}
                />
              </FormFieldSet>
            </Grid>
          </Grid>
        )}
      />
    </>
  );
};

export default Customer;
