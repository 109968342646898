import React from 'react';
import ChartWrapperNoDataError from '../Common/ReusableComponents/ChartWrapper/ChartWrapperComponents/ChartWrapperNoDataError';
import { ICumulativeConsumptionChartProps } from './ChartProps';
import ConsumptionChartTooltip from './Tooltips/ConsumptionChartTooltip';
import { useTranslation } from 'react-i18next';
import { Legend, XAxis, YAxis, LineChart, Line, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';

const CumulativeConsumptionChart = (props: ICumulativeConsumptionChartProps) => {
  const { data, isLoading } = props; // Keys: "date", "cost", "cumulative"
  const { t } = useTranslation();

  if (!isLoading && (!Array.isArray(data) || !data.length)) {
    return <ChartWrapperNoDataError />;
  }

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis hide dataKey="date" />
        <YAxis unit="€" />
        <Tooltip content={<ConsumptionChartTooltip />} animationDuration={500} />
        <Legend />
        <Line name={t('label-cumulative-costs')} type="monotone" dataKey="cumulative" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CumulativeConsumptionChart;
