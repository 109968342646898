import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getSelectedCustomerName, getSelectedTaskName } from '../../redux/reducers';
import { IState } from '../../types/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: theme.palette.primary.contrastText,
      display: 'flex',
      alignItems: 'center',
    },
    link: { maxWidth: '5rem' },
    linkImage: {
      minWidth: '50px',
      paddingLeft: '20px',
    },
    header: {
      color: 'inherit',
      padding: theme.spacing(1),
    },
  }),
);

const LogoBar = () => {
  let customerName = useSelector<IState, string>(getSelectedCustomerName, shallowEqual);
  const taskName = useSelector<IState, string>(getSelectedTaskName, shallowEqual);
  const classes = useStyles();

  if (customerName === 'Erto' || customerName === 'ERTO') {
    customerName = 'ETTK';
  }

  return (
    <div className={classes.container}>
      <Link className={classes.link} to={'/'}>
        <img
          className={classes.linkImage}
          alt="logo"
          src={require('../../Assets/MostLogo.png')}
          style={{ width: '130px', paddingTop: '10px', paddingRight: '62px' }}
        />
      </Link>
      <Typography className={classes.header} variant="h2">
        {`${customerName} ${!!taskName ? ' - ' : ''} ${taskName}`}
      </Typography>
    </div>
  );
};

export default LogoBar;
