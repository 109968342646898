import React, { useState } from 'react';
import { EditCancelSave } from '../../FormButtonGroups';
import { FormFieldSet } from '../../FormFields';
import { ITask } from '../../../types/types';

interface IProps {
  task: ITask;
}

type Props = IProps;

const Jira: React.FC<Props> = (props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <>
      <FormFieldSet legend="Jira">
        <div style={{ minHeight: '300px', width: '100%' }}></div>
        <EditCancelSave
          disabled={false}
          isEditing={isEditing}
          onEdit={() => setIsEditing(true)}
          onCancel={() => null}
        />
      </FormFieldSet>
    </>
  );
};

export default Jira;
