import { fetchEventsActionCreator } from '../actions/event';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IDictionary, INormalizedBase, IEvent, IEventState } from '../../types/types';

import { Success } from 'typescript-fsa';
import { combineReducers } from 'redux';

const initialState: IEventState = {
  byId: {},
  ids: [],
};

const byIdHandler = (byId: IDictionary<IEvent>, payload: Success<string, INormalizedBase<IEvent, string>>) => {
  if (payload.result.byId) {
    return { ...byId, ...payload.result.byId };
  }
  return byId;
};

const byId = reducerWithInitialState<{}>(initialState.byId).cases([fetchEventsActionCreator.done], byIdHandler);

const idsHandler = (ids: string[], payload: Success<string, INormalizedBase<IEvent, string>>) => {
  if (payload.result.ids && payload.result.ids.length > 0) {
    return Array.from(new Set(ids.concat(payload.result.ids)));
  }
  return ids;
};

const ids = reducerWithInitialState<string[]>(initialState.ids).cases([fetchEventsActionCreator.done], idsHandler);

export default combineReducers({
  byId,
  ids,
});

export const getAllEvents: (state: IEventState, customerId: string) => IEvent[] = (state, customerId) => {
  return state.ids.map((id) => state.byId[id]).filter((t) => t.customer_guid === customerId);
};
