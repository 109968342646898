import React from 'react';
import NavBar from './Nav';
import TabBar from './Nav/TabBar';

const PlayGround = () => {
  return (
    <>
      <NavBar></NavBar>
      <TabBar></TabBar>
    </>
  );
};

export default PlayGround;
