import { useQuery } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf, newApiConf } from '../../../../configs/apiConfig';

const CheckUserCustomerPermissions = (customerId: string, serviceproviderId: string) => {
  return useQuery(
    'checkUserCustomerPermissions',
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(newApiConf.apiUrl + `/permissions/customer/${customerId}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};
export default CheckUserCustomerPermissions;
