import { Grid } from '@material-ui/core';
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormFieldArray, FormTextArea, FormTextField } from '../../FormFields';
import { getCollectionMembers, getPortalLanguages, isActionPending } from '../../../redux/reducers';
import { updateCollectionMemberApiRequest, UPDATE_COLLECTION_MEMBER } from '../../../redux/actions/collection';
import { ICollectionMember, ICollectionMemberUpdate, ICollectionValue, ILanguage, IState } from '../../../types/types';
import LanguageArrayItem from './LanguageArrayItem';
import AddCollectionMember from './Collections/AddCollectionMember';

interface IProps {
  collectionId: number;
  isMultilanguage: boolean;
}

interface IFormObject extends Omit<ICollectionMember, 'values'> {
  values: IFormObjectValue[];
}

interface IFormObjectValue extends ICollectionValue {
  label: string;
}

const validationSchema = yup.object<IFormObject>({
  id: yup.string(),
  description: yup.string(),
  deleted: yup.boolean(),
  values: yup.array<IFormObjectValue>(),
});

const getAddComponent: (
  collectionId: number,
  isMultilanguage: boolean,
) => (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (collectionId, isMultilanguage) => {
  return (props) => {
    return (
      <AddCollectionMember
        collectionId={collectionId}
        isMultilanguage={isMultilanguage}
        data={props.values}
        onCancel={props.onCancel}
      />
    );
  };
};

type Props = IProps;

const CollectionValues: React.FC<Props> = (props) => {
  const { collectionId, isMultilanguage } = props;

  const languages = useSelector<IState, ILanguage[]>(getPortalLanguages, shallowEqual);

  const data = useSelector<IState, ICollectionMember[]>(
    (state: IState) => getCollectionMembers(state, collectionId),
    shallowEqual,
  );

  const isUpdating = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, UPDATE_COLLECTION_MEMBER),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const formData = data.map((d) => {
    const itemValues = isMultilanguage
      ? languages.map((language, index) => {
          const value = d.values.find((v) => v.language_code === language.code);
          return {
            value: value?.value || '',
            language_code: value?.language_code || language.code,
            label: `Value text - ${language.name}`,
          };
        })
      : d.values.map((v) => {
          return {
            value: v.value,
            language_code: v.language_code,
            label: 'Value text',
          };
        });

    return {
      id: d.id,
      description: d.description,
      deleted: d.deleted,
      values: itemValues,
    };
  });

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    return formData.find((d) => d.id === id);
  };

  const handleSubmit: (data: IFormObject) => void = useCallback(
    (data) => {
      const payload: ICollectionMemberUpdate = {
        collectionId,
        id: data.id,
        description: data.description,
        values: data.values.map((v) => {
          return {
            value: v.value,
            language_code: v.language_code,
          };
        }),
      };

      dispatch(updateCollectionMemberApiRequest(collectionId, data.id, payload));
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <SplitFormFrame<IFormObject>
        tableLabel="Collection Values"
        formLabel="Value"
        validationSchema={validationSchema}
        data={formData}
        idProperty="id"
        properties={[
          { key: 'id', label: 'Value ID' },
          { key: 'description', label: 'Description' },
          { key: 'deleted', label: 'Status' },
        ]}
        isUpdating={isUpdating}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="id" label="Value ID:" readonly={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea name="description" label="Description" readonly={!isEditing} />
            </Grid>
            <Grid item xs={12}>
              <FormFieldArray name="values">
                {values.values.map((value, index) => (
                  <LanguageArrayItem
                    key={index}
                    hiddenName={`values.${index}.language_code`}
                    hiddenValue={value.language_code}
                    name={`values.${index}.value`}
                    label={value.label}
                    readonly={!isEditing}
                  />
                ))}
              </FormFieldArray>
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent(collectionId, isMultilanguage)}
      ></SplitFormFrame>
    </>
  );
};

export default CollectionValues;
