import { TextField, Tooltip, IconButton, InputAdornment } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

import HelpIcon from '@material-ui/icons/Help';
import { useField } from 'formik';
import React, { useState } from 'react';

interface IProps {
  name: string;
  label: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  TextFieldProps?: TextFieldProps;
  showHelp?: boolean;
  className?: any;
}

const FormNumberField: React.FC<IProps> = (props) => {
  const { label, name, placeholder, readonly, required, TextFieldProps, showHelp, className } = props;
  const [field, meta] = useField(name);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    if (!!isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  field.value = field.value || 0;

  const errorText = meta.touched && meta.error && !readonly ? meta.error : '';

  let pageContent;

  if (!!showHelp) {
    pageContent = (
      <TextField
        {...field}
        {...TextFieldProps}
        label={label}
        placeholder={placeholder}
        error={!!errorText}
        helperText={errorText}
        required={required}
        disabled={!!readonly}
        variant="outlined"
        type="number"
        className={className}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="left"
                  onClose={handleTooltipClose}
                  open={isOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <React.Fragment>
                      <h3>Get help</h3>
                      <p>Help is on the way</p>
                    </React.Fragment>
                  }
                >
                  <IconButton>
                    <HelpIcon onClick={handleTooltipOpen} style={{ color: '#7eccff' }} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </InputAdornment>
          ),
        }}
      />
    );
  } else {
    pageContent = (
      <TextField
        {...field}
        {...TextFieldProps}
        label={label}
        placeholder={placeholder}
        error={!!errorText}
        helperText={errorText}
        required={required}
        disabled={!!readonly}
        variant="outlined"
        type="number"
        className={className}
      />
    );
  }

  return <>{pageContent}</>;
};

export default FormNumberField;
