/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { IDataNode } from '../../../../types/types';

const ContentViewContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: ${({ isOpen }) => (isOpen ? '30%' : '30px')};
  min-width: ${({ isOpen }) => (isOpen ? '250px' : '30px')};
  max-height: ${({ isOpen }) => (isOpen ? 'calc(100% - 80px)' : '30px')};
  top: 180px;
  right: 35px;
  box-sizing: border-box;
  background-color: #eeeeee;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  // border: 1px dotted gray;
  padding: 15px 25px 35px 25px;

  user-select: none;
  overflow: ${({ isOpen }) => (isOpen ? 'auto' : 'hidden')};
  border-radius: ${({ isOpen }) => (isOpen ? '0px' : '40px')};
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const DetailsContainer = styled.div<{ isOpen: boolean }>`
  color: #eeeeee;
  background-color: #888888;
  margin: 10px -25px -35px -25px;
  padding: 10px 25px 35px 25px;
`;

const ShowHideButton = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 16px;
  cursor: pointer;
  color: #444444;
  &:hover * {
    color: #666666;
  }
  ${({ isOpen }) => (isOpen ? '' : 'transform: rotate(180deg)')};
`;

const BlockDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IProps {
  dataNode: IDataNode;
  onNodeFieldChange: (id: string, field: string, value: string | boolean) => void;
}

export default class TreeView extends React.Component<IProps> {
  // static propTypes = {
  //   dataNode: propTypes.object,
  //   onNodeFieldChange: propTypes.func.isRequired,
  // };

  static defaultProps = {
    dataNode: {},
  };

  state = {
    isOpen: true,
    isDetailsOpen: false,
  };

  render() {
    const { dataNode, onNodeFieldChange } = this.props;
    const { isOpen, isDetailsOpen } = this.state;
    const uniqueId = dataNode.uniqueId;

    return (
      <ContentViewContainer isOpen={isOpen}>
        <ShowHideButton isOpen={isOpen} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
          <FontAwesomeIcon icon="chevron-circle-up" />
        </ShowHideButton>
        {isOpen && (
          <BlockDiv>
            <TextField
              id="name"
              key="name"
              label="Node Name"
              value={dataNode.name || ''}
              onChange={(event) => onNodeFieldChange(uniqueId, 'name', event.target.value)}
              margin="dense"
              variant="outlined"
            />

            <TextField
              id="description"
              key="description"
              label="Node Description"
              multiline
              rows={4}
              value={dataNode.description || ''}
              onChange={(event) => onNodeFieldChange(uniqueId, 'description', event.target.value)}
              margin="dense"
              variant="outlined"
            />
            {/* <TextField
              id="value"
              key="value"
              label="value"
              value={dataNode.value || ''}
              onChange={(event) => onNodeFieldChange(uniqueId, 'value', event.target.value)}
              margin="normal"
            /> */}
            <TextField
              id="status"
              key="status"
              label="Node Status"
              value={dataNode.status || ''}
              disabled={true}
              margin="dense"
              variant="outlined"
            />
            <TextField
              id="type"
              key="type"
              label="Node Type"
              select
              value={dataNode.type || 0}
              SelectProps={{
                native: true,
              }}
              margin="dense"
              variant="outlined"
            >
              <option value="0">Text</option>
              <option value="1">Numeric</option>
              <option value="2">Date</option>
              <option value="3">Boolean</option>
              <option value="4">Loop</option>
              <option value="5">File</option>
            </TextField>
            <TextField
              id="uniqueId"
              key="uniqueId"
              label="Node Technical ID"
              value={dataNode.uniqueId || ''}
              disabled={true}
              margin="dense"
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={dataNode.gdpr || false}
                  onChange={(event, checked) => onNodeFieldChange(uniqueId, 'gdpr', checked)}
                />
              }
              label="GDPR-tieto"
            />
            <TextField
              id="kpi"
              key="kpi"
              label="KPI-tieto"
              value={dataNode.kpi || ''}
              onChange={(event) => onNodeFieldChange(uniqueId, 'kpi', event.target.value)}
              margin="dense"
              variant="outlined"
            />
          </BlockDiv>
        )}
        {/* <br />

        <DetailsContainer isOpen={isDetailsOpen}>
          <ShowHideButton
            isOpen={isDetailsOpen}
            onClick={() => this.setState({ isDetailsOpen: !this.state.isDetailsOpen })}
          >
            <FontAwesomeIcon icon="chevron-circle-up" />
          </ShowHideButton>
          {isDetailsOpen && (
            <BlockDiv>
              <TextField
                id="uniqueId"
                key="uniqueId"
                label="uniqueId"
                value={dataNode.uniqueId || ''}
                onChange={(event) => onNodeFieldChange(uniqueId, 'uniqueId', event.target.value)}
                margin="normal"
              />
              <StatusContainer>
                <Select
                  id="type"
                  key="type"
                  label="type"
                  value={dataNode.type || enums.nodeType.UNKNOWN}
                  onChange={(event) => onNodeFieldChange(uniqueId, 'type', String(event.target.value))}
                  margin="none"
                >
                  {Object.keys(enums.nodeType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  id="status"
                  key="status"
                  label="status"
                  value={dataNode.status || enums.nodeStatus.DRAFT}
                  onChange={(event) => onNodeFieldChange(uniqueId, 'status', String(event.target.value))}
                  margin="none"
                >
                  {Object.keys(enums.nodeStatus).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </StatusContainer>
            </BlockDiv>
          )}
        </DetailsContainer> */}

        {/*<div>JSON: {JSON.stringify(dataNode)}</div>*/}
      </ContentViewContainer>
    );
  }
}
