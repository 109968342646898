import { useMutation } from 'react-query';
import axios from 'axios';
import { apiUrl, apiVersion } from '../../../configs/apiConfig';
import { acquireSilentToken } from '../APIManagementQueries/acquireSilentToken';

/**
 * Workaround for making the schedules refetching to work
 * after adding a new schedule. Couldn't make it work via Redux so this just makes
 * the request with React Query instead.
 */
const AddSchedule = () => {
  return useMutation(async (payload: any) => {
    const bearerToken = await acquireSilentToken();
    return await axios.post(apiUrl + '/api/schedules', payload, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      params: {
        'api-version': apiVersion,
      },
    });
  });
};

export default AddSchedule;
