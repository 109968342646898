import { initializeUserActionCreator, logoutUserActionCreator, acquireTokenFailedActionCreator } from '../actions/user';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IUserState } from '../../types/types';

import { combineReducers } from 'redux';
import * as Msal from 'msal';

export const initialState: IUserState = {
  userName: '',
  loggingOut: false,
  acquireTokenFailed: false,
};

const initializeUserHandler = (username: string, payload: Msal.Account) => {
  if (!payload) {
    return '';
  }

  return payload.userName;
};

const userName = reducerWithInitialState<string>(initialState.userName)
  .cases([initializeUserActionCreator], initializeUserHandler)
  .case(logoutUserActionCreator, () => '');

const loggingOut = reducerWithInitialState<boolean>(initialState.loggingOut).case(logoutUserActionCreator, () => true);

const acquireTokenFailed = reducerWithInitialState<boolean>(initialState.acquireTokenFailed).case(
  acquireTokenFailedActionCreator,
  () => true,
);

export default combineReducers({
  userName,
  loggingOut,
  acquireTokenFailed,
});

export const getUserName: (state: IUserState) => string = (state) => {
  return state.userName;
};

export const getUserIsLoggingOut: (state: IUserState) => boolean = (state) => {
  return state.loggingOut;
};

export const getAcquireTokenFailed: (state: IUserState) => boolean = (state) => {
  return state.acquireTokenFailed;
};
