import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';
import { clearApiMessageList } from '../../redux/actions/apiMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      zIndex: 999,
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(5),
    },
    cardContainer: {
      zIndex: 999,
      position: 'absolute',
      bottom: theme.spacing(11),
      right: theme.spacing(5),
    },
    listCard: {
      padding: '0.8rem',
      marginBottom: '0.2rem',
      color: 'white',
      backgroundColor: '#f44336',
    },
    button: {
      right: 0,
    },
  }),
);

interface IComponentProps {
  apiMessages: string[];
}

const ToastMemo = (props: IComponentProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { apiMessages } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClear = () => {
    dispatch(clearApiMessageList());
  };

  const renderCard = () => {
    return (
      <Grid container direction="column">
        <Paper elevation={1} className={classes.cardContainer}>
          {apiMessages.map((msg: any, index) => (
            <Card className={classes.listCard} key={index}>
              <Typography color="textSecondary">
                {t('label-page')}: {msg.apiAddress}
              </Typography>
              <Typography>{msg.apiMessage}</Typography>
            </Card>
          ))}
          <Grid container item direction="row" justify="center">
            <Tooltip title={t('label-clear-list')}>
              <IconButton onClick={handleClear}>
                <ClearAllIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return (
    <>
      {!!open ? renderCard() : null}

      <Badge color="error" className={classes.badge} badgeContent={apiMessages.length}>
        <Fab onClick={handleClick}>
          <ErrorIcon />
        </Fab>
      </Badge>
    </>
  );
};

export default ToastMemo;
