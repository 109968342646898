import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { IPermission, IPermissionNew } from '../../../types/types';

import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { Grid } from '@material-ui/core';
import { FormTextArea, FormTextField } from '../../FormFields';
import AddPermission from './Permissions/AddPermission';
import AlertWindow from '../../Common/ReusableComponents/AlertWindow';
import RoleWindowLoadingScreen from './Roles/RoleWindowLoadingScreen';

/** API */
import GetAllPermissions from '../../../services/api/APIManagementQueries/Permissions/GetAllPermissions';
import CreatePermission from '../../../services/api/APIManagementQueries/Permissions/CreatePermission';
import DeletePermission from '../../../services/api/APIManagementQueries/Permissions/DeletePermission';
import UpdatePermission from '../../../services/api/APIManagementQueries/Permissions/UpdatePermission';

interface IProps {}

const validationSchema = yup.object<IPermission>({
  id: yup.number(),
  name: yup.string(),
  description: yup.string(),
});

const getAddComponent: (
  mutation: any,
  addRoleMutationFn: (permission: IPermissionNew) => void,
) => (props: { values?: any; onCancel: () => void }) => React.ReactNode = (mutation, addRoleMutationFn) => {
  return (props) => {
    return <AddPermission mutation={mutation} mutationFn={addRoleMutationFn} onCancel={props.onCancel} />;
  };
};

const PermissionManagement: React.FC<IProps> = (props) => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  /** Hooks */
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [severity, setSeverity] = useState<string>('success');

  /** useQuery */
  const {
    data,
    isLoading: permissionsAreLoading,
    refetch: refetchPermissions,
    isRefetching: permissionsAreRefetching,
    isError: permissionsIsError,
  } = GetAllPermissions(currentLanguage);

  /** useMutation */
  const createPermissionMutation = CreatePermission();
  const deletePermissionMutation = DeletePermission();
  const updatePermissionMutation = UpdatePermission();

  /** On failed API request, show display alert */
  useEffect(() => {
    if (permissionsIsError) {
      setSeverity('error');
      setMessage('label-error-loading-data-unknown');
      setDisplayAlert(true);
    }
  }, [permissionsIsError]);

  const getDataItem: (id: string | number) => any | undefined = (id) => {
    return data.find((d: any) => d.id === Number(id));
  };

  const updatePermission = (permission: IPermission) => {
    setIsUpdating(true);
    updatePermissionMutation.mutate(
      {
        id: permission.id,
        language_code: currentLanguage,
        name: permission.name,
        description: permission.description,
      },
      {
        onSuccess: () => {
          refetchPermissions();
          setMessage(t('message-permission-updated'));
          setSeverity('success');
        },
        onError: (error) => {
          setMessage(String(error));
          setSeverity('error');
        },
        onSettled: () => {
          setIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
  };

  const addPermission = (permission: IPermissionNew) => {
    setIsUpdating(true);
    createPermissionMutation.mutate(
      {
        name: permission.name,
        description_en: permission.description_en,
        description_fi: permission.description_fi,
      },
      {
        onSuccess: () => {
          refetchPermissions();
          setMessage(t('message-permission-added'));
          setSeverity('success');
        },
        onError: (error) => {
          setMessage(String(error));
          setSeverity('error');
        },
        onSettled: () => {
          setIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
  };

  const deletePermission = (id: number | string) => {
    setIsUpdating(true);
    deletePermissionMutation.mutate(
      { id: id },
      {
        onSuccess: () => {
          refetchPermissions();
          setMessage(t('message-permission-deleted'));
          setSeverity('success');
        },
        onError: (error) => {
          setMessage(String(error));
          setSeverity('error');
        },
        onSettled: () => {
          setIsUpdating(false);
          setDisplayAlert(true);
        },
      },
    );
  };

  if (!permissionsIsError && isUpdating) {
    return <RoleWindowLoadingScreen isLoading={isUpdating} />;
  }
  if (!permissionsIsError && (!!!data || permissionsAreLoading)) {
    return <RoleWindowLoadingScreen isLoading={permissionsAreLoading} />;
  }
  if (!permissionsIsError && permissionsAreRefetching) {
    return <RoleWindowLoadingScreen isLoading={permissionsAreRefetching} />;
  }

  return (
    <>
      <AlertWindow message={message} severity={severity} display={displayAlert} setDisplay={setDisplayAlert} />
      <SplitFormFrame<IPermission>
        data={data}
        validationSchema={validationSchema}
        fetchDataItem={getDataItem}
        tableLabel="Permissions"
        formLabel={t('label-permission')}
        isLoading={permissionsAreLoading}
        idProperty="id"
        onSubmit={updatePermission}
        onDelete={deletePermission}
        properties={[
          { key: 'name', label: t('label-permission-name') },
          { key: 'description', label: t('label-permission-description') },
        ]}
        buttonsDisabled={permissionsIsError}
        duplicateDisabled={true}
        rowsPerPage={!!data ? data.length : 0}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="name" label={t('label-permission-name')} readonly={!isEditing} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea name="description" label={t('label-permission-description')} readonly={!isEditing} />
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent(createPermissionMutation, addPermission)}
      ></SplitFormFrame>
    </>
  );
};

export default PermissionManagement;
