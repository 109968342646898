import { ITask, ITaskCreate } from '../../types/types';
import { createSlice, createAsyncThunk, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import apiRequest from '../../services/api/apiService';
import HttpMethod from '../../services/api/httpMethod';

const taskAdapter = createEntityAdapter<ITask>({
  selectId: (task) => task.task_guid,
});

const initialState = taskAdapter.getInitialState({
  selectedTask: '',
  status: '',
});

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    selectTask(state, action: PayloadAction<string>) {
      state.selectedTask = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTasks.fulfilled, (state, { payload }: PayloadAction<ITask[]>) => {
        taskAdapter.setAll(state, payload);
        state.status = '';
      })
      .addCase(createTask.pending, (state) => {
        state.status = 'submitting';
      })
      .addCase(createTask.fulfilled, (state, { payload }: PayloadAction<ITask>) => {
        taskAdapter.addOne(state, payload);
        state.status = '';
      })
      .addCase(updateTask.pending, (state) => {
        state.status = 'submitting';
      })
      .addCase(updateTask.fulfilled, (state, { payload }: PayloadAction<ITask>) => {
        taskAdapter.upsertOne(state, payload);
        state.status = '';
      })
      .addCase(deleteTask.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(deleteTask.fulfilled, (state, { payload }: PayloadAction<string>) => {
        taskAdapter.removeOne(state, payload);
        state.status = '';
      })
      .addDefaultCase((state) => {
        state.status = '';
      });
  },
});

export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async (customerId: string) => {
  const res = await apiRequest<any, ITask[]>({
    resource: `customers/${customerId}/tasks/`,
    method: HttpMethod.GET,
    apiVersion: 'pythonApi',
  });
  return res.data;
});

export const createTask = createAsyncThunk('tasks/createTask', async (payload: ITaskCreate) => {
  const customerId = payload.customer_guid;
  const res = await apiRequest<ITaskCreate, ITask>({
    resource: `customers/${customerId}/tasks/`,
    payload,
    method: HttpMethod.POST,
    apiVersion: 'pythonApi',
  });
  return res.data;
});

export const updateTask = createAsyncThunk('tasks/updateTask', async (payload: ITask) => {
  // ITaskUpdate does not have all fields needed for python api task update at the moment, hence payload type is ITask
  const res = await apiRequest<ITask, ITask>({
    resource: `customers/${payload.customer_guid}/tasks/${payload.task_guid}`,
    payload,
    method: HttpMethod.PUT,
    apiVersion: 'pythonApi',
  });

  return res.data;
});

export const deleteTask = createAsyncThunk('tasks/deleteTask', async (payload: ITask) => {
  await apiRequest<any, any>({
    resource: `customers/${payload.customer_guid}/tasks/${payload.task_guid}`,
    method: HttpMethod.DELETE,
    apiVersion: 'pythonApi',
  });
  return payload.task_guid;
});

export const { selectTask } = taskSlice.actions;
export default taskSlice.reducer;

const taskSelectors = taskAdapter.getSelectors((state: any) => state.tasks);

// Selector for getting customer's tasks from the state
export const getAllTasks = (state: any, customerId: string) => {
  return taskSelectors.selectAll(state).filter((task: ITask) => task.customer_guid === customerId);
};

export const getSelectedTask = (state: any) => {
  return taskSelectors.selectById(state, state.tasks.selectedTask);
};

export const selectTasksStatus = (state: any) => state.tasks.status;
