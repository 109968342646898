/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../../FormFrames/SplitFormFrame';
import { FormSelect, FormTextArea, FormTextField } from '../../../FormFields';
import { useGetApiEffect, usePutApiEffect } from '../../../Hooks/Hooks';

import { ICustomer } from '../../../../types/types';
import { useTranslation } from 'react-i18next';
import { useCollection } from '../../../Hooks/Collections';
import { ISubnet, IResourceUpdate } from './NetworkTypes';
import AddSubNet from './AddSubNet';

const functionId = '00000000-0000-0000-0000-000000000000';

interface IProps {
  customer: ICustomer;
}

interface IFormObject extends ISubnet {}

type Props = IProps;

const validationSchema = yup.object<IFormObject>({
  name: yup.string().required(),
  description: yup.string().nullable(),
  address_space: yup.string().required(),
  type: yup.string().required(),
});

const getAddComponent: (
  id: string,
  customerId: string,
) => (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (id, customerId) => {
  return (props) => {
    return <AddSubNet id={id} customerId={customerId} data={props.values} onCancel={props.onCancel} />;
  };
};

const SubNet: React.FC<Props> = (props) => {
  const { customer } = props;

  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const subnetTypes = useCollection(5);

  const [data, isLoading, isError, getData] = useGetApiEffect<ISubnet[]>(
    `api/resources/subnets?customerId=${customer.customer_guid}`,
    null,
  );

  const [response, isUpdating, isUpdateError, submitUpdate] = usePutApiEffect<IResourceUpdate, void>(
    `api/resources/environments`,
    null,
  );

  const getDataItem: (id: string | number) => ISubnet | undefined = (id) => {
    return data.find((d) => d.name === id);
  };

  const handleSubmit: (data: IFormObject) => void = (data) => {
    const payload: IResourceUpdate = {
      id: functionId,
      customer_id: customer.customer_guid,
      data: {
        resource_type: 'subnet',
        resource_data: [
          {
            resource_name: data.name,
            resource_description: data.description,
            resource_address_space: data.address_space,
            resource_type: data.type,
          },
        ],
      },
    };

    submitUpdate(payload);
  };

  return (
    <>
      <SplitFormFrame<IFormObject>
        tableLabel={t('network-label-subnet')}
        formLabel={t('label-subnet')}
        validationSchema={validationSchema}
        data={data}
        idProperty="name"
        properties={[
          { key: 'name', label: t('label-subnet-name') },
          { key: 'address_space', label: t('label-address-space') },
          { key: 'type', label: t('label-type') },
        ]}
        isLoading={isLoading}
        isUpdating={isUpdating}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="name" label={t('label-subnet-name')} readonly={!isEditing} />
              <FormTextField name="address_space" label={t('label-address-space')} readonly={!isEditing} />
              <FormSelect name="type" label={t('label-subnet-type')} readonly={!isEditing} value={values.type}>
                {subnetTypes.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextArea name="description" label={t('subnet-description')} readonly={!isEditing} />
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent(functionId, customer.customer_guid)}
      ></SplitFormFrame>
    </>
  );
};

export default SubNet;
