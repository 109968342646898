import React from 'react';
import { BarChart, Bar, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import FormatToNDecimals from '../../../../../Common/ReusableUtilityFunctions/FormatToNDecimals';

type ComponentProps = {
  data: any;
  legendLabel: string;
  dataKeys: string[];
  xAxisLabel?: string;
  fullscreenSize?: boolean;
};

const DBarChart = (props: ComponentProps) => {
  const { data, legendLabel, dataKeys, fullscreenSize } = props;
  const { t } = useTranslation();
  const colors = ['#8884d8', '#82ca9d', '#FFBB28', '#FF8042', '#0088FE', '#00C49F'];

  const validateLabels = (labels: string[]) => {
    var translations: JSX.Element[] = [];
    labels.forEach(function (value, i) {
      var translation: string | null = null;
      var addMinSuffix = true;
      if (value === 'totalCount') {
        translation = t('label-run-count');
        addMinSuffix = false;
      } else if (value === 'medianRuntime') {
        translation = t('label-median-runtime');
      } else if (value === 'averageRuntime') {
        translation = t('label-average-runtime');
      } else if (value === 'runtimeHighest') {
        translation = t('label-runtime-highest');
      } else if (value === 'runtimeLowest') {
        translation = t('label-runtime-lowest');
      } else if (value === 'duration') {
        translation = t('label-time-reserved');
      }
      translations.push(
        <Bar key={value} name={translation} dataKey={value} fill={colors[i]} unit={addMinSuffix ? ' min' : null} />,
      );
    });
    return translations;
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={!!fullscreenSize ? 594 : '100%'}>
        <BarChart
          width={400}
          height={260}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <XAxis dataKey="name" tick={false} tickLine={false} />
          <YAxis domain={[0, 'dataMax']} allowDecimals={false} unit={legendLabel} />
          <Tooltip cursor={{ fill: '#ebf4fb' }} formatter={FormatToNDecimals} />
          <Legend />
          {validateLabels(dataKeys)}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default DBarChart;
