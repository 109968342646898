import { combineReducers } from 'redux';
import moment from 'moment';
import { IEvent, ILanguage, ITimelineEvent, ITimelineResource, IState } from '../../types/types';
import { ISchedule } from '../../components/Forms/Tasks/Schedule/ScheduleTypes';
import appStateReducer, * as fromAppStateReducer from './appState';
import collectionReducer, * as fromCollectionReducer from './collection';
import customerReducer, * as fromCustomerReducer from './customer';
import taskReducer, * as fromTaskReducer from './task';
import eventReducer, * as fromEventReducer from './event';
import userReducer, * as fromUserReducer from './user';
import scheduleReducer, * as fromScheduleReducer from './schedule';
import roleReducer, * as fromRoleReducer from './role';
import apiMessageReducer, * as fromApiMessageReducer from './apiMessage';
import consumptionReducer, * as fronConsumptionReducer from './consumption';
import secretsReducer from './secrets';
import tasksReducer from './tasks';
var cronParser = require('cron-parser');

export default combineReducers<IState>({
  appState: appStateReducer,
  collection: collectionReducer,
  customer: customerReducer,
  task: taskReducer,
  event: eventReducer,
  user: userReducer,
  schedule: scheduleReducer,
  role: roleReducer,
  apiMessage: apiMessageReducer,
  consumption: consumptionReducer,
  secrets: secretsReducer,
  tasks: tasksReducer,
});

export const getAllConsumptions = (state: IState) => {
  return fronConsumptionReducer.getAllConsumptions(state.consumption);
};

export const isActionPending = (state: IState, type: string) => {
  return fromAppStateReducer.isActionPending(state.appState, type);
};

export const getIsAppInitialized = (state: IState) => {
  return fromAppStateReducer.getIsAppInitialized(state.appState);
};

export const getTimeZones = (state: IState) => {
  return ['Europe/Helsinki', 'Europe/Stockholm', 'Europe/London'];
};

export const getRoles = (state: IState) => {
  return fromRoleReducer.getRolesForScope(state.role, state.customer.selectedCustomer);
};

export const getAllCollections = (state: IState) => {
  return fromCollectionReducer.getAllCollections(state.collection);
};

export const getCollection = (state: IState, id: number) => {
  return fromCollectionReducer.getCollection(state.collection, id);
};

export const getCollectionMembers = (state: IState, id: number) => {
  return fromCollectionReducer.getCollectionMembers(state.collection, id);
};

export const getUserName = (state: IState) => {
  return fromUserReducer.getUserName(state.user);
};

export const getUserIsAuthenticated = (state: IState) => {
  return !!fromUserReducer.getUserName(state.user) && !fromUserReducer.getAcquireTokenFailed(state.user);
};

export const getUserIsLoggingOut = (state: IState) => {
  return fromUserReducer.getUserIsLoggingOut(state.user);
};

export const getSelectedCustomerId = (state: IState) => {
  return fromCustomerReducer.getSelectedCustomerId(state.customer);
};

export const getSelectedCustomerName = (state: IState) => {
  return fromCustomerReducer.getSelectedCustomerName(state.customer);
};

export const getAllCustomers = (state: IState) => {
  return fromCustomerReducer.getAllCustomers(state.customer);
};

export const getSelectedTaskId = (state: IState) => {
  return fromTaskReducer.getSelectedTaskId(state.task);
};

export const getSelectedTask = (state: IState) => {
  return fromTaskReducer.getSelectedTask(state.task);
};

export const getSelectedTaskName = (state: IState) => {
  return fromTaskReducer.getSelectedTaskName(state.task);
};

export const getAllTasks = (state: IState, customerId: string = '') => {
  return fromTaskReducer.getAllTasks(state.task, customerId);
};

export const getAllEvents = (state: IState, customerId: string) => {
  return fromEventReducer.getAllEvents(state.event, customerId);
};

export const getAllApiMessages = (state: any) => {
  return fromApiMessageReducer.getAllApiMessages(state.apiMessage);
};

export const getPortalLanguages: (state: IState) => ILanguage[] = (state) => {
  const languageCollectionMembers = fromCollectionReducer.getCollectionMembers(state.collection, 1);

  return languageCollectionMembers.map((m) => {
    return {
      name: m.values[0].value,
      code: m.id,
    };
  });
};

export const getAllSchedules = (state: IState, customerId: string) => {
  return fromScheduleReducer.getAllSchedules(state.schedule, customerId);
};

export const getSchedulesForTask = (state: IState, taskId: string) => {
  return fromScheduleReducer.getSchedulesForTask(state.schedule, taskId);
};

export const getTimelineResources: (state: IState, customerId: string) => ITimelineResource[] = (state, customerId) => {
  const events = fromEventReducer.getAllEvents(state.event, customerId);
  const schedules = fromScheduleReducer.getAllSchedules(state.schedule, customerId);
  const eventResources = events.reduce((p: Map<string, ITimelineResource>, c: IEvent) => {
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }
    if (!p.has(c.vm_name)) {
      p.set(c.vm_name, { id: c.vm_name, name: c.vm_name });
    }
    if (!p.has(`${c.vm_name}-${c.task_guid}`)) {
      p.set(`${c.vm_name}-${c.task_guid}`, {
        id: `${c.vm_name}-${c.task_guid}`,
        name: c.task_name,
        parentId: c.vm_name,
      });
    }
    return p;
  }, new Map<string, ITimelineResource>());
  const scheduleResources = schedules.reduce((p: Map<string, ITimelineResource>, c: ISchedule) => {
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }
    if (!p.has(c.vm_name)) {
      p.set(c.vm_name, { id: c.vm_name, name: c.vm_name });
    }

    if (!p.has(`${c.vm_name}-${c.task_id}`)) {
      p.set(`${c.vm_name}-${c.task_id}`, {
        id: `${c.vm_name}-${c.task_id}`,
        name: c.task_name,
        parentId: c.vm_name,
      });
    }
    return p;
  }, eventResources);
  return Array.from(scheduleResources.values());
};

export const getTimelineEvents: (
  state: IState,
  customerId: string,
  startDate: Date,
  endDate: Date,
  includeScheduled?: boolean,
) => ITimelineEvent[] = (state, customerId, startDate, endDate, includeScheduled) => {
  const events = fromEventReducer.getAllEvents(state.event, customerId);

  const start = startDate.getTime();
  const end = endDate.getTime();

  const now = new Date().getTime();

  const actual = events.reduce((p: ITimelineEvent[], c: IEvent) => {
    const plannedStartTime = new Date(c.scheduled_start_time);
    const plannedEndTime = c.scheduled_stop_time
      ? new Date(c.scheduled_stop_time)
      : moment(c.scheduled_start_time).add(1, 'minutes').toDate();

    const viewStart = c.actual_start_time
      ? plannedStartTime > new Date(c.actual_start_time)
        ? new Date(c.actual_start_time)
        : plannedStartTime
      : plannedStartTime;

    const viewEnd = c.actual_stop_time
      ? plannedEndTime > new Date(c.actual_stop_time)
        ? plannedEndTime
        : new Date(c.actual_stop_time)
      : plannedEndTime;

    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }

    if (plannedStartTime.getTime() >= start && plannedStartTime.getTime() < end) {
      p.push({
        id: c.event_guid,
        resourceId: `${c.vm_name}-${c.task_guid}`,
        vmId: c.vm_name,
        taskId: c.task_guid,
        title: '',
        start: viewStart.toISOString(),
        end: viewEnd.toISOString(),
        errorCount: c.error_count,
        status: c.status,
        scheduled_start_time: plannedStartTime,
        scheduled_stop_time: plannedEndTime,
        actual_start_time: c.actual_start_time ? new Date(c.actual_start_time) : undefined,
        actual_stop_time: c.actual_stop_time
          ? new Date(c.actual_stop_time)
          : c.actual_start_time
          ? new Date(c.actual_start_time)
          : undefined,
        type: 'actual',
      });
    }

    return p;
  }, []);

  const scheduled = includeScheduled && end > now ? getEventsFromSchedule(state, customerId, endDate) : [];

  return actual.concat(scheduled).sort((a, b) => a.scheduled_start_time.getTime() - b.scheduled_start_time.getTime());
};

const getEventsFromSchedule: (state: IState, customerId: string, endDate: Date) => ITimelineEvent[] = (
  state,
  customerId,
  endDate,
) => {
  const schedules = fromScheduleReducer.getAllSchedules(state.schedule, customerId);
  const now = new Date();
  const start = now.getTime();
  const end = endDate.getTime();
  const result = schedules.reduce((p: ITimelineEvent[], c: ISchedule) => {
    if (!c.vm_name) {
      c.vm_name = 'Other environment';
    }
    if (c.daily_recurrence) {
      let scheduleStart = new Date(c.start_time);
      let scheduleEnd = new Date(c.end_time);
      while (scheduleStart.getTime() <= end) {
        if (scheduleStart.getTime() >= start && scheduleStart.getTime() <= end) {
          p.push({
            id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${scheduleStart.getTime()}`,
            resourceId: `${c.vm_name}-${c.task_id}`,
            vmId: c.vm_name,
            taskId: c.task_id,
            title: '',
            start: scheduleStart.toISOString(),
            end: scheduleEnd.toISOString(),
            errorCount: 0,
            status: 0,
            scheduled_start_time: scheduleStart,
            scheduled_stop_time: scheduleEnd,
            type: 'scheduled',
          });
        }
        scheduleStart = moment(scheduleStart).add(c.daily_recurrence.frequency, 'days').toDate();
        scheduleEnd = moment(scheduleEnd).add(c.daily_recurrence.frequency, 'days').toDate();
      }
    }

    if (c.weekly_recurrence) {
      let scheduleStart = new Date(c.start_time);
      let scheduleEnd = new Date(c.end_time);

      let weekday = scheduleStart.getDay();

      if (weekday === 0) {
        weekday = 7;
      }
      scheduleStart = moment(scheduleStart)
        .add(1 - weekday, 'days')
        .toDate();

      scheduleEnd = moment(scheduleEnd)
        .add(1 - weekday, 'days')
        .toDate();

      while (scheduleStart.getTime() <= end) {
        for (let i = 0; i < 7; i++) {
          const process =
            (i === 0 && c.weekly_recurrence.runs_monday) ||
            (i === 1 && c.weekly_recurrence.runs_tuesday) ||
            (i === 2 && c.weekly_recurrence.runs_wednesday) ||
            (i === 3 && c.weekly_recurrence.runs_thursday) ||
            (i === 4 && c.weekly_recurrence.runs_friday) ||
            (i === 5 && c.weekly_recurrence.runs_saturday) ||
            (i === 6 && c.weekly_recurrence.runs_sunday);

          const currentStart = moment(scheduleStart).add(i, 'days').toDate();
          const currentEnd = moment(scheduleEnd).add(i, 'days').toDate();
          if (process && currentStart.getTime() >= start && currentStart.getTime() <= end) {
            p.push({
              id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${currentStart.getTime()}`,
              resourceId: `${c.vm_name}-${c.task_id}`,
              vmId: c.vm_name,
              taskId: c.task_id,
              title: '',
              start: currentStart.toISOString(),
              end: currentEnd.toISOString(),
              errorCount: 0,
              status: 0,
              scheduled_start_time: currentStart,
              scheduled_stop_time: currentEnd,
              type: 'scheduled',
            });
          }
        }
        scheduleStart = moment(scheduleStart).add(c.weekly_recurrence.frequency, 'weeks').toDate();
        scheduleEnd = moment(scheduleEnd).add(c.weekly_recurrence.frequency, 'weeks').toDate();
      }
    }

    if (c.monthly_recurrence) {
      const scheduleStart = new Date(c.start_time);
      const scheduleEnd = new Date(c.end_time);
      const nextRunStart = c.next_run ? new Date(c.next_run) : null;
      const nextRunEnd = nextRunStart
        ? new Date(nextRunStart.getTime() + (scheduleEnd.getTime() - scheduleStart.getTime()))
        : null;
      const startMonth = scheduleStart.getMonth();
      const endMonth = endDate.getMonth();
      let months = endMonth - startMonth;

      // DOCK-790: Take into account the difference between the event's start year and the currently selected end year
      const startYear = scheduleStart.getFullYear();
      const endYear = endDate.getFullYear();
      let yearDiff = endYear - startYear;
      while (yearDiff > 0) {
        months += 12;
        yearDiff--;
      }

      for (let i = 0; i <= months; i += c.monthly_recurrence.frequency) {
        const currentMonth = startMonth + i;
        let currentStart = moment(scheduleStart).set('month', currentMonth).toDate();
        let currentEnd = moment(scheduleEnd).set('month', currentMonth).toDate();
        if (i > 0) {
          // DOCK-943: Use the day from the `Recur` rule for occasions other than the initial schedule
          if (!!nextRunStart && !!nextRunEnd) {
            currentStart = moment(nextRunStart).set('month', currentMonth).toDate();
            currentEnd = moment(nextRunEnd).set('month', currentMonth).toDate();
          }
        }
        p.push({
          id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${currentStart.getTime()}`,
          resourceId: `${c.vm_name}-${c.task_id}`,
          vmId: c.vm_name,
          taskId: c.task_id,
          title: '',
          start: currentStart.toISOString(),
          end: currentEnd.toISOString(),
          errorCount: 0,
          status: 0,
          scheduled_start_time: currentStart,
          scheduled_stop_time: currentEnd,
          type: 'scheduled',
        });
      }
    }

    if (c.cron_recurrence) {
      const scheduleStart = new Date(c.start_time);
      const now = new Date();
      var options = {
        currentDate: now < scheduleStart ? scheduleStart : now,
        endDate: endDate,
        iterator: true,
      };
      const interval = cronParser.parseExpression(c.cron_recurrence.cron_string, options);
      const duration = c.cron_recurrence.duration;
      while (interval.hasNext()) {
        var nextRun = interval.next();
        p.push({
          id: `${c.vm_name}-${c.task_id}-${c.schedule_id}-${nextRun.value.getTime()}`,
          resourceId: `${c.vm_name}-${c.task_id}`,
          vmId: c.vm_name,
          taskId: c.task_id,
          title: '',
          start: nextRun.value.toISOString(),
          end: moment(nextRun.value.toDate()).add(duration, 'minutes').toDate().toISOString(),
          errorCount: 0,
          status: 0,
          scheduled_start_time: nextRun.value.toDate(),
          scheduled_stop_time: moment(nextRun.value.toDate()).add(duration, 'minutes').toDate(),
          type: 'scheduled',
        });
      }
    }

    return p;
  }, []);

  return result;
};
