/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isActionPending } from '../../../redux/reducers';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from '../../FormFrames/FormFrame';
import { FormCheckbox, FormFieldSet, FormSelect, FormTextField, FormTextArea, FormTextDisplay } from '../../FormFields';
import { ITask, IState, ITaskUpdate } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { useCollection } from '../../Hooks/Collections';
import MDButton from '../../Common/ReusableComponents/MDButton';
import { selectTasksStatus, updateTask } from '../../../redux/reducers/tasks';
import { usePostApiEffect } from '../../Hooks/Hooks';
import { useAppDispatch } from '../../Hooks/StoreHooks';
import StartTaskDialog from './Task/StartTaskDialog';
import { toast } from 'react-toastify';
import { createApiMessage } from '../../../redux/actions/apiMessage';

interface IProps {
  task: ITask;
  customerId: string;
}

interface IFormObject extends Omit<ITaskUpdate, 'task_guid' | 'vm_names' | 'priority'> {
  priority: string | undefined;
}

const validationSchema = yup.object<IFormObject>({
  task_name: yup.string().required(),
  task_description: yup.string().nullable(),
  task_type: yup.string().nullable(),
  screenshots_allowed: yup.boolean().nullable(),
  recording_allowed: yup.boolean().nullable(),
  priority: yup.string(),
  start_script_path: yup.string().nullable(),
  technical_contact_email: yup.string().email().nullable(),
  business_contact_email: yup.string().email().nullable(),
  customer_email: yup.string().email().nullable(),
  customer_error_email: yup.string().email().nullable(),
});

type Props = IProps;

const BasicInfo: React.FC<Props> = (props) => {
  const { task, customerId } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  const [open, setOpen] = useState<boolean>(false);

  const statusValues = useCollection(7);
  const taskTypes = useCollection(11);
  const taskPriority = useCollection(12);

  const isSubmitting = useSelector((state) => selectTasksStatus(state)) === 'submitting';

  const [response, isLoading, isError, postData] = usePostApiEffect<any, void>('api/resources/environments', null);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isSubmitting);
    }
    if (isSubmitting === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStartNow = (duration: number, vm: string) => {
    const payload = {
      id: 'b0193b48-b950-11eb-98bb-e45e37c85d51',
      customer_id: customerId,
      data: {
        resource_type: 'vm',
        resource_data: [
          {
            resource_name: vm,
            resource_operation: 'run-now',
            task_guid: task.task_guid,
            event_duration: duration,
          },
        ],
      },
    };
    postData(payload);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSubmit: (
    data: IFormObject,
    setIsSubmitting: SetIsSubmittingFn,
    setStopEditing: SetStopEditingFn,
  ) => void = useCallback(
    (data, setIsSubmitting, setStopEditing) => {
      setIsSubmittingFn(() => setIsSubmitting);
      setStopEditingFn(() => setStopEditing);
      const payload: ITaskUpdate = {
        ...data,
        task_guid: task.task_guid,
        vm_names: task.vm_names,
        task_type: data.task_type ?? null,
        priority:
          data.priority === taskPriority[1].value
            ? 1
            : data.priority === taskPriority[2].value
            ? 2
            : data.priority === taskPriority[0].value
            ? 3
            : null,
      };

      const updatePayload: ITask = {
        ...task,
        ...payload,
      };

      dispatch(updateTask(updatePayload))
        .unwrap()
        .then(() => {
          toast.success('Task updated!');
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(createApiMessage(`customers/${customerId}/tasks/`, err.message));
        });
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const data: IFormObject = task && {
    task_name: task.task_name,
    task_description: task.task_description,
    task_type: task.task_type,
    screenshots_allowed: task.screenshots_allowed,
    recording_allowed: task.recording_allowed,
    priority:
      task.priority === 1
        ? taskPriority[1].value
        : task.priority === 2
        ? taskPriority[2].value
        : task.priority === 3
        ? taskPriority[0].value
        : undefined,
    start_script_path: task.start_script_path,
    technical_contact_email: task.technical_contact_email,
    business_contact_email: task.business_contact_email,
    customer_email: task.customer_email,
    customer_error_email: task.customer_error_email,
  };

  return (
    <>
      {!!open && (
        <StartTaskDialog open={open} handleClose={handleOpen} handleSubmit={handleStartNow} vms={task.vm_names} />
      )}
      <FormFrame<IFormObject>
        label={t('label-basic-info')}
        validationSchema={validationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormTextField name="task_name" label={t('label-task-name')} readonly={!isEditing} />
              <FormTextArea name="task_description" label={t('label-task-description')} readonly={!isEditing} />
              {isEditing ? (
                <FormTextField name="task_logo" label="" TextFieldProps={{ type: 'file' }} readonly={!isEditing} />
              ) : (
                <div style={{ marginBottom: '16px', width: '100%', textAlign: 'left' }}>
                  <img
                    src={
                      task.task_logo_url
                        ? task.task_logo_url
                        : 'https://www.mostdigital.fi/wp-content/uploads/2019/03/most-digital-logo.png'
                    }
                    width="50px"
                    alt="logo"
                  />
                </div>
              )}
              {/*
              <FormSelect name="task_type" label={t('label-task-type')} readonly={!isEditing} value={values.task_type}>
                {taskTypes.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>

                */}
              <FormSelect
                name="priority"
                label={t('label-task-priority')}
                readonly={!isEditing}
                value={values.priority}
              >
                {taskPriority.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
              <FormTextField name="start_script_path" label={t('label-script-path')} readonly={!isEditing} />
              <Grid item xs={12}>
                <MDButton label="Run now" handleClick={handleOpen} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSelect name="task_status" label={t('label-task-status')} readonly={true}>
                {statusValues.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </FormSelect>
              <FormFieldSet legend={t('label-generated-ids')}>
                <FormTextDisplay label={t('label-task-guid')} value={task.task_guid} />
              </FormFieldSet>
              <FormFieldSet legend={'label-permissions'}>
                <FormCheckbox name="screenshots_allowed" label={t('label-screenshots-allowed')} readonly={!isEditing} />
                <FormCheckbox
                  name="recording_allowed"
                  label={t('label-video-recording-allowed')}
                  readonly={!isEditing}
                />
              </FormFieldSet>
              <FormFieldSet legend={t('label-addresses')}>
                <FormTextField name="customer_email" label={t('label-customers-email')} readonly={!isEditing} />
                <FormTextField
                  name="technical_contact_email"
                  label={t('label-customers-technical-email')}
                  readonly={!isEditing}
                />
                <FormTextField
                  name="business_contact_email"
                  label={t('label-customers-business-email')}
                  readonly={!isEditing}
                />
                <FormTextField name="customer_error_email" label={t('label-error-email')} readonly={!isEditing} />
              </FormFieldSet>
            </Grid>
          </Grid>
        )}
      ></FormFrame>
    </>
  );
};

export default BasicInfo;
