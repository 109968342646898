import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import { Link, LinkOff } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { FormTextField } from '../../../FormFields';
import { ISecret } from '../../../../types/types';

const SecretLink = (props: { isEditing: boolean }) => {
  const { isEditing } = props;
  const [linkActive, setLinkActive] = useState<boolean>(false);

  const { values, setFieldValue } = useFormikContext<ISecret>();

  useEffect(() => {
    if (linkActive) {
      setFieldValue('test_value', values.production_value);
    }
  }, [values.production_value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (linkActive) {
      setFieldValue('production_value', values.test_value);
    }
  }, [values.test_value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleLink: () => void = () => {
    setLinkActive((prev) => !prev);
  };

  const { t } = useTranslation();

  return (
    <Grid item container spacing={0}>
      <Grid item xs={12} sm={5}>
        <FormTextField
          name="production_value"
          label={t('label-production-keyvault-value')}
          readonly={!isEditing}
          TextFieldProps={{ type: 'password' }}
        />
      </Grid>
      <Grid item xs={12} sm={2} container direction="column" justify="center" alignContent="center">
        <Grid item>
          <Button variant="contained" disabled={!isEditing} onClick={(e) => handleToggleLink()}>
            {linkActive ? <Link /> : <LinkOff />}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormTextField
          name="test_value"
          label={t('label-development-keyvault-value')}
          readonly={!isEditing}
          TextFieldProps={{ type: 'password' }}
        />
      </Grid>
    </Grid>
  );
};

export default SecretLink;
