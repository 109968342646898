// General
import React from 'react';
import { useTranslation } from 'react-i18next';

//MUI
import AspectRatio from '@mui/icons-material/AspectRatio';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MinimizeIcon from '@mui/icons-material/Minimize';

// ChartWrapper helpers
import { IChartBoxButtonsProps } from '../../ChartWrapperTypes';

/**
 * The buttons in ChartBox may be rendered conditionally. By default, the minimize & fullscreen buttons are rendered without any checks.
 *
 * If 'state' prop was provided
 *  - do not display the buttons during loading
 *  - display only minimize button if the data loading fails
 */
const ChartBoxButtons = (props: IChartBoxButtonsProps) => {
  const { state, onMinimize, onFullscreen, disableFullscreen } = props;
  const { t } = useTranslation();

  if (!!state) {
    if (state.isLoading) {
      return <></>;
    } else if (state.isError) {
      return (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title={t('label-minimize') as string}>
            <IconButton onClick={onMinimize}>
              <MinimizeIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
  }
  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title={t('label-minimize') as string}>
        <IconButton onClick={onMinimize}>
          <MinimizeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('label-fullscreen') as string}>
        {!disableFullscreen ? (
          <IconButton onClick={onFullscreen}>
            <AspectRatio />
          </IconButton>
        ) : (
          <></>
        )}
      </Tooltip>
    </Box>
  );
};

export default ChartBoxButtons;
