import { useQuery } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

/**
 * A query hook for fetching all the roles from the database.
 * https://react-query.tanstack.com/reference/useQuery
 * @param languageCode e.g. 'en-US' or 'fi-FI'
 * @returns useQuery hook
 */
const GetAllRoles = (languageCode: string) => {
  return useQuery(
    ['roleData', languageCode],
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(apimConf.apiManagementEndpoint + '/users-and-roles/get-all-roles', {
        headers: {
          'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          language_code: languageCode,
        },
      });
      return data;
    },
    {
      enabled: !!languageCode,
      cacheTime: 0,
    },
  );
};

export default GetAllRoles;
