import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormSelect, FormTextArea, FormTextField } from '../../../FormFields';
import { useCollection } from '../../../Hooks/Collections';
import SecretLink from './SecretLink';
import { ISecret } from '../../../../types/types';
import { useAppDispatch } from '../../../Hooks/StoreHooks';
import { createSecret, selectSecretsStatus } from '../../../../redux/reducers/secrets';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

interface IProps {
  data?: any;
  onCancel: () => void;
  customerId: string;
}

interface IFormObject extends ISecret {}

const validationSchema = yup.object<any>({
  // validates name to match azure keyvault's name constraints
  name: yup
    .string()
    .required()
    .matches(/^[A-Za-z0-9-]+$/, { message: 'Name can only contain a-z, A-Z, 0-9 and -' }),
  description: yup.string().nullable(),
  additional_info: yup.string().nullable(),
  production_value: yup.string().required(),
  test_value: yup.string().required(),
  type: yup.string(),
  status: yup.string().nullable(),
});

type Props = IProps;

const AddSecret: React.FC<Props> = (props) => {
  const { onCancel, customerId } = props;
  const { t } = useTranslation();
  const secretTypes = useCollection(9);
  const dispatch = useAppDispatch();
  const isLoading = useSelector(selectSecretsStatus) === 'submitting';

  const formData = {
    name: '',
    description: null,
    additional_info: null,
    production_value: '',
    test_value: '',
    type: secretTypes[0].id,
    status: '',
  };

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading, isSubmitting, isSubmittingFn]);

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = (data, setIsSubmitting) => {
    setIsSubmittingFn(() => setIsSubmitting);
    const payload = {
      name: data.name,
      type: data.type,
      production_value: data.production_value,
      test_value: data.test_value,
      description: data.description,
      additional_info: data.additional_info,
      status: null,
    };

    dispatch(createSecret({ customerId, payload }))
      .unwrap()
      .then(() => {
        toast.success('Secret added');
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  };

  return (
    <ModalFormFrame<IFormObject>
      label={t('label-add-new-secret')}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="name" label={t('label-secret-name')} readonly={isSubmitting} />
            <FormTextArea name="description" label={t('label-secret-description')} readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect name="type" label={t('label-secret-type')} readonly={isSubmitting}>
              {secretTypes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </FormSelect>
            <FormTextArea name="additional_info" label={t('label-additional-information')} readonly={isSubmitting} />
          </Grid>
          <SecretLink isEditing={!isSubmitting} />
        </Grid>
      )}
    />
  );
};

export default AddSecret;
