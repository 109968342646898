import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormatToNDecimals from '../../../../../Common/ReusableUtilityFunctions/FormatToNDecimals';

const calculateTotals = (data: any[]) => {
  let counts = {
    totalCount: 0,
    value: 0,
    duration: 0,
  };

  // Loop through the given dataset and count the totals
  // of columns that are summable.
  if (data[0].totalCount) {
    for (let row of data) {
      counts['totalCount'] += row['totalCount'];
    }
  } else if (data[0].value) {
    for (let row of data) {
      counts['value'] += row['value'];
    }
  } else if (data[0].duration) {
    for (let row of data) {
      counts['duration'] += row['duration'];
    }
  }
  return counts;
};

const renderTotalRow = (data: any[], dataKeys: string[], t: any) => {
  let counts = calculateTotals(data);
  let summableColumns: any[] = [];

  // If the currently iterated column in the dataset is a summable row
  // add it in the summableColumns
  for (let key of dataKeys) {
    if (key === 'totalCount' || key === 'value' || key === 'duration') {
      summableColumns.push({
        key: key,
        value: counts[key],
      });
    } else {
      summableColumns.push(null);
    }
  }

  // Explanation: First we check if there were any summable rows in the first place.
  // If there were none (all values are null), we don't render the sum row at all.
  // If summable rows exist: if the current cell that is being looped returns null,
  // just return an empty string inside the TableCell since it's a non-summable row.
  // When the loop hits a summable row, just return the value with the correct suffix.
  return summableColumns.every((element) => element === null) ? null : (
    <TableRow key="row_total">
      <TableCell key="total" align="left">
        {t('label-table-total')}
      </TableCell>
      {summableColumns.map((d, idx) => (
        <TableCell key={idx} align="left">
          {d === null ? '' : FormatToNDecimals(d['value'], 2) + renderMinSuffix(d['key'], d['value'])}
        </TableCell>
      ))}
    </TableRow>
  );
};

const renderMinSuffix = (key: string, value: any) => {
  // dataKeys names of columns where we want to add the 'min' suffix
  const needMinSuffix = [
    'averageRuntime',
    'medianRuntime',
    'runtimeHighest',
    'runtimeLowest',
    'duration',
    'actual',
    'scheduled',
  ];
  if (!needMinSuffix.includes(key) || typeof value != 'number') {
    return '';
  } else {
    return ' min';
  }
};

const getRelevantDatakeys = (dataValues: any[]) => {
  let dataKeys: any[] = [];
  for (const [key, value] of Object.entries(dataValues)) {
    if (typeof value != 'object' || value === null) {
      dataKeys.push(key);
    }
  }
  return dataKeys;
};

const getHeaderTranslations = (dataKeys: string[], t: any) => {
  var headerTranslations: string[] = [];
  for (let key of dataKeys) {
    if (key === 'averageRuntime') {
      headerTranslations.push(t('label-average-runtime'));
    } else if (key === 'medianRuntime') {
      headerTranslations.push(t('label-median-runtime'));
    } else if (key === 'runtimeHighest') {
      headerTranslations.push(t('label-runtime-highest'));
    } else if (key === 'runtimeLowest') {
      headerTranslations.push(t('label-runtime-lowest'));
    } else if (key === 'task_name') {
      headerTranslations.push(t('label-task-name'));
    } else if (key === 'name') {
      headerTranslations.push(t('label-stamp-name'));
    } else if (key === 'totalCount') {
      headerTranslations.push(t('label-run-count'));
    } else if (key === 'duration') {
      headerTranslations.push(t('label-runtimes'));
    } else if (key === 'timestampStart') {
      headerTranslations.push(t('label-timestamp-start'));
    } else if (key === 'timestampEnd') {
      headerTranslations.push(t('label-timestamp-end'));
    }
  }
  return headerTranslations;
};

export { renderTotalRow, getHeaderTranslations, getRelevantDatakeys, renderMinSuffix };
