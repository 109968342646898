/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import FormTable from '../../FormFrames/FormTable';
import FormFrame, { SetIsSubmittingFn } from '../../FormFrames/FormFrame';
import { FormFieldSet, FormSelect, FormTextDisplay, FormTextField, FormTextArea } from '../../FormFields';

import { TextField, Button } from '@material-ui/core';
import { useGetApiEffect } from '../../Hooks/Hooks';
import { ICustomer } from '../../../types/types';
import { useTranslation } from 'react-i18next';

interface IProps {
  customer: ICustomer;
}

interface IFormObject {
  id: string;
  name: string;
  description: string;
  team_logo: string;
}

const validationSchema = yup.object({
  id: yup.string(),
  name: yup.string().required(),
  description: yup.string(),
  team_logo: yup.string(),
});

type Props = IProps;

const Collaborations: React.FC<Props> = (props) => {
  const { customer } = props;
  const [data, isLoading, isError, getData] = useGetApiEffect<IFormObject>(
    `api/teams?customerId=${customer?.customer_guid}`,
    null,
  );

  const [selectedItem, setSelectedItem] = useState<string | number>('');
  const [selectedChannel, setSelectedChannel] = useState<string | number>('');

  const { t } = useTranslation();

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  const renderNewUserOptions = (isEditing: boolean) => {
    return (
      <FormFrame<IFormObject>
        label={'New user'}
        validationSchema={validationSchema}
        initialValues={null}
        onSubmit={handleSubmit}
        render={({ isEditing }) => (
          <>
            <Grid container>
              <Grid item xs={6}>
                <FormSelect name="User name" label="User name" readonly={!isEditing} showHelp={false}></FormSelect>
              </Grid>
              <Grid item xs={6}>
                <FormSelect name="Role" label="Role" readonly={!isEditing} showHelp={false}>
                  <option>Owner</option>
                  <option>Member</option>
                  <option>Guest</option>
                </FormSelect>
              </Grid>
            </Grid>
          </>
        )}
      />
    );
  };
  */

  const internalUsers = [
    { id: 0, name: 'Internal 1', role: 'Owner' },
    { id: 1, name: 'Internal 2', role: 'Member' },
    { id: 2, name: 'Internal 3', role: 'Member' },
  ];

  const externalUsers = [
    { id: 0, name: 'External 1', role: 'guest' },
    { id: 1, name: 'External 2', role: 'guest' },
    { id: 2, name: 'External 3', role: 'guest' },
  ];

  const channelList = [
    { id: 0, name: 'Channel 1' },
    { id: 1, name: 'Channel 2' },
    { id: 2, name: 'Channel 3' },
  ];

  const handleSubmit: (data: IFormObject) => void = (data) => {};

  const handleSelect = () => {};

  return (
    <>
      <FormFrame<IFormObject>
        label="Teams"
        validationSchema={validationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
        render={({ isEditing }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="name" label={t('label-teams-site-name')} readonly={!isEditing} />

                {isEditing ? (
                  <FormTextField name="team_logo" label="" TextFieldProps={{ type: 'file' }} readonly={!isEditing} />
                ) : (
                  <div style={{ marginBottom: '16px', width: '100%', textAlign: 'left' }}>
                    <img
                      src="https://www.mostdigital.fi/wp-content/uploads/2019/03/most-digital-logo.png"
                      width="50px"
                      alt="logo"
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextArea name="description" label={t('label-teams-site-description')} readonly={!isEditing} />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  value={selectedChannel}
                  select
                  SelectProps={{
                    native: true,
                    multiple: true,
                  }}
                  disabled={!isEditing}
                  variant="outlined"
                >
                  {channelList.map((channel) => (
                    <option key={channel.id} value={channel.name}>
                      {channel.name}
                    </option>
                  ))}
                </TextField>
                {!!isEditing ? <Button>Add new</Button> : null}
              </Grid>

              <Grid item xs={12}>
                <FormTable
                  label={t('label-internal-users')}
                  data={internalUsers}
                  properties={[
                    { key: 'name', label: t('label-user-name') },
                    { key: 'role', label: t('label-role') },
                  ]}
                  isLoading={false}
                  selectedId={selectedItem}
                  onSelectRow={handleSelect}
                  onDelete={() => null}
                  optionalButtons={!!isEditing}
                />
                <FormTable
                  label={t('label-external-users')}
                  data={externalUsers}
                  properties={[
                    { key: 'name', label: t('label-user-name') },
                    { key: 'role', label: t('label-role') },
                  ]}
                  isLoading={false}
                  selectedId={selectedItem}
                  onSelectRow={handleSelect}
                  onDelete={() => null}
                  optionalButtons={!!isEditing}
                />
              </Grid>
            </Grid>
          </>
        )}
      />
    </>
  );
};

export default Collaborations;
