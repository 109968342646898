/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import * as yup from 'yup';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormFieldArray, FormTextField } from '../../../FormFields';
import { usePostApiEffect } from '../../../Hooks/Hooks';
import { getPortalLanguages } from '../../../../redux/reducers';
import { ILanguage, IState, IText } from '../../../../types/types';
import LanguageArrayItem from '../LanguageArrayItem';

interface IProps {
  data?: IFormObject;
  onCancel: () => void;
}

interface IFormObject {
  id: string;
  values: IFormObjectValue[];
}

interface IFormObjectValue {
  value: string;
  language_code: string;
  label: string;
}

interface ITextCreate {
  id: string;
  values: ITextCreateValue[];
}

interface ITextCreateValue {
  value: string;
  language_code: string;
}

const validationSchema = yup.object<IFormObject>({
  id: yup.string(),
  values: yup.array<IFormObjectValue>(),
});

type Props = IProps;

const AddText: React.FC<Props> = (props) => {
  const { data, onCancel } = props;
  const languages = useSelector<IState, ILanguage[]>(getPortalLanguages, shallowEqual);

  const [payload, isLoading, isError, postData] = usePostApiEffect<ITextCreate, IText>('api/texts', null);

  const formData: IFormObject = data
    ? { ...data, id: '' }
    : {
        id: '',
        values: languages.map((language, index) => {
          return {
            value: '',
            language_code: language.code,
            label: `Value text - ${language.name}`,
          };
        }),
      };

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = (data, setIsSubmitting) => {
    setIsSubmittingFn(() => setIsSubmitting);

    const payload: ITextCreate = {
      id: data.id,
      values: data.values.map((v) => {
        return {
          value: v.value,
          language_code: v.language_code,
        };
      }),
    };

    postData(payload);
  };

  return (
    <ModalFormFrame<IFormObject>
      label="label-form-add-text"
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ values, isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="id" label="Text ID:" readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12}>
            <FormFieldArray name="values">
              {values.values.map((value, index) => (
                <LanguageArrayItem
                  key={index}
                  hiddenName={`values.${index}.language_code`}
                  hiddenValue={value.language_code}
                  name={`values.${index}.value`}
                  label={value.label}
                  readonly={isSubmitting}
                />
              ))}
            </FormFieldArray>
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddText;
