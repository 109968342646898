import React from 'react';
import { CartesianGrid, ResponsiveContainer, BarChart, Bar, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { useTranslation } from 'react-i18next';
import RuntimesChartTooltip from './Tooltips/RuntimesChartTooltip';
import ChartWrapperNoDataError from '../Common/ReusableComponents/ChartWrapper/ChartWrapperComponents/ChartWrapperNoDataError';

type IRuntimesChartProps = {
  data: { scheduled: number; actual: number }[];
};

const RuntimesChart = (props: IRuntimesChartProps) => {
  const { data } = props;
  const { t } = useTranslation();
  const colors = ['#FF8042', '#82ca9d']; // Orange and green
  if (!Array.isArray(data) || !data.length) {
    return <ChartWrapperNoDataError translationKey={'label-no-data'} />;
  }
  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis hide />
        <YAxis width={100} tick={{ fontSize: 12 }} unit={` ${t('label-minutes')}`} />
        <Tooltip content={<RuntimesChartTooltip />} />
        <Legend wrapperStyle={{ fontSize: '75%', margin: '-10px' }} />
        <Bar name={t('label-scheduled-runtime')} dataKey="scheduled" fill={colors[0]} unit={` ${t('label-minutes')}`} />
        <Bar name={t('label-actual-runtime')} dataKey="actual" fill={colors[1]} unit={` ${t('label-minutes')}`} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RuntimesChart;
