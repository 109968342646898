import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import FormFrame, { SetIsSubmittingFn, SetStopEditingFn } from '../../FormFrames/FormFrame';
import * as yup from 'yup';
import { FormCheckbox, FormTwinMultiSelect } from '../../FormFields';
import { useSelector, shallowEqual } from 'react-redux';
import { isActionPending } from '../../../redux/reducers';
import { ITask, IState, ITaskUpdate } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import ListVms from '../../../services/api/APIManagementQueries/Resources/ListVms';
import ListOnsiteVms from '../../../services/api/APIManagementQueries/Resources/ListOnsiteVms';
import { useAppDispatch } from '../../Hooks/StoreHooks';
import { selectTasksStatus, updateTask } from '../../../redux/reducers/tasks';
import { toast } from 'react-toastify';
import { createApiMessage } from '../../../redux/actions/apiMessage';

interface IProps {
  task: ITask;
}

interface IFormObject {
  dev_machine_names: string[];
  prod_machine_names: string[];
  onsite_machine_names?: string[];
  onsite: boolean;
}

const validationSchema = yup.object({
  dev_machine_names: yup.array(yup.string()),
  prod_machine_names: yup.array(yup.string()),
  onsite: yup.boolean(),
});

type Props = IProps;

const Environments: React.FC<Props> = (props) => {
  const { task } = props;
  const { t } = useTranslation();
  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [stopEditingFn, setStopEditingFn] = useState<SetStopEditingFn>();

  const { data: vmRegular, isLoading: isLoadingVmRegular } = ListVms(task.customer_guid);
  const { data: vmOnsite, isLoading: isLoadingVmOnsite } = ListOnsiteVms(task.customer_guid);

  const dispatch = useAppDispatch();

  const isSubmitting = useSelector((state) => selectTasksStatus(state)) === 'submitting';

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isSubmitting);
    }
    if (isSubmitting === false && stopEditingFn !== undefined) {
      stopEditingFn();
    }
  }, [isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const prodMachines: string[] = [];
  const devMachines: string[] = [];
  const onsiteMachines: string[] = [];
  const selectedProdMachines: string[] = [];
  const selectedDevMachines: string[] = [];
  const selectedOnsiteMachines: string[] = [];

  const filterRegularVms = () => {
    for (let i = 0; i < vmRegular.length; i++) {
      if (vmRegular[i].category === 'Development') {
        if (task.vm_names.find((item) => item === vmRegular[i].name)) {
          selectedDevMachines.push(vmRegular[i].name);
        }
        devMachines.push(vmRegular[i].name);
      } else if (vmRegular[i].category === 'Production') {
        if (task.vm_names.find((item) => item === vmRegular[i].name)) {
          selectedProdMachines.push(vmRegular[i].name);
        }
        prodMachines.push(vmRegular[i].name);
      }
    }
  };

  const filterOnsiteVms = () => {
    for (let i = 0; i < vmOnsite.length; i++) {
      if (task.vm_names.find((item) => item === vmOnsite[i].name)) {
        selectedOnsiteMachines.push(vmOnsite[i].name);
      }
      onsiteMachines.push(vmOnsite[i].name);
    }
  };

  if (!!vmRegular && !!vmOnsite) {
    filterRegularVms();
    filterOnsiteVms();
  } else if (!!vmRegular) {
    filterRegularVms();
  } else if (!!vmOnsite) {
    filterOnsiteVms();
  }

  const data: IFormObject = {
    dev_machine_names: selectedDevMachines,
    prod_machine_names: selectedProdMachines,
    onsite_machine_names: selectedOnsiteMachines,
    onsite: true,
  };

  const handleSubmit: (
    data: IFormObject,
    setIsSubmitting: SetIsSubmittingFn,
    setStopEditing: SetStopEditingFn,
  ) => void = useCallback(
    (data, setIsSubmitting, setStopEditing) => {
      setIsSubmittingFn(() => setIsSubmitting);
      setStopEditingFn(() => setStopEditing);
      let vmNames = data.dev_machine_names.concat(data.prod_machine_names);
      if (!!data.onsite_machine_names) {
        vmNames = vmNames.concat(data.onsite_machine_names);
      }
      const payload: ITask = {
        ...task,
        vm_names: vmNames,
      };

      dispatch(updateTask(payload))
        .unwrap()
        .then(() => {
          toast.success('Task updated!');
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(createApiMessage(`customers/${task.customer_guid}/tasks/`, err.message));
        });
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <FormFrame<IFormObject>
        label={t('title-environments')}
        validationSchema={validationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={0}>
            {'Development:'}
            <FormTwinMultiSelect
              name="dev_machine_names"
              label="Development"
              availableValues={devMachines}
              readonly={!isEditing}
              isLoading={isLoadingVmRegular || isLoadingVmOnsite}
            ></FormTwinMultiSelect>
            {'Production:'}
            <FormTwinMultiSelect
              name="prod_machine_names"
              label="Production"
              availableValues={prodMachines}
              readonly={!isEditing}
              isLoading={isLoadingVmRegular || isLoadingVmOnsite}
            ></FormTwinMultiSelect>
            {'Onsite:'}
            <FormTwinMultiSelect
              name="onsite_machine_names"
              label="Onsite"
              availableValues={onsiteMachines}
              readonly={!isEditing}
              isLoading={isLoadingVmRegular || isLoadingVmOnsite}
            ></FormTwinMultiSelect>
            <FormCheckbox name="onsite" label={t('label-onsite-implementation')} />
          </Grid>
        )}
      ></FormFrame>
    </>
  );
};

export default Environments;
