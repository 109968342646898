const testData = {
  'Unique Event ID':
    'AAMkADE0OTE3MzE0LTk3NWQtNGZhNC05YzM0LWIwNjM1MDhjYWI4OQBGAAAAAACULq4TJylGQoAmv2vI5E5vBwAS8dsxD3ZfQJHAKJSabfRBAAAAAAENAAAS8dsxD3ZfQJHAKJSabfRBAAAOJHnEAAA=',
  'VM Name': 'Playgrou-Test01',
  'ResourceGroup Name': 'MOST-Playground',
  'Robotic task name': 'Ke_CASE3_test',
  'Robotic task priority': 'Normal',
  'Robotic task start script name(full path)': 'H:\\RPA\\PerRo-Playground\\Robot\\PerRo_Playground.sikuli',
  'Start time': '2018-10-24T09:15:00Z',
  'Stop time': '2018-10-24T09:45:00Z',
  test1: { asdas: 1, asd2as: 1, dsffs2dds: 1, test2: { asda3s: 1, asdas3: 1, dsffsd3ds: 1, sgdg3ds: 1 }, sgdg4ds: 1 },
  test2: { asdas: 1, asdas4: 1, dsffsdds: 1, sgdgds: 1 },
  RecordedStartTime: '2018-10-24T09:16:41Z',
  RecordedStopTime: '2018-10-24T09:16:43Z',
  RunStatus: 'COMPLETED',
  'Next scheduled run': '2000-01-01T10:35:00Z',
  Timestamp: '2018-10-24T08:58:16.6939443Z',
  Customer: ' Sinappi Oy',
  ScreenshotsAllowed: 'True',
  Environment: 'TEST',
  CustomerEmail: 'heikki@mostdigital.fi',
  CustomerErrorEmail: 'heikki@mostdigital.fi',
  EndBuffer: '300',
};

export default testData;
