import { useQuery } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

const ListVms = (customer_guid: string) => {
  return useQuery(
    ['listVms', customer_guid],
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(apimConf.apiManagementEndpoint + '/resources/list-vms', {
        headers: {
          'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          customer_guid: customer_guid,
        },
      });
      return data;
    },
    {
      enabled: !!customer_guid,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
};

export default ListVms;
