import React from 'react';
import { FormTextArea } from '../../FormFields';

interface IProps {
  hiddenName: string;
  hiddenValue: string;
  name: string;
  label: string;
  readonly: boolean;
}

type Props = IProps;

const LanguageArrayItem: React.FC<Props> = (props) => {
  const { hiddenName, hiddenValue, name, label, readonly } = props;

  return (
    <>
      <input type="hidden" name={hiddenName} value={hiddenValue} />
      <FormTextArea name={name} label={label} readonly={readonly} />
    </>
  );
};

export default LanguageArrayItem;
