import React, { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';

/**
 * dataSetOptions: the key is the translation key and the value is the identification key (string) of the dataset
 * selectedDataSet: must always be one of the values in 'dataSetOptions'
 * handleSetChange: changes the currently selected option in the parent component
 *
 * e.g.:
 * dataSetOptions = {"label-used-time": "used", "label-scheduled-time": "scheduled"}
 * selectedDataSet = "used"
 * handleSetChange: responsible for changing the selectedDataSet to "scheduled"
 *                  when the user clicks on e.g. "Scheduled time"
 *                  (which is the translation for "label-scheduled-time")
 */
interface ISetSelectorProps {
  dataSetOptions: object;
  handleSetChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  selectedDataSet: string;
  customTitle?: string;
}

const SetSelector = (props: ISetSelectorProps) => {
  const { dataSetOptions, handleSetChange, selectedDataSet, customTitle } = props;
  const { t } = useTranslation();

  var menuItems = [];
  for (const [key, value] of Object.entries(dataSetOptions)) {
    menuItems.push(
      <MenuItem key={value} value={value}>
        {t(key)}
      </MenuItem>,
    );
  }

  return (
    <FormControl style={{ width: '20vh' }}>
      <FormHelperText sx={{ marginLeft: 0 }}>{!!customTitle ? t(customTitle) : t('label-view')}</FormHelperText>
      <Select value={selectedDataSet} onChange={handleSetChange}>
        {menuItems}
      </Select>
    </FormControl>
  );
};

export default SetSelector;
