import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import FormatToNDecimals from '../../Common/ReusableUtilityFunctions/FormatToNDecimals';

interface IDataTableRuntimesProps {
  data: any;
}

const DataTableRuntimes = (props: IDataTableRuntimesProps) => {
  const { data } = props;
  const { t } = useTranslation();
  const cellStyles = { textAlign: 'left' };
  return (
    <>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell key={'scheduled-runtime'} sx={cellStyles}>
              {t('label-scheduled-runtime')}
            </TableCell>
            <TableCell key={'actual-runtime'} sx={cellStyles}>
              {t('label-actual-runtime')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((obj: any, id: number) => {
            return (
              <TableRow key={id}>
                <TableCell key={'scheduled-' + id} sx={cellStyles}>
                  {obj['scheduled']} {t('label-minutes')} ({FormatToNDecimals(obj['scheduled'] / 60, 1)}{' '}
                  {t('label-hours')})
                </TableCell>
                <TableCell key={'actual-' + id} sx={cellStyles}>
                  {obj['actual']} {t('label-minutes')} ({FormatToNDecimals(obj['actual'] / 60, 1)} {t('label-hours')})
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default DataTableRuntimes;
