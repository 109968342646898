import React from 'react';
import FormatToNDecimals from '../../Common/ReusableUtilityFunctions/FormatToNDecimals';
// MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography/Typography';

const RuntimesChartTooltip = (data: any) => {
  if (data.payload.length === 0) {
    return null;
  }
  const cardStyles = { opacity: '0.85' };
  const cardContentStyles = {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '2vh',
    height: '13vh',
  };
  const payload = data.payload;
  const reservedTimeStyles = { color: payload[0].fill, fontSize: '2vh' };
  const actualTimeStyles = { color: payload[1].fill, fontSize: '2vh' };
  return (
    <Card variant="elevation" elevation={10} style={cardStyles}>
      <CardContent sx={cardContentStyles}>
        <Typography variant="h1" component="h2" style={reservedTimeStyles}>
          {payload[0].name}: {FormatToNDecimals(payload[0].value, 1)}
          {payload[0].unit}
        </Typography>
        <Typography variant="h1" component="h2" style={actualTimeStyles}>
          {payload[1].name}: {FormatToNDecimals(payload[1].value, 1)}
          {payload[1].unit}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default RuntimesChartTooltip;
