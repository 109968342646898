import React from 'react';
import { IRunErrorsByDayChartProps } from './ChartProps';
import { useTranslation } from 'react-i18next';
import { Legend, XAxis, YAxis, LineChart, Line, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import RunErrorsByDayChartTooltip from './Tooltips/RunErrorsByDayChartTooltip';
import ChartWrapperNoDataError from '../Common/ReusableComponents/ChartWrapper/ChartWrapperComponents/ChartWrapperNoDataError';

const RunErrorsByDayChart = (props: IRunErrorsByDayChartProps) => {
  const { data } = props;
  const { t } = useTranslation();
  if (!Array.isArray(data) || !data.length) {
    return <ChartWrapperNoDataError translationKey={'label-no-data'} />;
  }
  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis hide dataKey="date" />
        <YAxis />
        <Tooltip content={<RunErrorsByDayChartTooltip />} animationDuration={500} />
        <Legend />
        <Line name={t('label-error-count')} type="monotone" dataKey="errors" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RunErrorsByDayChart;
