import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography/Typography';

const VmUtilizationChartTooltip = (data: any) => {
  const { t } = useTranslation();
  if (data.payload.length === 0) {
    return null;
  }
  const payload = data.payload[0];
  const titleFont = { color: payload.payload.fill, fontSize: '2vh' };
  const subFont = { color: payload.payload.fill, fontSize: '1.5vh' };
  const cardStyles = { opacity: '0.9' };
  const cardContentStyles = {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '2vh',
    height: '13vh',
  };

  const calculatePercentages = (chartData: any) => {
    // chartData is the dataset that is used to form the chart
    // it looks like follow: [{name: 'unused_time', value: 7560}, {name: 'reserved_time', value: 2520}, {name: 'executed_time', value: 0}]
    const total = chartData.reduce((a: any, b: any) => a + (b['value'] || 0), 0);
    return `${((payload.value / total) * 100).toFixed(0)}%`;
  };

  const renderTitle = (activeSelection: string) => {
    if (activeSelection === 'unused_time') {
      return t('label-unused-time');
    } else if (activeSelection === 'reserved_time') {
      return t('label-reserved-time');
    } else if (activeSelection === 'executed_time') {
      return t('label-executed-time');
    } else if (activeSelection === 'scheduled_time') {
      return t('label-scheduled-time');
    } else if (activeSelection === 'overtime') {
      return t('label-overtime');
    } else {
      return t('label-nan');
    }
  };

  return (
    <Card variant="elevation" elevation={10} style={cardStyles}>
      <CardContent sx={cardContentStyles}>
        <Typography variant="h1" style={titleFont}>
          {renderTitle(payload.name)}
        </Typography>
        <Typography variant="h2" style={subFont}>
          {t('label-percentage-share')}: {calculatePercentages(data.data)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VmUtilizationChartTooltip;
