import {
  createScheduleActionCreator,
  deleteScheduleActionCreator,
  fetchSchedulesActionCreator,
  IFetchSchedulesParams,
  updateScheduleActionCreator,
} from '../actions/schedule';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IDictionary, INormalizedBase } from '../../types/types';

import {
  IScheduleState,
  ISchedule,
  IScheduleCreate,
  IScheduleUpdate,
  IScheduleDelete,
} from '../../components/Forms/Tasks/Schedule/ScheduleTypes';
import { Success } from 'typescript-fsa';
import { combineReducers } from 'redux';

const initialState: IScheduleState = {
  byId: {},
  ids: [],
};

const byIdHandler = (
  byId: IDictionary<ISchedule>,
  payload: Success<IFetchSchedulesParams, INormalizedBase<ISchedule, string>>,
) => {
  if (payload.result.byId) {
    return { ...byId, ...payload.result.byId };
  }
  return byId;
};

const byIdCreateHandler = (byId: IDictionary<ISchedule>, payload: Success<IScheduleCreate, ISchedule>) => {
  if (payload.result) {
    return { ...byId, [payload.result.schedule_id]: payload.result };
  }
  return byId;
};

const byIdUpdateHandler = (byId: IDictionary<ISchedule>, payload: Success<IScheduleUpdate, void>) => {
  if (payload.params) {
    const current = byId[payload.params.schedule_id];
    const updated = current ? { ...current, ...payload.params } : payload.params;
    return { ...byId, [payload.params.schedule_id]: updated };
  }
  return byId;
};

const byIdDeleteHandler = (byId: IDictionary<ISchedule>, payload: Success<IScheduleDelete, void>) => {
  if (payload.params) {
    const { [payload.params.schedule_id]: removed, ...updated } = byId;
    return updated;
  }
  return byId;
};

const byId = reducerWithInitialState<{}>(initialState.byId)
  .cases([fetchSchedulesActionCreator.done], byIdHandler)
  .case(createScheduleActionCreator.done, byIdCreateHandler)
  .case(updateScheduleActionCreator.done, byIdUpdateHandler)
  .case(deleteScheduleActionCreator.done, byIdDeleteHandler);

const idsHandler = (ids: string[], payload: Success<IFetchSchedulesParams, INormalizedBase<ISchedule, string>>) => {
  if (payload.result.ids && payload.result.ids.length > 0) {
    return Array.from(new Set(ids.concat(payload.result.ids)));
  }
  return ids;
};

const idsCreateHandler = (ids: string[], payload: Success<IScheduleCreate, ISchedule>) => {
  if (payload.result) {
    return Array.from(new Set(ids.concat(payload.result.schedule_id)));
  }
  return ids;
};

const idsUpdateHandler = (ids: string[], payload: Success<IScheduleUpdate, void>) => {
  return ids;
};

const idsDeleteHandler = (ids: string[], payload: Success<IScheduleDelete, void>) => {
  return ids.filter((i) => i !== payload.params.schedule_id);
};

const ids = reducerWithInitialState<string[]>(initialState.ids)
  .cases([fetchSchedulesActionCreator.done], idsHandler)
  .case(createScheduleActionCreator.done, idsCreateHandler)
  .case(updateScheduleActionCreator.done, idsUpdateHandler)
  .case(deleteScheduleActionCreator.done, idsDeleteHandler);

export default combineReducers({
  byId,
  ids,
});

export const getAllSchedules: (state: IScheduleState, customerId: string) => ISchedule[] = (state, customerId) => {
  return state.ids.map((id) => state.byId[id]).filter((t) => t.customer_id === customerId);
};

export const getSchedulesForTask: (state: IScheduleState, taskId: string) => ISchedule[] = (state, taskId) => {
  return state.ids.map((id) => state.byId[id]).filter((t) => t.task_id === taskId);
};
