import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import TabView from '../../Common/TabView';
import { ITabItem } from '../../Common/TabView/TabViewUtils';
import {
  BasicInfo,
  TaskSelector,
  Scheduler,
  Environments,
  Stamps,
  BusinessData,
  DockFrame,
  Database,
  DataModel,
  GitHub,
  Jira,
  ProcessInfo,
} from '../../Forms/Tasks';
import { getSelectedCustomerId } from '../../../redux/reducers';
import { fetchTasks, getAllTasks, getSelectedTask, selectTask, selectTasksStatus } from '../../../redux/reducers/tasks';
import { ITask, IState } from '../../../types/types';
import { useAppDispatch } from '../../Hooks/StoreHooks';
import { toast } from 'react-toastify';
import { createApiMessage } from '../../../redux/actions/apiMessage';

const Task = () => {
  const dispatch = useAppDispatch();
  const setSelectedTaskId = (id: string) => dispatch(selectTask(id));

  const selectedTask = useSelector((state: any) => getSelectedTask(state));
  const isLoading = useSelector(selectTasksStatus) === 'loading';
  const customerId = useSelector<IState, string>(getSelectedCustomerId, shallowEqual);
  const data = useSelector<IState, ITask[]>((state: IState) => getAllTasks(state, customerId), shallowEqual);

  useEffect(() => {
    dispatch(fetchTasks(customerId))
      .unwrap()
      .catch((err) => {
        toast.error(err.message);
        dispatch(createApiMessage(`customers/${customerId}/tasks/`, err.message));
      });
  }, []);

  useEffect(() => {
    return () => {
      setSelectedTaskId(String(' '));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectItem: (id: number | string) => void = (id) => {
    setSelectedTaskId(String(id));
  };

  const selectorTab: ITabItem[] = [
    {
      displayName: 'menu-sub-task',
      component: (
        <TaskSelector
          customerId={customerId}
          data={data}
          isLoading={isLoading}
          selectedItem={selectedTask}
          onSelectItem={handleSelectItem}
        />
      ),
    },
  ];

  const tabs = selectedTask
    ? selectorTab.concat([
        {
          displayName: 'basic-info',
          component: <BasicInfo task={selectedTask} customerId={customerId} />,
        },
        {
          displayName: 'menu-sub-environments',
          component: <Environments task={selectedTask} />,
        },
        {
          displayName: 'menu-sub-scheduler',
          component: <Scheduler task={selectedTask} />,
        },
        {
          displayName: 'menu-sub-stamps',
          component: <Stamps task={selectedTask} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-businessdata',
          component: <BusinessData task={selectedTask} />,
        },
        {
          displayName: 'menu-sub-database',
          component: <Database task={selectedTask} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-datamodel',
          component: <DataModel task={selectedTask} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-dockframe',
          component: <DockFrame task={selectedTask} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-process-info',
          component: <ProcessInfo />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-github',
          component: <GitHub task={selectedTask} />,
          roles: ['administrators'],
        },
        {
          displayName: 'menu-sub-jira',
          component: <Jira task={selectedTask} />,
          roles: ['administrators'],
        },
      ])
    : selectorTab;

  return (
    <>
      <TabView tabs={tabs} selectedParentTab="tasks" />
    </>
  );
};

export default Task;
