import React from 'react';
import LoadingScreen from '../../../Common/LoadingScreen';

interface IProps {
  isLoading: boolean;
  errorMsg?: string;
}

const RoleWindowLoadingScreen = (props: IProps) => {
  if (props.isLoading) {
    return <LoadingScreen />;
  } else if (!!props.errorMsg) {
    return <p>{props.errorMsg}</p>;
  } else {
    return null;
  }
};

export default RoleWindowLoadingScreen;
