import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { FormFieldSet, FormSelect } from '../../../FormFields';
import { useCollection } from '../../../Hooks/Collections';
import { IComputeFormObject as IFormObject } from './ComputeTypes';

interface IComponentProps {
  isEditing: boolean;
  dscOptions: any;
}

interface IDscOption {
  VMConfigurationName: string;
  VMConfigurationStatus: string;
  VMConfigurationDescription: string;
  VMConfigurationType: string;
  VMConfigurationDefault: boolean;
}

const TypeSpecificInfo = (props: IComponentProps) => {
  const { isEditing, dscOptions } = props;
  const { values } = useFormikContext<IFormObject>();

  const environmentCategories = useCollection(3);
  const environmentLevels = useCollection(4);

  const { t } = useTranslation();

  const customTooltip = (
    <React.Fragment>
      <Typography color="inherit">Within Azure these values mean:</Typography>
      <p>{'Lite = B1 ms'}</p>
      <p>{'Normal = B2 ms'}</p>
      <p>{'Heavy = Standard D2 v3'}</p>
      <p>{'Super Heavy = Standard D8s v3'}</p>
    </React.Fragment>
  );

  const configurationTooltip = !!dscOptions ? (
    <React.Fragment>
      <Typography color="inherit">Configuration options:</Typography>
      {dscOptions.map((option: IDscOption) => (
        <p>{option.VMConfigurationName}</p>
      ))}
    </React.Fragment>
  ) : null;

  return (
    <Grid item xs={12}>
      <FormFieldSet legend={t('label-type-specified-information')}>
        <FormSelect
          name="category"
          label={t('label-environment-category')}
          readonly={!isEditing}
          value={values.category}
        >
          {environmentCategories.map((item) => (
            <option key={item.id} value={item.value}>
              {item.value}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          name="level"
          label={t('label-environment-level')}
          readonly={!isEditing}
          showHelp={true}
          helpText={customTooltip}
          value={values.level}
        >
          {environmentLevels.map((item) => (
            <option key={item.id} value={item.value}>
              {item.value}
            </option>
          ))}
        </FormSelect>
        {!!dscOptions ? (
          <FormSelect
            name="configuration"
            label={t('label-configuration')}
            readonly={!isEditing}
            showHelp={true}
            helpText={configurationTooltip}
          >
            {dscOptions.map((option: IDscOption, index: number) => (
              <option key={index} value={option.VMConfigurationName}>
                {option.VMConfigurationName}
              </option>
            ))}
          </FormSelect>
        ) : null}
      </FormFieldSet>
    </Grid>
  );
};

export default TypeSpecificInfo;
