import { Grid } from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import FormFrame from '../../FormFrames/FormFrame';
import { FormTwinMultiSelect } from '../../FormFields';
import { ITask } from '../../../types/types';

interface IProps {
  task: ITask;
}

interface IFormObject {
  components: string[];
  libraries: string[];
}

const validationSchema = yup.object({
  components: yup.array(yup.string()),
  libraries: yup.array(yup.string()),
});

type Props = IProps;

const DockFrame: React.FC<Props> = (props) => {
  const allComponents = ['Component1', 'Component2', 'Component3', 'Component4', 'Component5', 'Component6'];
  const allLibraries = ['Library1', 'Library2', 'Library3', 'Library4', 'Library5', 'Library6'];
  const data: IFormObject = {
    components: ['Component1', 'Component3'],
    libraries: ['Library1', 'Library2', 'Library3', 'Library4'],
  };
  const handleSubmit: (data: IFormObject) => void = (data) => {};

  return (
    <>
      <FormFrame<IFormObject>
        label="Dock Frame"
        validationSchema={validationSchema}
        initialValues={data}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <FormTwinMultiSelect
              name="components"
              label="Common Components"
              availableValues={allComponents}
              readonly={!isEditing}
            ></FormTwinMultiSelect>
            <FormTwinMultiSelect
              name="libraries"
              label="Python Libraries"
              availableValues={allLibraries}
              readonly={!isEditing}
            ></FormTwinMultiSelect>
          </Grid>
        )}
      ></FormFrame>
    </>
  );
};

export default DockFrame;
