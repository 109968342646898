import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const CHANGE_UI_LANGUAGE = 'CHANGE_UI_LANGUAGE';
export const INITIALIZE_USER = 'INITIALIZE_USER';
export const ACQUIRE_TOKEN_FAILED = 'ACQUIRE_TOKEN_FAILED';
export const LOGOUT = 'LOGOUT';

export const changeLanguageActionCreator = actionCreator<string>(CHANGE_UI_LANGUAGE);

export const initializeUserActionCreator = actionCreator<any>(INITIALIZE_USER);

export const acquireTokenFailedActionCreator = actionCreator<void>(ACQUIRE_TOKEN_FAILED);

export const logoutUserActionCreator = actionCreator<void>(LOGOUT);
