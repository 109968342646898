import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { FormSelect, FormTextField, FormTextArea } from '../../../FormFields';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { useCollection } from '../../../Hooks/Collections';
import { useTranslation } from 'react-i18next';
import { IState, ITask, ITaskCreate } from '../../../../types/types';
import * as yup from 'yup';
import { createTask, selectTasksStatus } from '../../../../redux/reducers/tasks';
import { useAppDispatch } from '../../../Hooks/StoreHooks';
import { toast } from 'react-toastify';
import { createApiMessage } from '../../../../redux/actions/apiMessage';

interface IProps {
  customerId: string;
  data?: ITask;
  onCancel: () => void;
}

type Props = IProps;

interface IFormObject extends Omit<ITaskCreate, 'customer_guid' | 'priority'> {
  priority: string | undefined;
}

const validationSchema = yup.object<IFormObject>({
  task_name: yup.string().required(),
  task_description: yup.string().nullable(),
  task_logo: yup.object<File>().nullable(),
  task_type: yup.string().nullable(),
  start_script_path: yup.string().nullable(),
  priority: yup.string(),
  // screenshots_allowed: yup.boolean().nullable(),
  // recording_allowed: yup.boolean().nullable(),
});

const AddTask: React.FC<Props> = (props) => {
  const { customerId, data, onCancel } = props;
  const dispatch = useAppDispatch();
  const taskTypes = useCollection(11);
  const taskPriority = useCollection(12);
  const { t } = useTranslation();

  const formData: IFormObject = {
    task_name: '',
    task_description: data?.task_description ?? '',
    task_logo: null,
    task_type: data?.task_type ?? '',
    start_script_path: data?.start_script_path ?? '',
    priority: '',
  };

  const isLoading = useSelector((state: any) => selectTasksStatus(state)) === 'submitting';

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = useCallback(
    (data, setIsSubmitting) => {
      setIsSubmittingFn(() => setIsSubmitting);

      const payload: ITaskCreate = {
        customer_guid: customerId,
        task_name: data.task_name,
        task_description: data.task_description || null,
        task_logo: data.task_logo || null,
        task_type: data.task_type,
        start_script_path: data.start_script_path || null,
        priority:
          data.priority === taskPriority[1].value
            ? 1
            : data.priority === taskPriority[2].value
            ? 2
            : data.priority === taskPriority[0].value
            ? 3
            : null,
      };

      dispatch(createTask(payload))
        .unwrap()
        .then(() => {
          onCancel();
          toast.success('Task added!');
        })
        .catch((err) => {
          const resource = `/customers/${customerId}/tasks/`;
          toast.error(err.message);
          dispatch(createApiMessage(resource, err.message));
        });
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ModalFormFrame<IFormObject>
      label={t('label-add-new-task')}
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField name="task_name" label={t('label-task-name')} readonly={isSubmitting} />
            <FormTextArea name="task_description" label={t('label-task-description')} readonly={isSubmitting} />
            <FormTextField name="task_logo" label="" TextFieldProps={{ type: 'file' }} readonly={isSubmitting} />
            <FormSelect name="task_type" label={t('label-task-type')} readonly={isSubmitting}>
              {taskTypes.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </FormSelect>
            <FormSelect name="priority" label={t('label-task-priority')} readonly={isSubmitting}>
              {taskPriority.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </FormSelect>
            <FormTextField name="start_script_path" label={t('label-script-path')} readonly={isSubmitting} />
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddTask;
