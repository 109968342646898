import { createApiAction } from './common';
import moment from 'moment';
import { schema as Schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import { INormalizedBase, IEvent } from '../../types/types';
import HttpMethod from '../../services/api/httpMethod';

const actionCreator = actionCreatorFactory();

/**
 * Define schema for normalizing api response
 * This schema normalizes an array of objects to a dictionary of objects and an array if ids
 * If the property that defines the object identity is not named 'id' the idAttribute needs to be set
 */
const entityName = 'event';
const entity = new Schema.Entity(
  entityName,
  {},
  {
    idAttribute: 'event_guid',
  },
);
const schema = [entity];

/**
 * The type of action for a fetch operation
 */
export const FETCH_EVENTS = 'FETCH_EVENTS';

/**
 * Define action creators for an async operation
 */
export const fetchEventsActionCreator = actionCreator.async<string, INormalizedBase<IEvent, string>>(FETCH_EVENTS, {
  normalize: {
    entityName,
    schema,
  },
});

/**
 * Creates an async action that defines an api call to fetch events for a customer in a time range
 * @param customerId The id of the customer to fetch events for
 * @param from The start of the time range
 * @param to The end of the time range
 */
export const getEventsApiRequest = (customerId: string, from?: Date, to?: Date) => {
  const fromDate = from?.toISOString() || moment().add(-6, 'months').startOf('month').toISOString();
  const toDate = to?.toISOString() || new Date().toISOString();
  return createApiAction(
    HttpMethod.GET,
    `api/events?customerId=${customerId}&from=${fromDate}&to=${toDate}`,
    fetchEventsActionCreator,
  );
};
