import React, { useState } from 'react';
import { FormFieldSet } from '../../FormFields';
import FormTable from '../../FormFrames/FormTable';

const PrivX = () => {
  const [selectedItem, setSelectedItem] = useState<number | string>('');
  const [selectedUser, setSelectedUser] = useState<number | string>('');

  const mockupData = [
    {
      id: '0',
      groupName: 'group1',
      groupStatus: 'STATUS_OK',
      users: [
        { id: 0, name: 'John Doe' },
        { id: 1, name: 'Foo' },
        { id: 2, name: 'Bar' },
        { id: 3, name: 'Diipa Daapa' },
        { id: 4, name: 'Place Holder' },
        { id: 5, name: 'Made Up Name' },
      ],
    },
    {
      id: '1',
      groupName: 'group2',
      groupStatus: 'STATUS_OK',
      users: [
        { id: 0, name: 'Kimi Räikkönen' },
        { id: 1, name: 'Matti Nykänen' },
        { id: 2, name: 'Teemu Selänne' },
      ],
    },
    {
      id: '2',
      groupName: 'group3',
      groupStatus: 'STATUS_OK',
      users: [
        { id: 0, name: 'Leonardo Di Caprio' },
        { id: 1, name: 'Amy Adams' },
        { id: 2, name: 'Adam Driver' },
        { id: 3, name: 'Scarlett Johanson' },
        { id: 4, name: 'Tom Hanks' },
      ],
    },
    {
      id: '3',
      groupName: 'group4',
      groupStatus: 'STATUS_BAD',
      users: [
        { id: 0, name: 'Mohammad Ali' },
        { id: 1, name: 'Arnold Schwarzenegger' },
      ],
    },
  ];

  const userData = mockupData.map((group) => {
    return group.users;
  });

  const handleSelect = (id: number | string) => {
    setSelectedItem(id);
  };

  const handleSelectUser = (id: number | string) => {
    setSelectedUser(id);
  };

  let usersList;

  if (selectedItem) {
    usersList = (
      <FormTable
        label="Users in selected group"
        data={userData[0]}
        properties={[{ key: 'name', label: '' }]}
        isLoading={false}
        selectedId={selectedUser}
        onSelectRow={handleSelectUser}
        onDelete={() => null}
        optionalButtons={false}
      />
    );
  }

  return (
    <>
      <FormFieldSet legend="PrivX">
        <div style={{ minHeight: '300px', width: '100%' }}>
          <FormTable
            label="PrivX role groups"
            data={mockupData}
            properties={[{ key: 'groupName', label: 'PrivX role groups' }]}
            isLoading={false}
            selectedId={selectedItem}
            onSelectRow={handleSelect}
            onDelete={() => null}
            optionalButtons={false}
          />
          {usersList}
        </div>
      </FormFieldSet>
    </>
  );
};

export default PrivX;
