/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getSelectedCustomerId } from '../../../../redux/reducers';
import { IState } from '../../../../types/types';
import EventLogList from './EventLogList';

import MDButton from '../../../Common/ReusableComponents/MDButton';
import { usePostApiEffect } from '../../../Hooks/Hooks';

interface IComponentProps {
  customerId: string;
  selectedVm: string;
}

const EventLogs = (props: IComponentProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [eventId, setEventId] = useState<string>();
  //const customerId = useSelector<IState, string>(getSelectedCustomerId, shallowEqual);

  const { customerId, selectedVm } = props;

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    setEventId(inputValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const [response, isLoading, isError, postData] = usePostApiEffect<any, void>('api/resources/environments', null);

  const handleRerun = (eventId: string) => {
    if (!!selectedVm) {
      const payload = {
        id: '6e60f329-2993-4b52-95eb-7b5701825dbf',
        customer_id: customerId,
        data: {
          resource_type: 'vm',
          resource_data: [
            {
              resource_name: selectedVm,
              resource_operation: 'rerun',
              event_guid: eventId,
            },
          ],
        },
      };
      postData(payload);
    }
  };

  //Alexande-Test03-b1762f9d-30d5-4d7f-8119-205b5365a875-78a0d366-f914-4b15-b117-8f1d2a49b8d0-1622040600000

  // eventId: '78a0d366-f914-4b15-b117-8f1d2a49b8d0-1622040600000'

  return (
    <>
      <form onSubmit={submit}>
        <fieldset style={{ border: 0, margin: 0, padding: '1em 0' }}>
          <TextField
            value={inputValue}
            onChange={handleChange}
            variant="outlined"
            size="small"
            style={{ width: 450, marginRight: 12 }}
          />
          <Button disabled={!inputValue} type="submit" variant="contained" color="primary" size="large">
            Search
          </Button>
          <MDButton label="Re-run event" handleClick={() => handleRerun(inputValue)} disabled={!inputValue} />
        </fieldset>
      </form>
      {customerId && eventId && <EventLogList customerId={customerId} eventId={eventId} />}
    </>
  );
};

export default EventLogs;
