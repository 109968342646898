import React, { useState } from 'react';

import DatawindowFrame from './DatawindowFrame';
import FullscreenGraph from './FullscreenGraph';

type ComponentProps = {
  t: any;
  data?: any;
  dataKeys: string[];
  legendLabel: any;
  windowTitle: string;
  xAxisLabel?: string;
  graphType: string;
  dataSetOptions?: any;
  selectedDataSet: string;
  loading: boolean;
  handleSetChange: (event: any) => void;
  loopOptions?: any;
  handleLoopChange?: any;
  eventStatus: any;
  selectedLoop?: any;
  selectedStampType?: string;
  stampTypeOptions?: any;
  handleSingleChange?: any;
};

const SingleDatawindow = (props: ComponentProps) => {
  const [minimized, setMinimized] = useState<boolean>(false);
  const [fullscreenOpen, setFullscreenOpen] = useState<boolean>(false);

  const {
    t,
    data,
    windowTitle,
    dataKeys,
    legendLabel,
    xAxisLabel,
    graphType,
    dataSetOptions,
    selectedDataSet,
    handleSetChange,
    loading,
    loopOptions,
    handleLoopChange,
    selectedLoop,
    eventStatus,
    selectedStampType,
    stampTypeOptions,
    handleSingleChange,
  } = props;

  const handleOpenFullscreen = () => {
    setFullscreenOpen(!fullscreenOpen);
  };

  const handleMinimize = () => {
    setMinimized(!minimized);
  };
  return (
    <>
      <DatawindowFrame
        t={t}
        data={data}
        graphType={graphType}
        loading={loading}
        windowTitle={windowTitle}
        dataKeys={dataKeys}
        legendLabel={legendLabel}
        minimized={minimized}
        handleMinimize={handleMinimize}
        handleSetChange={handleSetChange}
        selectedDataSet={selectedDataSet}
        dataSetOptions={dataSetOptions}
        xAxisLabel={xAxisLabel}
        handleOpenFullscreen={handleOpenFullscreen}
        loopOptions={loopOptions}
        handleLoopChange={handleLoopChange}
        selectedLoop={selectedLoop}
        eventStatus={eventStatus}
        stampTypeOptions={stampTypeOptions}
        selectedStampType={selectedStampType}
        handleSingleChange={handleSingleChange}
      />
      <FullscreenGraph
        t={t}
        data={data}
        fullscreenOpen={fullscreenOpen}
        handleOpenFullscreen={handleOpenFullscreen}
        graphType={graphType}
        legendLabel={legendLabel}
        dataKeys={dataKeys}
        xAxisLabel={xAxisLabel}
        windowTitle={windowTitle}
        selectedLoop={selectedLoop}
      />
    </>
  );
};

export default SingleDatawindow;
