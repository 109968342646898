import { IFeatureGroup } from './FeatureGroup';

export const featureGroups: IFeatureGroup[] = [
  {
    name: 'label_billing_group_database',
    translateName: true,
    features: [
      {
        name: 'database_capacity',
        label: 'label_billing_feature_database_capacity',
        translateLabel: true,
        type: 'Number',
      },
    ],
  },
  {
    name: 'label_billing_group_security',
    translateName: true,
    features: [
      {
        name: 'logging',
        label: 'label_billing_feature_logging',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'seven_days',
            text: '7 Days',
          },
          {
            value: 'one_month',
            text: '1 Month',
          },
          {
            value: 'six_months',
            text: '6 Months',
          },
          {
            value: 'one_year',
            text: '1 Year',
          },
        ],
      },
      {
        name: 'security_surveillance',
        label: 'label_billing_feature_security_surveillance',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'basic',
            text: 'Basic',
          },
          {
            value: 'advanced',
            text: 'Advanced',
          },
          {
            value: 'premium',
            text: 'Premium',
          },
        ],
      },
      {
        name: 'privx_server',
        label: 'label_billing_feature_privx_server',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'multitenant',
            text: 'Multitenant',
          },
          {
            value: 'private',
            text: 'Private',
          },
        ],
      },
      {
        name: 'vpn_gateway',
        label: 'label_billing_feature_vpn_gateway',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'multitenant',
            text: 'Multitenant',
          },
          {
            value: 'private',
            text: 'Private',
          },
        ],
      },
      {
        name: 'automatic_secret_management',
        label: 'label_billing_feature_automatic_secret_management',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'true',
            text: 'Yes',
          },
          {
            value: 'false',
            text: 'No',
          },
        ],
      },
      {
        name: 'secure_file_sync',
        label: 'label_billing_feature_secure_file_sync',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'multitenant',
            text: 'Multitenant',
          },
          {
            value: 'private',
            text: 'Private',
          },
        ],
      },
    ],
  },
  {
    name: 'label_billing_group_dashboard',
    translateName: true,
    features: [
      {
        name: 'custom_data_windows',
        label: 'label_billing_feature_custom_data_windows',
        translateLabel: true,
        type: 'Number',
      },
      {
        name: 'mobile_data_windows',
        label: 'label_billing_feature_mobile_data_windows',
        translateLabel: true,
        type: 'Number',
      },
    ],
  },
  {
    name: 'label_billing_group_microsoft_licenses',
    translateName: true,
    features: [
      {
        name: 'unattended_licenses',
        label: 'label_billing_feature_unattended_licenses',
        translateLabel: true,
        type: 'Number',
      },
      {
        name: 'server_2019_licenses',
        label: 'label_billing_feature_server_2019_licenses',
        translateLabel: true,
        type: 'Number',
      },
    ],
  },
  {
    name: 'label_billing_group_environments',
    translateName: true,
    features: [
      {
        name: 'azure_subscription',
        label: 'label_billing_feature_azure_subscription',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'multitenant',
            text: 'Multitenant',
          },
          {
            value: 'private',
            text: 'Private',
          },
        ],
      },
      {
        name: 'sub_networks',
        label: 'label_billing_feature_sub_networks',
        translateLabel: true,
        type: 'Number',
      },
      {
        name: 'keyvaults',
        label: 'label_billing_feature_key_vaults',
        translateLabel: true,
        type: 'Number',
      },
      {
        name: 'windows_server_vm',
        label: 'label_billing_feature_windows_server_vms',
        translateLabel: true,
        type: 'Number',
      },
      {
        name: 'linux_server_vm',
        label: 'label_billing_feature_linux_server_vms',
        translateLabel: true,
        type: 'Number',
      },
      {
        name: 'linux_container_clusters',
        label: 'label_billing_feature_linux_container_clusters',
        translateLabel: true,
        type: 'Number',
      },
    ],
  },
  {
    name: 'label_billing_group_support',
    translateName: true,
    features: [
      {
        name: 'azure_sla',
        label: 'label_billing_feature_azure_sla',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'basic',
            text: 'Basic',
          },
          {
            value: 'advanced',
            text: 'Advanced',
          },
          {
            value: 'premium',
            text: 'Premium',
          },
        ],
      },
      {
        name: 'business_critical_slas',
        label: 'label_billing_feature_business_critical_sla',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'true',
            text: 'Yes',
          },
          {
            value: 'false',
            text: 'No',
          },
        ],
      },
      {
        name: 'jira_portal',
        label: 'label_billing_feature_jira_portal',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'true',
            text: 'Yes',
          },
          {
            value: 'false',
            text: 'No',
          },
        ],
      },
      {
        name: 'secure_teams_site',
        label: 'label_billing_feature_secure_teams_site',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'true',
            text: 'Yes',
          },
          {
            value: 'false',
            text: 'No',
          },
        ],
      },
      {
        name: 'service_desk_phone',
        label: 'label_billing_feature_service_desk_phone',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'true',
            text: 'Yes',
          },
          {
            value: 'false',
            text: 'No',
          },
        ],
      },
      {
        name: 'elearning_environment',
        label: 'label_billing_feature_elearning_environment',
        translateLabel: true,
        type: 'Select',
        options: [
          {
            value: 'true',
            text: 'Yes',
          },
          {
            value: 'false',
            text: 'No',
          },
        ],
      },
    ],
  },
];
