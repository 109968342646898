/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Scheduler, { SchedulerData, DATE_FORMAT } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import moment from 'moment';
import 'moment/locale/en-gb';
import { EventType, ITimelineEvent, ITimelineResource } from '../../../../types/types';

const Wrapper = styled.div<{
  isActive: boolean;
  height: any;
  maxWidth: any;
}>`
  border: ${({ isActive }) => (isActive ? '1px solid #0000ff' : '0')};
  height: ${({ height }) => `${height}px`};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none')};
`;

const Planned = styled.div<{
  startTime: number;
  runtime: number;
  totalTime: number;
}>`
  background-color: #80c5f6;
  height: 90%;
  width: ${({ runtime, totalTime }) => `calc(100% * ${runtime} / ${totalTime})`};
  left: ${({ startTime, totalTime }) => `calc(100% * ${startTime} / ${totalTime})`};
  top: 1px;
  position: absolute;
  border-radius: 0.15vh;
`;

const Actual = styled.div<{
  status: number;
  startTime: number;
  runtime: number;
  totalTime: number;
}>`
  background-color: ${({ status }) => selectStatusColor(status)};
  height: 70%;
  width: ${({ runtime, totalTime }) => `calc(100% * ${runtime} / ${totalTime})`};
  left: ${({ startTime, totalTime }) => `calc(100% * ${startTime} / ${totalTime})`};
  top: 3.5px;
  position: absolute;
  border-radius: 0.15vh;
`;

const PopoverItem = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const selectStatusColor: (status?: number) => string = (status) => {
  if (status === 4) {
    //finished with errors
    return '#ffeb3b';
  } else if (status === 5) {
    //finished
    return '#00e676';
  } else if (status === 6) {
    //suspended
    return '#ff7961';
  } else {
    //pending
    return '#80C5F6';
  }
};

type SchedulerData = any; // eslint-disable-line @typescript-eslint/no-redeclare

type ComponentState = {
  viewModel: SchedulerData;
};

type ComponentProps = {
  resources: ITimelineResource[];
  events: ITimelineEvent[];
  activeEvent?: IActiveEvent;
  setActiveEvent: (event: IActiveEvent) => void;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  setEventStatus: any;
  t: any;
};

type View = { viewType: ViewTypes; showAgenda: boolean; isEventPerspective: boolean };

export enum ViewTypes {
  Day = 0,
  Week = 1,
  Month = 2,
  Quarter = 3,
  Year = 4,
  Custom = 5,
  Custom1 = 6,
  Custom2 = 7,
}

export interface IActiveEvent {
  id: string;
  name: string;
  type: EventType;
}

class Timeline extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    const schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: '97%',
        startResizable: false,
        endResizable: false,
        movable: false,
        creatable: false,
      },
      {
        isNonWorkingTimeFunc: (schedulerData: SchedulerData, time: any) => false,
      },
    );

    moment.locale('en-gb');
    schedulerData.setLocaleMoment(moment);
    schedulerData.setResources(props.resources);
    schedulerData.setEvents(props.events);
    this.state = {
      viewModel: schedulerData,
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const viewModel = state.viewModel;
    if (
      props.resources.length !== viewModel.resources.length ||
      JSON.stringify(props.events) !== JSON.stringify(viewModel.events)
    ) {
      viewModel.setResources(props.resources);
      viewModel.setEvents(props.events);
      return {
        viewModel,
      };
    }
    return null;
  }

  render() {
    const { viewModel } = this.state;
    return (
      <>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          slotClickedFunc={this.slotClickedFunc}
          toggleExpandFunc={this.toggleExpandFunc}
          eventItemTemplateResolver={this.eventItemTemplateResolver}
          eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
        />
      </>
    );
  }

  prevClick = (schedulerData: SchedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData,
    });
    this.props.setStartDate(schedulerData.startDate);
    this.props.setEndDate(schedulerData.endDate);
  };

  nextClick = (schedulerData: SchedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData,
    });
    this.props.setStartDate(schedulerData.startDate);
    this.props.setEndDate(schedulerData.endDate);
  };

  onViewChange = (schedulerData: SchedulerData, view: View) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData,
    });
    this.props.setStartDate(schedulerData.startDate);
    this.props.setEndDate(schedulerData.endDate);
  };

  onSelectDate = (schedulerData: SchedulerData, date: Date) => {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData,
    });
    this.props.setStartDate(schedulerData.startDate);
    this.props.setEndDate(schedulerData.endDate);
  };

  eventClicked = (schedulerData: SchedulerData, event: ITimelineEvent) => {
    this.props.setActiveEvent({ id: event.id, name: event.title, type: EventType.Event });
    this.props.setEventStatus(event.status);
  };

  slotClickedFunc = (schedulerData: SchedulerData, slot: any) => {
    const type = !!slot.parentId ? EventType.Task : EventType.VM;
    this.props.setActiveEvent({ id: slot.slotId, name: slot.slotName, type });
  };

  toggleExpandFunc = (schedulerData: SchedulerData, slotId: any) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  eventItemTemplateResolver = (
    schedulerData: SchedulerData,
    event: ITimelineEvent,
    bgColor: string,
    isStart: boolean,
    isEnd: boolean,
    mustAddCssClass: string,
    mustBeHeight: any,
    agendaMaxEventWidth: any,
  ) => {
    const titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);

    let showActual = false;
    if (!!event.actual_start_time && !!event.actual_stop_time) {
      showActual = true;
    }

    const plannedStartTime = event.scheduled_start_time.getTime();
    const plannedEndTime = (event.scheduled_stop_time || event.scheduled_start_time).getTime();

    const actualStartTime = (event.actual_start_time || event.scheduled_start_time).getTime();
    const actualEndTime = (event.actual_stop_time || event.actual_start_time || event.scheduled_start_time).getTime();

    const eventStartTime = plannedStartTime <= actualStartTime ? plannedStartTime : actualStartTime;
    const eventEndTime = plannedEndTime >= actualEndTime ? plannedEndTime : actualEndTime;

    // Scheduler component shows 30 minute timeslots in day view. 24 hour slots in other view modes.
    const viewSlotTotalTime = schedulerData.viewType === ViewTypes.Day ? 30 * 60 * 1000 : 24 * 60 * 60 * 1000;

    //
    const viewSlotStartTime =
      schedulerData.viewType === ViewTypes.Day
        ? moment(eventStartTime)
            .startOf('hour')
            .add(moment(eventStartTime).minutes() >= 30 ? 30 : 0, 'minutes')
            .valueOf()
        : moment(eventStartTime).startOf('day').valueOf();

    const viewTotalTime = Math.ceil((eventEndTime - viewSlotStartTime) / viewSlotTotalTime) * viewSlotTotalTime;

    const plannedStartPoint = plannedStartTime - viewSlotStartTime;
    const plannedRunTime = plannedEndTime - plannedStartTime;

    const actualStartPoint = actualStartTime - viewSlotStartTime;
    const actualRunTime = actualEndTime - actualStartTime;

    return (
      <Wrapper
        key={event.id}
        isActive={event.id === this.props.activeEvent?.id}
        height={mustBeHeight}
        maxWidth={agendaMaxEventWidth}
      >
        <Planned startTime={plannedStartPoint} runtime={plannedRunTime} totalTime={viewTotalTime}></Planned>
        {showActual && (
          <Actual
            status={event.status || 0}
            startTime={actualStartPoint}
            runtime={actualRunTime}
            totalTime={viewTotalTime}
          ></Actual>
        )}
      </Wrapper>
    );
  };

  eventItemPopoverTemplateResolver = (
    schedulerData: SchedulerData,
    eventItem: ITimelineEvent,
    title: string,
    start: moment.Moment,
    end: moment.Moment,
    statusColor: string,
  ) => {
    const timeElapsed =
      eventItem.actual_start_time && eventItem.actual_stop_time
        ? moment
            .duration(moment(eventItem.actual_stop_time).diff(moment(eventItem.actual_start_time)))
            .asMinutes()
            .toFixed(0)
        : 'N/A';

    const scheduledStart = moment(eventItem.scheduled_start_time).format('HH:mm');
    const scheduledEnd = moment(eventItem.scheduled_stop_time).format('HH:mm');
    const actualStart = eventItem.actual_start_time ? moment(eventItem.actual_start_time).format('HH:mm') : 'N/A';
    const actualEnd = eventItem.actual_stop_time ? moment(eventItem.actual_stop_time).format('HH:mm') : 'N/A';

    const scheduledStartUTC = moment(eventItem.scheduled_start_time).utc().format('HH:mm');
    const scheduledEndUTC = moment(eventItem.scheduled_stop_time).utc().format('HH:mm');
    const actualStartUTC = eventItem.actual_start_time
      ? moment(eventItem.actual_start_time).utc().format('HH:mm')
      : 'N/A';
    const actualEndUTC = eventItem.actual_stop_time ? moment(eventItem.actual_stop_time).utc().format('HH:mm') : 'N/A';

    return (
      <div style={{ width: '300px' }}>
        <Grid container>
          <Grid container item>
            <Grid item xs={2}>
              <div className="status-dot" style={{ backgroundColor: selectStatusColor(eventItem.status) }} />
            </Grid>
            <Grid item xs={10}>
              <span className="header2-text" title={title}>
                {title}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PopoverItem>
              <b>{this.props.t('label-time-reserved')}: </b>
              <span className="header1-text">
                {scheduledStart} - {scheduledEnd}
              </span>
            </PopoverItem>
          </Grid>
          <Grid item xs={12}>
            <PopoverItem>
              {this.props.t('label-time-reserved-utc')}
              <span className="header2-text">
                {scheduledStartUTC} - {scheduledEndUTC}
              </span>
            </PopoverItem>
          </Grid>
          {eventItem.type === 'actual' && (
            <>
              <Grid item xs={12}>
                <PopoverItem>
                  <b>{this.props.t('label-time-executed')}: </b>
                  <span className="header1-text">
                    {actualStart} - {actualEnd}
                  </span>
                </PopoverItem>
              </Grid>
              <Grid item xs={12}>
                <PopoverItem>
                  {this.props.t('label-time-executed-utc')}
                  <span className="header2-text">
                    {actualStartUTC} - {actualEndUTC}
                  </span>
                </PopoverItem>
              </Grid>
            </>
          )}
          {eventItem.type === 'actual' && (
            <Grid item xs={12}>
              <PopoverItem>
                <b>{this.props.t('label-time-elapsed')} (min): </b>
                <span className="header1-text">{timeElapsed}</span>
              </PopoverItem>
            </Grid>
          )}
          {eventItem.type === 'actual' && (
            <Grid item xs={12}>
              <PopoverItem>
                <b>{this.props.t('label-error-count')}: </b>
                <span className="header1-text">{eventItem.errorCount || 0}</span>
              </PopoverItem>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };
}

export default DragDropContext(HTML5Backend)(Timeline);
