/**
 * Format to N decimals unless the number is a whole number
 */
const FormatToNDecimals = (value: any, n: number) => {
  if (typeof value === 'number' && value % 1 !== 0) {
    return value.toFixed(n);
  } else if (typeof value === 'undefined') {
    return '';
  } else {
    return value;
  }
};

export default FormatToNDecimals;
