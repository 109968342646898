import React from 'react';
import { Typography } from '@material-ui/core';
import { ICostWindowChartProps } from './ChartProps';

const CostWindowChart = (props: ICostWindowChartProps) => {
  const { totalCost } = props;
  const styles = { color: '#206FB7', fontSize: '8vh', paddingTop: '8vh', display: 'flex', justifyContent: 'center' };

  const renderNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  return (
    <Typography variant="h1" component="h2" style={styles}>
      {renderNumber(totalCost as number)}€
    </Typography>
  );
};

export default CostWindowChart;
