/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FETCH_TASKS } from '../../../../redux/actions/task';
import { isActionPending, getAllTasks } from '../../../../redux/reducers';
import { ITask, IState } from '../../../../types/types';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface IComponentProps {
  customerId: string;
}

const TaskList = (props: IComponentProps) => {
  const { customerId } = props;
  const tasks = useSelector<IState, ITask[]>((state: IState) => getAllTasks(state, customerId), shallowEqual);
  const tasksFetching = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, FETCH_TASKS),
    shallowEqual,
  );
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Tasks
      </Typography>
      {tasksFetching && <CircularProgress />}
      {!tasksFetching && (
        <List>
          {tasks.map((item: ITask) => (
            <ListItem key={item.task_guid}>
              {/* <ListItemText primary={`${item.task_name}`} /> */}
              <Button
                variant="contained"
                size="large"
                color="secondary"
                // onClick={() => props.setCustomerId(item.customer_guid)}
              >
                {`${item.task_name}`}
              </Button>
            </ListItem>
          ))}
        </List>
      )}
      {/* {!tasksFetching && (
        <Select 
          className={classes.formControl}
          value={age}
          onChange={handleChange}
        
        >
          {tasks.map((item: ITask) => (
            <ListItem key={item.task_guid}>
              <ListItemText primary={`${item.task_name}`} />
            </ListItem>
          ))}
        </Select>
      )} */}
    </>
  );
};

export default TaskList;
