import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import Feature, { IFeature } from './Feature';

interface IProps {
  group: IFeatureGroup;
  readonly: boolean;
}

export interface IFeatureGroup {
  name: string;
  translateName: boolean;
  features: IFeature[];
}

type Props = IProps;

const FeatureGroup = (props: Props) => {
  const { group, readonly } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ textAlign: 'left' }}>{group.translateName ? t(group.name) : group.name}</Typography>
        </Grid>
        <Grid container spacing={0} item xs={12}>
          {group.features.map((feature) => (
            <Feature key={feature.name} feature={feature} readonly={readonly} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FeatureGroup;
