import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ButtonGroupContainer from './ButtonGroupContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      background: '#2F518A',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
      '&:hover': {
        backgroundColor: '#4c75ba',
        color: '#FFF',
      },
    },
    buttonCancel: {
      marginLeft: theme.spacing(2),
      background: '#FFFFFF',
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.12)',
      },
    },
    disabled: {},
  }),
);

interface IProps {
  disabled: boolean;
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  editRendered?: boolean;
  dirtySave?: any;
  onDirtySave?: any;
}

type Props = IProps;

const EditCancelSave: React.FC<Props> = (props) => {
  const { disabled, isEditing, onEdit, onCancel, editRendered, dirtySave } = props;
  const classes = useStyles();

  const handleEdit: () => void = () => {
    onEdit();
  };

  const handleCancel: () => void = () => {
    onCancel();
  };

  if (editRendered === false) {
    return null;
  } else
    return (
      <>
        <ButtonGroupContainer>
          {!dirtySave && isEditing ? (
            <Button
              classes={{ root: classes.button, disabled: classes.disabled }}
              variant="contained"
              color="primary"
              disabled={disabled}
              type="submit"
            >
              Save
            </Button>
          ) : null}
          {!!dirtySave && isEditing ? (
            <Button
              classes={{ root: classes.button, disabled: classes.disabled }}
              variant="contained"
              color="primary"
              disabled={disabled}
              //onClick={onDirtySave}
              type="submit"
            >
              DIRTY SAVE
            </Button>
          ) : null}
          {isEditing && (
            <Button
              classes={{ root: classes.buttonCancel, disabled: classes.disabled }}
              variant="outlined"
              color="primary"
              disabled={disabled}
              type="reset"
              onClick={(e) => handleCancel()}
            >
              Cancel
            </Button>
          )}
          {!isEditing && (
            <Button
              classes={{ root: classes.button, disabled: classes.disabled }}
              variant="contained"
              color="primary"
              role="edit"
              disabled={disabled}
              onClick={(e) => handleEdit()}
            >
              Edit
            </Button>
          )}
        </ButtonGroupContainer>
      </>
    );
};

export default EditCancelSave;
