import { fetchRolesActionCreator } from '../actions/role';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { IRoleCollection, IRoleState, IDictionary, INormalizedBase, Role } from '../../types/types';

import { Success } from 'typescript-fsa';
import { combineReducers } from 'redux';

const initialState: IRoleState = {
  byId: {},
  ids: [],
};

const byIdHandler = (
  byId: IDictionary<IRoleCollection>,
  payload: Success<void, INormalizedBase<IRoleCollection, string>>,
) => {
  if (payload.result.byId) {
    return { ...byId, ...payload.result.byId };
  }
  return byId;
};

const byId = reducerWithInitialState<{}>(initialState.byId).cases([fetchRolesActionCreator.done], byIdHandler);

const idsHandler = (ids: string[], payload: Success<void, INormalizedBase<IRoleCollection, string>>) => {
  if (payload.result.ids && payload.result.ids.length > 0) {
    return Array.from(new Set(ids.concat(payload.result.ids)));
  }
  return ids;
};

const ids = reducerWithInitialState<string[]>(initialState.ids).cases([fetchRolesActionCreator.done], idsHandler);

export default combineReducers({
  byId,
  ids,
});

export const getRolesForScope: (state: IRoleState, scope: string) => Role[] = (state, scope) => {
  return state.byId[scope]?.roles || [];
};
