import { useQuery } from 'react-query';
import axios from 'axios';
import moment from 'moment';
import { apiUrl, apiVersion } from '../../../configs/apiConfig';
import { acquireSilentToken } from '../APIManagementQueries/acquireSilentToken';
import { IEvent } from '../../../types/types';

const GetEvents = (customerGuid: string, from?: Date, to?: Date) => {
  const fromDate = from?.toISOString() || moment().add(-6, 'months').startOf('month').toISOString();
  const toDate = to?.toISOString() || new Date().toISOString();
  return useQuery(
    ['getEvents', customerGuid, from, to],
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(apiUrl + '/api/events', {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          'api-version': apiVersion,
          customerId: customerGuid,
          from: fromDate,
          to: toDate,
        },
      });
      const d: IEvent[] = data;
      return d;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
};

export default GetEvents;
