import { Role } from '../../../types/types';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface ITabItem {
  displayName: string;
  component: React.FunctionComponent | JSX.Element;
  roles?: Role[];
  userCustomerPermissions?: string[] | undefined | null;
}

export interface IComponentProps {
  tabs: ITabItem[];
  selectedParentTab: string;
  setRefresh?: (state: boolean) => void;
  isRefreshing?: boolean;
}

export const a11yProps = (index: number) => {
  return {
    id: `tab-view-${index}`,
    'aria-controls': `tab-panel-${index}`,
  };
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const filterPermissions: (tabs: ITabItem[], check: (roles: Role[]) => boolean) => ITabItem[] = (tabs, check) => {
  return tabs.reduce((p: ITabItem[], c: ITabItem): ITabItem[] => {
    let hasPermission = check(c.roles || ['all']);
    if (c.displayName === 'menu-sub-secrets') {
      if (!c.userCustomerPermissions?.includes('secrets/read')) {
        hasPermission = false;
      }
    }
    if (hasPermission) {
      p.push(c);
    }
    return p;
  }, []);
};
