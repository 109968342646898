import React from 'react';
import DatePicker, { DayRange, Day } from 'react-modern-calendar-datepicker';

function Calendar() {
  const [dayRange, setDayRange] = React.useState<DayRange>({
    from: null,
    to: null,
  });
  const [days, setDays] = React.useState<Day[]>([]);

  return (
    <>
      <h3>This is calendar component</h3>
      <DatePicker value={dayRange} onChange={setDayRange} />
      <DatePicker value={days} onChange={setDays} />
    </>
  );
}

export default Calendar;
