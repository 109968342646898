import { API_ACTION } from './constants';
import { AsyncActionCreators } from 'typescript-fsa';
import HttpMethod, { ResponseType } from '../../services/api/httpMethod';

/*

This file exports basic building blocks to API actions.
Extend ApiActionMetadata interface if you need additional functionality.

*/

export interface ApiAction<P, S, E> {
  type: string;
  payload?: P;
  meta: ApiActionMetadata<P, S, E>;
}

export interface ApiActionMetadata<P, S, E> {
  method: HttpMethod;
  params?: P;
  resource: string;
  actions: AsyncActionCreators<P, S, E>;
  responseType?: ResponseType;
}

export function createApiAction<P, S, E>(
  method: HttpMethod,
  resource: string,
  actions: AsyncActionCreators<P, S, E>,
  data?: P,
  responseType?: ResponseType,
): ApiAction<P, S, E> {
  return {
    type: API_ACTION,
    payload: data,
    meta: {
      method,
      params: data,
      resource,
      actions,
      responseType,
    },
  };
}
