import React, { useEffect, useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { getUser } from '../../services/auth/authService';

const AadCallback = (props: RouteComponentProps) => {
  const [isFetchingUser, setIsFetchingUser] = useState(true);

  useEffect(() => {
    const user = getUser();
    if (Boolean(user)) {
      const localUrl = window.sessionStorage.getItem('local-url') || '/';
      window.sessionStorage.removeItem('local-url');
      navigate(localUrl);
    }

    setIsFetchingUser(false);
  }, []);

  if (isFetchingUser) {
    // Getting id token
    return null;
  }

  return (
    <div>
      <p>Sorry, something went wrong with login</p>
    </div>
  );
};

export default AadCallback;
