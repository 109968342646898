import React from 'react';
import { Legend, XAxis, YAxis, LineChart, Line, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { splitCamelCase } from '../../../../../../UTILS';

type ComponentProps = {
  data: any;
  dataKeys: string[];
  legendLabel: string;
  xAxisLabel?: string;
  fullscreenSize?: boolean;
};

const DLineChart = (props: ComponentProps) => {
  const { data, dataKeys, xAxisLabel, fullscreenSize } = props;

  const colors = ['#8884d8', '#82ca9d', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#cd84d8'];

  return (
    <>
      <ResponsiveContainer width="100%" height={!!fullscreenSize ? 594 : '100%'}>
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisLabel} />
          <YAxis />
          <Tooltip />
          <Legend />
          {dataKeys.map((key, index) => (
            <Line key={index} name={splitCamelCase(key)} type="monotone" dataKey={key} stroke={colors[index]} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default DLineChart;
