import api from './middleware/api';
import asyncMiddleware from './middleware/async';
import normalize from './middleware/normalize';
import createRootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';

/**
 * Create the root redux store
 * Reducer functions are defined in separate files for each slice of the store
 * Middleware is used for handling api calls, normalization of api call results and keeping track of ongoing async operations
 */

/**
 * getDefaultMiddleware adds thunk and other redux toolkit middleware
 * immutable and serializable are default toolkit middleware for development
 * At the moment immutableCheck fails on appState reducer
 */
const store = configureStore({
  reducer: createRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(asyncMiddleware, normalize, api),
});

export type AppDispatch = typeof store.dispatch;

export type AppStore = ReturnType<typeof configureStore>;

export default store;
