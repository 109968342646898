import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormFieldSet } from '../../../FormFields';
import { useTranslation } from 'react-i18next';

const MonitorGraph = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <FormFieldSet legend={t('label-graphs')}></FormFieldSet>
    </Grid>
  );
};

export default MonitorGraph;
