import React from 'react';
import { FormFieldSet } from '../../FormFields';
import { ITask } from '../../../types/types';

interface IProps {
  task: ITask;
}

type Props = IProps;

const Database: React.FC<Props> = (props) => {
  return (
    <>
      <FormFieldSet legend="Database Information">
        <div style={{ minHeight: '300px', width: '100%' }}></div>
      </FormFieldSet>
      <FormFieldSet legend="Data View">
        <div style={{ minHeight: '300px', width: '100%' }}></div>
      </FormFieldSet>
    </>
  );
};

export default Database;
