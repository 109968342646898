import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

interface IComponentProps {
  handleStart: () => void;
  handleRestart: () => void;
  handleDeallocate: () => void;
  handleRedeploy: () => void;
  disabled: boolean;
}

const StartRestartReallocate = (props: IComponentProps) => {
  const { handleStart, handleRestart, handleDeallocate, handleRedeploy, disabled } = props;

  const styleObject = disabled
    ? { backgroundColor: '#757575', color: 'white', marginRight: '1vh' }
    : { backgroundColor: '#2F518A', color: 'white', marginRight: '1vh' };

  const { t } = useTranslation();
  return (
    <Grid container alignItems="flex-end" direction="row">
      <Button style={styleObject} variant="contained" onClick={handleStart} disabled={disabled}>
        {t('label-start')}
      </Button>

      <Button style={styleObject} variant="contained" onClick={handleRestart} disabled={disabled}>
        {t('label-restart')}
      </Button>

      <Button style={styleObject} variant="contained" onClick={handleDeallocate} disabled={disabled}>
        {t('label-deallocate')}
      </Button>

      <Button style={styleObject} variant="contained" onClick={handleRedeploy} disabled={disabled}>
        {t('label-redeploy')}
      </Button>
    </Grid>
  );
};

export default StartRestartReallocate;
