const INITIAL_STATE: string[] = [];

const apiMessageReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case 'CREATE_APIMESSAGE':
      return state.concat(action.data);
    case 'CLEAR_APIMESSAGE':
      return [];
    default:
      return state;
  }
};

export default apiMessageReducer;

export const getAllApiMessages = (state: any) => {
  return state;
};
