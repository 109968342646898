import { createApiAction } from './common';
import { schema as Schema } from 'normalizr';
import actionCreatorFactory from 'typescript-fsa';
import {
  ICollection,
  ICollectionCreate,
  ICollectionUpdate,
  ICollectionMemberCreate,
  ICollectionMemberUpdate,
  INormalizedBase,
} from '../../types/types';
import HttpMethod from '../../services/api/httpMethod';

const actionCreator = actionCreatorFactory();

/**
 * Define schema for normalizing api response
 * The schema can be customised for you own needs, see normalizr docs
 *
 * This schema normalizes an array of objects to a dictionary of objects and an array if ids
 * If the property that defines the object identity is not named 'id' the idAttribute needs to be set
 */
const entityName = 'collection';
const entity = new Schema.Entity(
  entityName,
  {},
  {
    idAttribute: 'id',
  },
);
const schema = [entity];

/**
 * The type of action for a fetch operation
 */
export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';

/**
 * Define action creators for an async operation
 */
export const fetchCollectionsActionCreator = actionCreator.async<void, INormalizedBase<ICollection, number>>(
  FETCH_COLLECTIONS,
  {
    normalize: {
      entityName,
      schema,
    },
  },
);

/**
 * Creates an async action that defines an api call
 */
export const getCollectionsApiRequest = () =>
  createApiAction(HttpMethod.GET, 'api/collections', fetchCollectionsActionCreator);

/**
 * The type of action for an update operation
 */
export const CREATE_COLLECTION = 'CREATE_COLLECTION';

/**
 * Define action creators for an async operation
 */
export const createCollectionActionCreator = actionCreator.async<ICollectionCreate, ICollection>(CREATE_COLLECTION);

/**
 * Creates an async action that defines an api call
 */
export const createCollectionApiRequest = (data: ICollectionCreate) =>
  createApiAction(HttpMethod.POST, `api/collections`, createCollectionActionCreator, data);

/**
 * The type of action for an update operation
 */
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';

/**
 * Define action creators for an async operation
 */
export const updateCollectionActionCreator = actionCreator.async<ICollectionUpdate, void>(UPDATE_COLLECTION);

/**
 * Creates an async action that defines an api call
 */
export const updateCollectionApiRequest = (data: ICollectionUpdate) =>
  createApiAction(HttpMethod.PUT, `api/collections/${data.id}`, updateCollectionActionCreator, data);

/**
 * The type of action for an update operation
 */
export const CREATE_COLLECTION_MEMBER = 'CREATE_COLLECTION_MEMBER';

/**
 * Define action creators for an async operation
 */
export const createCollectionMemberActionCreator = actionCreator.async<ICollectionMemberCreate, void>(
  CREATE_COLLECTION_MEMBER,
);

/**
 * Creates an async action that defines an api call
 */
export const createCollectionMemberApiRequest = (collectionId: number, data: ICollectionMemberCreate) =>
  createApiAction(
    HttpMethod.POST,
    `api/collections/${collectionId}/members`,
    createCollectionMemberActionCreator,
    data,
  );

/**
 * The type of action for an update operation
 */
export const UPDATE_COLLECTION_MEMBER = 'UPDATE_COLLECTION_MEMBER';

/**
 * Define action creators for an async operation
 */
export const updateCollectionMemberActionCreator = actionCreator.async<ICollectionMemberUpdate, void>(
  UPDATE_COLLECTION_MEMBER,
);

/**
 * Creates an async action that defines an api call
 */
export const updateCollectionMemberApiRequest = (
  collectionId: number,
  memberId: string,
  data: ICollectionMemberUpdate,
) =>
  createApiAction(
    HttpMethod.PUT,
    `api/collections/${collectionId}/members/${memberId}`,
    updateCollectionMemberActionCreator,
    data,
  );
