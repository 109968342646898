import { useMutation } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

const DeletePermission = () => {
  return useMutation('permission', async (payload: any) => {
    const bearerToken = await acquireSilentToken();
    return await axios.delete(apimConf.apiManagementEndpoint + '/permissions/delete-permission', {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
      },
      data: {
        id: payload.id,
      },
    });
  });
};

export default DeletePermission;
