import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  data: any;
  disabled?: boolean;
  colorUsed?: boolean;
}

const ExportExcel = (props: IComponentProps) => {
  const { data, disabled, colorUsed } = props;

  const { t } = useTranslation();

  const exportExcel = () => {
    /* convert state to workbook */
    if (!!data) {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, `dataset.xlsx`);
    }
  };

  return (
    <Tooltip title={t('label-export-excel')}>
      <IconButton disabled={disabled} onClick={exportExcel}>
        {!colorUsed ? (
          <FileCopyIcon fontSize="small" />
        ) : (
          <FileCopyIcon fontSize="small" style={{ color: '#2F518A' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ExportExcel;
