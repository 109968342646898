/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import FileInput from 'react-simple-file-input';
import styled from 'styled-components';
import XLSX from 'xlsx';
import config from './config';
import enums from './enums';

import { IDataNode } from '../../../../types/types';

const TreeViewNodeContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
`;

const BranchContainer = styled.div`
  display: inline-block;
  margin: 0px 0px;
`;

const Label = styled.div<{ isSelected: boolean }>`
  display: inline-block;
  padding: 1px 10px;
  background-color: ${({ isSelected }) => (isSelected ? '#dddddd' : '#ffffff')};
  margin: 0px 20px 0px 0px;
  vertical-align: middle;
  cursor: pointer;
`;

const NodeSymbol = styled.div`
  display: inline-block;
  background-color: ${config.colors.mostBlue};
  width: 21px;
  height: 21px;
  margin: 0px 6px 0px -10px;
  border-radius: 21px;
  vertical-align: middle;
  cursor: pointer;
`;

const FoldSymbol = styled.div`
  display: inline-block;
  color: ${config.colors.treeLineColor};
  border: 1px solid ${config.colors.treeLineColor};
  background-color: ${config.colors.background};
  width: 17px;
  height: 19px;
  line-height: 19px;
  margin: 0px 31px 0px -50px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
`;

const BranchVertical = styled.div<{ isLastChild: boolean }>`
  display: inline-block;
  border-right: 1px solid ${config.colors.treeLineColor};
  width: 40px;
  height: ${({ isLastChild }) => (isLastChild ? '21px' : '41px')};
  margin-top: ${({ isLastChild }) => (isLastChild ? '-20px' : '0px')};
  opacity: ${({ hidden }) => (hidden ? '0' : '1.0')};
  vertical-align: middle;
`;

const BranchOpenVertical = styled.div`
  display: inline-block;
  border-right: 1px solid ${config.colors.treeLineColor};
  width: 0px;
  height: 10px;
  margin: 31px 16px 0px -16px;
  vertical-align: top;
`;

const BranchHorizontal = styled.div`
  display: inline-block;
  border-top: 1px solid ${config.colors.treeLineColor};
  width: 40px;
  height: 0px;
  margin: 0px;
  vertical-align: middle;
`;

const NodeButton = styled.div`
  color: #dddddd;
  display: inline-block;
  margin: 0px 5px;
  cursor: pointer;
  &:hover * {
    margin: -1px 1px 1px -1px;
    color: #000000;
  }
  vertical-align: middle;
`;

const allowedFileTypesXls = [
  '.xls',
  '.xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
const allowedFileTypesCsv = [
  '.csv',
  // 'application/csv',
  // 'text/csv',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // 'application/vnd.ms-excel',
];

type Props = IDataNode;

const TreeViewNode = (props: Props) => {
  // static propTypes = {
  //   uniqueId: propTypes.string.isRequired,
  //   name: propTypes.string.isRequired,
  //   status: propTypes.string.isRequired,
  //   type: propTypes.string.isRequired,
  //   onNodeSelect: propTypes.func.isRequired,
  //   onNodeAddChild: propTypes.func.isRequired,
  //   onNodeAddChildren: propTypes.func.isRequired,
  //   onNodeAddSibling: propTypes.func.isRequired,
  //   onNodeRemove: propTypes.func.isRequired,
  //   selectedUniqueId: propTypes.string,
  //   indexPath: propTypes.array.isRequired,
  //   children: propTypes.array,
  //   isLastChild: propTypes.bool,
  // };

  // static defaultProps = {
  //   value: '',
  //   isLastChild: false,
  // };

  // state = {
  //   cancelButtonClicked: false,
  //   progressBarVisible: false,
  //   progressPercent: 0,
  //   isOpen: false,
  // };

  const {
    uniqueId,
    name,
    indexPath,
    children,
    status,
    type,
    onNodeSelect,
    onNodeAddChild,
    onNodeAddSibling,
    onNodeAddChildren,
    onNodeRemove,
    selectedUniqueId,
    isLastChild,
  } = props;

  const readOnly = props.readOnly || type === enums.nodeType.ROBOTROOT;

  const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(false);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const cancelButtonClicked: (event: ProgressEvent<FileReader>, file: File) => boolean = () => isCancelButtonClicked;

  const resetCancelButtonClicked: (event: ProgressEvent<FileReader>, file: File) => void = () =>
    setIsCancelButtonClicked(false);

  const showInvalidFileTypeMessage: (file: File) => void = (file) =>
    window.alert('Tried to upload invalid filetype ' + file.type);

  const showProgressBar: (event: ProgressEvent<FileReader>, file: File) => void = (event, file) =>
    setProgressBarVisible(true);

  const updateProgressBar: (event: ProgressEvent<FileReader>, file: File) => void = (event, file) =>
    setProgressPercent((event.loaded / event.total) * 100);

  const fileIsIncorrectFiletypeXls: (file: File) => boolean = (file) =>
    allowedFileTypesXls.indexOf(file.type) === -1 ? true : false;

  const fileIsIncorrectFiletypeCsv: (file: File) => boolean = (file) =>
    allowedFileTypesCsv.indexOf(file.type) === -1 ? true : false;

  const handleFileSelected: (event: ProgressEvent<FileReader>, file: File) => void = (event, file) => {
    const workbook = XLSX.read(event?.target?.result, { type: 'binary' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
    const values = [];
    for (var C = range.s.c; C <= range.e.c; ++C) {
      const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: 0 })];
      if (cell && cell.v && cell.v !== '') values.push(cell.v);
    }
    props.onNodeAddChildren(props.uniqueId, values);
  };

  const isSelected = uniqueId === selectedUniqueId;
  const isRoot = indexPath.length === 0;

  const fileInputProps = {
    readAs: 'binary',
    style: { display: 'none' },
    abortIf: cancelButtonClicked,
    onAbort: resetCancelButtonClicked,
    onCancel: showInvalidFileTypeMessage,
    onLoad: handleFileSelected,
    onLoadStart: showProgressBar,
    onProgress: updateProgressBar,
  };

  return (
    <TreeViewNodeContainer>
      {!isRoot && (
        <BranchContainer>
          {indexPath.map((pos, step) => (
            <BranchVertical key={step} isLastChild={isLastChild && step === indexPath.length - 1} />
          ))}
          <BranchHorizontal />
          {children && !!children.length && (
            <FoldSymbol onClick={() => setIsOpen((isOpen) => !isOpen)}>{isOpen ? '-' : '+'}</FoldSymbol>
          )}
          <NodeSymbol onClick={() => onNodeSelect(uniqueId)} />
          {isOpen && children && !!children.length && <BranchOpenVertical />}
          <Label isSelected={isSelected} onClick={() => onNodeSelect(uniqueId)}>
            {name}
          </Label>
          {isSelected && !readOnly && (
            <NodeButton onClick={() => onNodeAddSibling(uniqueId)}>
              <FontAwesomeIcon icon="plus-circle" />
            </NodeButton>
          )}
          {isSelected && !readOnly && (
            <NodeButton onClick={() => onNodeAddChild(uniqueId)}>
              <FontAwesomeIcon icon="project-diagram" />
            </NodeButton>
          )}
          {isSelected && !readOnly && (
            <NodeButton>
              <label>
                <FileInput
                  {...fileInputProps}
                  {...{ cancelIf: fileIsIncorrectFiletypeXls, accept: allowedFileTypesXls }}
                />
                <FontAwesomeIcon icon="file-excel" />
              </label>
            </NodeButton>
          )}
          {isSelected && !readOnly && (
            <NodeButton>
              <label>
                <FileInput
                  {...fileInputProps}
                  {...{ cancelIf: fileIsIncorrectFiletypeCsv, accept: allowedFileTypesCsv }}
                />
                <FontAwesomeIcon icon="file-csv" />
              </label>
            </NodeButton>
          )}
          {isSelected && !readOnly && (
            <NodeButton onClick={() => onNodeRemove(uniqueId)}>
              <FontAwesomeIcon icon="trash" />
            </NodeButton>
          )}
        </BranchContainer>
      )}
      {(isOpen || isRoot) &&
        children &&
        children.map((node, index: number) => (
          <TreeViewNode
            {...node}
            key={node.uniqueId}
            onNodeSelect={onNodeSelect}
            onNodeAddChild={onNodeAddChild}
            onNodeAddChildren={onNodeAddChildren}
            onNodeAddSibling={onNodeAddSibling}
            onNodeRemove={onNodeRemove}
            selectedUniqueId={selectedUniqueId}
            indexPath={indexPath.concat(index)}
            isLastChild={index === children.length - 1}
            readOnly={readOnly}
          />
        ))}
    </TreeViewNodeContainer>
  );
};

export default TreeViewNode;
