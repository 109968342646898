/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../../FormFrames/SplitFormFrame';
import { FormSelect, FormTextArea, FormTextField } from '../../../FormFields';
import { useCollection } from '../../../Hooks/Collections';
import { useGetApiEffect, usePutApiEffect } from '../../../Hooks/Hooks';
import { ICustomer } from '../../../../types/types';
import { useTranslation } from 'react-i18next';
import { IComputeFormObject as IFormObject, IResourceUpdate } from './ComputeTypes';
import TypeSpecificInfo from './TypeSpecificInfo';
import SituationMonitor from './SituationMonitor';
import MonitorGraph from './MonitorGraph';
import AddCompute from './AddCompute';
import StartRestartReallocate from '../../../FormButtonGroups/StartRestartReallocate';
import ListOnsiteVms from '../../../../services/api/APIManagementQueries/Resources/ListOnsiteVms';
import ListVms from '../../../../services/api/APIManagementQueries/Resources/ListVms';
import { FormControlLabel, Switch } from '@mui/material';
import ToggleAutomaticShutdown from '../../../../services/api/APIManagementQueries/Resources/ToggleAutomaticShutdown';
import AlertWindow from '../../../Common/ReusableComponents/AlertWindow';

const functionId = '00000000-0000-0000-0000-000000000000';

const operationGuid = 'fbaeb387-a8b0-4348-9b24-ab56d4db18b4';
// const getCurrentDscId = '76fc65d2-c53a-46b2-97de-b6505887bdff';
interface IProps {
  customer: ICustomer;
}

interface IResourceOperation {
  customer_id: string;
  id: string;
  data: {
    resource_type: string;
    resource_data: [
      {
        resource_name: string;
        resource_operation: string;
      },
    ];
  };
}

const validationSchema = yup.object<IFormObject>({
  name: yup.string().required(),
  description: yup.string().nullable(),
  type: yup.string().required(),
  category: yup.string().required(),
  level: yup.string().required(),
  status: yup.string(),
  enable_shutdown: yup.string(),
});

const getAddComponent: (
  id: string,
  customerId: string,
  dscConfigs: any,
) => (props: { values?: IFormObject; onCancel: () => void }) => React.ReactNode = (id, customerId, dscConfigs) => {
  return (props) => {
    return (
      <AddCompute
        id={id}
        customerId={customerId}
        data={props.values}
        onCancel={props.onCancel}
        dscOptions={dscConfigs}
      />
    );
  };
};

type Props = IProps;

const Compute: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { customer } = props;
  const vmCategory = 'production';
  const environmentTypes = useCollection(2);
  const statusValues = useCollection(7);
  const [data, setData] = useState<IFormObject[]>([]);
  const [shutdownEnabled, setShutdownEnabled] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);

  /**
   * API
   */

  const {
    data: dataRegular,
    isLoading: isLoadingRegular,
    refetch: refetchRegularVms,
    isRefetching: isRefetchingRegularVms,
  } = ListVms(customer.customer_guid);
  const {
    data: dataOnsite,
    isLoading: isLoadingOnsite,
    refetch: refetchOnsiteVms,
    isRefetching: isRefetchingOnsiteVms,
  } = ListOnsiteVms(customer.customer_guid);
  const toggleShutdown = ToggleAutomaticShutdown();
  const [dscConfigs, isLoadingDsc, isLoadErrorDsc, getDsconfigs] = useGetApiEffect<IFormObject[]>(
    `api/resources/dscconfigs?customerId=${customer.customer_guid}&vmCategory=${vmCategory}`,
    null,
  );
  const [response, isUpdating, isUpdateError, putData] = usePutApiEffect<IResourceUpdate | IResourceOperation, void>(
    `api/resources/environments`,
    null,
  );

  useEffect(() => {
    getDsconfigs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingRegular && !isLoadingOnsite) {
      if (!!dataRegular && !!dataOnsite) {
        setData(dataRegular.concat(dataOnsite));
      } else if (!!dataRegular) {
        setData(dataRegular);
      } else if (!!dataOnsite) {
        setData(dataOnsite);
      }
    }
  }, [isLoadingRegular, isLoadingOnsite]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataItem: (id: string | number) => IFormObject | undefined = (id) => {
    if (!!data) {
      const vm = data.find((d) => d.name === id);
      if (!!vm?.enable_shutdown) {
        if (vm.enable_shutdown === 'enabled') {
          setShutdownEnabled(true);
        } else if (vm.enable_shutdown === 'disabled') {
          setShutdownEnabled(false);
        } else {
          setShutdownEnabled(null);
        }
      } else {
        setShutdownEnabled(null);
      }
      return vm;
    }
  };

  const handleSubmit: (data: IFormObject) => void = (data) => {
    const payload: IResourceUpdate = {
      id: functionId,
      customer_id: customer.customer_guid,
      data: {
        resource_type: 'vm',
        resource_data: [
          {
            os_type: data.type,
            resource_name: data.name,
            resource_description: data.description,
            resource_category: data.category,
            resource_level: data.level,
            resource_status: data.status,
          },
        ],
      },
    };
    putData(payload);
  };

  const handleShutdownToggleClick = (vm_name: string) => {
    toggleShutdown.mutate(
      {
        machine_name: vm_name,
        customer_guid: customer.customer_guid,
        state: !shutdownEnabled,
      },
      {
        onSuccess: () => {
          setShutdownEnabled(!shutdownEnabled);
        },
        onError: (error: unknown) => {
          setErrorMessage(String(error));
          setDisplayAlert(true);
        },
      },
    );
  };

  const handleResourceOperation: (data: IFormObject, operation: string) => void = (data, operation) => {
    const operationPayload: IResourceOperation = {
      id: operationGuid,
      customer_id: customer.customer_guid,
      data: {
        resource_type: 'vm',
        resource_data: [
          {
            resource_name: data.name,
            resource_operation: operation,
          },
        ],
      },
    };
    putData(operationPayload);
    refetchRegularVms();
    refetchOnsiteVms();
  };

  return (
    <>
      <AlertWindow message={errorMessage} severity="error" display={displayAlert} setDisplay={setDisplayAlert} />
      <SplitFormFrame<IFormObject>
        tableLabel={t('label-environments')}
        formLabel={t('label-virtual-machine')}
        validationSchema={validationSchema}
        data={!!data ? data : []}
        idProperty="name"
        properties={[
          { key: 'name', label: t('label-machine-name') },
          { key: 'type', label: t('label-type') },
          { key: 'category', label: t('label-category') },
          { key: 'level', label: t('label-level') },
          { key: 'status', label: t('label-status') },
          { key: 'enable_shutdown', label: t('label-shutdown-enabled') },
        ]}
        isLoading={isLoadingRegular || isLoadingOnsite || isRefetchingRegularVms || isRefetchingOnsiteVms}
        isUpdating={isUpdating || toggleShutdown.isLoading}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ isEditing, values }) => (
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormTextField name="name" label={t('label-machine-name')} readonly={!isEditing} />
                <FormSelect name="type" label={t('label-type')} readonly={true} value={values.type}>
                  <option key={-1} value={values.type}>
                    {values.type}
                  </option>
                  {environmentTypes.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </FormSelect>
                <FormSelect name="status" label={t('label-status')} readonly={true} value={values.status}>
                  <option key={-1} value={values.status}>
                    {values.status}
                  </option>
                  {statusValues.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </FormSelect>
                <FormTextArea name="description" label={t('label-machine-description')} readonly={!isEditing} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TypeSpecificInfo isEditing={isEditing} dscOptions={dscConfigs} />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!shutdownEnabled ? true : false}
                      onChange={() => handleShutdownToggleClick(values.name)}
                      disabled={!isEditing || shutdownEnabled === null || toggleShutdown.isLoading}
                    />
                  }
                  label={t('label-shutdown-enabled') as string}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SituationMonitor values={values} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MonitorGraph />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ position: 'absolute', bottom: 14 }}>
              <StartRestartReallocate
                handleStart={() => handleResourceOperation(values, 'start')}
                handleRestart={() => handleResourceOperation(values, 'restart')}
                handleDeallocate={() => handleResourceOperation(values, 'stop')}
                handleRedeploy={() => handleResourceOperation(values, 'redeploy')}
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        )}
        renderAdd={getAddComponent(functionId, customer.customer_guid, dscConfigs)}
      ></SplitFormFrame>
    </>
  );
};

export default Compute;
