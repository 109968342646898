import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import MDButton from '../../../Common/ReusableComponents/MDButton';
import React, { useState } from 'react';
import { IPermission, IPermissionHandlingOperation } from '../../../../types/types';
import { getAvailablePermissions } from './RolePermissionFilteringUtils';

interface IProps {
  open: boolean;
  task: IPermissionHandlingOperation;
  handleClose: (dialog: IPermissionHandlingOperation) => void;
  handleAdd: (permission: IPermission | undefined) => void;
  handleDelete: (permission: IPermission | undefined) => void;
  translator: any;
  allPermissions: IPermission[];
  permissionsForRole: string[] | undefined;
}

const RolePermissionsDialogWindow = (props: IProps) => {
  const { open, task, handleClose, handleAdd, handleDelete, translator, allPermissions, permissionsForRole } = props;

  const availablePermissions = getAvailablePermissions(task, allPermissions, permissionsForRole);
  const [permission, setPermission] = useState<IPermission | undefined>(undefined);

  const handlePermissionChange = (e: any) => {
    let permissionName = e.target.value;
    setPermission(availablePermissions.find((p) => p.name === permissionName));
  };

  const handleSave = () => {
    setPermission(undefined);
    if (task === 'delete') {
      handleDelete(permission);
    } else {
      handleAdd(permission);
    }
  };

  const handleCancel = () => {
    setPermission(undefined);
    handleClose(task);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {task === 'add' ? translator('label-add-permission') : translator('label-delete-permission')}
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel>{translator('label-select-permission')}</InputLabel>
            <Select
              autoWidth={true}
              defaultValue={translator('label-select-permission')}
              value={!!permission ? permission.name : ''}
              onChange={handlePermissionChange}
              inputProps={{
                name: 'permission',
              }}
            >
              {availablePermissions.map((p) => (
                <MenuItem key={p.id} value={p.name}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton disabled={!!!permission} label={translator('button_form_save')} handleClick={handleSave} />
        <Button onClick={handleCancel} color="primary">
          {translator('button_form_cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RolePermissionsDialogWindow;
