import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

interface IRefreshButtonProps {
  onClick: () => void;
  isRefreshing: boolean | undefined;
}

const RefreshButton = (props: IRefreshButtonProps) => {
  const { onClick, isRefreshing } = props;
  const wrapperStyles = { flex: 1, display: 'flex', flexDirection: 'row-reverse', paddingRight: '2.5em' };
  const iconStyles = { '&:hover': { backgroundColor: 'transparent' } };
  return (
    <Box sx={wrapperStyles}>
      <Button onClick={onClick} disabled={!!isRefreshing}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          disabled={!!isRefreshing}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          sx={iconStyles}
        >
          <AutorenewIcon />
        </IconButton>
      </Button>
    </Box>
  );
};

export default RefreshButton;
