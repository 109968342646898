/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import { FormSelect, FormTextField } from '../../FormFields';
import FormTwinMultiSelect from '../../FormFields/FormTwinMultiSelect';
import { useGetApiEffect, usePutApiEffect } from '../../Hooks/Hooks';
import { ICustomer } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import AddUser from './Users/AddUser';
import { useQuery } from 'react-query';
import apiRequest from '../../../services/api/apiService';
import HttpMethod from '../../../services/api/httpMethod';
import { IUserUpdate } from './Users/Types';
import UpdateUser from './Users/UpdateUser';

const functionId = '233C48C3-FCE5-4CEF-85D9-B73C87359F68';

interface IProps {
  customer: ICustomer;
}

interface IUser {
  id: string;
  name: string;
  type: string;
  email: string;
  status: number;
  roles: number[];
}

interface IRole {
  role_id: number;
  role_name: string;
}

interface IListUser {
  id: string;
  name: string;
  type: string;
  email: string;
  status: number;
  roles: string[];
}
const validationSchema = yup.object({
  id: yup.string(),
  name: yup.string().required(),
  type: yup.string(),
  email: yup.string(),
  status: yup.number(),
  roles: yup.array(yup.string()),
  /*yup.array(
    yup.object({
      role_name: yup.string(),
      role_id: yup.number(),
    }),
  ),*/
});

const getAddComponent: (
  id: string,
  customerId: string,
  roles: IRole[],
) => (props: { values?: IListUser; onCancel: () => void }) => React.ReactNode = (id, customerId, roles) => {
  return (props) => {
    const payload = {
      first_name: '',
      last_name: '',
      email: '',
      type: '',
      user_id: '',
      roles: roles.filter((role) => props.values?.roles.includes(role.role_name)) || [],
    };
    return <AddUser id={id} customerId={customerId} roles={roles} data={payload} onCancel={props.onCancel} />;
  };
};

type Props = IProps;

const UsersAndRoles: React.FC<Props> = (props) => {
  const { customer } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [refreshKey, setRefreshKey] = useState<number>(0);

  useEffect(() => {
    getRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUsers();
  }, [refreshKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation();

  const [roles, isLoadingRoles, isRolesError, getRoles] = useGetApiEffect<IRole[]>(
    `customers/${customer.customer_guid}/roles`,
    [],
    'pythonApi',
  );

  const [users, isLoadingUsers, isUsersError, getUsers] = useGetApiEffect<IUser[]>(
    `customers/${customer.customer_guid}/users`,
    [],
    'pythonApi',
  );

  const [response, isUpdatingUsers, isUpdateUserError, putData] = usePutApiEffect<IUserUpdate, void>(
    `api/aad/users`,
    null,
  );

  const getDataItem: (id: string | number) => IListUser | undefined = (id) => {
    return rolesToNames(users).find((d) => d.id === id);
  };

  const handleSubmit: (data: IUser) => void = (data) => {
    const names = data.name.split(' ');
    const first_name = names.length === 2 ? names[0] : '';
    const last_name = names.length === 2 ? names[1] : '';
    const payload: IUserUpdate = {
      object_id: data.id,
      first_name,
      last_name,
      email: data.email,
      roles: data.roles,
    };
    putData(payload);
  };

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleRefresh = () => {
    setRefreshKey(refreshKey + 1);
  };

  const filterData = () => {
    if (searchValue.length > 0) {
      return users.filter((user) => {
        user.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    } else {
      return users;
    }
  };
  const rolesToNames = (users: IUser[]) => {
    return users.map((user) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        type: user.type,
        status: user.status,
        roles: user.roles.map((role) => {
          var r = roles.find((r) => r.role_id == role);
          return typeof r == 'undefined' ? 'testi' : r.role_name;
        }),
      };
    });
  };
  return (
    <>
      <SplitFormFrame<IListUser>
        tableLabel={t('label-users')}
        formLabel={t('label-user-information')}
        validationSchema={validationSchema}
        data={rolesToNames(filterData())}
        idProperty="id"
        properties={[
          { key: 'name', label: t('label-user-name') },
          { key: 'email', label: t('label-email') },
          { key: 'roles', label: t('label-rolegroups') },
          { key: 'type', label: t('label-type') },
        ]}
        isLoading={isLoadingRoles || isLoadingUsers}
        isUpdating={isUpdatingUsers}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        duplicateDisabled={true}
        handleSearch={handleSearch}
        searchValue={searchValue}
        render={({ values, isEditing }) => (
          <UpdateUser
            customerId={customer.customer_guid}
            roles={roles}
            isEditing={false}
            onCancel={() => null}
            onRefresh={handleRefresh}
          />
        )}
        renderAdd={getAddComponent(functionId, customer.customer_guid, roles)}
      ></SplitFormFrame>
    </>
  );
};

export default UsersAndRoles;
