import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import SplitFormFrame from '../../FormFrames/SplitFormFrame';
import FormTwinMultiSelect from '../../FormFields/FormTwinMultiSelect';
import { ISecret, ILinkedSecret } from '../../../types/types';
import { useGetApiEffect } from '../../Hooks/Hooks';

interface IProps {
  customerId: string;
  name: string;
  secrets: ISecret[];
}

const validationSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required(),
  linked_secrets: yup.array(yup.string()),
});

type Props = IProps;

const LinkedSecrets: React.FC<Props> = (props) => {
  const { customerId, name, secrets } = props;

  const secretNames = secrets.map((secret) => secret.name);

  const [data, isLoading, isError, getData] = useGetApiEffect<ILinkedSecret[]>( // eslint-disable-line @typescript-eslint/no-unused-vars
    `api/secrets/linked?customerId=${customerId}&name=${name}`,
    null,
  );

  useEffect(() => {
    getData();
  }, [customerId, name]); // eslint-disable-line react-hooks/exhaustive-deps

  const linkedSecrets =
    data &&
    data.map((d) => {
      return {
        name: d.name,
        type: d.type,
        linked_secrets: data.map((l) => l.name),
      };
    });

  const getDataItem: (id: string | number) => ILinkedSecret | undefined = (id) => {
    return linkedSecrets.find((d) => d.name === id);
  };

  const handleSubmit: (data: ILinkedSecret) => void = (data) => {};

  return (
    <>
      <SplitFormFrame<ILinkedSecret>
        tableLabel="Linked Secrets"
        formLabel="Linked Secrets"
        validationSchema={validationSchema}
        data={linkedSecrets}
        idProperty="name"
        properties={[
          { key: 'name', label: 'Secret Name' },
          { key: 'type', label: 'Link Type' },
        ]}
        fetchDataItem={getDataItem}
        onSubmit={handleSubmit}
        render={({ values, isEditing }) => (
          <Grid container spacing={2}>
            <FormTwinMultiSelect
              name="linked_secrets"
              label="Linked Secrets"
              availableValues={secretNames}
              readonly={!isEditing}
            ></FormTwinMultiSelect>
          </Grid>
        )}
      ></SplitFormFrame>
    </>
  );
};

export default LinkedSecrets;
