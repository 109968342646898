// General
import React from 'react';

// MUI
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// ChartWrapper helpers
import { ChartWrapperTab, IChartBoxFullscreenHeaderProps } from '../../ChartWrapperTypes';
import ExportExcel from '../../../../../FormButtonGroups/ExportExcel';

/**
 * Displays the header content of the fullscreen view, that is:
 *  - title
 *  - chart icon that displays the chart in full screen mode
 *  - icons of the additional tabs that were provided to ChartWrapper
 *  - 'Export to Excel' feature if excelData was provided
 *  - close icon
 */
const ChartBoxFullscreenHeader = (props: IChartBoxFullscreenHeaderProps) => {
  const { title, tabs, excelData, changeTab, onClose } = props;
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        {tabs.map((tab: ChartWrapperTab) => (
          <IconButton key={tab.id} onClick={() => changeTab(tab.id)}>
            {tab.icon}
          </IconButton>
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {!!excelData ? <ExportExcel data={excelData} /> : null}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChartBoxFullscreenHeader;
