import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as yup from 'yup';
import ModalFormFrame, { SetIsSubmittingFn } from '../../../FormFrames/ModalFormFrame';
import { FormFieldArray, FormTextArea, FormTextField } from '../../../FormFields';
import { getPortalLanguages, isActionPending } from '../../../../redux/reducers';
import {
  ICollectionMember,
  ICollectionMemberCreate,
  ICollectionValue,
  ILanguage,
  IState,
} from '../../../../types/types';
import { createCollectionMemberApiRequest, CREATE_COLLECTION_MEMBER } from '../../../../redux/actions/collection';
import LanguageArrayItem from '../LanguageArrayItem';

interface IProps {
  collectionId: number;
  isMultilanguage: boolean;
  data?: IFormObject;
  onCancel: () => void;
}

interface IFormObject extends Omit<ICollectionMember, 'values'> {
  values: IFormObjectValue[];
}

interface IFormObjectValue extends ICollectionValue {
  label: string;
}

const validationSchema = yup.object<IFormObject>({
  id: yup.string(),
  description: yup.string(),
  deleted: yup.boolean(),
  values: yup.array<IFormObjectValue>(),
});

type Props = IProps;

const AddCollectionMember: React.FC<Props> = (props) => {
  const { collectionId, isMultilanguage, data, onCancel } = props;
  const languages = useSelector<IState, ILanguage[]>(getPortalLanguages, shallowEqual);

  const isLoading = useSelector<IState, boolean>(
    (state: IState) => isActionPending(state, CREATE_COLLECTION_MEMBER),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const formData: IFormObject = {
    id: data?.id || '',
    description: data?.description || '',
    deleted: data?.deleted || false,
    values: data
      ? isMultilanguage
        ? languages.map((language, index) => {
            const value = data.values.find((v) => v.language_code === language.code);
            return {
              value: value?.value || '',
              language_code: value?.language_code || language.code,
              label: `Value text - ${language.name}`,
            };
          })
        : data.values.map((v) => {
            return {
              value: v.value,
              language_code: v.language_code,
              label: 'Value text',
            };
          })
      : isMultilanguage
      ? languages.map((language, index) => {
          return {
            value: '',
            language_code: language.code,
            label: `Value text - ${language.name}`,
          };
        })
      : [
          {
            value: '',
            language_code: 'xx-xx',
            label: 'Value text',
          },
        ],
  };

  const [isSubmittingFn, setIsSubmittingFn] = useState<SetIsSubmittingFn>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmittingFn !== undefined) {
      isSubmittingFn(isLoading);
    }
    if (!isSubmitting && isLoading) {
      setIsSubmitting(true);
    }
    if (isSubmitting && !isLoading && onCancel !== undefined) {
      onCancel();
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit: (data: IFormObject, setIsSubmitting: SetIsSubmittingFn) => void = useCallback(
    (data, setIsSubmitting) => {
      setIsSubmittingFn(() => setIsSubmitting);

      const payload: ICollectionMemberCreate = {
        collectionId,
        id: data.id,
        description: data.description,
        values: data.values.map((v) => {
          return {
            value: v.value,
            language_code: v.language_code,
          };
        }),
      };

      dispatch(createCollectionMemberApiRequest(collectionId, payload));
    },
    [dispatch], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ModalFormFrame<IFormObject>
      label="label-form-add-collection"
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      render={({ values, isSubmitting }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField name="id" label="Value ID:" readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextArea name="description" label="Description" readonly={isSubmitting} />
          </Grid>
          <Grid item xs={12}>
            <FormFieldArray name="values">
              {values.values.map((value, index) => (
                <LanguageArrayItem
                  key={index}
                  hiddenName={`values.${index}.language_code`}
                  hiddenValue={value.language_code}
                  name={`values.${index}.value`}
                  label={value.label}
                  readonly={isSubmitting}
                />
              ))}
            </FormFieldArray>
          </Grid>
        </Grid>
      )}
    ></ModalFormFrame>
  );
};

export default AddCollectionMember;
