import { useQuery } from 'react-query';
import axios from 'axios';
import { acquireSilentToken } from '../acquireSilentToken';
import { apimConf } from '../../../../configs/apiConfig';

/**
 * A query hook for fetching permissions for a given role
 * https://react-query.tanstack.com/reference/useQuery
 * @param serviceProviderId
 * @param roleId
 * @returns useQuery hook
 */
const GetPermissionsForRole = (serviceProviderId: string, roleId: number, language_code: string) => {
  return useQuery(
    ['permissionsForRole', roleId, serviceProviderId],
    async () => {
      const bearerToken = await acquireSilentToken();
      const { data } = await axios.get(apimConf.apiManagementEndpoint + `/permissions/get-role-permissions`, {
        headers: {
          'Ocp-Apim-Subscription-Key': apimConf.apiManagementSubscriptionKey,
          Authorization: `Bearer ${bearerToken}`,
        },
        params: {
          serviceprovider_id: String(serviceProviderId),
          role_id: String(roleId),
        },
      });
      return data;
    },
    {
      enabled: !!roleId || !!language_code,
      cacheTime: 0,
    },
  );
};

export default GetPermissionsForRole;
