import React, { useState } from 'react';
import CustomerList from './CustomerList';
import Tasks from '../Tasks';
import ChartCard from '../Content/ChartCard';
import { Grid, Card, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../Calendar/Calendar';
// import TimeLine from "react-gantt-timeline";

const useStyles = makeStyles({
  root: {
    // maxWidth: 450,
    backgroundColor: '#e6faff',
    padding: '1px',
    margin: '10 2px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Customer = () => {
  const classes = useStyles();
  const [customerId, setCustomerId] = useState<string>('');

  return (
    <>
      <Calendar></Calendar>
      <Grid container>
        <Grid item sm>
          <Paper className={classes.root}>
            <Card className={classes.root}>
              <CustomerList setCustomerId={setCustomerId} />
            </Card>
          </Paper>
        </Grid>
        <Grid item sm>
          <Paper className={classes.root}>
            <Card className={classes.root}>
              <Tasks customerId={customerId} />
            </Card>
          </Paper>
        </Grid>
      </Grid>
      <ChartCard></ChartCard>
    </>
  );
};

export default Customer;
